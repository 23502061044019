import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { CTAButton } from "../../common";
import dompurify from "dompurify";
import { clearCookie } from "../../helper/util";

export function MyxConfirmationPage({ contents, ...props }) {
    const { fields } = contents;
    const { errorMessages, pageBody, failurePageBody, pageTitle, pageIcon, buttonText } = fields;
    const [pageMessage, setPageMessage] = useState(pageBody);
    const { t } = props;
    const orderConfirmationDetails = useSelector((state) => state.checkout.orderConfirmationDetails);
    const { deviceActivationFailed } = useSelector((state) => state.activation);
    const {
        order: { id },
        hasWarnings,
        warningMessages,
    } = orderConfirmationDetails || { order: {} };

    const onContinueBtn = () => {
        // handle onclick of button
    };
    useEffect(() => {
        if (hasWarnings) {
            if (!errorMessages) {
                return;
            }
            const { errorCode } = warningMessages && warningMessages[0];
            const messageObject = errorMessages.find((message) => {
                const formattedTitle = message.fields.title.replaceAll(" ", "_").toUpperCase();
                if (errorCode === formattedTitle) {
                    return true;
                }
                return false;
            });
            const messageContent =
                (messageObject && messageObject.fields.desktopContent.content[0].content[0].value) ||
                (messageObject && messageObject.fields.mobileContent.content[0].content[0].value);
            setPageMessage(messageContent);
        } else {
            deviceActivationFailed ? setPageMessage(failurePageBody) : setPageMessage(pageBody);
        }
    }, [hasWarnings]);

    useEffect(() => {
        localStorage.removeItem("activationCode");
        localStorage.removeItem("email");
        localStorage.removeItem("productCode");
        localStorage.removeItem("offerId");
        sessionStorage.removeItem("oid");
        sessionStorage.removeItem("coachId");
        sessionStorage.removeItem("coachSelection");
        clearCookie(["webSourceCode", "userData"]);
    }, []);

    const sanitizer = dompurify.sanitize;
    const orderNo = t("order-no");

    return (
        <Fragment>
            <section className="myx-confirmation-page container">
                <div className="myx-container w-100">
                    <div className="myx-panel">
                        {pageIcon ? <img src={pageIcon.fields.file.url} className="confirmation-icon" /> : ""}

                        {pageTitle ? <h1 dangerouslySetInnerHTML={{ __html: sanitizer(pageTitle) }} /> : ""}

                        {pageMessage ? <p dangerouslySetInnerHTML={{ __html: sanitizer(pageMessage) }} /> : ""}
                        {buttonText ? (
                            <CTAButton
                                outerBtnClass="text-center"
                                innerBtnClass={"continue-btn"}
                                buttonName={t(buttonText)}
                                onButtonClick={() => onContinueBtn()}
                            />
                        ) : (
                            ""
                        )}

                        {id ? (
                            <div className="order-confirmation-number">
                                <span>{orderNo}</span> {id}{" "}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

MyxConfirmationPage.propTypes = {
    contents: PropTypes.object,
    isTabletFlow: PropTypes.bool,
    props: PropTypes.object,
    t: PropTypes.func,
};
