import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dompurify from "dompurify";
import PropTypes from "prop-types";
import { ProductCarousel } from "./product-carousel";
import { Loader, CTAButton } from "../../common";
import { offersActions } from "../offers-page/data/offers-page-action";
import { BIKES_PDP_IMG_PATH } from "../../constants/urls";
import { FieldRenderer } from "../../contentful/component-renderer";
import "./myx-details.scss";

export const MyxProductDetailScreen = ({ t, ...props }) => {
    const sanitizer = dompurify.sanitize;
    const dispatch = useDispatch();
    const { content } = props;
    const { offerId, pageContents } = content && content.fields;
    const { sessionId, productDetails } = useSelector((state) => state.offers);
    const [images, setImages] = useState([]);
    const pageContent = pageContents && pageContents?.fields?.content;

    const renderContent = (id) => {
        const item = pageContent.find((item) => item.fields.id === id);
        if (item) return item;
        else return null;
    };

    useEffect(() => {
        sessionId &&
            dispatch({
                type: offersActions.GET_OFFERS_DETAILS,
                payload: offerId,
            });
    }, [sessionId]);

    useEffect(() => {
        if (productDetails) {
            setImages(
                productDetails.productImages.map((item) => ({
                    original: BIKES_PDP_IMG_PATH + item,
                    thumbnail: BIKES_PDP_IMG_PATH + item,
                })),
            );
        }
    }, [productDetails]);

    return (
        <main className="myx-pdp-page">
            {productDetails ? (
                <section className="product-container">
                    <div className="product-image">
                        <ProductCarousel images={images} />
                    </div>
                    <div className="product-detail">
                        <h1 className="product-title">{productDetails.cdpTitle}</h1>
                        <h2 className="product-price">{productDetails.skus[0].baseListPrice}</h2>
                        <FieldRenderer contents={renderContent("seeTheValue")} />
                        <div className="product-description">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: sanitizer(productDetails.productPDPDescription),
                                }}
                            />
                        </div>
                        <FieldRenderer contents={renderContent("weightSetInfo")} />
                        <CTAButton buttonName={t("checkout")} outerBtnClass="checkout-button" />
                    </div>
                </section>
            ) : (
                <section>
                    <Loader className="loader-wrapper" isToShowLoader={true} />
                </section>
            )}
        </main>
    );
};

MyxProductDetailScreen.propTypes = {
    t: PropTypes.func,
    content: PropTypes.object,
};
