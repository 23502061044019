import { checkoutActions } from "./checkout-page-action";
const initialState = {
    userDetails: null,
    orderConfirmationDetails: null,
    threeDSAuthentication: null,
    cybsDeviceDataCollection: null,
    stepUpResponse: {},
    defaultCreditCard: null,
    referringCoachDetails: null,
    isCVViFrame: true,
};

const checkout = (state = initialState, action) => {
    switch (action.type) {
        case checkoutActions.SET_CHECKOUT_STATE:
            return {
                ...state,
                ...action.payload,
            };
        case checkoutActions.ORDER_CONFIRMATION_DETAILS:
            return { ...state, orderConfirmationDetails: action.payload };
        case checkoutActions.CHECKOUT_FORM_ERROR:
            return { ...state, formErrors: action.payload };
        case checkoutActions.SET_ZIPCODE_DETAILS:
            return { ...state, zipCodeDetails: { ...state.zipCodeDetails, ...action.payload } };
        case checkoutActions.SET_CYBERSOURCE_KEY:
            return { ...state, cyberSourceKey: action.payload };
        case checkoutActions.SET_MERLIN_CONFIG:
            return { ...state, ...action.payload };
        case checkoutActions.MICROFORM_ERROR:
            return { ...state, microFormError: action.payload };
        case checkoutActions.UPDATE_ENROLL_AUTH_3DS_RESULT:
            return { ...state, threeDSAuthentication: action.payload };
        case checkoutActions.CYBS_DEVICE_DATA_COLLECTION:
            return { ...state, cybsDeviceDataCollection: action.payload };
        case checkoutActions.STEPUP_RESPONSE:
            return { ...state, stepUpResponse: action.payload };
        case checkoutActions.LOAD_PAGE:
            return { ...state, loadPage: action.payload };
        case checkoutActions.DEFAULT_CREDIT_CARD:
            return { ...state, defaultCreditCard: action.payload };
        case checkoutActions.ASSIGN_TO_LEADWHEEL:
            return { ...state, ...action.payload };
        case checkoutActions.REFERRING_COACH_DETAILS:
            return { ...state, referringCoachDetails: action.payload };
        default:
            return state;
    }
};
export default checkout;
