
export const offersActions = {
    ADD_ITEM_TO_CART: "ADD_ITEM_TO_CART",
    GET_CART_ABANDONED_ORDER_DETAILS: "GET_CART_ABANDONED_ORDER_DETAILS",
    GET_CART_DETAILS: "GET_CART_DETAILS",
    GET_OFFERS_DETAILS: "GET_OFFERS_DETAILS",
    GET_SESSION_ID: "GET_SESSION_ID",
    REMOVE_ITEM_FROM_CART: "REMOVE_ITEM_FROM_CART",
    SAVE_SURVEY_RESPONSE: "SAVE_SURVEY_RESPONSE",
    SET_IS_LOADING: "SET_IS_LOADING",
    SET_OFFERS_DETAILS: "SET_OFFERS_DETAILS",
    UPDATE_ABANDONED_PROFILE: "UPDATE_ABANDONED_PROFILE",
    ADD_SUBSKU_TO_CART: "ADD_SUBSKU_TO_CART",
};
