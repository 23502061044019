import React from "react";
import PropTypes from "prop-types";
import "./select-dropdown.scss";
export const SelectDropDown = ({
    id,
    label,
    error,
    name,
    onChange,
    disableElement,
    dValue,
    floatLabel,
    options,
    displayKey,
    displayName,
    selectIcon = "",
    selectIconAction,
    outerStyle,
}) => {
    const hasFloatingLabel = floatLabel && options;
    const onChangeValue = (e) => {
        try {
            const { value, name, options } = e.target;
            const element = e.target;
            const text = options[element.selectedIndex].text;
            onChange(name, value, text);
        } catch (err) {
            console.error("select dropdown onChangeValue error:::", err);
        }
    };
    return (
        <div className={`select-drop-down ${outerStyle}`}>
            {hasFloatingLabel && (
                <label htmlFor="selectDropdown" className={`${floatLabel ? "floating-label" : ""} pt-1`}>
                    {floatLabel}
                </label>
            )}
            <select
                value={dValue}
                name={name}
                className={"select-element" + (error ? " error-border" : "") + (hasFloatingLabel ? " dropdown-selected" : "")}
                id={id ? id : ""}
                onChange={onChangeValue}
                disabled={disableElement}
            >
                {label && <option value="">{label}</option>}
                {options &&
                    options.map((option, index) => {
                        const { disabled, hidden } = option;
                        return (
                            <option key={index} value={option[displayKey]} disabled={disabled} hidden={hidden}>
                                {option[displayName]}
                            </option>
                        );
                    })}
            </select>
            {selectIcon && <i className={"fa " + selectIcon + " selectIcon"} onClick={selectIconAction}></i>}
        </div>
    );
};

SelectDropDown.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    disableElement: PropTypes.bool,
    dValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    htmlFor: PropTypes.string,
    displayKey: PropTypes.string, // Pass the option value key from the Obj
    displayName: PropTypes.string, // Pass the option name key from the Obj
    options: PropTypes.array, // List of options array
    selectIcon: PropTypes.string,
    selectIconAction: PropTypes.func,
    outerStyle: PropTypes.string,
    floatLabel: PropTypes.string,
};
