import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { CTAButton } from "../../common";
import { validateField } from "../common/validator";
import { pageRouterActions } from "../../component/page-router/data/page-router-action";
import { MYX_LOGIN_PATH } from "../../constants/urls";
import { setCookie } from "../../helper/util";
import { CreateAccount } from "../../model";
import { checkoutActions } from "../checkout-page/data/checkout-page-action";
import { encryptData } from "../common/util";
import { EmailPanel, EmailLookupStatus } from "../create-account/components";

export function MyxForgotPasswordPage({ contents, ...props }) {
    const dispatch = useDispatch();
    const { fields } = contents;
    const { content, t } = props;
    const { primaryProductCode } = content.fields;
    const emailRef = useRef();
    const [disableSendBtn, setDisableSendBtn] = useState(false);
    const [isRegisteredUser, setRegisteredUser] = useState(false);
    const [isContinueButtonEnabled] = useState(false);
    const { userDetails } = useSelector((state) => state.checkout);
    const { userDetail } = useSelector((state) => state.pageRouter);
    const [emailLookupStatus, setEmailLookupStatus] = useState(EmailLookupStatus.NOT_TRIGGED);
    const [doEmailLookup] = useState(false);
    const [wasEmailSent, setEmailSent] = useState(false);
    const [accountFormData, setAccountFormData] = useState(new CreateAccount());
    const [formErrors, setAccountFormErrors] = useState(new CreateAccount());
    const [redirectToNextPage] = useState(false);
    const [errorMessage, setError] = useState();
    const onChange = (name, value) => {
        if (errorMessage !== "") {
            setError(null);
        }
        setAccountFormData({ ...accountFormData, [name]: value });
    };
    const onContinueBtn = () => {
        const { email } = accountFormData;
        if (!wasEmailSent) {
            const isEmailFormValid = !validateFormErrors("email", email);
            isEmailFormValid ? sendForgotPasswordEmail() : "";
        } else {
            goToLoginPage();
        }
    };
    const onEnter = (event) => {
        const { keyCode, charCode } = event || {};
        (keyCode || charCode) === 13 ? onContinueBtn() : null;
    };
    const setFormErrors = (name, value) => {
        setAccountFormErrors({ ...formErrors, [name]: value ? t(value) : "" });
    };
    const validateFormErrors = (name, value) => {
        const error = validateField({ name, value, hybridValidator: true });
        if (error) {
            setFormErrors(name, error);
            return true;
        } else {
            setFormErrors(name, "");
            return false;
        }
    };
    const encryptUserData = () => {
        const { email, password } = accountFormData;
        const userDetail = { ...userDetails, email, password };
        dispatch({ type: pageRouterActions.SET_USER_DETAILS, payload: userDetails });
        const userData = JSON.stringify(userDetail);
        const encipheredData = encryptData(userData);
        const date = new Date();
        date.setTime(date.getTime() + 60 * 60 * 1000);
        setCookie("userData", encipheredData, date);
    };
    const goToLoginPage = () => {
        encryptUserData();
        props.history.push(MYX_LOGIN_PATH);
    };
    const onForgotPasswordEmailSuccess = () => {
        setEmailSent(true);
        setDisableSendBtn(false);
    };
    const onForgotPasswordEmailFailure = () => {
        setEmailSent(false);
    };
    const sendForgotPasswordEmail = () => {
        setDisableSendBtn(true);
        const productCode = localStorage.getItem("productCode") || primaryProductCode;
        const activationCode = localStorage.getItem("activationCode") || "";
        dispatch({
            type: checkoutActions.SEND_FORGOT_PASSWORD_EMAIL,
            payload: {
                email: email.trim(),
                productCode,
                activationCode: activationCode,
            },
            callbacks: { success: onForgotPasswordEmailSuccess, failure: onForgotPasswordEmailFailure },
        });
    };
    const formProps = {
        emailLookupStatus,
        formData: accountFormData,
        formErrors: formErrors,
        isRegisteredUser,
        onChange,
        redirectToNextPage,
        setEmailLookupStatus,
        setFormErrors,
        setRegisteredUser,
        t,
        validateField,
    };
    const { email } = formProps.formData;
    const emailFieldHasValue = validateField({ name: "email", value: accountFormData.email }) ? "" : "has-value";

    const resetPasswordTxt = t("reset-password");
    const linkResetPasswordMsg = t("link-reset-password-msg");
    const enterEmail = t("enter-email");
    const resetPassword = t("reset-password");
    const ifAccountExistMsg = t("if-account-exist-msg");
    const continueBtn = t("continue-btn");
    const emailLookupFail = t("email-lookup-fail");
    useEffect(() => {
        if (userDetail) {
            const { email } = userDetail || {};
            email && onChange("email", email);
        }
    }, [userDetail]);
    return (
        <Fragment>
            <section className="myx-forgot-pass-page container">
                <div className="myx-container w-100">
                    <div className={"myx-panel"}>
                        {!wasEmailSent ? (
                            <div className="email-reset">
                                <h1>{fields.emailEntryPageTitle || resetPasswordTxt}</h1>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: fields.emailEntryPageDescription || linkResetPasswordMsg,
                                    }}
                                />
                                <EmailPanel ref={emailRef} {...formProps} floatLabel={enterEmail} onKeyPress={onEnter} />
                            </div>
                        ) : (
                            ""
                        )}
                        {wasEmailSent ? (
                            <div className={"email-sent-message"}>
                                <div className="success-check-mark">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                        <g fill="none" fillRule="evenodd">
                                            <g>
                                                <g>
                                                    <path
                                                        fill="#198754"
                                                        d="M24 42c2.47 0 4.79-.472 6.963-1.417 2.173-.944 4.086-2.243 5.74-3.898 1.655-1.654 2.951-3.565 3.89-5.731C41.53 28.787 42 26.469 42 24c0-2.47-.47-4.787-1.407-6.954-.939-2.166-2.238-4.08-3.899-5.74-1.66-1.66-3.577-2.96-5.75-3.899C28.772 6.47 26.451 6 23.981 6c-2.444 0-4.753.47-6.925 1.407-2.173.939-4.09 2.238-5.75 3.899-1.66 1.66-2.96 3.574-3.899 5.74C6.47 19.213 6 21.531 6 24c0 2.47.472 4.787 1.417 6.954.944 2.166 2.243 4.077 3.898 5.731 1.654 1.655 3.568 2.954 5.74 3.898C19.229 41.528 21.544 42 24 42zm-1.963-9.389c-.321 0-.614-.07-.88-.213-.265-.142-.515-.367-.75-.676l-4.333-5.296c-.16-.185-.278-.38-.352-.583-.074-.204-.11-.41-.11-.62 0-.42.147-.782.444-1.084.296-.303.654-.454 1.074-.454.259 0 .5.053.722.158.222.105.438.299.648.583l3.463 4.426 7.352-11.778c.358-.568.808-.852 1.352-.852.407 0 .774.13 1.102.39.327.258.49.604.49 1.036 0 .21-.046.417-.139.62-.092.204-.2.399-.324.584l-8.203 12.87c-.198.297-.426.519-.686.667-.259.148-.549.222-.87.222z"
                                                        transform="translate(-816.000000, -170.000000) translate(816.000000, 170.000000)"
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h1>{fields.successPageTitle || resetPassword}</h1>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: fields.successPageDescription || ifAccountExistMsg,
                                    }}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        <CTAButton
                            buttonName={continueBtn}
                            disableButton={disableSendBtn ? { disabled: "disabled" } : null}
                            icon={disableSendBtn ? "fa fa-spinner fa-spin d-inline-block" : ""}
                            innerBtnClass={emailFieldHasValue}
                            onButtonClick={() => onContinueBtn()}
                            outerBtnClass={wasEmailSent ? "email-sent" : isContinueButtonEnabled ? "enabled-color" : ""}
                        />
                    </div>
                </div>
                {emailLookupStatus === EmailLookupStatus.FAILED && doEmailLookup && (
                    <p
                        className="error-msg"
                        dangerouslySetInnerHTML={{
                            __html: emailLookupFail,
                        }}
                    ></p>
                )}
            </section>
        </Fragment>
    );
}

MyxForgotPasswordPage.propTypes = {
    content: PropTypes.object,
    contents: PropTypes.object,
    history: PropTypes.object,
    isTabletFlow: PropTypes.bool,
    props: PropTypes.object,
    t: PropTypes.func,
};
