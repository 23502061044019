import React, { Fragment } from "react";
import PropTypes from "prop-types";
import dompurify from "dompurify";

export function MyxSessionExpiredPage({ contents, isTabletFlow, ...props }) {
    const { fields } = contents;
    const { pageBody, pageTitle } = fields;

    const sanitizer = dompurify.sanitize;
    return (
        <Fragment>
            <section className="myx-session-expired container">
                <div className="myx-container w-100">
                    <div className="myx-panel">
                        <section className="session-expired-message">
                            {pageTitle ? <h1 dangerouslySetInnerHTML={{ __html: sanitizer(pageTitle) }} /> : ""}

                            {pageBody ? <p dangerouslySetInnerHTML={{ __html: sanitizer(pageBody) }} /> : ""}
                        </section>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

MyxSessionExpiredPage.propTypes = {
    contents: PropTypes.object,
    isTabletFlow: PropTypes.bool,
    props: PropTypes.object,
};
