import React from "react";
import dompurify from "dompurify";
import PropTypes from "prop-types";

export function SanitizeHTML({ html, tag = "p", className = "" }) {
    const sanitizer = dompurify.sanitize;
    return React.createElement(tag, { dangerouslySetInnerHTML: { __html: sanitizer(html) }, className }, null);
}
SanitizeHTML.propTypes = {
    tag: PropTypes.string,
    html: PropTypes.string.isRequired,
    className: PropTypes.string,
};
