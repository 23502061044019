import { useState, useEffect, useRef } from "react";

// custom hook used to execute a callback when the user is viewing the current element
const useIntersectionObserver = (options, callback) => {
    const containerRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const callBack = (entries) => {
        if (!entries) {
            return;
        }

        const [entry] = entries;
        if (!isVisible && entry.isIntersecting) {
            setIsVisible(entry.isIntersecting);
            if (callback && callback instanceof Function) {
                callback();
            }
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(callBack, options);
        if (containerRef.current && !isVisible) observer.observe(containerRef.current);
        return () => containerRef.current && observer.unobserve(containerRef.current);
    }, [containerRef, isVisible]);

    return [containerRef, isVisible];
};

export default useIntersectionObserver;
