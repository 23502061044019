import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";
export function SurveyIntroOutro(props) {
    const { fields, onStartButton, className } = props || {};
    return (
        <section className={`intro-outro-content ${className}`}>
            {fields && Object.keys(fields).map((field, index) => {
                return field && <FieldRenderer key={index} contents={fields[field]} onButtonClick={onStartButton} />;
            })}
        </section>
    )
}
SurveyIntroOutro.propTypes = {
    props: PropTypes.object
};
