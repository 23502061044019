import React from "react";
import PropTypes from "prop-types";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import VideoPlayerConfiguration from "../player-config";
import "../index.scss";

export const BrightcoveVideoContainer = ({ fields }) => {
    const { videoId } = fields;
    const onSuccess = function (success) {
        const playerRef = success.ref;
        if (playerRef) {
            playerRef.loop(true);
            playerRef.muted(true);
            playerRef.controls(false);
            playerRef.play();
        }
    };
    return (
        <div>
            <ReactPlayerLoader
                accountId={VideoPlayerConfiguration.ACCOUNT_ID}
                videoId={videoId}
                embedId={VideoPlayerConfiguration.EMBED_ID}
                playerId={VideoPlayerConfiguration.PLAYER_ID}
                onSuccess={onSuccess}
            />
        </div>
    );
};

BrightcoveVideoContainer.propTypes = {
    fields: PropTypes.object,
};

export const LegacyBrightcoveVideoContainer = ({ fields }) => {
    const { videoId } = fields;
    const onSuccess = function (success) {
        const playerRef = success.ref;
        if (playerRef) {
            playerRef.play();
        }
    };
    return (
        <div>
            <ReactPlayerLoader
                accountId={VideoPlayerConfiguration.ACCOUNT_ID}
                videoId={videoId}
                embedId={VideoPlayerConfiguration.EMBED_ID}
                playerId={VideoPlayerConfiguration.PLAYER_ID}
                onSuccess={onSuccess}
            />
        </div>
    );
};

LegacyBrightcoveVideoContainer.propTypes = {
    fields: PropTypes.object,
};
