import React, { useState, useRef } from "react";
import { CreditCardIframe, CyberSourceIframe, TextInput } from "../../../common";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ExpiryDate, validateMMYYFormat, isGBLocale } from "../../../helper/util";
import * as Constants from "../constants";

export function CreditCardDetails({
    t,
    onChange,
    setFormErrors,
    formErrors,
    formData,
    validateField,
    doFieldValidation,
    calculateTax,
    dispatch,
    alternateStyle,
}) {
    const { cyberSource, microFormError, isCVViFrame } = useSelector((state) => state.checkout);
    const [showInfoModal, setShowInfoModal] = useState();
    const postalCodeInputRef = useRef(null);
    const isCCDetailsError =
        doFieldValidation && (formErrors["ccNumber"] || formErrors["ccExpiry"] || !formData["ccMonth"] || !formData["ccYear"] || formErrors["ccCVV"])
            ? true
            : false;
    const onCCExpiryDetailsChange = (name, value) => {
        let details;
        if (value) {
            // formErrors && formErrors["ccExpiry"] && setFormErrors("ccExpiry", "");
            const result = validateMMYYFormat(value);
            const monthAndDate = result && result.split("/");
            details = {
                ccMonth: monthAndDate["0"],
                ccYear: monthAndDate["1"],
                ccExpDate: result,
            };
            onChange("", "", details);
        } else {
            details = {
                ccMonth: "",
                ccYear: "",
                ccExpDate: "",
            };
            onChange("", "", details);
        }
        const { ccMonth, ccYear } = details || {};
        if (doFieldValidation) {
            const isExpired = ExpiryDate(ccYear, ccMonth);
            if (isExpired || (!ccMonth && !ccYear)) {
                const ccE = isExpired ? t("card-expired") : t("exp-date-required");
                setExpDateErrors("", "", ccE);
            } else {
                const ccMonthErr = validateField({ name: "ccMonth", value: ccMonth });
                const ccYearErr = validateField({ name: "ccYear", value: ccYear });
                setExpDateErrors(ccMonthErr ? t(ccMonthErr) : "", ccYearErr ? t(ccYearErr) : "", "");
            }
        }
    };
    const setExpDateErrors = (ccM, ccY, ccE) => {
        const errors = {
            ccMonth: ccM,
            ccYear: ccY,
            ccExpiry: ccE,
        };
        setFormErrors("", "", errors);
    };
    const getNameField = (name, label) => {
        return (
            <TextInput
                name={name}
                id={name}
                floatLabel={t(label)}
                maxLength={30}
                error={formErrors[name]}
                onBlur={doFieldValidation ? validateField : null}
                setFormErrors={setFormErrors}
                dValue={formData[name]}
                onChange={onChange}
                autoComplete={Constants.noAutoComplete}
            />
        );
    };
    const handleBlur = (data) => {
        calculateTax(data);
        if (isGBLocale() && postalCodeInputRef.current) {
            postalCodeInputRef.current.setAttribute("data-postal-entered", postalCodeInputRef.current.value !== "");
        }
    };

    return (
        <section id="cust-cc-info" className={`cust-cc-info mt-4 ${alternateStyle ? "cc-details--alternate" : ""}`}>
            <div className="section-content position-relative">
                <div className="row m-0 cc-details__name">
                    <div className="col-6 pl-0 pr-2">{getNameField("firstName", "first-name")}</div>
                    <div className="col-6 pl-md-0 pr-0">{getNameField("lastName", "last-name")}</div>
                </div>
                <div className={`row mx-0 mb-3 credit-card-details cc-details__card ${isCCDetailsError ? "error-border" : ""}`}>
                    <div className="iframe-section pl-0 cc-details__card__cc-number">
                        {cyberSource ? (
                            <CyberSourceIframe
                                id="cardNumber-container"
                                onChange={onChange}
                                dispatch={dispatch}
                                formData={formData}
                                formErrors={formErrors}
                                setFormErrors={setFormErrors}
                                doFieldValidation={doFieldValidation}
                                t={t}
                                validateField={validateField}
                            />
                        ) : (
                            <CreditCardIframe
                                name="tokenframe"
                                id="tokenframe"
                                type="hidden"
                                error={t("cc-error")}
                                inputStyle={encodeURI(
                                    "body{margin: 0} input{outline:none;position:relative;top:8px;padding-top: .8125rem;padding-bottom: .8125rem;padding-left: 11px;display: block;margin: 0 0 .5625rem;box-sizing: border-box;border-radius: 0px;width: 100%;font-size: 14px;font-weight: 300;background-color: #151819;border: 0px solid rgb(182, 182, 182);color: rgb(255,255,255);height: auto;} input.error{border: 0px solid red} inpu:focus{border:0px;}",
                                )}
                                placeholder={t("cc-number")}
                                required={true}
                                additParams="cardlabel=+&invalidinputevent=true&highlightinvalidinput=true"
                                setFormErrors={setFormErrors}
                                formErrors={formErrors}
                                hintText={t("cc-accept")}
                                formData={formData}
                                onChange={onChange}
                            />
                        )}
                    </div>
                    <div className="d-flex justify-content-between exp-dd credit-card-exp-cvv-details cc-details__card__exp-and-ccv">
                        <TextInput
                            name="ccExpDate"
                            id="ccExpDate"
                            type="text"
                            inputType="mm/yy"
                            maxLength={5}
                            onChange={onCCExpiryDetailsChange}
                            onBlur={doFieldValidation ? () => onCCExpiryDetailsChange("ccExpDate", formData["ccExpDate"]) : null}
                            setFormErrors={setFormErrors}
                            dValue={formData["ccExpDate"]}
                            error={formErrors["ccYear"] ? true : false}
                            floatLabel={t("exp-month-year")}
                            autoComplete="cc-exp"
                        />
                        <span className="field-separeator">/</span>
                        <div className="d-flex cvv-row">
                            <div className="cvv-left-col">
                                {isCVViFrame ? (
                                    <div className="text-input cybersource-iframe">
                                        <label className={`cvv-iframe iframe-placeholder ${formData.floatCVVLabel ? "float" : ""}`}>{t("cvv")}</label>
                                        <div
                                            id="ccCVV_iFrame"
                                            className={`${doFieldValidation && formErrors && formErrors["ccCVV"] ? "error-border" : ""}`}
                                        ></div>
                                        <span className="cc-cvv-hint-element" onClick={() => setShowInfoModal(true)}>
                                            i
                                        </span>
                                    </div>
                                ) : (
                                    <TextInput
                                        name="ccCVV"
                                        id="ccCVV"
                                        type="type"
                                        inputType="number"
                                        maxLength={4}
                                        onChange={onChange}
                                        onBlur={doFieldValidation ? validateField : null}
                                        setFormErrors={setFormErrors}
                                        dValue={formData["ccCVV"]}
                                        error={formErrors["ccCVV"] ? true : false}
                                        floatLabel={t("cvv")}
                                        hintElement={
                                            <span className="cc-cvv-hint-element" onClick={() => setShowInfoModal(true)}>
                                                i
                                            </span>
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {showInfoModal && (
                        <div className="cvv-info-modal">
                            <div className="close-icon">
                                <span onClick={() => setShowInfoModal(false)}>
                                    <i className="fa fa-times"></i>
                                </span>
                            </div>
                            <div className="cvv-info">{t("cvv-info")}</div>
                        </div>
                    )}
                </div>
                {microFormError && (
                    <label
                        className="error-msg"
                        dangerouslySetInnerHTML={{
                            __html: microFormError,
                        }}
                    ></label>
                )}

                {doFieldValidation && formErrors["ccNumber"] && (
                    <label htmlFor={name} id="ccError" className="error-msg">
                        {formErrors["ccNumber"]}
                    </label>
                )}
                {doFieldValidation && formErrors["ccMonth"] && <div className="error-msg">{formErrors["ccMonth"]}</div>}
                {doFieldValidation && formErrors["ccYear"] && <div className="error-msg">{formErrors["ccYear"]}</div>}
                {doFieldValidation && formErrors["ccExpiry"] && <div className="error-msg">{formErrors["ccExpiry"]}</div>}
                {doFieldValidation && formErrors["ccCVV"] && (
                    <label htmlFor={name} id="ccError" className="error-msg">
                        {formErrors["ccCVV"]}
                    </label>
                )}
                <TextInput
                    name="billingPostalCode"
                    id={Constants.zip}
                    type="text"
                    maxLength={10}
                    textDivStyle="billing-zip-code cc-details__zip"
                    onChange={onChange}
                    onBlur={handleBlur}
                    setFormErrors={setFormErrors}
                    dValue={formData["billingPostalCode"]}
                    error={formErrors["billingPostalCode"]}
                    floatLabel={t("billing-zip-code")}
                    autoComplete={Constants.noAutoComplete}
                    refProp={postalCodeInputRef}
                />
            </div>
        </section>
    );
}
CreditCardDetails.propTypes = {
    t: PropTypes.func,
    onChange: PropTypes.func,
    setFormErrors: PropTypes.func,
    formErrors: PropTypes.object,
    formData: PropTypes.object,
    validateField: PropTypes.func,
    doFieldValidation: PropTypes.bool,
    calculateTax: PropTypes.func,
    cartItem: PropTypes.object,
    dispatch: PropTypes.func,
    alternateStyle: PropTypes.bool,
};
