import React from "react";
import { FieldRenderer } from "../contentful/component-renderer";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
export const FieldRendererTemplate = (props) => {
    const { content = null } = props;
    const { t } = useTranslation();
    const { commonHeader, pageContents = null, commonFooter } = content ? content.fields : {};
    const contents = [commonHeader,pageContents, commonFooter];
    return (
        <div id={props.primaryProductCode || ""} className="main-container" >
            <FieldRenderer contents={contents} t={t} {...props}/>
        </div>
    );
};

export default FieldRendererTemplate;
FieldRendererTemplate.propTypes = {
    content: PropTypes.object,
    primaryProductCode: PropTypes.string
};

