class ChildSkuInfo {
    constructor(obj = {}) {
        const result = obj?.cart?.item
            ? obj?.cart?.item.flatMap((data) => {
                  return {
                      ...new ProductInfo({
                          ...data.productInfo,
                          quantity: data.quantity,
                          new: true,
                          base_price: data.productInfo.baseListPrice,
                      }),
                  };
              })
            : [];
        if (result.length) {
            this.product_child_name = result[0].product_child_name || [];
            this.product_child_sku = result[0].product_child_sku || [];
        }
    }
}
export class DigitalData extends ChildSkuInfo {
    constructor(obj = {}) {
        super(obj);
        this.basket_id = obj.orderID || "";
        this.page = new Page(obj.page ? { ...obj.page, offer_description: obj.offer_description, link_label: obj.link_label } : {});
        this.cart = new Cart(obj.cart ? obj.cart : {});
        this.pageInstanceID = obj.pageInstanceID || "";
        this.product =
            obj.product && obj.product instanceof Array
                ? obj.product.map((data) => {
                      const productInfo = new ProductInfo(data.productInfo);
                      return { productInfo };
                  })
                : [];
        this.event = [
            {
                eventInfo: { eventName: "product", eventAction: "view" },
            },
        ];
        if (obj.guid) this.bod_guid = obj.guid || "";
    }
}

class PageInfo {
    constructor(obj = {}) {
        this.pageName = obj.pageName || "";
        this.pageID = obj.pageID || "";
        this.customerID = obj.customerID || "";
        this.currencyCode = obj.currencyCode || "USD";
        this.queryParams = obj.queryParams || {};
    }
}
class Category {
    constructor(obj = {}) {
        this.pageType = obj.pageType || "product";
        this.siteName = "beachbody";
    }
}
class Page {
    constructor(obj = {}) {
        this.pageInfo = new PageInfo(obj.pageInfo ? obj.pageInfo : {});
        this.category = new Category(obj.category ? obj.category : {});
    }
}
class ProductInfo {
    constructor(obj = {}) {
        if (obj.new) {
            this.offer_description = obj.offer_description || obj.localizedTitle || "";
            this.product_id = [obj.productID || ""];
            this.product_sku = [obj.skuID || obj.id || ""];
            this.product_name = [obj.productName || obj.displayName || ""];
            this.product_child_sku = getSubSkus(obj?.subSkus, "skuId") || [];
            this.product_child_name = getSubSkus(obj?.subSkus, "skuName") || [];
            this.product_original_price = [obj.base_price || ""];
            this.product_price = [obj.salePrice || 0];
            this.product_quantity = [obj.quantity || 1];
            this.link_label = obj.link_label || "";
        } else {
            this.description = obj.description || "";
            this.price = obj.price || [0];
            this.productID = obj.productID || "";
            this.productName = obj.productName || obj.displayName || "";
            this.salePrice = obj.salePrice || [0];
            this.skus = obj.skus || [0];
            this.skuID = obj.skuID || obj.id || "";
        }
    }
}

class Cart {
    constructor(obj = {}) {
        this.cartID = obj.cartID || "";
        this.item =
            obj.item && obj.item instanceof Array
                ? obj.item.map((data) => {
                      const productInfo = new ProductInfo(data.productInfo);
                      return {
                          price: { basePrice: data.productInfo.baseListPrice || "" },
                          productInfo,
                          quantity: data.quantity || 1,
                          product_info: new ProductInfo({
                              ...data.productInfo,
                              quantity: data.quantity,
                              new: true,
                              base_price: data.productInfo.baseListPrice,
                          }),
                      };
                  })
                : [];
    }
}
const getSubSkus = (data, type) => {
    if (data && data.length) {
        if (type === "skuId") return [data.map((subSku) => Object?.keys(subSku).join(",")).join(",")];
        else return [data.map((subSku) => Object?.values(subSku).join(",")).join(",")];
    }
};
// class Site {
//     constructor(obj = {}) {
//         this.env = obj.env || "dev"; // Updated by WebPack
//         this.country = obj.country || ""; // Default ++ Update during Login ++ Update during Locale Change
//         this.name = "SHAC"; // Update To SHAC
//         this.section = obj.section || ""; // Update during history.push()
//         this.locale = obj.locale || "";
//     }
// }

// class Attribute {
//     constructor(obj = {}) {
//         this.source = obj.source || "";
//     }
// }
// class ProfileInfo {
//     constructor(obj = {}) {
//         this.isExistingCoach = obj.isExistingCoach || false;
//         this.customerType = obj.customerType || "";
//         this.profileID = obj.profileID || "";
//     }
// }
// class Profile {
//     constructor(obj = {}) {
//         this.profileInfo = new ProfileInfo(obj.profileInfo ? obj.profileInfo : {});
//     }
// }
// class User {
//     constructor(obj = {}) {
//         this.profile = new Profile(obj.profile ? obj.profile : {});
//     }
// }
