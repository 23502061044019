import React from "react";
import PropTypes from "prop-types";
import { CTAButton } from "../../../../common";
import { USE_DIFFERENT_EMAIL } from "../constants";
import "./modal.scss";

export const BaseAccountModal = ({ t, closePopup, signInUrl }) => {
    const signIn = () => {
        signInUrl && window.open(signInUrl);
        closePopup();
    };
    return (
        <div className="pb-5">
            <div className="modal-text pt-3 pb-3">
                <CTAButton buttonName={t("sign-in")} onButtonClick={signIn} innerBtnClass="bod-generic-btn" />
            </div>
            <div className="or-text">
                <p>
                    <span>{t("or")}</span>
                </p>
            </div>
            <div className="modal-text pt-3">
                <CTAButton buttonName={t("signup-use-diff-email")} onButtonClick={() => closePopup(USE_DIFFERENT_EMAIL)} innerBtnClass="bod-generic-btn" />
            </div>
        </div>
    );
};
BaseAccountModal.propTypes = {
    t: PropTypes.func,
    children: PropTypes.func,
    closePopup: PropTypes.func,
    signInUrl: PropTypes.string,
    customerWithoutBOD: PropTypes.bool,
    cancelResetPassword: PropTypes.func,
};
