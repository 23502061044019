import React, { useState } from "react";
import PropTypes from "prop-types";

import AccordionElement from "./AccordionElement";
import { FieldRenderer } from "../../contentful/component-renderer";
import { getPropValue, checkForValidArray } from "../../helper/util";
import "./index.scss";
import { logLinkTracking } from "../../web-analytics";
import * as tealiumTags from "../../constants/tealium-tags";
import { AccordionData } from "../../types/interfaces";
import { AnimateSection } from "../common/animation";

export const Accordion = ({ fields }) => {
    const [openAccordions, setOpenAccordions] = useState([]);
    const accordionTitle = getPropValue(fields, "accordionTitle") || null;
    const accordionTitleTextColor = getPropValue(fields, "accordionTitleTextColor") || "";
    const accordionContent = getPropValue(fields, "accordionContent.fields.contents") || null;
    const accordionContentTextColor = getPropValue(fields, "accordionContentTextColor") || "";
    const accordionMaxWidth = getPropValue(fields, "maxWidth") || "";
    const backgroundColor = getPropValue(fields, "backgroundColor") || "";
    const separatorColor = getPropValue(fields, "separatorColor") || "";
    const openIconColor = getPropValue(fields, "openIconColor") || "";
    const animationClass = getPropValue(fields, "animationClass") || "";
    const validAccordionContent = checkForValidArray(accordionContent);

    const accordionTitleStyles = {
        color: accordionTitleTextColor,
    };

    const accordionStyles = {
        backgroundColor: backgroundColor,
        maxWidth: `${accordionMaxWidth}px`,
    };

    const separatorStyles = {
        backgroundColor: separatorColor,
    };

    const AccordionLine = () => {
        return <div className="accordion__line" style={separatorStyles}></div>;
    };

    const getFAQtext = (id: string) => {
        const filteredFAQText: AccordionData[] = accordionContent.filter((accordion: AccordionData) => {
            const {
                fields: { id: currentQuestionID },
            } = accordion || { fields: { id: "" } };
            return currentQuestionID === id;
        });
        return filteredFAQText?.[0]?.fields?.contents?.[0]?.fields?.headingTitle?.content?.[0]?.content?.[0]?.value;
    };

    const onOpenAccordion = (id: string, isOpen: boolean) => {
        const FAQtext = getFAQtext(id);
        if (isOpen) {
            const filteredAccordions = openAccordions.filter((accordion) => accordion !== id);
            setOpenAccordions(filteredAccordions);
            FAQtext && logLinkTracking(`${tealiumTags.faq_collapsed_event_name}:${FAQtext}`);
            return;
        } else {
            FAQtext && logLinkTracking(`${tealiumTags.faq_expanded_event_name}:${FAQtext}`);
        }
        setOpenAccordions([...openAccordions, id]);
    };

    return (
        <React.Fragment>
            {accordionTitle && validAccordionContent ? (
                <AnimateSection animationClass={animationClass}>
                    {({ containerRef }) => (
                        <section className="accordion" style={accordionStyles} ref={containerRef}>
                            <div className="accordion__container">
                                <div className="accordion__title" style={accordionTitleStyles}>
                                    <FieldRenderer contents={accordionTitle} />
                                </div>
                                <AccordionLine />
                                {accordionContent.map((currentAccordion) => {
                                    const id = getPropValue(currentAccordion, "fields.id") || "";
                                    const fieldContent = getPropValue(currentAccordion, "fields.contents") || [];
                                    const title = checkForValidArray(fieldContent) ? fieldContent[0] : null;
                                    const content = checkForValidArray(fieldContent, 1) ? fieldContent[1] : null;
                                    const isOpen = openAccordions.includes(id);

                                    return (
                                        <React.Fragment key={id}>
                                            {title && content ? (
                                                <React.Fragment>
                                                    <AccordionElement
                                                        title={title}
                                                        content={content}
                                                        isOpen={isOpen}
                                                        onOpen={() => onOpenAccordion(id, isOpen)}
                                                        iconFill={openIconColor}
                                                        textColor={accordionContentTextColor}
                                                    />
                                                    <AccordionLine />
                                                </React.Fragment>
                                            ) : null}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </section>
                    )}
                </AnimateSection>
            ) : null}
        </React.Fragment>
    );
};

Accordion.propTypes = {
    fields: PropTypes.object,
};
