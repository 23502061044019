export class SKU {
    constructor(data = {}) {
        this.baseListPrice = data.baseListPrice || "";
        this.canPurchase = data.canPurchase || false;
        this.canView = data.canView || false;
        this.discountPrice = data.discountPrice || "";
        this.displayName = data.displayName || "";
        this.id = data.id || "";
        this.isShippable = data.isShippable || "";
        this.maxCartQty = data.maxCartQty || false;
        this.options = data.options || [];
        this.personalVolume = data.personalVolume || "";
        this.skuType = data.skuType || "";
        this.stock = data.stock || "";
        this.title = data.title || "";
        this.subtitle1 = data.subtitle1 || "";
        this.subtitle2 = data.subtitle2 || "";
        this.stamp = data.stamp || "";
        this.displayName = data.displayName || "";
        this.marketingValue = data.marketingValue || "";
        this.localizedTitle = data.localizedTitle || "";
        this.rawPrice = data.rawPrice || "";
        this.subSkus = getSubSkus(data?.configurableProperties) || "";
        this.dynamicPrice = data.dynamicPrice || "";
        this.dynamicMonthlyPrice = data.dynamicMonthlyPrice || "";
    }
}
function getSubSkus(configurableProperties) {
    return configurableProperties?.flatMap((prop) => {
        return prop.skus.map((sku) => {
            return { [sku.id]: sku.displayName };
        });
    });
}
