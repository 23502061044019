import React from "react";
import PropTypes from "prop-types";

import { FieldRenderer } from "../../contentful/component-renderer";
import PlusIcon from "./images/PlusIcon";
import MinusIcon from "./images/MinusIcon";

const AccordionElement = ({ title, content, isOpen, onOpen, iconFill, textColor }) => {
    const style = {
        color: textColor,
    };

    return (
        <div className="accordion__content">
            <div className="accordion__toggle" onClick={onOpen}>
                <div className="accordion__toggle__title" style={style}>
                    <FieldRenderer contents={title} />
                </div>
                <div className="accordion__toggle__icon">{isOpen ? <MinusIcon fill={iconFill} /> : <PlusIcon fill={iconFill} />}</div>
            </div>
            {isOpen && (
                <div className="accordion__toggle__content" style={style}>
                    <FieldRenderer contents={content} />
                </div>
            )}
        </div>
    );
};

AccordionElement.propTypes = {
    title: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    iconFill: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
};

export default AccordionElement;
