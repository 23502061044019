import createSagaMiddleware, { END } from "redux-saga";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import rootSaga from "../sagas";
const sagaMiddleware = createSagaMiddleware();

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const configureStore = (preloadState = null) => {
    let store = {};
    const composeEnhancers = (process.env.NODE_ENV !== "production" && typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
    if (preloadState) {
        store = createStore(rootReducer, preloadState, composeEnhancers(applyMiddleware(sagaMiddleware)));
    } else {
        store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
    }
    sagaMiddleware.run(rootSaga);
    store.closeSagas = () => store.dispatch(END);
    return store;
};

export default configureStore;
