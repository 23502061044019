import { DefaulCCBillingAddress } from "./billing-address";
export class DefaultCreditCard extends DefaulCCBillingAddress {
    constructor(data = {}) {
        super(data);
        this.ccYear = (data.expirationYear && data.expirationYear.slice(-2)) || "";
        this.ccMonth = data.expirationMonth || "";
        this.ccType = data.creditCardType || "";
        this.ccNumber = data.creditCardNumber || "";
        this.ccExpDate = data.ccExpDate || "";
        this.ccCVV = data.ccCVV || "";

        if (this.ccType === "PAYPAL") {
            this.token = data.token || "";
        }
    }
}
