import React from "react";
import PropTypes from "prop-types";
import "./cta-button.scss";
export const CloseButton = ({
    content,
    onButtonClick = null,
}) => {
    const { fields, sys } = content;
    const entryId = sys.id;
    const {
        buttonAction,
        backgroundImage,
        id
    } = fields;
    const fieldId = id ? `${id.trim()}-${entryId}` : `element-${entryId}`;
    
    
    const onClickEvent = () => {
        setTimeout(() => {
            switch (buttonAction) {
                case "default-button-action":
                    onButtonClick && onButtonClick();
                    return;
            }
        }, 500);
    };
    return (
        <div id={fieldId}
            onClick={backgroundImage ? onClickEvent : null}
            className={backgroundImage ? "cta cta-icon" : "cta"}>
            <a
                className="button"
                onClick={onClickEvent}
            >
            </a>
        </div>
    );
};

CloseButton.propTypes = {
    content: PropTypes.object,
    onButtonClick: PropTypes.func,
};
