import { CheckoutForm } from "./checkout-form";
export class CheckoutFormErrors extends CheckoutForm {
    constructor(data = {}) {
        super(data);
        this.continuity = data.continuity || "";
        this.legalTermsCondition = data.legalTermsCondition || "";
        this.captcha = data.captcha || "";
        this.ccExpiry = data.ccExpiry || "";
    }
}
