import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";
import { AnimateSection } from "../common/animation";

export const TextContainer = ({
    fields,
    fieldId,
    RichText,
    hideFieldCallback,
    customizedDescriptionBox = null,
    isDesktopView,
    inlineClass = "",
    onButtonClick,
}) => {
    const { headingTitle, mobileHeadingTitle, description, mobileDescription, ctaButton, disclaimer, animationClass } = fields;
    const title = isDesktopView ? headingTitle : mobileHeadingTitle || headingTitle;
    const descriptionBox = isDesktopView ? description : mobileDescription || description;

    return (
        <AnimateSection animationClass={animationClass}>
            {({ containerRef }) => (
                <div id={fieldId} className={inlineClass ? `text-container ${inlineClass}` : "text-container"} ref={containerRef}>
                    {title && <RichText content={title} inlineClass="title" id={fields?.id} />}
                    {descriptionBox ? (
                        customizedDescriptionBox ? (
                            customizedDescriptionBox
                        ) : (
                            <RichText content={descriptionBox} inlineClass="description" />
                        )
                    ) : null}
                    {ctaButton ? (
                        hideFieldCallback ? (
                            !hideFieldCallback("ctaButton") && <FieldRenderer contents={ctaButton} onButtonClick={onButtonClick} />
                        ) : (
                            <FieldRenderer contents={ctaButton} onButtonClick={onButtonClick} />
                        )
                    ) : null}
                    {disclaimer && <RichText content={disclaimer} inlineClass="disclaimer" />}
                </div>
            )}
        </AnimateSection>
    );
};
TextContainer.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    inlineClass: PropTypes.string,
    hideFieldCallback: PropTypes.func,
    RichText: PropTypes.func,
    FieldRenderer: PropTypes.func,
    isDesktopView: PropTypes.bool,
    customizedDescriptionBox: PropTypes.node,
    onButtonClick: PropTypes.func,
};
