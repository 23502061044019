import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";

export const TableCell = ({ fields, fieldId, isDesktopView, isHeader, ...props }) => {
    const { cellContent, headerKey = "", hiddenInMobile = false } = fields;
    const comparisonHeaderDatas = cellContent && isHeader ? true : false;
    const hiddenCell = !isDesktopView && hiddenInMobile;

    return (
        <>
            {!hiddenCell &&
                (comparisonHeaderDatas ? (
                    <th id={fieldId} className={`${headerKey ? headerKey : ""}`}>
                        <FieldRenderer contents={cellContent} isDesktopView={isDesktopView} {...props} />
                    </th>
                ) : (
                    <td id={fieldId} className={`${headerKey ? headerKey : ""}`}>
                        <FieldRenderer contents={cellContent} isDesktopView={isDesktopView} {...props} />
                    </td>
                ))}
        </>
    );
};

TableCell.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    RichText: PropTypes.func,
    hideFieldCallback: PropTypes.func,
    isDesktopView: PropTypes.bool,
    isHeader: PropTypes.bool,
};
