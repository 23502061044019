export const myxTranslations = {
    accountInformation: "myx-account-information",
    email: "email",
    editEmail: "myx-edit-email",
    firstName: "first-name",
    lastName: "last-name",
    password: "password",
    saveAndContinue: "save-continue-btn",
    passwordErrors: {
        lowercase: "pwd-validation-lowercase",
        count: "pwd-validation-length",
        uppercase: "pwd-validation-uppercase",
        space: "pwd-validation-space",
        number: "pwd-validation-number",
        specialChars: "pwd-validation-special-chars",
    },
};
