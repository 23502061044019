export class ContentStyle {
    constructor(style = {}) {
        this.width = style.width || "";
        this.height = style.height || "";
        this.color = style.color || "";
        this.paddingTop = style.paddingTop || "";
        this.paddingRight = style.paddingRight || "";
        this.paddingBottom = style.paddingBottom || "";
        this.paddingLeft = style.paddingLeft || "";
        this.margin = style.margin || "";
        this.marginTop = style.marginTop || "";
        this.marginRight = style.marginRight || "";
        this.marginBottom = style.marginBottom || "";
        this.marginLeft = style.marginLeft || "";
        this.background = style.backgroundImage ? `url(${typeof(style.backgroundImage) === "object" ? style.backgroundImage.fields.file.url : style.backgroundImage})  top center/cover no-repeat` : "";
        this.fontSize = style.fontSize || "";
        this.fontWeight = style.fontWeight || "";
        this.position = style.position || "";
        this.lineHeight = style.lineHeight || "";
        this.leterSpacing = style.leterSpacing || "";
        this.backgroundColor = style.backgroundColor || "";
        this.boxShadow = style.boxShadow || "";
        this.textAlign = style.textAlign || "";
        this.top = style.top || "";
        this.bottom = style.bottom || "";
        this.left = style.left || "";
        this.right = style.right || "";
        this.maxWidth = style.maxWidth || "";
        this.fontFamily = style.fontFamily ? style.fontFamily.fields.fontFamily : "";
        this.minWidth = style.minWidth || "";
        this.borderRadius = style.borderRadius || "";
        this.minHeight = style.minHeight || "";
        this.borderColor = style.borderColor || "";
        this.backgroundSize = style.backgroundSize || "";
        this.backgroundPositionX = style.backgroundPositionX || "";
        this.backgroundPositionY = style.backgroundPositionY || "";
        this.display = style.display || "";
        this.borderBottom = style.borderBottom || "";
        this.borderTop = style.borderTop || "";
        this.borderRight = style.borderRight || "";
        this.borderLeft = style.borderLeft || "";
    }
}

