import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../../../contentful/component-renderer";

export const TopBarWithDoubleLogo = ({ fields }) => {
    const { companyLogo, brandLogo } = fields;


    return (<div className="top-bar-double-logo" >
        <FieldRenderer contents={brandLogo} />
        <FieldRenderer contents={companyLogo} />
    </div>);
};
TopBarWithDoubleLogo.propTypes = {
    fields: PropTypes.object
};

export const TBLogosCompanyLeftBrandRight = ({ fields }) => {
    const { companyLogo, brandLogo } = fields;


    return (<div className="top-bar-double-logo" >
        <FieldRenderer contents={companyLogo} />
        <FieldRenderer contents={brandLogo} />
    </div>);
};
TBLogosCompanyLeftBrandRight.propTypes = {
    fields: PropTypes.object
};

export const TBLogosBrandLeftCompanyRight = ({ fields }) => {
    const { companyLogo, brandLogo } = fields;


    return (<div className="top-bar-double-logo" >
        <FieldRenderer contents={brandLogo} />
        <FieldRenderer contents={companyLogo} />
    </div>);
};
TBLogosBrandLeftCompanyRight.propTypes = {
    fields: PropTypes.object
};