import React from "react";
import PropTypes from "prop-types";
import "./header.scss";
import { FieldRenderer } from "../../../contentful/component-renderer";

export const BeachbodyHeader = ({ fields }) => {
    const { companyLogo, localeSwitcher } = fields;
    return (
        <header className="beachbody-header">
            <section className="inner-container w-100">
                <div className="bb-header gd-flex align-items-left w-100 d-flex align-items-center">
                    <div className="container d-flex justify-content-center">
                        <img
                            src={companyLogo.fields.file.url}
                            alt={companyLogo.fields.title}
                            height={companyLogo.fields.file.details.image.height}
                            width={companyLogo.fields.file.details.image.width}
                        />
                    </div>
                    {localeSwitcher && <FieldRenderer contents={localeSwitcher} />}
                </div>
            </section>
        </header>
    );
};
BeachbodyHeader.propTypes = {
    fields: PropTypes.object,
};
