export const creditCardType = (number) => {
    // visa
    let re = new RegExp("^4");
    if (number.match(re) != null) return "visa";

    // Mastercard
    re = new RegExp("^5[1-5]");
    if (number.match(re) != null) return "mastercard";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null) return "amex";

    // Discover
    re = new RegExp("^(6|6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null) return "discover";

    // China Union Pay
    re = new RegExp("^(62[0-9]{14,17})$");
    if (number.match(re) != null) return "cup";

    return "";
};
