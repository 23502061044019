import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FieldRenderer } from "../../contentful/component-renderer";
import { PageHeader } from "../../module";
import { convertSCSSToCSS, validateVideoContainer, getImageSource } from "../../helper/util";
import { AnimateSection } from "../common/animation";
import "./hero-banner.scss";

export const HeroBanner = ({ fields, fieldId, sys }) => {
    const { page } = useSelector((state) => state.pageRouter);
    const { contents, backgroundImage, backgroundVideo, animationClass } = fields;
    const {
        desktopImage = {},
        mobileImage = {},
        cloudinaryDesktopImage = {},
        cloudinaryMobileImage = {},
        title: titleImage = "",
    } = backgroundImage?.fields || {};
    const isDesktopView = useSelector((state) => state.pageRouter.isDesktopView);
    const validBackgroundVideo = validateVideoContainer(backgroundVideo);
    const contentType = sys?.contentType?.sys?.id;

    const hasFixedHeader = () => {
        const { header } = page.fields;
        if (header && header.fields.headerType === "fixed") {
            const entryId = header.sys.id;
            const { id = "" } = header.fields;
            const fieldId = id ? `${id}-${entryId}` : "";
            return <PageHeader fields={header.fields} fieldId={fieldId} loadHeader={true} />;
        }
    };

    const getBackgroundMedia = () => {
        const backgroundMediaContainerId = "background-media-container";
        const desktopImageSrc = getImageSource(cloudinaryDesktopImage, desktopImage);
        const mobileImageSrc = getImageSource(cloudinaryMobileImage, mobileImage);
        const imageSrc = isDesktopView ? desktopImageSrc : mobileImageSrc;

        // custom CSS for the background image, that applies a background as URL() in the backgroundMediaContainerId
        const backgroundImageCSS = imageSrc ? `background: url(${imageSrc}) top center/cover no-repeat;\n` : "";
        const backgroundImageStyle =
            !process.env.BROWSER && imageSrc
                ? convertSCSSToCSS({ fieldId: backgroundMediaContainerId, customCss: backgroundImageCSS, title: titleImage, contentType })
                : "";

        return (
            <Fragment>
                {backgroundImageStyle && <style>{backgroundImageStyle}</style>}
                <div id={backgroundMediaContainerId}>{validBackgroundVideo && <FieldRenderer contents={backgroundVideo} />}</div>
            </Fragment>
        );
    };

    // Need to hide the background here, the backgroundImageStyle creates a backgroundImage that is behind the video instead of in the front of it
    const hideBackgroundStyle = {
        background: "none",
        zIndex: "0",
    };

    return (
        <AnimateSection animationClass={animationClass}>
            {({ containerRef }) => (
                <section id={fieldId} className="hero-banner content-container" style={hideBackgroundStyle} ref={containerRef}>
                    {getBackgroundMedia()}
                    {hasFixedHeader()}
                    {contents && <FieldRenderer contents={contents} />}
                </section>
            )}
        </AnimateSection>
    );
};
HeroBanner.propTypes = {
    fields: PropTypes.any,
    fieldId: PropTypes.string,
    sys: PropTypes.shape({
        contentType: PropTypes.shape({
            sys: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
    }),
};
