import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../../../contentful/component-renderer";

export const TopBarWithSingleLogoLeft = ({ fields }) => {
    const { companyLogo, brandLogo } = fields;
    const logo = companyLogo || brandLogo;

    return (<div className="top-bar-single-logo-left" >
        <FieldRenderer contents={logo} />
    </div>);
};
TopBarWithSingleLogoLeft.propTypes = {
    fields: PropTypes.object
};

export const TopBarWithSingleLogoRight = ({ fields }) => {
    const { companyLogo, brandLogo } = fields;
    const logo = companyLogo || brandLogo;
    return (<div className="top-bar-single-logo-right" >
        <FieldRenderer contents={logo} />
    </div>);
};
TopBarWithSingleLogoRight.propTypes = {
    fields: PropTypes.object
};