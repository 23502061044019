import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FieldRenderer } from "../../../contentful/component-renderer";
import "./load-more.scss";

export const LoadMore = ({ fields, fieldId }) => {
    const [fieldsToShow, setFieldsToShow] = useState(5);
    const { topContainer, carouselContent } = fields;
    const { t } = useTranslation();

    const handleMore = () => {
        setFieldsToShow(fieldsToShow + 5);
    };

    const ordenedSlides = useMemo(
        () =>
            carouselContent
                .sort((slideAfter, slideBefore) => (slideBefore.fields.orderPriorityValue || 0) - (slideAfter.fields.orderPriorityValue || 0))
                .filter(() => {
                    return true;
                }),
        [carouselContent],
    );

    return (
        <div className="carousel-load-more__wrapper" id={fieldId}>
            <FieldRenderer contents={topContainer} />
            <div className="carousel-load-more__content">
                {ordenedSlides
                    .filter((_, index) => index < fieldsToShow)
                    .map((item, index) => (
                        <FieldRenderer key={index} index={index} contents={item} isLoadMore={true} />
                    ))}
            </div>
            <div className="carousel-load-more__button-container">
                {fieldsToShow < ordenedSlides.length && (
                    <button className="carousel-load-more__button" onClick={handleMore}>
                        {t("show-more")}
                    </button>
                )}
            </div>
        </div>
    );
};

LoadMore.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
};
