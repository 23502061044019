import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { CTAButton, Loader, TextInput } from "../../common";
import { checkoutActions } from "../checkout-page/data/checkout-page-action";
import { getProductDetails, getUrlParam, setCookie, extractFieldValues } from "../../helper/util";
import { encryptData, redirectWithParam, triggerUserEmailLookup } from "../common/util";
import { validateForm } from "./myx-validator";
import { AccountDetails, MyxResetPwd } from "../../model";
import { PWD_EYE_INVISIBLE, PWD_EYE_VISIBLE, MYX_LOGIN_PATH, MYX_ACTIVATE_BIKE_PATH } from "../../constants/urls";
import "../reset-password-page/reset-password.scss";
import { pageRouterActions } from "../../component/page-router/data/page-router-action";
import { ErrorMessageToaster } from "../../common/message-toaster";
import usePasswordValidation from "../../hooks/usePasswordValidation";

export function MyxResetPasswordPage({ contents, ...props }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { fields } = contents;
    const { textContent } = fields || {};
    const { reset_password_update_fail = "" } = extractFieldValues(textContent, "textValue");
    const [formData, setFormData] = useState(new MyxResetPwd({}));
    const [showPassword, setShowPassword] = useState(false);
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
    const [validToken, setValidToken] = useState(false);
    const sessionId = useSelector((state) => state.offers.sessionId);
    const [formErrors, setResetFormErrors] = useState(new MyxResetPwd());
    const [disableBtn, setDisableBtn] = useState(false);
    const [updateResetPasswordResponse, setPasswordUpdateResponse] = useState();
    const { passwordValidations, validatePassword, displayPasswordError } = usePasswordValidation();
    const { hasCharCount, hasLowerCaseChar, hasNoSpaces, hasNoSpecialChars, hasNumber, hasUpperCaseChar } = passwordValidations;

    const onValidateToken = (response) => {
        const {
            validateToken: { result, email },
        } = response || { validateToken: {} };
        setValidToken(result);
        if (!result && email) {
            email && sessionStorage.setItem("resetPassValFail", email);
            setPasswordUpdateResponse({ email, result, redirectToLogin: true });
        } else if (!result) {
            redirectWithParam(props && props.history, MYX_ACTIVATE_BIKE_PATH.substring(1));
        }
    };
    const onSubmitRestPassword = () => {
        setDisableBtn(true);
        setPasswordUpdateResponse();
        if (displayPasswordError) {
            setDisableBtn(false);
        }
        const errors = validateForm(formData, t);
        if (errors) {
            setResetFormErrors({ ...formErrors, ...errors });
            setDisableBtn(false);
            return true;
        } else {
            setFormErrors(new MyxResetPwd());
            dispatch({
                type: checkoutActions.RESET_PASSWORD,
                payload: { ...formData, token: getUrlParam("token") },
                callbacks: { success: onSubmitRestPasswordSuccess, failure: onSubmitRestPasswordFailure },
            });
        }
    };

    const onSubmitRestPasswordSuccess = (response) => {
        setDisableBtn(false);
        setResetPasswordSuccess(true);
        const { formError: result, email } = response || {};
        if (!result) setPasswordUpdateResponse({ result, email, redirectToLogin: false });
    };
    const onSubmitRestPasswordFailure = (response) => {
        setDisableBtn(false);
        setFormData({ password: "" });
        setResetFormErrors({});
        const { formError: result, formExceptions } = response || {};
        setPasswordUpdateResponse({ result, formExceptions });
    };
    const setFormErrors = (name, value) => {
        setResetFormErrors({ ...formErrors, [name]: value ? t(value) : "" });
    };
    const onChange = (name, value, fieldValues) => {
        updateResetPasswordResponse && setPasswordUpdateResponse();
        validatePassword(value);
        if (fieldValues && typeof fieldValues === "object") {
            const resetData = { ...formData, ...fieldValues };
            setFormData(resetData);
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    const preventCopyText = (e) => {
        e.preventDefault();
    };
    const onShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const goToLoginPage = () => {
        redirectWithParam(props && props.history, MYX_LOGIN_PATH.substring(1));
    };
    const userDetailsSuccess = (userDetails) => {
        const { redirectToLogin } = updateResetPasswordResponse || {};
        const customerType = userDetails && userDetails.customerType;
        const isExistingFreeUser = customerType && customerType.indexOf("BOD") !== -1;
        const user = new AccountDetails({ ...userDetails, isExistingFreeUser });
        dispatch({ type: pageRouterActions.SET_USER_DETAILS, payload: user });
        getProductDetails(user, dispatch);
        const userData = JSON.stringify(user);
        const encipheredData = encryptData(userData);
        const date = new Date();
        date.setTime(date.getTime() + 60 * 60 * 1000);
        setCookie("userData", encipheredData, date);
        redirectToLogin && goToLoginPage();
    };
    const userDetailsFailure = () => {
        return undefined;
    };
    const passwordUpdateError = () => {
        const { result, formExceptions } = updateResetPasswordResponse || {};
        return result && formExceptions ? (
            <ErrorMessageToaster
                message={updateResetPasswordResponse.formExceptions && updateResetPasswordResponse.formExceptions["0"].localizedMessage}
                messageHeading={reset_password_update_fail}
                showIcon={true}
            />
        ) : (
            ""
        );
    };
    useEffect(() => {
        if (sessionId) {
            const activationCodeParam = getUrlParam("activationCode");
            const productCodeParam = getUrlParam("productCode");

            dispatch({
                type: checkoutActions.VALIDATE_TOKEN,
                payload: { token: getUrlParam("token") },
                callbacks: { success: onValidateToken, failure: onValidateToken },
            });
            activationCodeParam && localStorage.setItem("activationCode", activationCodeParam);
            productCodeParam && localStorage.setItem("productCode", productCodeParam);
        }
    }, [sessionId]);
    useEffect(() => {
        if (updateResetPasswordResponse) {
            const { email } = updateResetPasswordResponse || {};
            if (email) {
                triggerUserEmailLookup(email, dispatch, userDetailsSuccess, userDetailsFailure);
            }
        }
    }, [updateResetPasswordResponse]);
    const passIsValidClass = hasLowerCaseChar && hasCharCount && hasUpperCaseChar && hasNumber && hasNoSpaces && hasNoSpecialChars ? "password-valid" : "";

    const enterYourNewPassword = t("enter-your-new-password");
    const newPassword = t("new-password");
    const pwdValidationLength = t("pwd-validation-length");
    const pwdValidationUppercase = t("pwd-validation-uppercase");
    const pwdValidationNumber = t("pwd-validation-number");
    const pwdValidationSpace = t("pwd-validation-space");
    const pwdValidationLowercase = t("pwd-validation-lowercase");
    const pwdValidationSpecialChars = t("pwd-validation-special-chars");
    const yourPasswordHasBeenUpdated = t("your-password-has-been-updated");
    const passwordUpdated = t("password-updated");
    const loginToContinue = t("login-to-continue");
    const loginBth = t("login-title");
    const updateBtn = t("update-text");

    return (
        <Fragment>
            <div className="myx-reset-pass-page d-flex flex-column align-items-center">
                {validToken ? (
                    <div className="rest-password-details mb-4">
                        <div className="d-flex justify-content-center">
                            <div className="myx-panel">
                                {!resetPasswordSuccess && (
                                    <section className="reset-password">
                                        <h1>{fields.emailEntryPageTitle || enterYourNewPassword}</h1>
                                        <div className="password-box mt-6">
                                            <TextInput
                                                name="password"
                                                id="password"
                                                placeholder={newPassword}
                                                type={showPassword ? "text" : "password"}
                                                onChange={onChange}
                                                dValue={formData["password"]}
                                                error={formErrors["password"]}
                                                maxLength={20}
                                                hybridValidation={false}
                                                setFormErrors={setFormErrors}
                                                onCopy={preventCopyText}
                                                hintElement={
                                                    <img
                                                        name="show-password"
                                                        id="show-password"
                                                        className="pwd-icon"
                                                        src={showPassword ? PWD_EYE_VISIBLE : PWD_EYE_INVISIBLE}
                                                        onClick={onShowPassword}
                                                    />
                                                }
                                                onKeyPress={(e) => {
                                                    if (e.charCode === 13 || e.which === 13) {
                                                        let value = e.target.value;
                                                        value ? onSubmitRestPassword() : "";
                                                    }
                                                }}
                                            />
                                        </div>
                                        <ul className={"password-validation " + (formData["password"] !== "" ? "filled" : "")}>
                                            <li className={hasLowerCaseChar ? "valid " : ""}>{pwdValidationLowercase}</li>
                                            <li className={hasCharCount ? "valid" : ""}>{pwdValidationLength}</li>
                                            <li className={hasUpperCaseChar ? "valid" : ""}>{pwdValidationUppercase}</li>
                                            <li className={hasNumber ? "valid" : ""}>{pwdValidationNumber}</li>
                                            <li className={hasNoSpecialChars ? "valid" : ""}>{pwdValidationSpecialChars}</li>
                                            <li className={hasNoSpaces ? "valid" : ""}>{pwdValidationSpace}</li>
                                        </ul>
                                        {updateResetPasswordResponse && passwordUpdateError()}
                                        <CTAButton
                                            outerBtnClass="text-center"
                                            innerBtnClass={"submit-btn text-center " + passIsValidClass}
                                            buttonName={updateBtn}
                                            disableButton={disableBtn ? { disabled: "disabled" } : null}
                                            icon={disableBtn ? "fa fa-spinner fa-spin d-inline-block" : ""}
                                            onButtonClick={() => onSubmitRestPassword()}
                                        />
                                    </section>
                                )}
                                {resetPasswordSuccess && (
                                    <section className="reset-password">
                                        <div className="success-check-mark">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                                <g fill="none" fillRule="evenodd">
                                                    <g>
                                                        <g>
                                                            <path
                                                                fill="#198754"
                                                                d="M24 42c2.47 0 4.79-.472 6.963-1.417 2.173-.944 4.086-2.243 5.74-3.898 1.655-1.654 2.951-3.565 3.89-5.731C41.53 28.787 42 26.469 42 24c0-2.47-.47-4.787-1.407-6.954-.939-2.166-2.238-4.08-3.899-5.74-1.66-1.66-3.577-2.96-5.75-3.899C28.772 6.47 26.451 6 23.981 6c-2.444 0-4.753.47-6.925 1.407-2.173.939-4.09 2.238-5.75 3.899-1.66 1.66-2.96 3.574-3.899 5.74C6.47 19.213 6 21.531 6 24c0 2.47.472 4.787 1.417 6.954.944 2.166 2.243 4.077 3.898 5.731 1.654 1.655 3.568 2.954 5.74 3.898C19.229 41.528 21.544 42 24 42zm-1.963-9.389c-.321 0-.614-.07-.88-.213-.265-.142-.515-.367-.75-.676l-4.333-5.296c-.16-.185-.278-.38-.352-.583-.074-.204-.11-.41-.11-.62 0-.42.147-.782.444-1.084.296-.303.654-.454 1.074-.454.259 0 .5.053.722.158.222.105.438.299.648.583l3.463 4.426 7.352-11.778c.358-.568.808-.852 1.352-.852.407 0 .774.13 1.102.39.327.258.49.604.49 1.036 0 .21-.046.417-.139.62-.092.204-.2.399-.324.584l-8.203 12.87c-.198.297-.426.519-.686.667-.259.148-.549.222-.87.222z"
                                                                transform="translate(-816.000000, -170.000000) translate(816.000000, 170.000000)"
                                                            />
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <h1>{yourPasswordHasBeenUpdated}</h1>
                                        <p>
                                            {passwordUpdated} <br />
                                            {loginToContinue}
                                        </p>
                                        <CTAButton
                                            outerBtnClass="text-center"
                                            innerBtnClass="login-btn text-center"
                                            buttonName={loginBth}
                                            disableButton={disableBtn ? { disabled: "disabled" } : null}
                                            icon={disableBtn ? "fa fa-spinner fa-spin d-inline-block" : ""}
                                            onButtonClick={() => goToLoginPage()}
                                        />
                                    </section>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="reset-password-loader d-flex align-items-center">
                        <Loader isToShowLoader={true} />
                    </div>
                )}
            </div>
        </Fragment>
    );
}

MyxResetPasswordPage.propTypes = {
    contents: PropTypes.object,
    history: PropTypes.object,
    props: PropTypes.object,
};
