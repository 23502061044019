import React, { Fragment } from "react";
import PropTypes from "prop-types";
import topBarSection from "./top-bar-section";
import "./page-header.scss";
import { useSelector } from "react-redux";
import { FieldRenderer } from "../../../contentful/component-renderer";

export const PageHeader = ({ fields, fieldId, loadHeader = false, onButtonClick }) => {
    const { headerType, topBarSectionType, customizedTopBar } = fields;
    const isCustomizedTopBar = topBarSectionType === "user-customized";
    const TopBarSectionRenderer = isCustomizedTopBar ? null : topBarSection[topBarSectionType];
    const isDesktopView = useSelector(state => state.pageRouter.isDesktopView);
    const getHeader = () => {
        return (<Fragment>
            <div id={fieldId} className={`landing-page-header ${headerType}-header`}>
                {isCustomizedTopBar && <FieldRenderer contents={customizedTopBar} />}
                {!isCustomizedTopBar && TopBarSectionRenderer ? <TopBarSectionRenderer fields={fields} isDesktopView={isDesktopView} onButtonClick={onButtonClick} /> : null}
            </div>
        </Fragment>);
    };

    return (loadHeader ? getHeader() : (headerType === "relative" ? getHeader() : null));
};
PageHeader.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    loadHeader: PropTypes.bool
};
