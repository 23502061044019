import braintree from "braintree-web";
import { generatePaypalClientToken } from "../../api/checkoutApi";
import { paypalButtonStyles } from "../../module/checkout-page/constants";

export const getPayPalToken = async (config) => {
    return generatePaypalClientToken(config.dynSessionConf, config.locale, config.pushSite)
        .then((response) => response.json())
        .then((data) => {
            return data.result.clientToken;
        });
};

export const getCheckoutButton = async (t, config, callback, onPayPalButtonInit, onPaypalButtonClick) => {
    const err = t("error-paypal-widget");
    try {
        const authorization = await getPayPalToken(config);

        if (!authorization) {
            throw new Error(err);
        }

        braintree.client.create(
            {
                authorization,
            },
            (clientErr, clientInstance) => {
                if (clientErr) {
                    throw new Error(err);
                }

                braintree.paypalCheckout.create(
                    {
                        client: clientInstance,
                    },
                    (paypalCheckoutErr, paypalCheckoutInstance) => {
                        if (paypalCheckoutErr) {
                            throw new Error(err);
                        }

                        paypalCheckoutInstance.loadPayPalSDK(
                            {
                                vault: true,
                            },
                            () => {
                                paypal
                                    .Buttons({
                                        style: paypalButtonStyles,

                                        fundingSource: paypal.FUNDING.PAYPAL,

                                        onInit: function (_data, actions) {
                                            onPayPalButtonInit(actions);
                                        },

                                        onClick: function () {
                                            onPaypalButtonClick();
                                        },

                                        createBillingAgreement: () => {
                                            return paypalCheckoutInstance.createPayment({
                                                flow: "vault",
                                            });
                                        },

                                        onApprove: (data) => {
                                            return paypalCheckoutInstance.tokenizePayment(data, (err, payload) => {
                                                callback(payload);
                                            });
                                        },

                                        onError: () => {
                                            throw new Error(err);
                                        },
                                    })
                                    .render(config.buttonTarget);
                            },
                        );
                    },
                );
            },
        );
    } catch (e) {
        throw new Error(err);
    }
};
