import { CheckoutForm } from "./checkout-form";
export class ExpressCheckout extends CheckoutForm {
    constructor(data = {}) {
        super(data);
        this.continuity = data.continuity || false;
        this.legalTermsCondition = data.legalTermsCondition || false;
        this.captcha = data.captcha || "";
        this.isCVVValid = data.isCVVValid || false;
        this.isCCNumberValid = data.isCCNumberValid || false;
        this.creditCardInfo = data.creditCardInfo || {};
        this.floatCVVLabel = data.floatCVVLabel || false;
    }
}
