import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../../contentful/component-renderer";
import "./flex-header.scss";

export const FlexHeader = ({ fieldId, fields }) => {
    const { leftContainer, middleContainer, rightContainer } = fields;

    return (
        <Fragment>
            <header id={fieldId} className="bb-fh">
                <div className="bb-fh-left">{<FieldRenderer contents={leftContainer} />}</div>
                <div className="bb-fh-middle">{<FieldRenderer contents={middleContainer} />}</div>
                <div className="bb-fh-right">{<FieldRenderer contents={rightContainer} />}</div>
            </header>
        </Fragment>
    );
};

FlexHeader.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
};
