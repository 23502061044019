import React, { useEffect, useReducer } from "react";
import { EventEmitter } from "fbemitter";
import { Popup } from "../modal";
import { reducer } from "./popup-emitter-reducer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { logCustomEvents } from "../../web-analytics";
export const PopupEmitter = new EventEmitter();
export const SessionEmitter = new EventEmitter();
export const TRIGGER_POPUP = "TRIGGER:POPUP";
export const TRIGGER_SESSION_MODAL = "TRIGGER:SESSION:MODAL";

export const HarmonyPopup = () => {
    const initialState = {
        showPopup: false,
        modalContent: null,
        modalSize: "md",
        modalHeader: null,
        customizedContent: null,
        closeButton: true,
        footer: false,
        onClickNo: null,
        cancelBtnName: null,
        modalClass: "",
        header: true,
        popupContent: null,
        customCloseButton: null,
        modalId: "",
        backdrop: null,
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        header,
        showPopup,
        modalContent,
        modalSize,
        modalHeader,
        customizedContent,
        closeButton,
        footer,
        onClickNo,
        cancelBtnName,
        modalClass,
        popupContent,
        customCloseButton,
        modalId,
        backdrop,
    } = state || {};
    const _handleClose = () => {
        dispatch({ type: "toggle-popup", payload: false });
    };
    useEffect(() => {
        const popupEmitter = PopupEmitter.addListener(
            "TRIGGER:POPUP",
            ({
                showPopup,
                modalContent,
                modalSize,
                modalHeader,
                customizedContent,
                closeButton,
                footer,
                onClickNo,
                cancelBtnName,
                modalClass,
                header,
                popupContent,
                customCloseButton,
                modalId,
                backdrop,
            }) => {
                modalId && logCustomEvents("modal", "popup_event", modalId);
                dispatch({
                    type: "state-updation",
                    payload: {
                        showPopup: showPopup,
                        modalContent,
                        modalSize,
                        modalHeader,
                        customizedContent,
                        closeButton,
                        footer,
                        onClickNo,
                        cancelBtnName,
                        modalClass,
                        header,
                        popupContent,
                        customCloseButton,
                        modalId,
                        backdrop,
                    },
                });
            },
        );
        return () => {
            popupEmitter.remove();
        };
    }, []);
    return (
        <div className="harmony-popup">
            <Popup
                show={showPopup}
                handleClose={_handleClose}
                header={header}
                closeButton={closeButton}
                customCloseButton={customCloseButton}
                content={popupContent ? popupContent : documentToReactComponents(modalContent)}
                size={modalSize}
                modalSize={modalSize === "lg" || modalSize === "xl" ? "align-items-inherit" : null}
                modalHeading={modalHeader}
                customizedContent={customizedContent}
                backdrop={backdrop ? backdrop : "static"}
                footer={footer}
                onClickNo={onClickNo ? onClickNo : _handleClose}
                cancelBtnName={cancelBtnName}
                modalClass={modalClass}
                modalId={modalId}
            />
        </div>
    );
};
