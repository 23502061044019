import { put, takeLatest, all, call } from "redux-saga/effects";
import { pageRouterActions } from "./page-router-action";
import { fetchContentfulPage, fetchWCSContent, logUserAction } from "../../../api";
import { sessionLogout } from "../../../api/contentfulApi";
import { fetchIPAddress, fetchSurveyInfo, fetchCountryCode } from "../../../api/offersApi";
import { setOffersPageId } from "../../../helper/util";

export function* fetchContentfulPageData(action) {
    const {
        payload: { contentTypeId, field, value = "default", isToGetConfiguration, csr },
        callbacks,
    } = action || {};
    try {
        const response = yield fetchContentfulPage({ contentTypeId, field, value, csr });
        if (response && response.status?.toLowerCase() === "error") {
            callbacks && callbacks.failure(response);
            return;
        } else {
            if (response) {
                const {
                    fields: { pageTemplate, offersPageId, featureFlags, regionConflictPopup },
                    hostUrl,
                    basePageCSS,
                    sites,
                    user_country,
                } = response || { fields: {} };
                setOffersPageId(offersPageId);
                yield !isToGetConfiguration &&
                    put({
                        type: pageRouterActions.SET_PAGE_ROUTER_STATE,
                        payload: {
                            page: response,
                            pageId: contentTypeId,
                            pageTemplateName: pageTemplate ? pageTemplate : "OneColumnTemplate",
                            hostUrl,
                            basePageCSS,
                            sites: sites || [],
                            user_country,
                        },
                    });
                if (featureFlags) {
                    yield put({ type: pageRouterActions.FEATURE_FLAGS_CONTENT, payload: featureFlags, user_country });
                }
                regionConflictPopup
                    ? yield put({ type: pageRouterActions.SET_PAGE_ROUTER_STATE, payload: { regionConflictPopup, basePageCSS, user_country } })
                    : null;
                callbacks && callbacks.success(response);
            }
        }
    } catch (error) {
        console.error(`Error in fetchContentfulPageData - Content Type: "${contentTypeId}" - ${error}`);
        callbacks && callbacks.failure(error);
    }
}
function* doLogout(action) {
    const { callbacks } = action || {};
    const { success, failure } = callbacks || {};
    try {
        const result = yield call(sessionLogout, action);
        result.status === "success" ? success && success() : failure && failure();
    } catch (error) {
        console.error(error);
    }
}
function* getWCSContent() {
    try {
        const termsOfuse = yield call(fetchWCSContent, "termsofuse");
        const privacyPolicy = yield call(fetchWCSContent, "privacyPolicy");
        const terms = termsOfuse && termsOfuse.status === "success" ? termsOfuse.response : null;
        const policy = privacyPolicy && privacyPolicy.status === "success" ? privacyPolicy.response : null;
        const details = { termsOfUse: terms || null, privacyPolicy: policy || null };
        yield put({ type: pageRouterActions.MODAL_CONTENT, payload: details });
    } catch (error) {
        console.error(error);
    }
}
function* doLogUserAction(action) {
    const { payload } = action;
    try {
        yield call(logUserAction, payload);
    } catch (error) {
        console.error(error);
    }
}
function* getSurveyInfo(action) {
    const { payload } = action;
    try {
        const info = yield call(fetchSurveyInfo, payload);
        yield put({ type: pageRouterActions.SET_SURVEY_INFO, payload: info });
    } catch (error) {
        console.error(error);
    }
}
function* getCountryCode(action) {
    let userGEOLocation = "us";
    const { callback } = action || {};
    try {
        const ip = yield fetchIPAddress();
        const response = yield fetchCountryCode(ip);
        const { userGeoLocation } = response || {};
        userGEOLocation = userGeoLocation;
    } catch (err) {
        console.error(err);
    }
    sessionStorage.setItem("user_geolocation", userGEOLocation);
    //localStorage.setItem("i18nextLng", defaultLanguage);
    sessionStorage.setItem("ipDetectionCompleted", "true");
    callback instanceof Function && callback();
}
function* actionWatcher() {
    yield all([
        takeLatest(pageRouterActions.GET_PAGE_DATA, fetchContentfulPageData),
        takeLatest(pageRouterActions.SESSION_LOGOUT, doLogout),
        takeLatest(pageRouterActions.GET_TERMS_OF_USE_AND_PRIVACY_CONTENT, getWCSContent),
        takeLatest(pageRouterActions.LOG_USER_ACCESS, doLogUserAction),
        takeLatest(pageRouterActions.GET_SURVEY_INFORMATION, getSurveyInfo),
        takeLatest(pageRouterActions.GET_COUNTRY_CODE, getCountryCode),
    ]);
}
export default function* pageRouterSaga() {
    yield all([actionWatcher()]);
}
