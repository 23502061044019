export const Configuration = () => {
    return {
        AKAMAI: {
            clientId: "654ugjgt445zpvvpsw5pkezehr99786b",
            host: "https://beachbody.us.janraincapture.com",
            maxAge: "6000",
            redirectHost: "http://www.beachbody.com",
        },
        ATG_PROXY_URL: "https://www.teambeachbody.com",
        BEACHBODY_ON_DEMAND: "https://www.beachbodyondemand.com",
        BEACHBODY_ON_DEMAND_API_URL: "https://api.prod.cd.beachbodyondemand.com",
        BEACHBODY_ON_DEMAND_API_URL_API_KEY: "Y7Xa9iTYjY4hRlOp10QFG53A4TH16BG885GpbZ3i",
        BEACHBODY_ON_DEMAND_MESSAGE_API_URL: "https://message-api.prod.cd.beachbodyondemand.com",
        BEACHBODY_ON_DEMAND_MESSAGE_API_KEY: "JfzLUdBnHfE7ZaCYWUwHA7nL8nCLyKDu",
        CAPTCHA: {
            siteKey: "6LceQKwZAAAAAOic6XvWj_7uTbYMUHVUWGMn1VJj",
        },
        CC_IFRAME_URL: "https://beachbody.cardconnect.com/itoke/ajax-tokenizer.html",
        CONTENTFULCMS: {
            environment: "master",
            host: {
                master: "https://cdn.contentful.com",
                preview: "preview.contentful.com",
            },
            spaceId: "imp192nihlxo",
            useKey: "master",
        },
        CYBERSOURCE: {
            host: "https://beachbody.com",
        },
        TEALIUM: {
            environment: "prod",
            profile: "main",
        },
        WCS: {
            host: "http://wcsrem.beachbody.local",
            page: {
                privacyPolicy: "/privacy",
                termsofuse: "/terms-of-use",
            },
        },
        GTAG: {
            key: "UA-336852-1",
            ukKey: "UA-336852-17",
            INCLUDE_GOOGLE_ANALYTICS_TAG_IN_HOME_PAGE: true,
        },
        APPLE_APP_SITE_ASSOCIATION_CONFIG: {
            webcredentials: {
                apps: ["MHDLZCPG9V.com.beachbody.bod"],
            },
        },
        OPTIMIZELY: {
            sdkKey: "HSWLhVGB429W1sJEQSZxv",
        },
        DATADOG: {
            applicationId: "95508111-5b76-4f36-b130-8b335aca9139",
            clientToken: "pub5bf2cdfbb0b8c0d0b64ea9c5f138cb20",
            env: "prod",
            enableRUM: true,
        },
        DIGIOH: {
            enableDigioh: true,
            url: "https://www.lightboxcdn.com/vendor/a8311464-70d5-4858-8075-c78362632740/lightbox_inline.js",
        },
        PAYPAL: {
            clientId: {
                GB: "Af0nxl5Cm6HZ7W3_xKxt298JWcavVQ2xXc-Aq0JMSr0tkDFQdW9v6cNWnMS-W_93Z4yzMX9DXClNPrJA",
                CA: "AZIC71WpqmZ39LEF93oKplqs9hdPuI-OlF9OKolGjXl0gebvYkS9dzkog72f9fE9dAngTcTFOMPWdVT8",
                US: "AYLXd8Ek0rkU3y--5-FuOW2DnBn3yIYtQmTtj6UhYEiOtb1snTFc0-Qfdxn-66Xr8BZ_cvPJtqdCPrxT",
            },
        },
    };
};
