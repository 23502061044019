import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../../contentful/component-renderer";
import "./navigation-menu.scss";

export const NavigationMenu = ({ fieldId, fields }) => {
    const { menuItems } = fields;
    const [openDrawer, setOpenDrawer] = useState(false);
    const clickDrawerButton = () => {
        setOpenDrawer(!openDrawer);
    };

    return (
        <Fragment>
            <nav id={fieldId} className="bb-nm">
                <ul className="bb-nm-desktop">{<FieldRenderer contents={menuItems} />}</ul>
                <ul className={`bb-nm-mobile${openDrawer ? " open" : ""}`}>{<FieldRenderer contents={menuItems} />}</ul>
                <div className="bb-hamburger-menu-button">
                    <button className={`hamburger hamburger--slider${openDrawer ? " open" : ""}`} onClick={clickDrawerButton} type="button">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </nav>
        </Fragment>
    );
};

NavigationMenu.propTypes = {
    fieldId: PropTypes.string,
    fields: PropTypes.object,
};
