import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { ModalContent } from "./modal-content";
import { CTAButton } from "../cta-button";
import { Loader } from "../loader";
import "./modal.scss";
export const Popup = ({
    show,
    modalHeading,
    handleClose,
    modalSize,
    content,
    onClickYes,
    onClickNo,
    footer,
    showLoader,
    header = true,
    cancelBtnName,
    actionBtnName,
    closeButton = false,
    customizedContent,
    btnDisable,
    size = "md",
    backdrop,
    modalClass = "",
    customModalClass = "",
    customCloseButton = null,
    modalId = "",
    backdropClassName = ""
}) => {
    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className={customModalClass ? customModalClass : `bdd-modal-popup ${modalSize} ${modalClass}`}
            show={show}
            onHide={handleClose}
            centered
            size={size}
            backdrop={backdrop}
            keyboard={false}
            id={modalId || modalClass}
            backdropClassName={backdropClassName}
        >
            {customizedContent ? customCloseButton : null}
            {header && (
                <Modal.Header className={modalHeading ? "" : "no-border"} closeButton={closeButton}>
                    <Modal.Title id="contained-modal-title-vcenter">{modalHeading}</Modal.Title>
                </Modal.Header>
            )}
            <Modal.Body>
                {
                    <ModalContent
                        handleClose={handleClose}
                        content={content}
                        customizedContent={customizedContent}
                    />
                }
            </Modal.Body>
            {footer && (
                <Modal.Footer>
                    {showLoader && <Loader isToShowLoader={showLoader} />}
                    {!showLoader && onClickNo && (
                        <CTAButton
                            outerBtnClass="confirm-no"
                            buttonName={cancelBtnName}
                            onButtonClick={onClickNo}
                        />
                    )}
                    {!showLoader && onClickYes && (
                        <CTAButton
                            outerBtnClass="confirm-yes"
                            buttonName={actionBtnName}
                            onButtonClick={onClickYes}
                            disableButton={btnDisable ? { disabled: "disabled" } : null}
                        />
                    )}
                </Modal.Footer>
            )}
        </Modal>
    );
};
Popup.propTypes = {
    show: PropTypes.bool, // To Hide/Show Popup
    modalHeading: PropTypes.string, //Receives Heading for modal
    handleClose: PropTypes.func, //Triggers function to close the popup
    modalSize: PropTypes.string, //Receives modal size class- options (small-modal, medium modal) *If anything is required need to add class in layout css*
    content: PropTypes.any, // Content Type For modal popup
    onClickYes: PropTypes.func, // Confirm on Yes
    onClickNo: PropTypes.func, // Confirm on No
    footer: PropTypes.bool, // To Toggle Modal Footer,
    showLoader: PropTypes.bool, // To Toggle the loader
    header: PropTypes.bool, // TO Toggle Header
    cancelBtnName: PropTypes.string,
    actionBtnName: PropTypes.string,
    closeButton: PropTypes.bool,
    customizedContent: PropTypes.node,
    btnDisable: PropTypes.bool,
    size: PropTypes.string,
    backdrop: PropTypes.string,
    modalClass: PropTypes.string,
    customCloseButton: PropTypes.any,
    modalId: PropTypes.string,
    backdropClassName: PropTypes.string,
    customModalClass: PropTypes.string
};
