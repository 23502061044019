export const pageRouterActions = {
    GET_SITE_MAP: "GET_SITE_MAP",
    GET_PAGE_DATA: "GET_PAGE_DATA",
    SET_PAGE_ROUTER_STATE: "SET_PAGE_ROUTER_STATE",
    HANDLE_WINDOW_RESIZE: "HANDLE_WINDOW_RESIZE",
    SESSION_LOGOUT: "SESSION_LOGOUT",
    GET_TERMS_OF_USE_AND_PRIVACY_CONTENT: "GET_TERMS_OF_USE_AND_PRIVACY_CONTENT",
    MODAL_CONTENT: "MODAL_CONTENT",
    LOG_USER_ACCESS: "LOG_USER_ACCESS",
    SET_USER_DETAILS: "SET_USER_DETAILS",
    GET_SURVEY_INFORMATION: "GET_SURVEY_INFORMATION",
    SET_SURVEY_INFO: "SET_SURVEY_INFO",
    EDIT_EMAIL: "EDIT_EMAIL",
    OUTRO_PAGE_CONTENT: "OUTRO_PAGE_CONTENT",
    GET_COUNTRY_CODE: "GET_COUNTRY_CODE",
    SET_INTERSTITIAL_PAGE: "SET_INTERSTITIAL_PAGE",
    FEATURE_FLAGS_CONTENT: "FEATURE_FLAGS_CONTENT",
};
