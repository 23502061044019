import React from "react";
import PropTypes from "prop-types";

export const CheckBox = ({ name, checkBoxClass, onChange, checked }) => {
    return (
        <div className={checkBoxClass}>
            <input
                id={name}
                name={name}
                onChange={onChange}
                checked={checked}
                type="checkbox"
            />
        </div>
    );
};
CheckBox.propTypes = {
    name: PropTypes.string, // Input name attr
    onChange: PropTypes.func, // On Change Func
    checkBoxClass: PropTypes.string, //Class for the input form group,
    checked: PropTypes.bool,
};
