import React from "react";
import PropTypes from "prop-types";
import { YOUTUBE_EMBED_URL, YOUTUBE_EMBED_URL_PARAMS } from "../../../constants/urls";
import "../index.scss";

export const YouTubeContainer = ({ fields }) => {
    const { videoId } = fields;
    const youtubeURL = YOUTUBE_EMBED_URL + videoId + `?playlist=${videoId}` + YOUTUBE_EMBED_URL_PARAMS;

    return (
        <div>
            <iframe
                src={youtubeURL}
                width="100%"
                height="484"
                frameBorder="0"
                allow="accelerometer;
                autoplay;
                lipboard-write;
                encrypted-media;
                gyroscope;
                picture-in-picture"
                allowFullScreen
            />
        </div>
    );
};

YouTubeContainer.propTypes = {
    fields: PropTypes.object,
};
