import React from "react";
import PropTypes from "prop-types";

export const ModalContentElement = ({ content }) => {
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: content,
            }}
        ></div>
    );
};

ModalContentElement.propTypes = {
    content: PropTypes.any,
};
