export const CyberSourceIframeStyles = {
    input: {
        "font-family":
            "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
        "font-size": "14px",
        "line-height": "1.5",
        color: "#000 !important",
        "font-weight": "normal",
    },
    ":focus": { color: "blue" },
    ":disabled": { cursor: "not-allowed" },
    valid: { color: "#3c763d" },
    invalid: { color: "#a94442" },
};
export const CyberSourceIframeStylesMyx = {
    input: {
        color: "#fff",
        "font-family":
            "'Lato',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
        "font-size": "16px",
        "font-weight": "normal",
        "line-height": "1.5",
    },
    ".flex-microform-autocomplete": {
        background: "#151819 !important",
        "-webkit-box-shadow": "0 0 0px 1000px #151819 inset",
        "-moz-box-shadow": "0 0 0px 1000px #151819 inset",
        "box-shadow": "0 0 0px 1000px #151819 inset",
        "-webkit-text-fill-color": "#fff",
    },
    ":disabled": { cursor: "not-allowed" },
    //":focus": { "color": "blue" },
    invalid: { color: "#f00" },
    //"valid": { "color": "#3c763d" },
};
