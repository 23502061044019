import React, { Fragment, useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { CreditCardIframe, CTAButton, CyberSourceIframe, TextInput } from "../../../common";
import { ExpiryDate, validateMMYYFormat } from "../../../helper/util";
import { CreditCards } from "./credit-cards";
import { useOnClickOutside } from "../../common/util";
import * as tealiumTags from "../../../constants/tealium-tags";
import { logLinkTracking } from "../../../web-analytics";
import { PayPalLogo } from "../components";

export function MyxCreditCardDetails({
    calculateTax,
    dispatch,
    doFieldValidation,
    formData,
    formErrors,
    onChange,
    onShowUpdateCCModal,
    sectionTitle,
    setFormErrors,
    showSummary,
    t,
    validateField,
    isPaypalDefault,
    setIsCreditCardSelected,
    isCreditCardSelected,
}) {
    const { cyberSource, microFormError, isCVViFrame } = useSelector((state) => state.checkout);
    const [showInfoModal, setShowInfoModal] = useState();
    const modalRef = useRef();
    const isCCDetailsError =
        doFieldValidation && (formErrors["ccNumber"] || formErrors["ccExpiry"] || !formData["ccMonth"] || !formData["ccYear"] || formErrors["ccCVV"])
            ? true
            : false;

    useOnClickOutside(modalRef, () => setShowInfoModal(false));

    const onCCExpiryDetailsChange = (name, value) => {
        let details;
        if (value) {
            // formErrors && formErrors["ccExpiry"] && setFormErrors("ccExpiry", "");
            const result = validateMMYYFormat(value);
            const monthAndDate = result && result.split("/");
            details = {
                ccMonth: monthAndDate["0"],
                ccYear: monthAndDate["1"],
                ccExpDate: result,
            };
            onChange("", "", details);
        } else {
            details = {
                ccMonth: "",
                ccYear: "",
                ccExpDate: "",
            };
            onChange("", "", details);
        }
        const setExpDateErrors = (ccM, ccY, ccE) => {
            const errors = {
                ccMonth: ccM,
                ccYear: ccY,
                ccExpiry: ccE,
            };
            setFormErrors("", "", errors);
        };
        const { ccMonth, ccYear } = details || {};
        if (doFieldValidation) {
            const isExpired = ExpiryDate(ccYear, ccMonth);
            if (isExpired || (!ccMonth && !ccYear)) {
                const ccE = isExpired ? t("card-expired") : t("exp-date-required");
                setExpDateErrors("", "", ccE);
            } else {
                const ccMonthErr = validateField({ name: "ccMonth", value: ccMonth });
                const ccYearErr = validateField({ name: "ccYear", value: ccYear });
                setExpDateErrors(ccMonthErr ? t(ccMonthErr) : "", ccYearErr ? t(ccYearErr) : "", "");
            }
        }
    };
    const getNameField = (name, label, analyticsId) => {
        return (
            <TextInput
                name={name}
                id={name}
                floatLabel={t(label)}
                maxLength={30}
                error={formErrors[name]}
                onBlur={doFieldValidation ? validateField : null}
                setFormErrors={setFormErrors}
                dValue={formData[name]}
                onChange={onChange}
                analyticsId={analyticsId}
            />
        );
    };
    const showCVVInfoModal = () => {
        setShowInfoModal(!showInfoModal);
    };
    const openUpdateCCModal = () => {
        logLinkTracking(tealiumTags.myx_cc_change);
        onShowUpdateCCModal();
    };
    const getCardTypeName = (type) => {
        let name = "";
        switch (type) {
            case "amex":
                name = "AM";
                break;
            case "visa":
                name = "VISA";
                break;
            case "mastercard":
                name = "MC";
                break;
            case "discover":
                name = "DISC";
            case "cup":
                name = "CUP";
                break;
        }
        return name;
    };
    const creditCardType = formData["ccType"] || "";
    const cardCardName = getCardTypeName(creditCardType);
    const billingZipCode = formData["billingPostalCode"] || "";
    const lastFourDigits = formData?.["lastFourDigits"] ? formData["lastFourDigits"] : formData["ccNumber"] ? formData["ccNumber"].substr(-4) : "";
    const paymentInfo = t("payment-information");
    const whatsCvv = t("whats-a-cvv-code");
    const ccError = t("cc-error");
    const ccNumber = t("cc-number");
    const ccAccept = t("cc-accept");
    const expMonthYear = t("exp-month-year");
    const cvv = t("cvv");
    const cvvInfo = t("myx-cvv-info");
    const billingZipCodeTxt = t("billing-zip-code");
    const endingIn = t("ending-in");

    return (
        <section id="cust-cc-info" className="cust-cc-info mt-4">
            <h2>{sectionTitle ? sectionTitle : paymentInfo}</h2>
            {showSummary && (
                <div className="input-group d-flex">
                    <h3 className="heading mb-3">{t("pay-with")}</h3>
                    <div className="credit-card-summary d-flex flex-wrap align-items-start mb-4">
                        <div>
                            {isPaypalDefault ? (
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="creditCardPayment"
                                                id="credit-card-payment"
                                                checked={isCreditCardSelected}
                                                onChange={() => setIsCreditCardSelected(true)}
                                                aria-label={t("checkout-radio-buttons-cc-title")}
                                            />
                                            <label className="form-check-label d-flex" htmlFor="credit-card-payment">
                                                {t("checkout-radio-buttons-cc-title")}
                                                <CreditCards containerClassNames="ml-3" />
                                            </label>
                                        </div>
                                        <div className="input-group-text">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="paypalPayment"
                                                id="paypal-payment"
                                                checked={isCreditCardSelected !== true}
                                                onChange={() => setIsCreditCardSelected(false)}
                                                aria-label="PayPal"
                                            />
                                            <label className="form-check-label" htmlFor="paypal-payment">
                                                <PayPalLogo />
                                            </label>
                                        </div>
                                        {!isCreditCardSelected && (
                                            <div className="paypal-user-info d-flex flex-column mt-3">
                                                <div>{formData["paymentMethodAccount"]}</div>
                                                <div className="mt-2">{`${billingZipCodeTxt} ${billingZipCode}`}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <Fragment>
                                    <div className="credit-card-type d-flex flex-row">
                                        <CreditCards type={creditCardType} />
                                        <div className="ml-3">{`${cardCardName} ${endingIn} ${lastFourDigits}`}</div>
                                    </div>
                                    <div className="cc-billing-zip-code-info mt-2">{`${billingZipCodeTxt} ${billingZipCode}`}</div>
                                </Fragment>
                            )}
                        </div>
                        {!isPaypalDefault && (
                            <CTAButton
                                outerBtnClass="change-btn-container align-items-start"
                                innerBtnClass="change-btn"
                                buttonName={t("different-card")}
                                onButtonClick={openUpdateCCModal}
                            />
                        )}
                    </div>
                </div>
            )}

            {(!showSummary || isCreditCardSelected) && (
                <div className="section-content position-relative">
                    {(!isCreditCardSelected || isPaypalDefault) && (
                        <Fragment>
                            <h3 className="heading mb-3">{t("pay-with")}</h3>
                            <div className="cc-flags mb-4">
                                <CreditCards />
                            </div>
                        </Fragment>
                    )}
                    <div className="row m-0">
                        <div className="col-md-6 pl-0 pr-0 pr-md-2">
                            {getNameField("billingFirstName", "card-holder-first-name", tealiumTags.myx_cc_first_name)}
                        </div>
                        <div className="col-md-6 pl-0 pr-0">{getNameField("billingLastName", "card-holder-last-name", tealiumTags.myx_cc_last_name)}</div>
                    </div>
                    <div className="cc-info-row d-flex">
                        <div className="ml-2">
                            <span className="cvv-info-text" onClick={showCVVInfoModal}>
                                {whatsCvv}
                            </span>
                        </div>
                    </div>
                    <div className="credit-card mx-0 mb-4">
                        <div className={`row credit-card-details ${isCCDetailsError ? "error-border" : ""}`}>
                            <div className="iframe-section pl-0">
                                {cyberSource ? (
                                    <CyberSourceIframe
                                        id="cardNumber-container"
                                        dispatch={dispatch}
                                        doFieldValidation={doFieldValidation}
                                        formData={formData}
                                        formErrors={formErrors}
                                        formOrigin="myx"
                                        onChange={onChange}
                                        setFormErrors={setFormErrors}
                                        t={t}
                                        validateField={validateField}
                                    />
                                ) : (
                                    <CreditCardIframe
                                        name="tokenframe"
                                        id="tokenframe"
                                        type="hidden"
                                        error={ccError}
                                        inputStyle={encodeURI(
                                            "body{margin: 0} input{outline:none;position:relative;top:8px;padding-top: .8125rem;padding-bottom: .8125rem;padding-left: 11px;display: block;margin: 0 0 .5625rem;box-sizing: border-box;border-radius: 0px;width: 100%;font-size: 14px;font-weight: 300;background-color: rgb(255, 255, 255);border: 0px solid rgb(182, 182, 182);color: rgb(0, 0, 0);height: auto;} input.error{border: 0px solid red} inpu:focus{border:0px;}",
                                        )}
                                        placeholder={ccNumber}
                                        required={true}
                                        additParams="cardlabel=+&invalidinputevent=true&highlightinvalidinput=true"
                                        setFormErrors={setFormErrors}
                                        formErrors={formErrors}
                                        hintText={ccAccept}
                                        formData={formData}
                                        onChange={onChange}
                                    />
                                )}
                            </div>
                            <div className="d-flex exp-dd credit-card-exp-cvv-details">
                                <TextInput
                                    dValue={formData["ccExpDate"]}
                                    error={formErrors["ccYear"] ? true : false}
                                    floatLabel={expMonthYear}
                                    id="ccExpDate"
                                    inputType="mm/yy"
                                    maxLength={5}
                                    name="ccExpDate"
                                    onBlur={doFieldValidation ? () => onCCExpiryDetailsChange("ccExpDate", formData["ccExpDate"]) : null}
                                    onChange={onCCExpiryDetailsChange}
                                    setFormErrors={setFormErrors}
                                    type="text"
                                />
                                {isCVViFrame ? (
                                    <div className="text-input cybersource-iframe">
                                        <label className={`cvv-iframe iframe-placeholder ${formData.floatCVVLabel ? "float" : ""}`}>{t("cvv")}</label>
                                        <div
                                            id="ccCVV_iFrame"
                                            className={`${doFieldValidation && formErrors && formErrors["ccCVV"] ? "error-border" : ""}`}
                                        ></div>
                                    </div>
                                ) : (
                                    <TextInput
                                        dValue={formData["ccCVV"]}
                                        error={formErrors["ccCVV"] ? true : false}
                                        floatLabel={cvv}
                                        id="ccCVV"
                                        inputType="number"
                                        maxLength={4}
                                        name="ccCVV"
                                        onBlur={doFieldValidation ? validateField : null}
                                        onChange={onChange}
                                        setFormErrors={setFormErrors}
                                        type="type"
                                    />
                                )}
                            </div>
                            {showInfoModal && (
                                <div className="cvv-info-modal" ref={modalRef}>
                                    <div
                                        className="cvv-info"
                                        dangerouslySetInnerHTML={{
                                            __html: cvvInfo,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {microFormError && (
                        <label
                            className="error-msg"
                            dangerouslySetInnerHTML={{
                                __html: microFormError,
                            }}
                        ></label>
                    )}
                    {doFieldValidation && formErrors["ccNumber"] && (
                        <label htmlFor={name} id="ccError" className="error-msg">
                            {formErrors["ccNumber"]}
                        </label>
                    )}
                    {doFieldValidation && formErrors["ccMonth"] && <div className="error-msg">{formErrors["ccMonth"]}</div>}
                    {doFieldValidation && formErrors["ccYear"] && <div className="error-msg">{formErrors["ccYear"]}</div>}
                    {doFieldValidation && formErrors["ccExpiry"] && <div className="error-msg">{formErrors["ccExpiry"]}</div>}
                    {doFieldValidation && formErrors["ccCVV"] && (
                        <label htmlFor={name} id="ccError" className="error-msg">
                            {formErrors["ccCVV"]}
                        </label>
                    )}
                    <TextInput
                        dValue={formData["billingPostalCode"]}
                        error={formErrors["billingPostalCode"]}
                        floatLabel={billingZipCodeTxt}
                        id="billingPostalCode"
                        maxLength={10}
                        name="billingPostalCode"
                        onBlur={calculateTax}
                        onChange={onChange}
                        setFormErrors={setFormErrors}
                        textDivStyle="billing-zip-code"
                        type="text"
                        analyticsId={tealiumTags.myx_checkout_zipcode}
                    />
                </div>
            )}
        </section>
    );
}
MyxCreditCardDetails.propTypes = {
    calculateTax: PropTypes.func,
    cartItem: PropTypes.object,
    dispatch: PropTypes.func,
    doFieldValidation: PropTypes.bool,
    formData: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    onShowUpdateCCModal: PropTypes.func,
    sectionTitle: PropTypes.string,
    setFormErrors: PropTypes.func,
    showSummary: PropTypes.bool,
    t: PropTypes.func,
    validateField: PropTypes.func,
    isPaypalDefault: PropTypes.bool,
    setIsCreditCardSelected: PropTypes.func,
    isCreditCardSelected: PropTypes.bool,
};
