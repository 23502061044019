import React from "react";
import PropTypes from "prop-types";
import dompurify from "dompurify";
import { logLinkTracking } from "../../web-analytics";
import "./cta-button.scss";
export const CTAButton = ({
    buttonName,
    onButtonClick,
    outerBtnClass = "",
    innerBtnClass = "",
    disableButton = {},
    icon,
    style = null,
    trackId,
    appendElem = null,
}) => {
    const _logLinkTracking = () => {
        const source = (trackId ? trackId + ":" : "") + (buttonName && buttonName.toLowerCase().split(" ").join("-"));
        logLinkTracking(source);
    };
    const sanitizer = dompurify.sanitize;

    return (
        <div className={`cta-btn ${outerBtnClass}`}>
            <button
                aria-label=""
                className={`button ${innerBtnClass}`}
                onClick={(event) => {
                    _logLinkTracking();
                    onButtonClick(event);
                }}
                {...disableButton}
                style={style}
            >
                {icon && <span className={`fa ${icon} mr-2 button-icon`}></span>}
                <span
                    dangerouslySetInnerHTML={{
                        __html: sanitizer(buttonName),
                    }}
                />
                {appendElem}
            </button>
        </div>
    );
};

CTAButton.propTypes = {
    buttonName: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    innerBtnClass: PropTypes.string,
    outerBtnClass: PropTypes.string,
    icon: PropTypes.string,
    disableButton: PropTypes.object,
    style: PropTypes.object,
    trackId: PropTypes.string,
    appendElem: PropTypes.element,
};
