import { FlexHeader } from "./top-bar-flex-header";
import { TBLogosBrandLeftCompanyRight, TBLogosCompanyLeftBrandRight } from "./top-bar-with-double-logo";
import { TopBarLoginLeftSignUPRight, TopBarSignUPLeftLoginRight, TopBarSingleCTA } from "./top-bar-with-signup-login";
import { TopBarWithSingleLogoLeft, TopBarWithSingleLogoRight } from "./top-bar-with-single-logo";

export default {
    "tb-flex-header": FlexHeader,
    "tb-login-left-signup-right": TopBarLoginLeftSignUPRight,
    "tb-logos-brand-left-company-right": TBLogosBrandLeftCompanyRight,
    "tb-logos-company-left-brand-right": TBLogosCompanyLeftBrandRight,
    "tb-signup-left-login-right": TopBarSignUPLeftLoginRight,
    "tb-single-logo-left": TopBarWithSingleLogoLeft,
    "tb-single-logo-right": TopBarWithSingleLogoRight,
    "top-bar-single-cta": TopBarSingleCTA,
};