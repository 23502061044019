/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./i18n/i18n";
import "./styles/App.scss";
import { FieldRenderer, PageTemplateRenderer } from "./contentful/component-renderer";
import { PopupEmitter, TRIGGER_POPUP } from "./common/popup-emitter";
import { countryCodeForDomain } from "./constants/app-languages";
import { defaultLanguages } from "./helper/language-supported";
import { createDigitalData, logDigitalData } from "./web-analytics";
import { REGION_CONFLICT_POPUP } from "./constants/urls";
import { getCookie } from "./helper/util";
import { initializeDataDogRUM, shouldFetchSessionId } from "./helper/util";
import { checkoutActions } from "./module/checkout-page/data/checkout-page-action";
import { offersActions } from "./module/offers-page/data/offers-page-action";

const BDDirectApp = () => {
    const dispatch = useDispatch();
    const { offerId, page, pageId, pageTemplateName, primaryProductCode, locale, regionConflictPopupData, user_country, digitalData } = useSelector(
        (state) => state.pageRouter,
    );
    const { merlineConfig, datadogRumEnabled, datadogRumSamplingRate } = useSelector((state) => state.checkout);
    const sessionId = useSelector((state) => state.offers.sessionId);
    const checkIfUserIsNotFromSameCountry = () => {
        const siteLocale = process.env.BROWSER ? localStorage.getItem("i18nextLng") : locale;
        if (siteLocale && countryCodeForDomain) {
            const sitecountry = siteLocale.split("_")[1];
            const domain = countryCodeForDomain.find((domain) => domain.code === sitecountry.toLowerCase());
            const countryCode = user_country || getCookie("bb_akmes-geoloc-country");
            if (countryCode && domain?.notAllowedFrom?.includes(countryCode)) {
                const localeCode = defaultLanguages[countryCode];
                const { regionConflictPopup, extractedCSS } = regionConflictPopupData || {};
                const popUpContent = regionConflictPopup?.find((content) => content.fields.id === localeCode);
                const content = popUpContent && <FieldRenderer contents={popUpContent} />;
                if (content) {
                    const data = createDigitalData({ dd: digitalData, path: window.location.pathname + REGION_CONFLICT_POPUP });
                    data && logDigitalData(data);
                    PopupEmitter.emit(TRIGGER_POPUP, {
                        backdropClassName: "content-managed-popup-backdrop",
                        customizedContent: (
                            <div>
                                <style>{extractedCSS.basePageCSS}</style>
                                {content}
                            </div>
                        ),
                        header: false,
                        showPopup: true,
                        modalSize: "md",
                        modalClass: "conflict-modal",
                    });
                }
            }
        }
    };
    useEffect(checkIfUserIsNotFromSameCountry, []);

    useEffect(() => {
        shouldFetchSessionId(true) && !sessionId && dispatch({ type: offersActions.GET_SESSION_ID });
    }, []);

    useEffect(() => {
        sessionId && !merlineConfig && dispatch({ type: checkoutActions.GET_CONFIGURATIONS });
    }, [sessionId]);

    useEffect(() => {
        if (datadogRumEnabled === "true" || datadogRumEnabled === true) {
            initializeDataDogRUM(datadogRumSamplingRate);
            window.DD_RUM_Config = { samplingRate: datadogRumSamplingRate, isEnabled: true };
        } else {
            window.DD_RUM_Config = { samplingRate: "", isEnabled: false };
        }
    }, [datadogRumEnabled]);

    return (
        <div className="main-container full-container">
            {pageTemplateName && page ? (
                <PageTemplateRenderer
                    offerId={offerId}
                    page={page}
                    pageId={pageId}
                    pageTemplateName={pageTemplateName}
                    primaryProductCode={primaryProductCode}
                />
            ) : null}
        </div>
    );
};

export default BDDirectApp;
