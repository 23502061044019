export class DefaulCCBillingAddress {
    constructor(data = {}) {
        this.billingFirstName = data.firstName || "";
        this.billingLastName = data.lastName || "";
        this.billingAddressLine1 = data.address1 || "";
        this.billingCountry = data.country || "";
        this.billingCity = data.city || "";
        this.billingPostalCode = data.postalCode || "";
        this.billingState = data.state || "";
    }
}