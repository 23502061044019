import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { pageRouterActions } from "../../component/page-router/data/page-router-action";
import { getCookie, getOffersPageId, getUrlParam, setCookie, setOffersPageId } from "../../helper/util";
import { useDispatch, useSelector } from "react-redux";
import { CTAButton, Loader } from "../../common";
import { SurveyQuestion } from "./survey-question";
import { redirectWithParam } from "../common/util";
import { useHistory } from "react-router";
import "./survey.scss";
import { CREATE_ACCOUNT_PATH, DEFAULT_OFFER_CODE } from "../../constants/urls";
import { PageHeader } from "../page/page-header";
import { SurveyIntroOutro } from "./intro-page";
import { QuizInterstitialUpsellPage } from "../interstitial-page/index";
import useSessionStorageState from "../../hooks/useSessionStorageState";
import { PROGRAM_RECOMMENDATIONS_ID } from "../create-account/components/constants";
export function SurveyContent(props) {
    const { t } = props || {};
    const {
        fields: { surveyHeader },
    } = props || { fields: {} };
    const dispatch = useDispatch();
    const history = useHistory();
    const surveyDetails = useSelector((state) => state.pageRouter.surveyDetails);
    const { surveyIntroPage, surveyOutroPage } = (surveyDetails && surveyDetails.fields) || { fields: {} };
    const { fields } = surveyIntroPage || {};
    const [showIntroPage, setShowIntroPage] = useState();
    const [surveyQuestions, setSurveyQuestions] = useState();
    const [page, setPage] = useState();
    const [enableButton, setEnableButton] = useState(false);
    const [nextQuestionIds, setNextQuestionIds] = useState([]);
    const id = getUrlParam("sId");
    const loadSurveyData = JSON.parse(getCookie(id) || "{}");
    const offerId = getUrlParam("offerId") || localStorage.getItem("offerId") || DEFAULT_OFFER_CODE;
    const productCode = getUrlParam("productCode") || localStorage.getItem("productCode");
    const royaltyCode = getUrlParam("rc") || localStorage.getItem("royaltyCode");
    const offersPageId = getOffersPageId();
    const [surveyAnswers, setSurveyAnswers] = useState(loadSurveyData.surveyId === id ? loadSurveyData : { surveyId: id });
    const [quizPageIds, setQuizPageIds] = useSessionStorageState("surveyPageIds", []);
    const isQuestionPage = (page && surveyQuestions && surveyQuestions[page - 1]?.sys?.contentType?.sys?.id === "questionAndAnswers") || false;
    const isSurveyEnd = surveyQuestions && surveyQuestions.length === page ? true : false;
    const updateButtonStatus = (pageNo) => {
        const page = pageNo ? pageNo : Number(getUrlParam("page")) || 1;
        const questions = surveyQuestions && surveyQuestions[page - 1];
        const {
            fields: { questionId },
        } = questions || { fields: {} };
        questionId && surveyAnswers[questionId] && surveyAnswers[questionId].length ? setEnableButton(true) : setEnableButton(false);
    };
    const getNextPageIndex = (skipQuestion) => {
        let pageNo;
        if (nextQuestionIds?.length > 0 && !skipQuestion) {
            const unansweredQuestion = nextQuestionIds?.reduce((prev, current) => {
                const { priority: prevPriority } = prev || {};
                const { nextQuestionId, priority: currentPriority } = current || {};
                const isAnswered = quizPageIds.includes(nextQuestionId);
                const validQuestion = surveyQuestions?.find((element) => {
                    const {
                        fields: { questionId, skuId },
                    } = element || { fields: {} };
                    const id = questionId || skuId;
                    return id === nextQuestionId;
                });
                if (validQuestion && !isAnswered) {
                    return prevPriority < currentPriority ? prev : current;
                }
            }, null);
            pageNo = surveyQuestions?.findIndex((item, index) => {
                const {
                    fields: { questionId, skuId },
                } = item || { fields: {} };
                const { nextQuestionId } = unansweredQuestion || {};
                const id = questionId || skuId;
                if (unansweredQuestion) {
                    return id === nextQuestionId;
                } else if (page && page <= index) {
                    return !quizPageIds.includes(id);
                }
            });
        }
        return typeof pageNo === "number" ? (pageNo !== -1 ? pageNo + 1 : pageNo) : null;
    };
    const getPageId = () => {
        const {
            fields: { questionId, skuId },
        } = surveyQuestions[page - 1] || { fields: {} };
        return isQuestionPage ? questionId : skuId;
    };
    const nextQuestion = (skipQuestion = false) => {
        const pageId = getPageId();
        !quizPageIds.includes(pageId) && setQuizPageIds([pageId, ...quizPageIds]);
        const nextPageNo = getNextPageIndex(skipQuestion);
        if (nextPageNo === -1) {
            doSubmitTheSurvey();
            return;
        }
        const pageNo = nextPageNo || (page && page + 1) || 1;
        setPage(pageNo);
        const url = `survey?sId=${id}&page=${pageNo}`;
        setNextQuestionIds([]);
        redirectWithParam(history, url);
        updateButtonStatus(pageNo);
    };
    const goToNextQuestion = () => {
        nextQuestion();
    };
    const getSurveyDetails = () => {
        sessionStorage.setItem("survey_id", id);
        dispatch({ type: pageRouterActions.GET_SURVEY_INFORMATION, payload: id });
    };
    const loadSurvey = () => {
        getSurveyDetails();
        const page = Number(getUrlParam("page")) || 1;
        setPage(page);
        redirectWithParam(history, `survey?sId=${id}${page ? `&page=${page}` : ""}`);
    };
    const doSubmitTheSurvey = () => {
        const pageId = getPageId();
        !quizPageIds.includes(pageId) && setQuizPageIds([pageId, ...quizPageIds]);
        surveyOutroPage && dispatch({ type: pageRouterActions.OUTRO_PAGE_CONTENT, payload: surveyOutroPage });
        redirectWithParam(history, CREATE_ACCOUNT_PATH);
    };
    const skipThisQuestion = () => {
        if (page && surveyQuestions.length) {
            const {
                fields: { questionId },
            } = surveyQuestions[page - 1] || { fields: {} };
            updateSurvey([], questionId);
        }
        page && isSurveyEnd ? doSubmitTheSurvey() : nextQuestion(true);
    };
    const updateSurvey = (answers, qid, nextqid) => {
        answers.length ? setEnableButton(true) : setEnableButton(false);
        const surveyAns = { ...surveyAnswers };
        surveyAns[qid] = answers;
        setSurveyAnswers(surveyAns);
        setNextQuestionIds(nextqid);
        const date = new Date();
        date.setDate(date.getDate() + 29);
        setCookie(id, JSON.stringify(surveyAns), date);
    };
    const navigateToSurveyQuestions = () => {
        setShowIntroPage(false);
        redirectWithParam(history, `survey?sId=${id}&page=1`);
    };
    function getQuizInterestitialContent(content) {
        if (content?.fields?.skuId === PROGRAM_RECOMMENDATIONS_ID) {
            const survey = getCookie(id);
            const answers = survey && JSON.parse(survey)?.fav_types_of_workouts;
            const result = content?.fields?.upsellContent[0]?.fields?.contents[0]?.fields?.carouselContent
                ?.filter((slide) => {
                    const {
                        fields: { id, defaultSlide },
                    } = slide || {};
                    return (answers?.includes(id) && !defaultSlide) || defaultSlide;
                })
                .sort((x, y) => {
                    return x.fields.defaultSlide === y.fields.defaultSlide ? 0 : x.fields.defaultSlide ? 1 : -1;
                });
            content.fields.upsellContent[0].fields.contents[0].fields.carouselContent = result?.length > 0 ? result : [];
        }
        return content;
    }
    useEffect(() => {
        const pageNumber = Number(getUrlParam("page") || null);
        if (pageNumber && pageNumber !== page) {
            loadSurvey();
        }
    }, [props]);
    useEffect(() => {
        if (getUrlParam("sId")) {
            getSurveyDetails();
        }
        productCode && localStorage.setItem("productCode", productCode);
        royaltyCode && localStorage.setItem("royaltyCode", royaltyCode);
        setOffersPageId(offersPageId);
        localStorage.setItem("offerId", offerId);
    }, []);
    useEffect(() => {
        updateButtonStatus(page);
    }, [surveyQuestions]);
    useEffect(() => {
        if (surveyDetails && surveyDetails.fields) {
            const {
                fields: { questionsList, surveyIntroPage },
            } = surveyDetails || { fields: {} };
            const page = getUrlParam("page");
            setShowIntroPage(surveyIntroPage && !page ? true : false);
            if (!page && !surveyIntroPage) {
                navigateToSurveyQuestions();
            }
            questionsList && questionsList instanceof Array && questionsList.length
                ? setSurveyQuestions(questionsList)
                : redirectWithParam(history, CREATE_ACCOUNT_PATH);
        }
    }, [surveyDetails]);
    return (
        <Fragment>
            {surveyHeader && (
                <div className={`survey-header ${showIntroPage ? "intro-page" : ""}`}>
                    <PageHeader
                        fields={surveyHeader && surveyHeader.fields}
                        onButtonClick={isSurveyEnd ? doSubmitTheSurvey : skipThisQuestion}
                        loadHeader={true}
                    />
                </div>
            )}
            {showIntroPage ? (
                <Fragment>
                    <SurveyIntroOutro fields={fields} onStartButton={navigateToSurveyQuestions} className="intro" />
                </Fragment>
            ) : (
                <Fragment>
                    {surveyQuestions ? (
                        <Fragment>
                            <section className="bb-user-survey px-4 pb-4">
                                {isQuestionPage ? (
                                    <SurveyQuestion
                                        key={page}
                                        surveyQuestion={surveyQuestions[page - 1]}
                                        updateSurvey={updateSurvey}
                                        surveyAnswers={surveyAnswers}
                                    />
                                ) : (
                                    <QuizInterstitialUpsellPage
                                        key={page}
                                        contentData={getQuizInterestitialContent(surveyQuestions[page - 1])}
                                        onButtonClick={isSurveyEnd ? doSubmitTheSurvey : goToNextQuestion}
                                    />
                                )}
                                {isQuestionPage && (
                                    <CTAButton
                                        buttonName={t("next")}
                                        onButtonClick={isSurveyEnd ? doSubmitTheSurvey : goToNextQuestion}
                                        innerBtnClass={`survey-submit ${enableButton ? null : "btn btn-primary disabled"}`}
                                        outerBtnClass="d-flex justify-content-end mb-4"
                                        disableButton={enableButton ? null : { disabled: "disabled" }}
                                    />
                                )}
                            </section>
                        </Fragment>
                    ) : (
                        <div className="d-flex justify-content-center align-items-center loader">
                            <Loader isToShowLoader={true} />
                        </div>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}
SurveyContent.propTypes = {
    props: PropTypes.object,
};
