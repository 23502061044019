import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { BaseOrderSummary } from "./base-order-summary";
export function OrderSummaryConfirmationCard({ t, order, isFreeTrailProduct }) {
    const taxCodes = order?.shippingTaxCode || "";

    const {
        priceInfo: { rawSubtotal, shipping, tax, total },
        taxPriceInfo: { amount, country, state },
    } = order || { priceInfo: {}, taxPriceInfo: {} };
    const isNotCanada = localStorage.getItem("i18nextLng") !== "en_CA" && localStorage.getItem("i18nextLng") !== "fr_CA";
    const displayTax = (tax) => {
        const { amount, label } = tax || {};
        return (
            <div className="cart-summary d-flex justify-content-between">
                <span className="cart-sum-type">{label}</span>
                <span className="cart-sum-amt">{amount}</span>
            </div>
        );
    };

    return (
        <BaseOrderSummary scrollToTop={true}>
            {() => {
                return (
                    <section id="order-summary" className="order-summary">
                        <div className="section-content">
                            <hr className="summary-border"></hr>

                            {isFreeTrailProduct ? (
                                <div className="free-membership-summary">
                                    <div className="your-cost-today">
                                        <span>{t("cost-today")}</span>
                                        <span>{rawSubtotal}</span>
                                    </div>
                                </div>
                            ) : (
                                <Fragment>
                                    <div className="cart-summary d-flex justify-content-between">
                                        <span className="cart-sum-type">{t("subtotal")}</span>
                                        <span className="cart-sum-amt">{rawSubtotal}</span>
                                    </div>
                                    <div className="cart-summary d-flex justify-content-between">
                                        <span className="cart-sum-type cart-sum-shipping">{t("shipping")}</span>
                                        <span className="cart-sum-amt">{shipping === -1 ? t("shipping-amount") : shipping}</span>
                                    </div>
                                    {taxCodes && (
                                        <div className="cart-summary d-flex justify-content-between">
                                            <span className="cart-sum-type"></span>
                                            <span className="cart-sum-amt">{taxCodes}</span>
                                        </div>
                                    )}
                                    {country && displayTax(country)}
                                    {state && displayTax(state)}
                                    {isNotCanada && (
                                        <div className="cart-summary d-flex justify-content-between">
                                            <span className="cart-sum-type">{t("estimated-tax")}</span>
                                            <span className="cart-sum-amt">{tax === -1 ? t("not-calculated") : amount && amount.amount}</span>
                                        </div>
                                    )}
                                    <hr className="summary-border"></hr>
                                    <div className="cart-summary d-flex justify-content-between">
                                        <span className="cart-sum-type">
                                            <strong>{t("estimated-total")}</strong>
                                        </span>
                                        <span className="cart-sum-amt">
                                            <strong>{total}</strong>
                                        </span>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </section>
                );
            }}
        </BaseOrderSummary>
    );
}
OrderSummaryConfirmationCard.propTypes = {
    t: PropTypes.func,
    order: PropTypes.object,
    isFreeTrailProduct: PropTypes.bool,
};
