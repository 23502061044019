// eslint-disable-next-line no-use-before-define
import React, { forwardRef, useImperativeHandle } from "react";
import { TextInput } from "../../../common";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { checkoutActions } from "../../checkout-page/data/checkout-page-action";
import { getConfig } from "./modal/config";
import { CREATE_ACCOUNT_PATH, DEFAULT_OFFER_CODE, ODP_FLOW, TABLET_FLOW } from "../../../constants/urls";
import { BODINT_ENTITLEMENT, PROGRAM_ENTITLEMENT, CREATE_ACCOUNT_PAGE } from "./constants";
import { checkFlowType, getProductDetails } from "../../../helper/util";
import { PopupEmitter, TRIGGER_POPUP } from "../../../common/popup-emitter";
import { UserLoginModal } from "./modal/user-login-modal";
import { redirectWithParam, triggerUserEmailLookup } from "../../common/util";
import { useHistory } from "react-router";
import { AccountDetails } from "../../../model";
import * as EmailLookupStatus from "./email-lookup-constant";
import { pageRouterActions } from "../../../component/page-router/data/page-router-action";
import { offersActions } from "../../offers-page/data/offers-page-action";
import { logCustomEvents, trackUserEmail } from "../../../web-analytics";
import { triggerConflictModal } from "../../common/util/conflict-modal";
// import { composeInitialProps } from "react-i18next";

export const EmailPanel = forwardRef(
    (
        {
            floatLabel,
            formData,
            formErrors,
            isCartAbandonmentFlow,
            isRegisteredUser,
            isWithLoginFlow = true,
            loginModalContent,
            oid,
            onCartDetailFetchFailure,
            onCartDetailsFetchSuccess,
            onChange,
            onKeyPress,
            onKeyUp,
            redirectToNextPage,
            removeCartAbondonementId,
            setEmailLookupStatus,
            setFormErrors,
            setRegisteredUser,
            t,
            validateField,
            programEntitlementConflict,
        },
        ref,
    ) => {
        const history = useHistory();
        const dispatch = useDispatch();
        const { enforceBBDirectLoginSecurity, entitlements: allEntitlements } = useSelector((state) => state.checkout);
        const { editEmail } = useSelector((state) => state.pageRouter);
        const { order } = useSelector((state) => state.offers);
        const onModalClose = () => {
            onChange("email", "");
            setEmailLookupStatus(EmailLookupStatus.NOT_TRIGGED);
            isRegisteredUser && setRegisteredUser(false);
            if (isCartAbandonmentFlow) {
                removeCartAbondonementId();
            }
            PopupEmitter.emit(TRIGGER_POPUP, { showPopup: false });
            setTimeout(() => {
                document.getElementById("email").focus();
            }, 200);
        };
        const closeLoginModal = ({ callback, error, message }) => {
            if (error) {
                callback(error, message);
            } else {
                setRegisteredUser(!error);
                redirectToNextPage(true);
            }
        };
        const userLoginSuccess = (result, callback) => {
            const refRepId = sessionStorage.getItem("refRepId");
            const { guid, email, accessToken } = result || {};
            const userDetail = { ...result, isExistingFreeUser: true };
            dispatch({
                type: pageRouterActions.SET_USER_DETAILS,
                payload: { ...userDetail },
            });
            //neet to check the below flow, whether it is required in normal flow
            isCartAbandonmentFlow &&
                dispatch({
                    type: offersActions.UPDATE_ABANDONED_PROFILE,
                    payload: {
                        abondonedUserData: { profile: { ...result } },
                    },
                });
            dispatch({
                type: checkoutActions.USER_LOGIN_SYNCUP_ATG,
                payload: { guid, oid, email, referringCoachId: refRepId ? refRepId : "", access_token: accessToken ? accessToken : "" },
                callbacks: { success: onCartDetailsFetchSuccess, failure: onCartDetailFetchFailure },
            });
            if (enforceBBDirectLoginSecurity) {
                PopupEmitter.emit(TRIGGER_POPUP, { showPopup: false });
                closeLoginModal({ callback, error: false });
            }
        };
        const userLoginFailure = (result, callback, message) => {
            closeLoginModal({ callback, error: true, message });
        };
        const onSignIn = (password, callback) => {
            password
                ? dispatch({
                      type: checkoutActions.USER_LOGIN,
                      payload: { email: formData["email"], password: password },
                      callbacks: { success: userLoginSuccess, failure: userLoginFailure, update: callback },
                  })
                : null;
        };
        const updateUserDetails = (newUser, existingUser, userDetails, isClubUserCanPurchase) => {
            dispatch({
                type: pageRouterActions.SET_USER_DETAILS,
                payload: { isExistingFreeUser: existingUser, isNewUser: newUser, createPartialProfile: newUser, isClubUserCanPurchase, ...userDetails },
            });
        };

        const checkProgramEntitlement = (entitlementArray) => {
            let userMatchingEntitlements = [],
                result;
            const { mandatoryEntitlements } = order || {};
            if (mandatoryEntitlements && !mandatoryEntitlements.includes("BOD")) {
                //To show program conflict modal for BOD user, if tries to purchase PE
                if (entitlementArray?.includes("BOD")) userMatchingEntitlements = mandatoryEntitlements;
                else userMatchingEntitlements = entitlementArray?.filter((item) => mandatoryEntitlements.includes(item));
            }
            if (allEntitlements && userMatchingEntitlements?.length) {
                //To get the value from merlin entitlements by iterating with matching entitlements
                result = userMatchingEntitlements.map((item) => allEntitlements[item]).find((result) => result !== undefined);
            }
            return result;
        };

        const onGetUserDetailsSuccess = (userDetails) => {
            setEmailLookupStatus(EmailLookupStatus.COMPLETED);
            let emailSource = "email:";
            const { customerType, isCoach, email, entitlements } = userDetails || {};
            const entitlementArray = entitlements && entitlements.split(",");
            const productId = localStorage.getItem("offerId") || DEFAULT_OFFER_CODE;
            let flowType = checkFlowType(productId);
            email && trackUserEmail(email);
            let programEntitlement;
            if (customerType) {
                if (flowType !== ODP_FLOW && flowType !== TABLET_FLOW) {
                    if (order && order?.mandatoryEntitlements?.length) programEntitlement = checkProgramEntitlement(entitlementArray);
                    else if (programEntitlementConflict) programEntitlement = programEntitlementConflict;
                }
                emailSource = emailSource + (customerType ? customerType.replace(",", "-").toLowerCase() : "existing-customer");
                const modalType = programEntitlement ? PROGRAM_ENTITLEMENT : customerType;
                const config = getConfig(modalType, isCoach);
                if (config) {
                    const { modal } = config || {};
                    const isBODINTUser = entitlementArray && entitlementArray.indexOf(BODINT_ENTITLEMENT) !== -1;
                    const isCustomerWithoutBOD = modal && modal === "customerWithoutBOD";
                    const modalConfigDetails = {
                        t,
                        page: CREATE_ACCOUNT_PAGE,
                        props: null,
                        programEntitlement,
                        onChange,
                        setEmailLookupStatus,
                        isRegisteredUser,
                        setRegisteredUser,
                        modalType,
                        isCartAbandonmentFlow,
                        removeCartAbondonementId,
                    };
                    if (oid && entitlements === "BOD" && !programEntitlement) flowType = ODP_FLOW;
                    if (flowType === ODP_FLOW) {
                        if (isCoach && isCoach !== "false") {
                            if (!enforceBBDirectLoginSecurity) {
                                // userLoginSuccess({ ...userDetails, guid: "" });
                                updateUserDetails(false, false, userDetails, false);
                            }
                            triggerConflictModal(modalConfigDetails);
                        } else if (!isCustomerWithoutBOD && !isBODINTUser) {
                            // This is ODP flow with BOD
                            if (!enforceBBDirectLoginSecurity) {
                                userLoginSuccess({ ...userDetails, guid: "" });
                                updateUserDetails(false, false, userDetails, true);
                            }
                        } else if (!isCustomerWithoutBOD && isBODINTUser) {
                            // This is ODP flow with BOD + BODINT
                            !enforceBBDirectLoginSecurity && updateUserDetails(false, false, userDetails, false);
                            oid ? redirectWithParam(history, CREATE_ACCOUNT_PATH) : triggerConflictModal(modalConfigDetails);
                        } else {
                            // This is ODP flow without BOD
                            if (!enforceBBDirectLoginSecurity) {
                                userLoginSuccess({ ...userDetails, guid: "" });
                                updateUserDetails(false, true, userDetails, false);
                            }
                        }
                    } else if (flowType === TABLET_FLOW) {
                        isCustomerWithoutBOD && getProductDetails(userDetails, dispatch);
                        updateUserDetails(false, isCustomerWithoutBOD, userDetails, !isCustomerWithoutBOD && !isBODINTUser);
                    } else if (programEntitlement) {
                        triggerConflictModal(modalConfigDetails);
                    } else {
                        // Is not ODP flow
                        if (isCustomerWithoutBOD) {
                            if (!enforceBBDirectLoginSecurity) {
                                userLoginSuccess({ ...userDetails, guid: "" });
                                updateUserDetails(false, true, userDetails, false);
                            }
                            isWithLoginFlow &&
                                PopupEmitter.emit(TRIGGER_POPUP, {
                                    modalClass: "login-modal",
                                    modalId: `login-modal:without-bod:${customerType.split(",").join(":").toLowerCase()}`,
                                    closeButton: false,
                                    header: false,
                                    showPopup: true,
                                    customizedContent: (
                                        <UserLoginModal
                                            t={t}
                                            loginModalContent={loginModalContent}
                                            closePopup={onModalClose}
                                            customerWithoutBOD={true}
                                            email={email}
                                            onSignIn={onSignIn}
                                            cancelResetPassword={validateEmailAddress}
                                        />
                                    ),
                                });
                        } else {
                            !enforceBBDirectLoginSecurity && updateUserDetails(false, false, userDetails, false);
                            oid ? redirectWithParam(history, CREATE_ACCOUNT_PATH) : triggerConflictModal(modalConfigDetails);
                        }
                    }
                }
            } else {
                emailSource = emailSource + "free-customer";
                updateUserDetails(true, false, userDetails);
            }
            enforceBBDirectLoginSecurity ? null : redirectToNextPage(true);
            logCustomEvents("userlookup", "userlookup_event", emailSource);
        };
        const onGetUserDetailsFailure = (email) => {
            setEmailLookupStatus(EmailLookupStatus.FAILED);
            if (!enforceBBDirectLoginSecurity) {
                updateUserDetails(false, false, { email });
                redirectToNextPage(true);
            }
        };
        const validateEmailAddress = ({ name, value }) => {
            try {
                const error = name && validateField({ name, value });
                setEmailLookupStatus(EmailLookupStatus.IN_PROGRESS);
                const { email, password } = formData;
                const resetUserData = { ...new AccountDetails(), email, password };
                dispatch({
                    type: pageRouterActions.SET_USER_DETAILS,
                    payload: { ...resetUserData },
                });

                if (error) {
                    setFormErrors(name, error);
                } else {
                    formErrors[name] && setFormErrors(name, "");
                    triggerUserEmailLookup(value, dispatch, onGetUserDetailsSuccess, onGetUserDetailsFailure);
                }
            } catch (err) {
                console.error("validateEmailAddress error::", err);
            }
        };
        useImperativeHandle(ref, () => ({
            triggerEmailLookup(value) {
                sessionStorage.removeItem("skipFunnelFlow");
                validateEmailAddress({ name: "email", value });
            },
        }));
        return (
            <TextInput
                name="email"
                id="email"
                floatLabel={floatLabel || t("email")}
                maxLength={100}
                onChange={onChange}
                onBlur={enforceBBDirectLoginSecurity ? validateEmailAddress : null}
                error={isRegisteredUser ? "" : formErrors["email"]}
                dValue={formData["email"]}
                disableElement={isRegisteredUser || (isCartAbandonmentFlow && !editEmail)}
                onKeyPress={onKeyPress}
                onKeyUp={onKeyUp}
            />
        );
    },
);
EmailPanel.propTypes = {
    floatLabel: PropTypes.string,
    formData: PropTypes.object,
    formErrors: PropTypes.object,
    isCartAbandonmentFlow: PropTypes.bool,
    isRegisteredUser: PropTypes.bool,
    isWithLoginFlow: PropTypes.bool,
    loginModalContent: PropTypes.object,
    oid: PropTypes.string,
    onCartDetailFetchFailure: PropTypes.func,
    onCartDetailsFetchSuccess: PropTypes.func,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
    redirectToNextPage: PropTypes.func,
    removeCartAbondonementId: PropTypes.func,
    setEmailLookupStatus: PropTypes.func,
    setFormErrors: PropTypes.func,
    setRegisteredUser: PropTypes.func,
    t: PropTypes.func,
    validateField: PropTypes.func,
    programEntitlementConflict: PropTypes.string,
};
