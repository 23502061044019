import { Configuration as devConfiguration } from "./harmony-dev-config";
import { Configuration as dev2Configuration } from "./harmony-dev2-config";
import { Configuration as dev3Configuration } from "./harmony-dev3-config";
import { Configuration as dev4Configuration } from "./harmony-dev4-config";
import { Configuration as dev5Configuration } from "./harmony-dev5-config";
import { Configuration as localConfiguration } from "./harmony-local-config";
import { Configuration as previewConfiguration } from "./harmony-preview-config";
import { Configuration as prodConfiguration } from "./harmony-prod-config";
import { Configuration as qaConfiguration } from "./harmony-qa-config";
import { Configuration as qa2Configuration } from "./harmony-qa2-config";
import { Configuration as qa3Configuration } from "./harmony-qa3-config";
import { Configuration as qa4Configuration } from "./harmony-qa4-config";
import { Configuration as qa5Configuration } from "./harmony-qa5-config";
import { Configuration as stageConfiguration } from "./harmony-stage-config";
import { Configuration as stage1Configuration } from "./harmony-stage1-config";

const Configurations = {
    dev: devConfiguration,
    dev2: dev2Configuration,
    dev3: dev3Configuration,
    dev4: dev4Configuration,
    dev5: dev5Configuration,
    local: localConfiguration,
    preview: previewConfiguration,
    prod: prodConfiguration,
    qa: qaConfiguration,
    qa2: qa2Configuration,
    qa3: qa3Configuration,
    qa4: qa4Configuration,
    qa5: qa5Configuration,
    stage: stageConfiguration,
    stage1: stage1Configuration,
};
const harmonyEnvironment = !process.env.BROWSER ? process.env.ENV_NAME : window.harmonyEnvironment;
const env = harmonyEnvironment || "local";
const configData = Configurations[env] instanceof Function ? Configurations[env]() : qaConfiguration();

export default configData;
