import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../../contentful/component-renderer";
import "./footer.scss";
export function BeachbodyFooter({ fields }) {
    const { footerBackgroundColor, footerNavigation, disclaimer } = fields || {};
    const footerStyles = {
        backgroundColor: footerBackgroundColor,
    };
    return (
        <footer style={footerStyles} className="beachbody-footer">
            <div className="footer-container">
                {footerNavigation && (
                    <div className="footer-navigation">
                        <FieldRenderer contents={footerNavigation} />
                    </div>
                )}
                {disclaimer && (
                    <div className="disclaimer">
                        <FieldRenderer contents={disclaimer} />
                    </div>
                )}
            </div>
        </footer>
    );
}
BeachbodyFooter.propTypes = {
    fields: PropTypes.object,
};
