import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import BDDirectApp from "./BDDirectApp";
import configureStore from "./configureStore";

// Read the state sent with markup
const preloadState = window.__STATE__;

// reproduce the store used to render the page on server
const store = configureStore(preloadState);

//delete the state from global window object
delete window.__STATE__;

ReactDOM.hydrate(
    <React.StrictMode>
        <Provider store={store}>
            <BDDirectApp />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root"),
);
