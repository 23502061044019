import { useState } from "react";

const usePasswordValidation = () => {
    const initialState = {
        hasNoSpecialChars: false,
        hasNumber: false,
        hasUpperCaseChar: false,
        hasLowerCaseChar: false,
        hasNoSpaces: false,
        hasCharCount: false,
    };

    const [passwordValidations, setPasswordValidations] = useState(initialState);

    const { hasCharCount, hasLowerCaseChar, hasNoSpaces, hasNoSpecialChars, hasNumber, hasUpperCaseChar } = passwordValidations;
    const displayPasswordError = !hasUpperCaseChar || !hasLowerCaseChar || !hasNumber || !hasCharCount || !hasNoSpaces || !hasNoSpecialChars;

    const validateSpecialChars = (password: string) => {
        const specialCharsRegex = /['_\\\/"<>,]/;
        const hasNoSpecialChars = specialCharsRegex.test(password) || password.length === 0;
        return !hasNoSpecialChars;
    };

    const validateNumber = (password: string) => {
        const numberRegex = /[0-9]/;
        const hasNumber = numberRegex.test(password);
        return hasNumber;
    };

    const validateUppercase = (password: string) => {
        const upperCaseRegex = /[A-Z]/;
        const hasUpperCaseChar = upperCaseRegex.test(password);
        return hasUpperCaseChar;
    };

    const validateLowercase = (password: string) => {
        const lowerCaseRegex = /[a-z]/;
        const hasLowerCaseChar = lowerCaseRegex.test(password);
        return hasLowerCaseChar;
    };

    const validateSpaces = (password: string) => {
        const spacesRegex = /\s/u;
        const hasNoSpaces = spacesRegex.test(password) || password.length === 0;
        return !hasNoSpaces;
    };

    const validateCharCount = (password: string) => {
        const hasCharCount = password.length >= 8 && password.length <= 20;
        return hasCharCount;
    };

    const validatePassword = (password: string) => {
        const hasNoSpecialChars = validateSpecialChars(password);
        const hasNumber = validateNumber(password);
        const hasUpperCaseChar = validateUppercase(password);
        const hasLowerCaseChar = validateLowercase(password);
        const hasNoSpaces = validateSpaces(password);
        const hasCharCount = validateCharCount(password);

        setPasswordValidations({ hasNoSpecialChars, hasNumber, hasUpperCaseChar, hasLowerCaseChar, hasNoSpaces, hasCharCount });
    };

    const clearPasswordErrors = () => {
        setPasswordValidations(initialState);
    };

    return { passwordValidations, validatePassword, clearPasswordErrors, displayPasswordError };
};

export default usePasswordValidation;
