import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { BaseOffersTile } from "./base-offer-tile";
import { useLocalizationLookup } from "../../../localization";
export const MonthlyPlanTile = (props) => {
    const { plan = {}, fields, t } = props;
    const { stamp } = plan;
    const { label, backgroundColor, textColor } = stamp;
    const { showCurrencyInPrefix } = useLocalizationLookup();
    let stampStyle = {
        backgroundColor: backgroundColor || "#f9e455",
        color: textColor || "#f9e455"
    };
    const isDesktopView = useSelector((state) => state.pageRouter.isDesktopView);

    return (
        <BaseOffersTile outerDivStyle="free-trial-box" {...props}>
            {({ showCTAButton }) => {
                return (
                    <div className="d-flex row mt-3 price-detail-row">
                        <div className="col-xs-4 col-sm-3 col-md-3 plan-row-left">
                            {label && <div className="save-box save-box-desktop" style={stampStyle}>{label}</div>}
                            <div
                                className="plan-title"
                                style={{ color: isDesktopView ? fields.primaryColor : fields.primaryColorMobile || fields.primaryColor }}
                            >{plan.localizedTitle}</div>
                        </div>
                        <div className="col-xs-4 col-sm-5 col-md-5  plan-row-middle px-2 py-0">
                            <div className="price-container">
                                <div className="price-2 d-flex mr-2">
                                    { showCurrencyInPrefix && <div className="strike-through-dollar">{t("currency-symbol")}</div>}
                                    <div className="strike-through-price">{plan.marketingValue}</div>
                                    {!showCurrencyInPrefix && <div className="strike-through-dollar">{t("currency-symbol")}</div>}
                                </div>
                                <div className="price-2">
                                    {showCurrencyInPrefix && <div className="dollar">{t("currency-symbol")}</div>}
                                    <div className="dollars">{plan.rawPrice}</div>
                                    {!showCurrencyInPrefix && <div className="dollar">{t("currency-symbol")}</div>}
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-4 col-sm-4 col-md-4 plan-row-right d-flex align-items-center px-2 px-sm-3 py-0">
                            {label && <div className="save-box save-box-mobile" style={stampStyle}>{label}</div>}
                            {showCTAButton()}
                        </div>
                    </div>
                );
            }}
        </BaseOffersTile>
    );
};

MonthlyPlanTile.propTypes = {
    fields: PropTypes.object,
    plan: PropTypes.object,
    t: PropTypes.func,
};
