import React, { useState, useRef, Fragment } from "react";
import { CTAButton } from "../../../../common";
import { StyledCheckBox } from "../../../../common";
import PropTypes from "prop-types";
import dompurify from "dompurify";
import { BaseOrderSummary } from "./base-order-summary";
import { redirectWithParam } from "../../../common/util";
import { checkIfODPFlow } from "../../../../helper/util";
import { useDispatch } from "react-redux";
import { checkoutActions } from "../../data/checkout-page-action";
import { CHECKOUT_PATH, OFFERS_PATH } from "../../../../constants/urls";
export function OrderSummary({
    t,
    formErrors,
    formData,
    order,
    onSubSkuChange,
    setFormErrors,
    validateField,
    doFieldValidation,
    checkboxes = [],
    cartTitle,
    isFreeTrailProduct,
    ...props
}) {
    const sanitizer = dompurify.sanitize;
    const dispatch = useDispatch();
    const config = {
        ADD_ATTR: ["href", "target"],
    };
    const onCheckBoxClick = (name, value) => {
        doFieldValidation && validateField({ name, value: !value, callback: setFormErrors });
        onSubSkuChange(name, !value);
    };

    const [showEditBlock] = useState(false);
    const orderSummaryHeader = useRef(null);
    const cartProductId = order && order.commerceItems.length > 0 ? order.commerceItems["0"].productId : "";
    const taxCodes = order?.shippingTaxCode || "";
    const isODPFlow = checkIfODPFlow(cartProductId);
    const onEditClick = () => {
        dispatch({ type: checkoutActions.UPDATE_ENROLL_AUTH_3DS_RESULT, payload: null });
        dispatch({ type: checkoutActions.CYBS_DEVICE_DATA_COLLECTION, payload: null });
        dispatch({ type: checkoutActions.STEPUP_RESPONSE, payload: {} });
        sessionStorage.removeItem("upsell_location");
        sessionStorage.setItem("skipFunnelFlow", CHECKOUT_PATH);
        redirectWithParam(props.history, OFFERS_PATH, true);
    };
    const {
        priceInfo: { rawSubtotal, shipping, tax, total },
        taxPriceInfo: { amount, country, state },
    } = order || { priceInfo: {}, taxPriceInfo: {} };
    const isNotCanada = localStorage.getItem("i18nextLng") !== "en_CA" && localStorage.getItem("i18nextLng") !== "fr_CA";
    const displayTax = (tax) => {
        const { amount, label } = tax || {};
        return (
            <div className="cart-summary d-flex justify-content-between">
                <span className="cart-sum-type">{label}</span>
                <span className="cart-sum-amt">{amount}</span>
            </div>
        );
    };

    const LoadAllCheckBoxes = () => {
        return (
            checkboxes &&
            checkboxes.map((checkbox, index) => {
                const { priorityOrder, showCheckbox, id, textContent, defaultState } = checkbox || {};
                if (showCheckbox) {
                    const checkBoxName = id;
                    const checkBoxValue = formData && formData[checkBoxName] !== undefined ? formData[checkBoxName] : defaultState;
                    return (
                        <div className="acceptance-item separation-line subsku-item-acceptance d-flex" key={index} style={{ order: priorityOrder }}>
                            <StyledCheckBox
                                name={checkBoxName}
                                id={checkBoxName}
                                checkBoxClass={`selection-checkbox ${checkBoxValue ? "checked" : "un-checked"}`}
                                onCheckboxClick={(name) => onCheckBoxClick(name, checkBoxValue)}
                            />
                            <p
                                className="subsku-item-acceptance-text"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizer(textContent, config),
                                }}
                            ></p>
                            {formErrors[checkBoxName] && <div className="error-msg text-center py-3">{t(formErrors[checkBoxName])}</div>}
                        </div>
                    );
                } else {
                    return "";
                }
            })
        );
    };

    return (
        <BaseOrderSummary orderSummaryHeader={orderSummaryHeader} scrollToTop={showEditBlock}>
            {() => {
                return (
                    <section id="order-summary" className="order-summary">
                        <h2 ref={orderSummaryHeader} className="order-sum-header">
                            {isFreeTrailProduct ? t("free-membership-summary") : t("order-summary")}
                            {!isODPFlow ? <CTAButton buttonName={t("edit")} onButtonClick={onEditClick} /> : ""}
                        </h2>

                        <div className="section-content">
                            <div
                                className="cart-item cart-title pb-2"
                                dangerouslySetInnerHTML={{
                                    __html: cartTitle,
                                }}
                            ></div>

                            {LoadAllCheckBoxes()}

                            <hr className="summary-border"></hr>

                            {isFreeTrailProduct ? (
                                <div className="free-membership-summary">
                                    <div className="your-cost-today">
                                        <span>{t("cost-today")}</span>
                                        <span>{rawSubtotal}</span>
                                    </div>
                                </div>
                            ) : (
                                <Fragment>
                                    <div className="cart-summary d-flex justify-content-between">
                                        <span className="cart-sum-type">{t("subtotal")}</span>
                                        <span className="cart-sum-amt">{rawSubtotal}</span>
                                    </div>
                                    <div className="cart-summary d-flex justify-content-between">
                                        <span className="cart-sum-type cart-sum-shipping">{t("shipping")}</span>
                                        <span className="cart-sum-amt">{shipping === -1 ? t("shipping-amount") : shipping}</span>
                                    </div>
                                    {taxCodes && (
                                        <div className="cart-summary d-flex justify-content-between">
                                            <span className="cart-sum-type"></span>
                                            <span className="cart-sum-amt">{taxCodes}</span>
                                        </div>
                                    )}
                                    {country && displayTax(country)}
                                    {state && displayTax(state)}
                                    {isNotCanada && (
                                        <div className="cart-summary d-flex justify-content-between">
                                            <span className="cart-sum-type">{t("estimated-tax")}</span>
                                            <span className="cart-sum-amt">{tax === -1 ? t("not-calculated") : amount && amount.amount}</span>
                                        </div>
                                    )}
                                    <hr className="summary-border"></hr>
                                    <div className="cart-summary d-flex justify-content-between">
                                        <span className="cart-sum-type">
                                            <strong>{t("estimated-total")}</strong>
                                        </span>
                                        <span className="cart-sum-amt">
                                            <strong>{total}</strong>
                                        </span>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </section>
                );
            }}
        </BaseOrderSummary>
    );
}
OrderSummary.propTypes = {
    t: PropTypes.func,
    order: PropTypes.object,
    onSubSkuChange: PropTypes.func,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    formData: PropTypes.object,
    setFormErrors: PropTypes.func,
    validateField: PropTypes.func,
    doFieldValidation: PropTypes.bool,
    checkboxes: PropTypes.array,
    history: PropTypes.object,
    cartTitle: PropTypes.string,
    isFreeTrailProduct: PropTypes.bool,
};
