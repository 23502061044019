import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";
import { RichText } from "../rich-text";
import "./index.scss";

export const ImageContainer = ({ fields, fieldId, isDesktopView, inlineTheme = "", inlineOuterStyle = {}, onButtonClick }) => {
    const { header, mobileHeader = "", image, description, mobileDescription = "", ctaButton } = fields;

    const title = isDesktopView ? header : mobileHeader || header;
    const descriptionBox = isDesktopView ? description : mobileDescription || description;

    return (
        <div id={fieldId} className={`image-container ${inlineTheme}`} style={inlineOuterStyle}>
            {title && <RichText content={title} inlineClass="title" />}
            {image && <FieldRenderer contents={image} />}
            {descriptionBox && <RichText content={descriptionBox} inlineClass="description" />}
            {ctaButton && <FieldRenderer contents={ctaButton} hideFieldCallback={hideFieldCallback} onButtonClick={onButtonClick} />}
        </div>
    );
};
ImageContainer.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    hideFieldCallback: PropTypes.func,
    RichText: PropTypes.func,
    isDesktopView: PropTypes.bool,
    inlineTheme: PropTypes.string,
    inlineOuterStyle: PropTypes.object,
    onButtonClick: PropTypes.func,
};
