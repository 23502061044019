import "isomorphic-fetch";
import { fetchDirect } from "./service";
import { CONTENTFUL_CMS_PAGE_URL, SESSION_LOGOUT } from "./url";
import queryString from "querystring";
import { setLocaleByDomain } from "../helper/util";

export const fetchContentfulPage = async (payload) => {
    const queryStr = queryString.stringify(payload);
    const locale = localStorage.getItem("i18nextLng");
    const pathname = window && window.location.pathname.split("/")[1];
    const language = pathname && pathname.length === 2 ? pathname : "";
    const definedLocale = setLocaleByDomain(language);
    const serviceUrl = `${CONTENTFUL_CMS_PAGE_URL}?${queryStr}&locale=${!locale || locale.length === 2 ? definedLocale : locale}`;
    const response = await fetchDirect(serviceUrl).then((res) => res.json());
    return response;
};

export async function sessionLogout() {
    const response = await fetchDirect(SESSION_LOGOUT).then((res) => res.json());
    return response;
}
