import { defaultLanguage, FLAG_CA, FLAG_FR, FLAG_GB, FLAG_US } from "./urls";

export const languagesSupported = [
    { language: "U.S.A. (English)", code: "en_US", flag: FLAG_US, topLevelDomain: "com" },
    { language: "U.S.A. (Español)", code: "es_US", flag: FLAG_US, topLevelDomain: "com" },
    { language: "Canada (English)", code: "en_CA", flag: FLAG_CA, topLevelDomain: "ca" },
    { language: "Canada (Français)", code: "fr_CA", flag: FLAG_CA, topLevelDomain: "ca" },
    { language: "United Kingdom (English)", code: "en_GB", flag: FLAG_GB, topLevelDomain: "co.uk" },
    { language: "Australia (English)", code: "en_AU", flag: FLAG_GB, topLevelDomain: "com.au" },
    { language: "France (Français)", code: "fr_FR", flag: FLAG_FR, topLevelDomain: "fr" },
];
export function checkSupportedLanguage(code: string) {
    const isIn = languagesSupported.find((language) => language.code === code);
    return isIn ? code : defaultLanguage;
}
export const countryLanguageCodes = Object.freeze({
    ca: ["en_CA", "fr_CA"],
    fr: ["fr_FR"],
    gb: ["en_GB"],
    mx: ["es_MX"],
    us: ["en_US", "es_US"],
    au: ["en_AU"],
});
export const countryCodeForDomain = Object.freeze([
    { site: "beachbody.ca", code: "ca", notAllowedFrom: [] },
    { site: "beachbody.co.uk", code: "gb", notAllowedFrom: [] },
    { site: "beachbody.com.au", code: "au", notAllowedFrom: [] },
    { site: "beachbody.com", code: "us", notAllowedFrom: ["au"] },
    { site: "beachbody.fr", code: "fr", notAllowedFrom: [] },
    { site: "beachbody.mx", code: "mx", notAllowedFrom: [] },
    { site: "localhost", code: "us", notAllowedFrom: [] },
]);
export const supportedLanguageCodes = Object.freeze(["en", "es", "fr"]);
