import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import defaultEnglish from "./localization.json";
import defaultFrench from "./fr/localization.json";
import defaultSpanish from "./es/localization.json";
import caEnglish from "./en_CA/localization.json";
import ukEnglish from "./en_GB/localization.json";
import usEnglish from "./en_US/localization.json";
import caFrench from "./fr_CA/localization.json";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en_CA: {
                translations: { ...defaultEnglish, ...caEnglish },
            },
            en_GB: {
                translations: { ...defaultEnglish, ...ukEnglish },
            },
            en_US: {
                translations: { ...defaultEnglish, ...usEnglish },
            },
            es_MX: {
                translations: { ...defaultEnglish, ...defaultSpanish },
            },
            es_US: {
                translations: { ...defaultEnglish, ...defaultSpanish },
            },
            fr_CA: {
                translations: { ...defaultEnglish, ...caEnglish, ...defaultFrench, ...caFrench },
            },
            fr_FR: {
                translations: { ...defaultEnglish, ...defaultFrench },
            },
        },
        fallbackLng: "en_US",
        debug: false,
        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false, // we use content as keys
        interpolation: {
            escapeValue: false,
        },
        store: "",
    });

export default i18n;
