export class CommerceItem {
    constructor(product = {}) {
        this.attributes = product.attributes || "";
        this.available = product.available || {};
        this.baseListPrice = product.baseListPrice || "";
        this.cartTitle = product.cartTitle || "";
        this.catalogRefId = product.catalogRefId || "";
        this.coachMembershipSku = product.coachMembershipSku || false;
        this.detailedPriceInfo = product.detailedPriceInfo || [];
        this.discountPrice = product.discountPrice || "";
        this.finalPrice = product.finalPrice || "";
        this.isShippable = product.isShippable || false;
        this.id = product.id || "";
        this.maxCartQty = product.maxCartQty || 0;
        this.modal = product.modal || false;
        this.personalVolume = product.personalVolume || -1;
        this.prePromoCodePrice = product.prePromoCodePrice || null;
        this.pre_order = product.pre_order || {};
        this.priceInfo = product.priceInfo || {};
        this.productDescription = product.productDescription || "";
        this.productDisplayName = product.productDisplayName || "";
        this.attributes = product.attributes || "";
        this.productId = product.productId || "";
        this.productTitle = product.productTitle || "";
        this.promoCodes = product.promoCodes || "";
        this.purchaseSource = product.purchaseSource || "";
        this.quantity = product.quantity || "";
        this.quantityWithFraction = product.quantityWithFraction || 0;
        this.removable = product.removable || false;
        this.removeCPInfo = product.removeCPInfo || {};
        this.shippingPriceSuffix = product.shippingPriceSuffix || "";
        this.specialOffer = product.specialOffer || false;
        this.stamp = product.stamp || {};
        this.subSku = product.subSku || null;
        this.subSkuFlavor = product.subSkuFlavor || null;
        this.subSkuIds = product.subSkuIds || null;
        this.subSkuServingSize = product.subSkuServingSize || null;
        this.taxCodes = product.taxCodes || "";
        this.thumbImageURL = product.thumbImageURL || "";
        this.totalPersonalVolume = product.totalPersonalVolume || "";
    }
}
