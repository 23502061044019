import React, { Fragment, useEffect, useState } from "react";
import { CTAButton, Loader } from "../../common";
import PropTypes from "prop-types";
import { getPushSite, getSiteLocale } from "../../helper/util";
import { BraintreePayPalButtons, usePayPalScriptReducer, FUNDING, SCRIPT_LOADING_STATE } from "@paypal/react-paypal-js";
import { paypalButtonStyles } from "../../module/checkout-page/constants";
import { getPayPalToken } from "../../common/paypal";

export function CheckoutAction(props) {
    const {
        t,
        zipCodeLookUpError,
        apiFormErrors,
        APIFormErrMsgs,
        isCheckoutLandscape,
        btnDisable,
        onPlaceOrder,
        expressCheckout,
        securityContentModule,
        fieldsToValidate,
        isPaypal,
        paypalLoader,
        setPaypalLoader,
        setPaymentComplete,
        setCheckoutData,
        paypalFeatureFlagEnabled,
        validateFormErrors,
        expressCheckoutRef,
        onPaypalButtonClickCb,
    } = props || {};
    const isUserConfirmationCompleted = () => {
        return (
            fieldsToValidate &&
            fieldsToValidate.every((field) => {
                return expressCheckout[field];
            })
        );
    };
    const [payPalActions, setPayPalActions] = useState(null);
    const [runPayPalValidations, setRunPayPalValidations] = useState(false);
    const locale = getSiteLocale();
    const pushSite = getPushSite();

    const [{ options: paypalScriptOptions, isResolved: isPayPalScriptResolved }, dispatchPayPalScriptReducer] = usePayPalScriptReducer();

    const onPaypalButtonClick = () => {
        setRunPayPalValidations(true);

        if (onPaypalButtonClickCb && typeof onPaypalButtonClickCb === "function") {
            onPaypalButtonClickCb();
        }
    };

    const onPayPalButtonInit = (actions) => {
        // Disable checkout button by default
        actions.disable();

        setPayPalActions(actions);
    };

    const getBillingState = (state: string) => {
        switch (locale) {
            case "en_GB":
                return "GB";

            default:
                return state;
        }
    };

    useEffect(() => {
        if (payPalActions) {
            const isError = validateFormErrors(runPayPalValidations);

            if (isError) {
                payPalActions.disable();
            } else {
                payPalActions.enable();
            }
        }
    }, [payPalActions, runPayPalValidations, expressCheckout]);

    useEffect(() => {
        if (apiFormErrors) {
            setPaypalLoader(false);
        }
    }, [apiFormErrors]);

    const clearSensitiveCCFields = () => {
        setCheckoutData({
            ...expressCheckoutRef.current,
            firstName: "",
            lastName: "",
            ccNumber: "",
            ccMonth: "",
            ccYear: "",
            ccExpDate: "",
            ccCVV: "",
        });
    };

    useEffect(() => {
        if (isPaypal) {
            clearSensitiveCCFields();
        }
    }, [isPaypal]);

    useEffect(() => {
        (async () => {
            const dynSessionConf = sessionStorage.getItem("sessionId");
            const token = await getPayPalToken({ dynSessionConf, locale, pushSite });

            dispatchPayPalScriptReducer({
                type: "resetOptions",
                value: {
                    ...paypalScriptOptions,
                    dataClientToken: token,
                },
            });

            dispatchPayPalScriptReducer({
                type: "setLoadingStatus",
                value: SCRIPT_LOADING_STATE.PENDING,
            });
        })();
    }, []);

    return (
        <Fragment>
            {zipCodeLookUpError && (
                <p
                    className="error-msg"
                    dangerouslySetInnerHTML={{
                        __html: t("cant-place-order"),
                    }}
                ></p>
            )}
            {apiFormErrors && (
                <ul className="form-errors error-msg">
                    {APIFormErrMsgs &&
                        APIFormErrMsgs.map((error, index) => {
                            const { localizedMessage } = error || {};
                            return (
                                <li
                                    key={index}
                                    dangerouslySetInnerHTML={{
                                        __html: localizedMessage,
                                    }}
                                ></li>
                            );
                        })}
                </ul>
            )}

            {paypalFeatureFlagEnabled && isPaypal && isPayPalScriptResolved && (
                <Fragment>
                    <div className="paypal-button" style={{ display: paypalLoader ? "none" : "block" }}>
                        <BraintreePayPalButtons
                            style={paypalButtonStyles}
                            fundingSource={FUNDING.PAYPAL}
                            onInit={(_data, actions) => onPayPalButtonInit(actions)}
                            onClick={() => onPaypalButtonClick()}
                            createBillingAgreement={(_, actions) => {
                                return actions.braintree.createPayment({ flow: "vault" });
                            }}
                            onApprove={(data, actions) => {
                                return actions.braintree.tokenizePayment(data).then((payload) => {
                                    const paymentMethod = payload.type === "PayPalAccount" ? "paypalBraintree" : payload.type;
                                    const currentBillingState = getBillingState(payload.details.billingAddress.state);
                                    setCheckoutData({
                                        ...expressCheckoutRef.current,
                                        paymentMethod,
                                        paymentMethodAccount: payload.details.email,
                                        paymentMethodToken: payload.nonce,
                                        shippingAddressLine1: payload.details.billingAddress.line1,
                                        shippingCity: payload.details.billingAddress.city,
                                        shippingState: currentBillingState,
                                        shippingPostalCode: payload.details.billingAddress.postalCode,
                                        shippingCountry: payload.details.countryCode,
                                        billingAddressLine1: payload.details.billingAddress.line1,
                                        billingCity: payload.details.billingAddress.city,
                                        billingCountry: payload.details.countryCode,
                                        billingState: currentBillingState,
                                        billingPostalCode: payload.details.billingAddress.postalCode,
                                        billingFirstName: payload.details.firstName,
                                        billingLastName: payload.details.lastName,
                                        firstName: payload.details.firstName,
                                        lastName: payload.details.lastName,
                                    });

                                    setPaypalLoader(true);
                                    setPaymentComplete(true);
                                    onPlaceOrder();
                                });
                            }}
                            onError={() => {
                                throw new Error(t("error-paypal-widget"));
                            }}
                        />
                    </div>
                    {paypalLoader && <Loader className="checkout-content_paypal-loader" isToShowLoader={paypalLoader} />}
                </Fragment>
            )}
            {!isPaypal && isCheckoutLandscape && (
                <CTAButton
                    buttonName={t("place-order")}
                    outerBtnClass="place-order"
                    innerBtnClass={isUserConfirmationCompleted() ? "orange-button" : ""}
                    onButtonClick={() => onPlaceOrder()}
                    disableButton={btnDisable ? { disabled: "disabled" } : null}
                    icon={btnDisable ? "fa-refresh fa-spin" : ""}
                />
            )}
            {!isPaypal && !isCheckoutLandscape && (
                <div className="place-order-container">
                    <CTAButton
                        buttonName={t("place-order")}
                        outerBtnClass="place-order"
                        innerBtnClass={isUserConfirmationCompleted() ? "orange-button" : ""}
                        onButtonClick={() => onPlaceOrder()}
                        disableButton={btnDisable ? { disabled: "disabled" } : null}
                        icon={btnDisable ? "fa-refresh fa-spin" : ""}
                    />
                </div>
            )}
            {isCheckoutLandscape ? securityContentModule : null}
        </Fragment>
    );
}
CheckoutAction.propTypes = {
    props: PropTypes.object,
    securityContentModule: PropTypes.node,
};
