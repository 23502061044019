import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FieldRenderer, FooterRenderer } from "../contentful/component-renderer";
import contentfulConfig from "../contentful/config";
export const StaticPageContentTemplate = (props) => {
    const { content = null } = props;
    const { t } = useTranslation();
    const { commonHeader, pageUrl, pageContents = null, commonFooter } = content ? content.fields : {};
    const pageContentConfiguration = {
        checkout: "checkoutPageContent",
        confirmation: "confirmationContent",
        products: "myxProductDetailScreen",
        special: "interstitialPage",
        "create-account": "createAccountContent",
        "reset-password": "resetPasswordContent",
        "create-password": "createPassword",
        "confirmation/coach-support": "confirmationContent",
        "welcome-confirmed": "confirmationContent",
        "confirmation/coach-confirmed": "confirmationContent",
        "confirmation-password": "confirmationPasswordContent",
    };
    const pageContent = pageContentConfiguration[pageUrl];
    const PageContent = pageContent ? contentfulConfig.moduleComponents[pageContent] : null;

    return (
        <div id={props.primaryProductCode || ""} className="main-container">
            <FieldRenderer contents={commonHeader} />
            <PageContent t={t} contents={pageContents} {...props} />
            <FooterRenderer contents={commonFooter} />
        </div>
    );
};
StaticPageContentTemplate.propTypes = {
    content: PropTypes.object,
    primaryProductCode: PropTypes.string,
};
