import { BrightcoveVideoContainer, LegacyBrightcoveVideoContainer } from "./brightcove-video";
import { YouTubeContainer } from "./youtube-video";
import { HostedContainer } from "./hosted-video";

export default {
    BRIGHTCOVE_VIDEO: BrightcoveVideoContainer,
    YOUTUBE_VIDEO: YouTubeContainer,
    CLOUDINARY_VIDEO: HostedContainer,
    HOSTED_VIDEO: HostedContainer,
    undefined: LegacyBrightcoveVideoContainer,
};
