import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { logCustomEvents } from "../../web-analytics";
import "./cta-button.scss";
import { FieldRenderer } from "../../contentful/component-renderer";
import { RichText } from "../rich-text";
import { DEFAULT_FUNNEL, DEFAULT_OFFER_CODE, DEFAULT_PRODUCT_CODE } from "../../constants/urls";
import { renderBaseName, getFunnel, customClick } from "../../helper/util";
import { click_cta } from "../../constants/tealium-tags";
import { createAccount } from "../../module/create-account/components/constants";
import { getNextPathIndex } from "../../module/common/util";
import { getUrlParam } from "../../helper/util";

export const CTAButton = ({ fields, fieldId, onButtonClick = null, toggleButton = false, tealiumId = "", sectionType = "" }) => {
    const {
        buttonText,
        buttonDisclaimer,
        redirectUrl,
        buttonAction,
        buttonContent,
        backgroundImage,
        title,
        autoAddSkuId,
        interstitialPageUrl,
        displayInterstitialBefore,
    } = fields;
    const {
        hostUrl,
        offerId,
        primaryProductCode = "",
        royaltyCode,
        offersPageId,
        survey,
        locale,
        footerHomeLandingPage,
        funnels = null,
    } = useSelector((state) => state.pageRouter);
    const [skuId, productId] = autoAddSkuId?.split("_") || [];
    const code = `productCode=${primaryProductCode || DEFAULT_PRODUCT_CODE}&rc=${royaltyCode || primaryProductCode || DEFAULT_PRODUCT_CODE}&offersPageId=${
        offersPageId || primaryProductCode
    }&offerId=${productId || offerId || DEFAULT_OFFER_CODE}${footerHomeLandingPage ? `&lp=${footerHomeLandingPage}` : ""}${skuId ? `&skuId=${skuId}` : ""}`;
    const path = process.env.BROWSER ? window.location.pathname : "";
    const isProductPage = path?.indexOf("/product/") !== -1;
    const {
        fields: { programId: surveyId },
    } = survey || { fields: {} };
    const generateURL = (fId) => {
        const funnel = getFunnel(funnels, fId);
        if (funnel?.fields?.funnelPath[0] === "survey") {
            return surveyId ? `/survey?sId=${surveyId}&${code}` : `/${funnel?.fields?.funnelPath[getNextPathIndex(funnel?.fields?.funnelPath, 1)]}?${code}`;
        } else if (funnel) {
            return `/${funnel?.fields?.funnelPath[0]}?${code}`;
        } else {
            return surveyId ? `/survey?sId=${surveyId}&${code}` : `/create-account?${code}`;
        }
    };
    const trackCTAModules = (event) => {
        if (isProductPage) {
            const node = event.target.closest(`${"#" + fieldId}`);
            const eventData = customClick(node, buttonText, title);
            logCustomEvents(click_cta, "click", eventData, true, "link_tracking");
            if (interstitialPageUrl) {
                sessionStorage.setItem("upsell_page", interstitialPageUrl);
                const upsellLocation = displayInterstitialBefore || createAccount;
                sessionStorage.setItem("upsell_location", upsellLocation);
            }
        }
    };

    const getFunnelId = (event) => {
        const funnelIdFromParams = getUrlParam("funnelId");
        if (funnelIdFromParams) {
            const isFunnelIdInList = funnels?.funnelList.some((funnel) => funnel.fields.funnelId === funnelIdFromParams);
            if (isFunnelIdInList) {
                return funnelIdFromParams;
            }
        }
        if (event.target.closest(".button").getAttribute("data-funnel-id")) {
            return event.target.closest(".button").getAttribute("data-funnel-id");
        }
        if (isProductPage) {
            return DEFAULT_FUNNEL;
        }
        return null;
    };
    const onClickEvent = (e) => {
        trackCTAModules(e);
        const fId = getFunnelId(e);
        const url = generateURL(fId);
        setTimeout(() => {
            switch (buttonAction) {
                case "redirect-to-offers-page":
                    process.env.BROWSER && global.window && (window.location = `${hostUrl}${renderBaseName(locale)}${url}`);
                    surveyId ? sessionStorage.setItem("survey_id", surveyId) : sessionStorage.removeItem("survey_id");
                    fId && sessionStorage.setItem("fId", fId);
                    return;
                case "redirect-to-url":
                    tealiumId && onButtonClick && onButtonClick(fieldId, buttonText);
                    process.env.BROWSER && global.window && (window.location = redirectUrl.startsWith("/") ? `${hostUrl}${redirectUrl}` : `${redirectUrl}`);
                    return;
                case "default-button-action":
                    onButtonClick && onButtonClick(fieldId, buttonText);
                    return;
                case "redirect-to-next-funnel-step":
                    process.env.BROWSER && global.window && (window.location = `${hostUrl}${renderBaseName(locale)}${createAccountPageUrl}`);
                    !surveyId && sessionStorage.removeItem("survey_id");
                    return;
            }
        }, 500);
    };
    return (
        <div id={fieldId} onClick={backgroundImage ? onClickEvent : null} className={backgroundImage ? "cta cta-icon" : "cta"} data-type={sectionType}>
            <a className={toggleButton ? "button toggle-cta" : "button"} onClick={onClickEvent}>
                {buttonContent ? <RichText content={buttonContent} /> : <span>{buttonText}</span>}
            </a>
            {buttonDisclaimer && <FieldRenderer contents={buttonDisclaimer} />}
        </div>
    );
};

CTAButton.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    FieldRenderer: PropTypes.func,
    onButtonClick: PropTypes.func,
    toggleButton: PropTypes.bool,
    tealiumId: PropTypes.string,
    sectionType: PropTypes.string,
};
