import { useState, useEffect, useRef } from "react";

function useSessionStorageState(key, defaultValue: unknown = "", { serialize = JSON.stringify, deserialize = JSON.parse } = {}) {
    const [state, setState] = useState(() => {
        const valueInSessionStorage = window.sessionStorage.getItem(key);
        if (valueInSessionStorage) {
            return deserialize(valueInSessionStorage);
        }
        return defaultValue;
    });
    const prevKeyRef = useRef(key);

    useEffect(() => {
        const prevKey = prevKeyRef.current;
        if (prevKey !== key) {
            sessionStorage.removeItem(prevKey);
        }
        prevKeyRef.current = key;
        sessionStorage.setItem(key, serialize(state));
    }, [key, serialize, state]);
    return [state, setState];
}

export default useSessionStorageState;
