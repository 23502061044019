export const BODINT_ENTITLEMENT = "BODINT";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const CREATE_PASSWORD = "CREATE_PASSWORD";
export const MYX_FLOW_SKUS = ["BODiStandaloneTablet", "BODStandaloneTablet"];
export const ODP_FLOW_SKUS = ["BODiOneDayPassDR"];
export const LANDING_PAGES = {
    DP: "dayPassAlternateNavigation",
    BS: "boDiSellAlternateNavigation",
    default: "footerNavigation",
};
export const cardsAccepted = {
    ca: ["visa", "mastercard", "amex", "discover", "cup"],
    fr: ["visa", "mastercard"],
    gb: ["visa", "mastercard"],
    us: ["visa", "mastercard", "amex", "discover", "cup"],
};
export const checkBoxNames = {
    "ContinuityDisclaimer-BBDirect_checkbox": "continuity",
    // termsAndConditions_checkbox: "legalTermsCondition",
};
export const validCheckBox = ["coachConsent", "companyConsent"];
export const COACH_OPT_IN_BUTTON = "coach_opt_in_btn";
export const COACH_OPT_OUT_BUTTON = "coach_opt_out_btn";
export const createAccount = "Create Account";
export const checkout = "Checkout";
export const PROGRAM_RECOMMENDATIONS_ID = "program_recommendations";
export const PROGRAM_ENTITLEMENT = "PROGRAM_ENTITLEMENT";
export const OFFERS_PAGE = "OFFERS";
export const CREATE_ACCOUNT_PAGE = "CREATE_ACCOUNT";
export const ERROR_CODE_PROGRAM_ENTITLEMENT = "alreadyAMember.bb";
export const USE_DIFFERENT_EMAIL = "USE_DIFFERENT_EMAIL";
