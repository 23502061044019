/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";
export const ContentBox = ({
    fields,
    fieldId,
    isDesktopView,
    RichText,
    inlineClass
}) => {
    const { desktopContent, mobileContent, renderWithoutContainer = false } = fields;


    const content = isDesktopView ? desktopContent : mobileContent || desktopContent;

    return (
        renderWithoutContainer ? <RichText fieldId={fieldId} content={content} /> :
            <div id={fieldId} className={inlineClass ? `content-box ${inlineClass}` : "content-box"} >
                <RichText content={content} />
            </div>
    );
};

ContentBox.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    isDesktopView: PropTypes.bool,
    RichText: PropTypes.func,
    inlineClass: PropTypes.string,
};
