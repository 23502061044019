import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "../create-account-page-content.scss";
import { EmailPanel, Constants, EmailLookupStatus } from "../components";
import { Fragment } from "react";
import { CTAButton } from "../../../common";
import { BaseCreateAccountWithoutLogin } from "../base-create-acct-without-login";
import { pageRouterActions } from "../../../component/page-router/data/page-router-action";
import { useDispatch } from "react-redux";
export function CreateAccountWithoutLogin(props) {
    const continueBtn = "continue-btn";
    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch({ type: pageRouterActions.EDIT_EMAIL, payload: null });
        };
    }, []);
    return (
        <BaseCreateAccountWithoutLogin {...props} page={Constants.CREATE_ACCOUNT}>
            {({ t, showPageLoader, onContinueBtn, validateEmailClientSide, emailRef, emailLookupStatus, onEnter, disableBtn, ...formProps }) => {
                return (
                    <Fragment>
                        <div className="email-section text-md-center">
                            <h2>{t("create-account-signup-text")}</h2>
                            <ul
                                dangerouslySetInnerHTML={{
                                    __html: t("create-account-signup-bullets"),
                                }}
                            ></ul>
                        </div>
                        <div className="email-field px-md-5 mx-md-4">
                            <EmailPanel
                                {...props}
                                {...formProps}
                                ref={emailRef}
                                isWithLoginFlow={false}
                                onKeyPress={onEnter}
                                floatLabel={t("create-account-email-label")}
                                onKeyUp={validateEmailClientSide}
                            />
                            {emailLookupStatus === EmailLookupStatus.FAILED && (
                                <p
                                    className="error-msg"
                                    dangerouslySetInnerHTML={{
                                        __html: t("email-lookup-fail"),
                                    }}
                                ></p>
                            )}
                            <CTAButton
                                outerBtnClass="continue-btn"
                                buttonName={t(continueBtn)}
                                onButtonClick={onContinueBtn}
                                innerBtnClass={disableBtn ? "btn edit-btn" : "btn edit-btn valid"}
                                trackId="sign-up"
                                icon={showPageLoader ? "fa-refresh fa-spin" : ""}
                            />
                            <div
                                className="user-acceptance-text"
                                dangerouslySetInnerHTML={{
                                    __html: t("create-acct-disclaimer"),
                                }}
                            ></div>
                        </div>
                    </Fragment>
                );
            }}
        </BaseCreateAccountWithoutLogin>
    );
}

CreateAccountWithoutLogin.propTypes = {
    props: PropTypes.object,
};
