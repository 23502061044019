import React from "react";
import { TextInput, SelectDropDown } from "../../../common";
import PropTypes from "prop-types";
import { states } from "../../../constants/usa-states";
import * as Constants from "../constants";

export function ShippingAddress({ t, onChange, formErrors, formData, calculateTax, cartItem, doFieldValidation, setFormErrors, validateField }) {
    const { isShippable } = cartItem || {};
    const validateShipingState = (name, value) => {
        onChange(name, value);
        validateField({ name, value, callback: setFormErrors, hybridValidator: true });
    };
    return (
        <section id="shipping-adress" className="shipping-address">
            <h2>{t("shipping-address")}</h2>
            <div className="section-content">
                <TextInput
                    name="shippingAddressLine1"
                    id="street1"
                    floatLabel={t("street-address")}
                    maxLength={30}
                    onChange={onChange}
                    error={formErrors["shippingAddressLine1"]}
                    dValue={formData["shippingAddressLine1"]}
                    onBlur={doFieldValidation ? validateField : null}
                    setFormErrors={setFormErrors}
                    autoComplete={Constants.addressLine1}
                />
                <TextInput
                    name="shippingAddressLine2"
                    id="street2"
                    floatLabel={t("optional-address")}
                    maxLength={30}
                    onChange={onChange}
                    error={formErrors["shippingAddressLine2"]}
                    dValue={formData["shippingAddressLine2"]}
                    onBlur={doFieldValidation ? validateField : null}
                    setFormErrors={setFormErrors}
                    autoComplete={Constants.addressLine2}
                />
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <TextInput
                            name="shippingCity"
                            id="shippingCity"
                            floatLabel={t("city")}
                            maxLength={30}
                            onChange={onChange}
                            error={formErrors["shippingCity"]}
                            dValue={formData["shippingCity"]}
                            onBlur={doFieldValidation ? validateField : null}
                            setFormErrors={setFormErrors}
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12 p-0 pr-sm-2">
                        <SelectDropDown
                            name="shippingState"
                            id="shippingState"
                            dValue={formData["shippingState"]}
                            options={states}
                            displayKey="value"
                            displayName="text"
                            outerStyle="dd-col shipping-states p-md-0 pl-sm-0 pr-sm-2"
                            onChange={validateShipingState}
                            setFormErrors={setFormErrors}
                            formData={formData}
                            error={formErrors["shippingState"]}
                        />
                        {formErrors["shippingState"] && <div className="error-msg">{formErrors["shippingState"]}</div>}
                    </div>
                    <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12 p-0">
                        <TextInput
                            name="shippingPostalCode"
                            id="shipping-postalCode"
                            floatLabel={t("shipping-zip-code")}
                            maxLength={10}
                            textDivStyle="postal-code-left-col"
                            onChange={onChange}
                            onBlur={doFieldValidation || isShippable ? calculateTax : null}
                            error={formErrors["shippingPostalCode"]}
                            dValue={formData["shippingPostalCode"]}
                        />
                    </div>
                </div>
                <div className="d-flex postal-code-row flex-column"></div>
            </div>
        </section>
    );
}
ShippingAddress.propTypes = {
    t: PropTypes.func,
    onChange: PropTypes.func,
    formErrors: PropTypes.object,
    formData: PropTypes.object,
    calculateTax: PropTypes.func,
    cartItem: PropTypes.object,
    doFieldValidation: PropTypes.bool,
    setFormErrors: PropTypes.func,
    validateField: PropTypes.func,
};
