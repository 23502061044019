import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

export function SurveyQuestion(props) {
    const { surveyQuestion, updateSurvey, surveyAnswers } = props || {};
    const {
        fields: { image, instructions, isMultipleChoice, options, question, questionId },
    } = surveyQuestion || { fields: {} };
    const {
        fields: {
            file: { url },
        },
    } = image || { fields: { file: {} } };
    const [surveyResponse, setSurveyResponse] = useState((surveyAnswers && surveyAnswers[questionId]) || []);
    const [list, setOptionsList] = useState();
    const filterOptions = () => {
        const result = [];
        const nextQuestionIds = options
            ?.filter((item) => {
                return surveyResponse?.some((response) => {
                    const {
                        fields: { optionsValue },
                    } = item || { fields: {} };
                    if (optionsValue === response) {
                        result.push(response);
                        return true;
                    }
                });
            })
            .sort((prev, current) => {
                const {
                    fields: { priority: prevPriority },
                } = prev || { fields: {} };
                const {
                    fields: { priority: currentPriority },
                } = current || { fields: {} };
                if (prevPriority && currentPriority) {
                    return prevPriority > currentPriority ? 1 : -1;
                }
                return 0;
            })
            .map((item) => {
                const {
                    fields: { nextQuestionId, priority },
                } = item || { fields: {} };
                const value = priority || 1000;
                if (nextQuestionId) {
                    if (isMultipleChoice) return { nextQuestionId, priority: value };
                    else return { nextQuestionId, priority: 1 };
                }
            })
            .filter((result) => result !== undefined);

        updateSurvey(result, questionId, nextQuestionIds);
    };
    const updateSurveyAnswers = (event) => {
        const {
            target: { checked, value },
        } = event || { target: {} };
        let surveyResp = [...surveyResponse];
        if (checked) {
            if (isMultipleChoice) {
                surveyResp ? surveyResp.push(value) : surveyResp.push(value);
            } else {
                surveyResp = [value];
            }
        } else {
            surveyResp && surveyResp.splice(surveyResp.indexOf(value), 1);
        }
        updateSurvey(surveyResp, questionId);
        setSurveyResponse(surveyResp);
    };
    const isOptionSelected = (optionsValue) => {
        return surveyResponse && surveyResponse.includes(optionsValue) ? true : false;
    };
    const renderList = () => {
        const list =
            options &&
            options.map((option, index) => {
                const {
                    fields: { optionText, optionsValue },
                } = option || { fields: {} };
                return (
                    <li key={index} className={isOptionSelected(optionsValue) ? "selected" : ""}>
                        <label htmlFor={optionsValue}>{optionText}</label>
                        <input
                            id={optionsValue}
                            type={isMultipleChoice ? "checkbox" : "radio"}
                            className={isMultipleChoice ? "" : "invisible"}
                            value={optionsValue}
                            name={isMultipleChoice ? optionsValue : questionId}
                            onChange={(event) => updateSurveyAnswers(event)}
                            checked={isOptionSelected(optionsValue) ? "selected" : ""}
                        />
                    </li>
                );
            });
        setOptionsList(list);
    };
    useEffect(() => {
        filterOptions();
        renderList();
    }, [surveyResponse]);
    return (
        <div className="survey-question animated fadeIn">
            {image && (
                <div className="d-flex justify-content-center">
                    <img src={url} className="trainer-image" alt="Trainer image" />
                </div>
            )}
            <div className="d-flex justify-content-between align-items-center">
                <h3 className="my-3 text-center w-100">{question}</h3>
            </div>
            {instructions && (
                <div className="d-flex">
                    <p className="instructions mb-3">{instructions}</p>
                </div>
            )}
            {<ul className="survey-options p-0">{list}</ul>}
        </div>
    );
}

SurveyQuestion.propTypes = {
    fields: PropTypes.object,
    onButtonClick: PropTypes.func,
};
