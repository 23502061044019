import { Accordion } from "./accordion";
import { BeachbodyFooter } from "../module/common/footer";
import { BeachbodyHeader } from "../module/common/header";
import { CarouselContainer } from "./carousel";
import { CarouselContent } from "./carousel-content";
import { CheckoutPageContent } from "../module/checkout-page/checkout-page-content";
import { ConfirmationDetails } from "../module/confirmation-page/confirmation-details";
import { Content } from "./content";
import { ContentBox } from "./content-box";
import { CTAButton } from "./cta-button";
import { Disclaimer } from "./disclaimer";
import { DynamicContainer } from "./dynamic-container";
import { FlexHeader } from "../module/page/flex-header";
import { FlexHeaderItem } from "../module/page/flex-header-item";
import { Footer } from "../module/page/footer";
import { HeroBanner } from "./hero-banner";
import { IconRightTextLeft } from "./icon-right-text-left";
import { ImageAsLink } from "./image-as-link";
import { ImageBox } from "./image-box";
import { ImageContainer } from "./image-container";
import { ImageGridContentContainer } from "./image-grid-content-container";
import { LocaleSwitcher } from "./locale-switcher";
import { MediaTextContainer } from "./media-text-container";
import { MyxForgotPasswordPage } from "../module/myx-login/myx-forgot-password";
import { MyxLoginPage } from "../module/myx-login/myx-login-page";
import { MyxResetPasswordPage } from "../module/myx-login/myx-reset-password";
import { NavigationMenu } from "../module/page/navigation-menu";
import { NavigationMenuItem } from "../module/page/navigation-menu-item";
import { PageHeader } from "../module/page/page-header";
import { PlainTag } from "./plain-tag";
import { ReferenceLinks } from "./reference-link";
import { RichText } from "./rich-text";
import { ShowWithAccess } from "./show-with-access";
import { SocialMediaIcons } from "./social-media-icons";
import { StickyContainer } from "../module/page/sticky-container";
import { SurveyContent } from "../module/survey";
import { TextContainer } from "./text-container";
import { ToggleCTAButton } from "./toggle-button";
import { VideoContainer } from "./video-container";
import { TableContainer, TableRow, TableCell } from "./table-container";
import { ScrollSnappingWrapper } from "./scroll-snapping-wrapper";

export default {
    accordion: Accordion,
    carouselContainer: CarouselContainer,
    carouselContent: CarouselContent,
    checkoutPageContent: CheckoutPageContent,
    commonFooter: BeachbodyFooter,
    commonHeader: BeachbodyHeader,
    confirmationPageContent: ConfirmationDetails,
    content: Content,
    contentBox: ContentBox,
    ctaButton: CTAButton,
    disclaimer: Disclaimer,
    dynamicContainer: DynamicContainer,
    flexHeader: FlexHeader,
    flexHeaderItem: FlexHeaderItem,
    footer: Footer,
    header: PageHeader,
    heroBanner: HeroBanner,
    iconRightTextLeft: IconRightTextLeft,
    imageAsLink: ImageAsLink,
    imageBox: ImageBox,
    imageContainer: ImageContainer,
    imageGridContentContainer: ImageGridContentContainer,
    localeSwitcher: LocaleSwitcher,
    mediaTextContainer: MediaTextContainer,
    myxForgotPasswordPage: MyxForgotPasswordPage,
    myxLoginPage: MyxLoginPage,
    myxResetPasswordPage: MyxResetPasswordPage,
    navigationMenu: NavigationMenu,
    navigationMenuItem: NavigationMenuItem,
    plainTag: PlainTag,
    referenceLinks: ReferenceLinks,
    RichText: RichText,
    showWithAccess: ShowWithAccess,
    socialMediaIcons: SocialMediaIcons,
    stickyContainer: StickyContainer,
    surveyPageContent: SurveyContent,
    textContainer: TextContainer,
    toggleCtaButton: ToggleCTAButton,
    videoContainer: VideoContainer,
    tableContainer: TableContainer,
    tableRow: TableRow,
    tableCells: TableCell,
    scrollSnappingWrapper: ScrollSnappingWrapper,
};

export { Content, CTAButton, LocaleSwitcher, RichText };
