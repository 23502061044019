import React, { useRef } from "react";
import PropTypes from "prop-types";
import { BaseOrderSummary } from "./base-order-summary";

export function FreeMembershipSummary({ t, securityContent, cartTitle }) {
    const orderSummaryHeader = useRef(null);
    return (
        <BaseOrderSummary orderSummaryHeader={orderSummaryHeader}>
            {(isCheckoutLandscape) => {
                return (
                    <section
                        id="free-membership-summary"
                        className="order-summary"
                    >
                        <h2
                            ref={orderSummaryHeader}
                            className="order-sum-header"
                        >
                            {t("free-membership-summary")}
                        </h2>
                        <div className="section-content">
                            <div className="cart-item">
                                {cartTitle}
                                <ul className="order-details">
                                    <li>Unlimited Access</li>
                                    <li>No Commitment</li>
                                    <li>Cancel Anytime within the first 14 days and you won’t get charged anything.</li>
                                </ul>
                            </div>
                            <hr className="summary-border"></hr>
                            <div className="cart-summary d-flex justify-content-between">
                                <span className="cart-sum-type">
                                    <strong>{t("cost-today")}</strong>
                                </span>
                                <span className="cart-sum-amt">
                                    <strong>$0.00</strong>
                                </span>
                            </div>
                            <div className="free-membership-msg">{t("free-membership-msg")}</div>
                        </div>
                        {isCheckoutLandscape && (
                            <div
                                className="security-content"
                                dangerouslySetInnerHTML={{
                                    __html: securityContent,
                                }}
                            ></div>
                        )}
                    </section>
                );
            }}
        </BaseOrderSummary>
    );
}
FreeMembershipSummary.propTypes = {
    t: PropTypes.func,
    securityContent: PropTypes.any,
    cartTitle: PropTypes.string,
};
