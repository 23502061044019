import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { BaseMediaContainer } from "./components";
import { FieldRenderer } from "../../../contentful/component-renderer";

const ImageTextContainer = (props) => {
    return (
        <BaseMediaContainer {...props}>
            {(data) => {
                const { imageContainer, textContainer, hideFieldCallback, onButtonClick } = data;
                return (
                    <Fragment>
                        <FieldRenderer contents={imageContainer} hideFieldCallback={hideFieldCallback} onButtonClick={onButtonClick} {...props} {...data} />
                        <FieldRenderer contents={textContainer} hideFieldCallback={hideFieldCallback} onButtonClick={onButtonClick} {...props} {...data} />
                    </Fragment>
                );
            }}
        </BaseMediaContainer>
    );
};

const TextImageContainer = (props) => {
    return (
        <BaseMediaContainer {...props}>
            {(data) => {
                const { imageContainer, textContainer, hideFieldCallback, onButtonClick } = data;
                return (
                    <Fragment>
                        <FieldRenderer contents={textContainer} hideFieldCallback={hideFieldCallback} onButtonClick={onButtonClick} />
                        <FieldRenderer contents={imageContainer} hideFieldCallback={hideFieldCallback} onButtonClick={onButtonClick} />
                    </Fragment>
                );
            }}
        </BaseMediaContainer>
    );
};
TextImageContainer.propTypes = {
    imageContainer: PropTypes.object,
    textContainer: PropTypes.object,
    FieldRenderer: PropTypes.func,
};

export const ImageLeftContentRight = (props) => <ImageTextContainer {...props} type="img-lft-container" />;
export const ImageRightContentLeft = (props) => <TextImageContainer {...props} type="img-rght-container" />;
export const ImageTopContentBottom = (props) => <ImageTextContainer {...props} type="img-top-container" />;
export const ImageBottomContentTop = (props) => <TextImageContainer {...props} type="img-bottom-container" />;
