import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { creditCardType } from "./credit-card-type";
import { CC_IFRAME_URL, CC_ICON_URL } from "../../constants/urls";
import "./credit-card-field.scss";
export const CreditCardIframe = ({
    id,
    name,
    placeholder,
    inputStyle,
    additParams = null,
    formErrors,
    onChange,
    setFormErrors,
}) => {
    const [cardDetailsUpdated, setCardDetailsUpdated] = useState(null);
    const validateCCNumber = (cardDetails, error) => {
        onChange("ccNumber", "", cardDetails);
        setFormErrors("ccNumber", error);
    };
    useEffect(() => {
        if (cardDetailsUpdated) {
            let token = {};
            try {
                token = JSON.parse(cardDetailsUpdated.data);
            } catch (e) {
                token = {};
            }
            let cardDetails = {
                ccType: "",
                ccNumber: "",
            };
            if ({}.hasOwnProperty.call(token, "validationError")) {
                const { message } = token || {};
                message && message.length > 0
                    ? validateCCNumber(cardDetails, "cc-error")
                    : validateCCNumber(cardDetails, "");
            } else if ({}.hasOwnProperty.call(token, "message")) {
                // Update Hidden Token Field
                // Get Credit Card Type
                let cardType = creditCardType(token.message.substring(1, 4));
                cardDetails = {
                    ccType: cardType,
                    ccNumber: token.message,
                };
                if (cardType) {
                    validateCCNumber(cardDetails, "");
                } else {
                    validateCCNumber(cardDetails, "cc-error");
                }
            }
            setCardDetailsUpdated(null);
        }
    }, [cardDetailsUpdated]);
    useEffect(() => {
        window.addEventListener(
            "message",
            function (event) {
                setCardDetailsUpdated(event);
            },
            false,
        );
    }, []);
    return (
        <div className="text-input">
            <iframe
                id={id}
                name={name}
                className={`${name} ${formErrors["ccNumber"] ? "tokenframe-error" : ""}`}
                scrolling="no"
                frameBorder="0"
                height="55"
                src={`${CC_IFRAME_URL}?${additParams}&css=${inputStyle}&placeholder=${placeholder}&cardnumbernumericonly=${true}`}
            ></iframe>
        </div>
    );
};
CreditCardIframe.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    htmlFor: PropTypes.string,
    inputStyle: PropTypes.string,
    required: PropTypes.bool,
    additParams: PropTypes.string,
    formData: PropTypes.object,
    formErrors: PropTypes.object,
    setFormErrors: PropTypes.func,
};
