import React from "react";
import { CheckoutEmailLookupModal } from "../../create-account/components/modal/checkout-email-lookup-modal";
import { PopupEmitter, TRIGGER_POPUP } from "../../../common/popup-emitter";
import { redirectWithParam } from "../../common/util";
import * as EmailLookupStatus from "../../create-account/components/email-lookup-constant";
import { OFFERS_PAGE, CREATE_ACCOUNT_PAGE, USE_DIFFERENT_EMAIL } from "../../create-account/components/constants";
import { getConfig } from "../../create-account/components/modal/config";
import { CREATE_ACCOUNT_PATH } from "../../../constants/urls";

export const triggerConflictModal = (modalDetails) => {
    const {
        t,
        page,
        props,
        programEntitlement,
        onChange,
        setEmailLookupStatus,
        isRegisteredUser,
        setRegisteredUser,
        modalType,
        isCartAbandonmentFlow,
        removeCartAbondonementId,
        offersList,
    } = modalDetails || {};
    const config = getConfig(modalType, false);
    const { header, signInUrl, signInLabel } = config || {};
    const onModalClose = (action) => {
        PopupEmitter.emit(TRIGGER_POPUP, { showPopup: false });
        if (page === OFFERS_PAGE && (action === USE_DIFFERENT_EMAIL || !offersList)) props && redirectWithParam(props?.history, CREATE_ACCOUNT_PATH);
        else if (page === CREATE_ACCOUNT_PAGE) {
            onChange("email", "");
            setEmailLookupStatus(EmailLookupStatus.NOT_TRIGGED);
            isRegisteredUser && setRegisteredUser(false);
            isCartAbandonmentFlow && removeCartAbondonementId();
            setTimeout(() => {
                document.getElementById("email").focus();
            }, 200);
        }
    };
    if (config) {
        PopupEmitter.emit(TRIGGER_POPUP, {
            closeButton: false,
            showPopup: true,
            modalId: `login-modal:with-bod:${modalType.split(",").join(":").toLowerCase()}`,
            modalHeader: programEntitlement
                ? t(header, {
                      programName: programEntitlement,
                  })
                : t(header),
            customizedContent: (
                <CheckoutEmailLookupModal t={t} closePopup={onModalClose} signInUrl={signInUrl} signInLabel={signInLabel} customerWithoutBOD={false} />
            ),
        });
    }
};
