import { all, fork } from "redux-saga/effects";
import pageRouterSaga from "../component/page-router/data/page-router-saga";
import offersSaga from "../module/offers-page/data/offers-page-saga";
import checkoutSaga from "../module/checkout-page/data/checkout-page-saga";
import activationSaga from "../module/activation-page/data/activation-page-saga";

export default function* rootSaga() {
    yield all([
        fork(pageRouterSaga),
        fork(offersSaga),
        fork(checkoutSaga),
        fork(activationSaga),
    ]);
}
