// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./cybersource.scss";
import { decodeUnicode, validateEligibleCards } from "../../helper/util";
import { useSelector } from "react-redux";
import { CyberSourceIframeStyles, CyberSourceIframeStylesMyx } from "../../constants/cybersource-iframe";
import { checkoutActions } from "../../module/checkout-page/data/checkout-page-action";
import { MICROFORM_ERRORS } from "../../constants/microform-errors";

export const CyberSourceIframe = ({
    dispatch,
    doFieldValidation,
    formData,
    formErrors,
    formOrigin = "default",
    id,
    onChange,
    setFormErrors,
    t,
    validateField,
}) => {
    const { cyberSourceKey, microFormError, isCVViFrame } = useSelector((state) => state.checkout);
    const [maskedNumber, setMaskedNumber] = useState();
    const [showMaskedNumber, setShowMaskedNumber] = useState();
    const [ccNumIframe, setIframeInstance] = useState();
    const [getPermanentId, callPermanentToken] = useState({});
    const [ccDetails, updateCCDetails] = useState();
    const [isToFloat, setIsToFloat] = useState();
    const [isToFloatCVV, setIsToFloatCVV] = useState();
    let ccFormData = { cvv: {}, card: [] };
    const isMyxBike = formOrigin === "myx";
    const getPermanentTokenSuccess = (ccNumber) => {
        const {
            fieldData: { card },
        } = getPermanentId || [];
        const ccType = card && card.length > 0 ? card[0].name : "";
        const isValidType = validateEligibleCards(ccType);
        ccNumber = isValidType ? ccNumber : "";
        const payload = { ccType, ccNumber };
        const error = validateField({ name: "ccNumber", value: ccNumber });
        error && setFormErrors("ccNumber", error);
        updateCCDetails(payload);
    };
    const getPermanentTokenFailure = (formExceptions) => {
        onChange("ccNumber", "");
        const error =
            formExceptions &&
            formExceptions.map((error) => {
                const { localizedMessage } = error || {};
                return `<div className="error-msg">${localizedMessage}</div>`;
            });
        dispatch({ type: checkoutActions.MICROFORM_ERROR, payload: error });
    };
    const getPermanentToken = (jti) => {
        dispatch({
            type: checkoutActions.CYBERSOURCE_PERMANENT_TOKEN,
            callbacks: { success: getPermanentTokenSuccess, failure: getPermanentTokenFailure },
            payload: jti,
        });
    };

    const generateCCNumberIframe = (key) => {
        let fieldData = {};
        const iframeStyles = isMyxBike ? CyberSourceIframeStylesMyx : CyberSourceIframeStyles;
        const flex = new window.Flex(key);
        const microForm = flex && flex.microform({ styles: iframeStyles, autocomplete: isMyxBike ? false : true });
        // Create Credit Card Iframe
        const number = microForm && microForm.createField("number");
        const securityCode = microForm && microForm.createField("securityCode");
        isCVViFrame && securityCode.load("#ccCVV_iFrame");

        setIsToFloat(false);
        setIsToFloatCVV(false);
        setIframeInstance(number);
        number.load(`#${id}`);
        number.on("autocomplete", function () {
            callPermanentToken({
                microForm,
                fieldData,
                ...(isCVViFrame && {
                    ccFormData,
                    isCardValid: ccFormData.card.length > 0 && ccFormData.card[0].valid && ccFormData?.cvv?.valid,
                }),
            });
        });
        number.on("focus", function () {
            setIsToFloat(true);
            microFormError && dispatch({ type: checkoutActions.MICROFORM_ERROR, payload: null });
        });

        number.on("change", function (data) {
            fieldData = data;
            ccFormData = { ...ccFormData, ...data };
            setMaskedNumber("");
            setIsToFloat(true);
        });

        number.on("blur", () => {
            callPermanentToken({
                microForm,
                fieldData,
                ...(isCVViFrame && {
                    ccFormData,
                    isCardValid: ccFormData.card.length > 0 && ccFormData.card[0].valid && ccFormData?.cvv?.valid,
                }),
            });
        });

        if (isCVViFrame) {
            securityCode.on("focus", () => {
                setIsToFloatCVV(true);
            });

            securityCode.on("change", (data) => {
                ccFormData.cvv = data;
                setIsToFloatCVV(true);
            });

            securityCode.on("blur", () => {
                const { empty } = ccFormData.cvv || {};
                if (empty || typeof empty === "undefined") {
                    setIsToFloatCVV(false);
                }

                callPermanentToken({
                    microForm,
                    fieldData,
                    ccFormData,
                    isCardValid: ccFormData.card.length > 0 && ccFormData.card[0].valid && ccFormData?.cvv?.valid,
                });
            });
        }
    };

    const clearCCDetails = () => {
        // Empty Hidden Values on invalid tokenization
        const cardDetails = {
            ccType: "",
            ccNumber: "",
            isCVVValid: false,
            ccCVV: "",
        };
        onChange("", "", cardDetails);
        const error = validateField({ name: "ccNumber", value: "" });
        error && setFormErrors("ccNumber", error);
    };
    const tokenizeCreditCard = (microForm, data, ccFormData, isCardValid) => {
        const { empty, card, valid } = data || {};
        const isValidType = validateEligibleCards(card?.[0]?.name ?? "");
        empty || typeof empty === "undefined" ? setIsToFloat(false) : setIsToFloat(true);
        if (typeof empty === "undefined" && typeof valid === "undefined") setShowMaskedNumber(true);
        isCVViFrame && isCardValid && ccFormData && onChange("ccCVV", ccFormData.cvv);
        if (valid) {
            microForm &&
                microForm.createToken({}, (err, token) => {
                    if (err) {
                        const { reason } = err || {};
                        console.log("Issue Generating Token : ", err);
                        if (MICROFORM_ERRORS.indexOf(reason) !== -1) {
                            dispatch({ type: checkoutActions.MICROFORM_ERROR, payload: t("microform-error") });
                            dispatch({ type: checkoutActions.GET_CYBERSOURCE_KEY });
                        }

                        if (card?.[0]?.valid && isValidType) {
                            setFormErrors("ccNumber", "");
                            onChange("", "", {
                                isCVVValid: ccFormData.card.length > 0 && ccFormData.card[0].valid && ccFormData?.cvv?.valid,
                                isCCNumberValid: true,
                            });
                        } else {
                            onChange("", "", {
                                isCVVValid: ccFormData.card.length > 0 && ccFormData.card[0].valid && ccFormData?.cvv?.valid,
                                isCCNumberValid: false,
                            });
                            clearCCDetails();
                        }
                        return;
                    } else {
                        if (isValidType) {
                            setFormErrors("", "", { ccNumber: "", ccCVV: "" });
                            const jwtPayload = decodeUnicode(token.split(".")[1]);
                            const payload = JSON.parse(jwtPayload);
                            const {
                                data: { number },
                                jti,
                            } = payload || { data: {} };
                            setMaskedNumber(number);
                            setShowMaskedNumber(true);
                            let ccPayload = null;
                            if (isCVViFrame) {
                                ccPayload = {
                                    ccNumber: token,
                                    ccType: ccFormData.card && ccFormData.card.length > 0 ? ccFormData.card[0].name : "",
                                    isCCNumberValid: ccFormData.card.length > 0 && ccFormData.card[0].valid,
                                    isCVVValid: ccFormData.card.length > 0 && ccFormData.card[0].valid && ccFormData?.cvv?.valid,
                                    creditCardInfo: ccFormData,
                                    ...(isMyxBike &&
                                        number && {
                                            lastFourDigits: number.slice(-4),
                                        }),
                                };
                            }
                            isCVViFrame ? onChange("", "", ccPayload) : getPermanentToken(jti);
                        } else {
                            onChange("", "", {
                                ccType: ccFormData.card && ccFormData.card.length > 0 ? ccFormData.card[0].name : "",
                                ...(formData?.["ccNumber"] && {
                                    ccNumber: "",
                                    isCVVValid: false,
                                    isCCNumberValid: false,
                                    ccCVV: "",
                                }),
                            });
                        }
                    }
                });
        } else if (valid === false) {
            clearCCDetails();
        }
    };
    const unMaskCCNumber = () => {
        setShowMaskedNumber(false);
        ccNumIframe.focus();
    };
    useEffect(() => {
        onChange("floatCVVLabel", isToFloatCVV);
    }, [isToFloatCVV]);
    useEffect(() => {
        if (cyberSourceKey) {
            generateCCNumberIframe(cyberSourceKey);
        }
    }, [cyberSourceKey]);
    useEffect(() => {
        const { microForm, fieldData, isCardValid, ccFormData } = getPermanentId || {};
        fieldData && tokenizeCreditCard(microForm, fieldData, ccFormData, isCardValid) && callPermanentToken({});
    }, [getPermanentId]);
    useEffect(() => {
        ccDetails && onChange("", "", ccDetails) && updateCCDetails();
    }, [ccDetails]);
    return (
        <div className="text-input cybersource-iframe">
            <label className={`iframe-placeholder ${isToFloat ? "float" : ""}`}>{t("cc-number")}</label>
            <div id={id} className={`${doFieldValidation && formErrors && formErrors["ccNumber"] ? "error-border" : ""}`}></div>
            {maskedNumber && showMaskedNumber && (
                <div className="masked-number" onClick={unMaskCCNumber}>
                    {maskedNumber}
                </div>
            )}
        </div>
    );
};
CyberSourceIframe.propTypes = {
    dispatch: PropTypes.func,
    doFieldValidation: PropTypes.bool,
    formData: PropTypes.object,
    formErrors: PropTypes.object,
    formOrigin: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    setFormErrors: PropTypes.func,
    t: PropTypes.func,
    validateField: PropTypes.func,
};
