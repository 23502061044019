import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ContentAlignment from "./content-alignment";
import { RichText } from "../rich-text";
import { FieldRenderer } from "../../contentful/component-renderer";
import "./content-alignment/themes/index.scss";

export const MediaTextContainer = ({ fields, ...data }) => {

    const { contentAlignment, ...props } = fields;
    const ContainerRenderer = ContentAlignment[contentAlignment];
    return (<Fragment>
        {ContainerRenderer ?
            <ContainerRenderer fields={props} {...data} RichText={RichText} FieldRenderer={FieldRenderer}/> : null
        }
    </Fragment>
    );



};
MediaTextContainer.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
};