/* eslint-disable react/display-name */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { FieldRenderer } from "../../contentful/component-renderer";
export const RichText = ({ content, fieldId = "", renderNode = {}, inlineClass = "", id = "" }) => {
    const props = fieldId ? { id: fieldId } : {};

    const removeEmptyBlocks = (children) => {
        if (children && children instanceof Array && children.length === 1) {
            const data = children && children instanceof Array ? children[0] : null;
            if (data && data instanceof Array && data.length === 2) {
                const innerText = data[1];
                if (!innerText) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    };
    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (_node, children) => (children && !removeEmptyBlocks(children) ? <h1 {...props}>{children}</h1> : null),
            [BLOCKS.HEADING_2]: (_node, children) => (children && !removeEmptyBlocks(children) ? <h2 {...props}>{children}</h2> : null),
            [BLOCKS.HEADING_3]: (_node, children) => (children && !removeEmptyBlocks(children) ? <h3 {...props}>{children}</h3> : null),
            [BLOCKS.HEADING_4]: (_node, children) => (children && !removeEmptyBlocks(children) ? <h4 {...props}>{children}</h4> : null),
            [BLOCKS.HEADING_5]: (_node, children) => (children && !removeEmptyBlocks(children) ? <h5 {...props}>{children}</h5> : null),
            [BLOCKS.HEADING_6]: (_node, children) => (children && !removeEmptyBlocks(children) ? <h6 {...props}>{children}</h6> : null),
            [BLOCKS.PARAGRAPH]: (_node, children) => (children && !removeEmptyBlocks(children) ? <p {...props}>{children}</p> : null),
            [BLOCKS.HR]: () => <hr {...props}></hr>,
            [BLOCKS.QUOTE]: (_node, children) => (
                <blockquote {...props} className="block-quote">
                    {children}
                </blockquote>
            ),
            [BLOCKS.UL_LIST]: (_node, children) => (children && !removeEmptyBlocks(children) ? <ul {...props}>{children}</ul> : null),
            [INLINES.EMBEDDED_ENTRY]: (node) => {
                return <FieldRenderer contents={node.data.target} />;
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                return <FieldRenderer contents={node.data.target} />;
            },
            ...renderNode,
        },
        renderText: (text) => text.split("\n").map((text, i) => [i > 0 && <br key={i} />, text]),
    };

    return inlineClass ? (
        <div className={inlineClass} id={id}>
            {documentToReactComponents(content, options)}
        </div>
    ) : (
        <Fragment>{documentToReactComponents(content, options)}</Fragment>
    );
};

RichText.propTypes = {
    content: PropTypes.object,
    renderNode: PropTypes.object,
    inlineClass: PropTypes.string,
    fieldId: PropTypes.string,
    id: PropTypes.string,
};
