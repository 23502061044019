import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../../../contentful/component-renderer";
export const MyxSecurityContent = ({ fields }) => {
    const { securityLogos, securityMessages } = fields;
    const firstLogoIndex = 0;
    const firstLogo = securityLogos && securityLogos[firstLogoIndex];
    const restLogos = [...securityLogos.slice(0, firstLogoIndex), ...securityLogos.slice(firstLogoIndex + 1)];
    const securityMessage = securityMessages && securityMessages[0];

    return (
        <div className="security-content">
            {firstLogo && <FieldRenderer contents={firstLogo} inlineClass="first-security-logo" />}
            {securityMessage && <FieldRenderer contents={securityMessage} inlineClass="security-message" />}
            <div className="separator-line"></div>
            <div className="rest-logos">
                {restLogos && <FieldRenderer contents={restLogos} inlineClass="security-logo" />}
            </div>
        </div>
    );
};

MyxSecurityContent.propTypes = {
    fields: PropTypes.object,
};
