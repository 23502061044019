import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";

export function Interstitial({ contents, onButtonClick, bannerImage, containerStyle, sectionStyle, basePageCSS, customCss, id }) {
    return (
        <section style={sectionStyle}>
            <style>
                {basePageCSS}
                {customCss}
            </style>
            <div id={`element-${id}`} style={containerStyle} className="container interstitial-container">
                <div className="content-container">
                    <div className="banner-container">
                        <FieldRenderer contents={bannerImage} />
                    </div>
                    <div className="main-content-container">
                        <FieldRenderer contents={contents} onButtonClick={onButtonClick} />
                    </div>
                </div>
            </div>
        </section>
    );
}

Interstitial.propTypes = {
    contents: PropTypes.array,
    onButtonClick: PropTypes.func,
    bannerImage: PropTypes.object || PropTypes.string,
    containerStyle: PropTypes.object,
    sectionStyle: PropTypes.object,
    basePageCSS: PropTypes.string,
    customCss: PropTypes.string,
    id: PropTypes.string,
};
