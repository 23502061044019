import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../../contentful/component-renderer";
import "./flex-header-item.scss";

export const FlexHeaderItem = ({ fields }) => {
    const { itemContainer } = fields;

    return (
        <Fragment>
            <div className="bb-fhi">{<FieldRenderer contents={itemContainer} />}</div>
        </Fragment>
    );
};

FlexHeaderItem.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
};
