import { CarouselBasicSlider } from "./carousel-basic-slider";
import { CarouselSliderOnThumbnail } from "./carousel-slider-on-thumbnail";
import { CarouselSliderOnFigure } from "./carousel-slider-on-img-figure";
import { CarouselInfiniteSlider } from "./carousel-infinite-slider";
export default {
    "carousel-basic-slider": CarouselBasicSlider,
    "carousel-slider-on-thumbnail": CarouselSliderOnThumbnail,
    "carousel-slider-on-img-figure": CarouselSliderOnFigure,
    "carousel-infinite-slider": CarouselInfiniteSlider,
};
