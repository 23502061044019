import React, { Fragment } from "react";
import PropTypes from "prop-types";
import contentfulConfig from "../config";
import { HarmonyPopup } from "../../common/popup-emitter";

export const PageTemplateRenderer = ({ page, pageTemplateName, ...props }) => {
    //Need a proper reference name for page templates
    const templateName = pageTemplateName.replace(/[^0-9a-zA-Z]/g, "");
    const ContentfulPageTemplateComponentToRender = contentfulConfig.pageTemplateComponents[templateName];
    return (
        <Fragment>
            <ContentfulPageTemplateComponentToRender content={page} {...props} />
            <HarmonyPopup />
        </Fragment>
    );
};

PageTemplateRenderer.propTypes = {
    pageTemplateName: PropTypes.string,
    props: PropTypes.object,
    page: PropTypes.object,
};
