import React from "react";
import PropTypes from "prop-types";
import ImageGallery from "react-image-gallery";
import ImageZoom from "./image-zoom";
import "./product-carousel.scss";

export const ProductCarousel = ({ images }) => {
    const handleOnMouseOver = (event) => {
        const img = event.target;
        img.style.cursor = "zoom-in";
    };

    const handleOnMouseLeave = (event) => {
        const img = event.target;
        img.style.cursor = "zoom-out";
    };

    const renderItem = (item) => {
        return (
            <div className="image-gallery-slides">
                <ImageZoom img={item.original} />
            </div>
        );
    };

    return (
        <ImageGallery
            items={images}
            showPlayButton={false}
            showFullscreenButton={false}
            onMouseOver={handleOnMouseOver}
            onMouseLeave={handleOnMouseLeave}
            renderItem={renderItem}
        />
    );
};

ProductCarousel.propTypes = {
    images: PropTypes.array,
};
