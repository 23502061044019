import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";
import "./themes/index.scss";

export const ImageGridContentContainer = (props) => {
    const { fields, fieldId, isDesktopView, RichText } = props;

    const {
        topContainer,
        imageGridContainer,
        bottomContainer,
        theme,
        imageContainersPerRowDesktop,
        imageContainersPerRowMobile,
        singleMobileImage,
        singleMobileContent,
    } = fields;
    const SINGGLE_MOBILE_IMG_THEME = "grid-on-desktop-single-image-on-mobile-theme";
    const SHOW_MORE_LESS_THEME = "grid-show-less-more-theme";
    const [showLess, toggleShow] = useState(true);
    const [isToggle, setToggled] = useState(false);

    const toggleButtonClick = () => {
        toggleShow(!showLess);
        setToggled(true);

    };
    useEffect(() => {
        if (theme === SHOW_MORE_LESS_THEME && isToggle) {
            const imageGrid = document.getElementsByClassName("grid-show-less-more-theme")[0];
            if (showLess) {
                imageGrid && imageGrid.scrollIntoView();
            } else {
                imageGrid && imageGrid.scrollIntoView(false);
            }
        }

    }, [showLess]);

    const getGridCountPerRow = () => {
        let count = 1;
        if (isDesktopView) {
            if (imageContainersPerRowDesktop === "none") {
                return null;
            }
            count = imageContainersPerRowDesktop === "auto" ? imageGridContainer ? imageGridContainer.fields.contents.length : 1 : parseInt(imageContainersPerRowDesktop);

        } else {
            if (imageContainersPerRowMobile === "none") {
                return null;
            }

            count = imageContainersPerRowMobile === "auto" ? 1 : parseInt(imageContainersPerRowMobile);
        }
        return count;
    };

    const ImageGridPerRow = getGridCountPerRow();
    const gridElementWidth = ImageGridPerRow ? `${100 / ImageGridPerRow}%` : null;
    const gridElementStyle = gridElementWidth ? { width: gridElementWidth } : {};

    const loadImageGridBasedOnTheme = () => {
        if (theme === SHOW_MORE_LESS_THEME) {
            return (
                <FieldRenderer
                    contents={imageGridContainer}
                    inlineClass={showLess ? "image-grid show-less" : "image-grid show-more"}
                    inlineTheme="grid-element"
                    inlineOuterStyle={gridElementStyle} />
            );
        } else if ((theme === SINGGLE_MOBILE_IMG_THEME && isDesktopView) ||
                theme !== SINGGLE_MOBILE_IMG_THEME) {
            return (
                <FieldRenderer
                    contents={imageGridContainer}
                    inlineClass="image-grid"
                    inlineTheme="grid-element"
                    inlineOuterStyle={gridElementStyle} />
            );
        } else if (theme === SINGGLE_MOBILE_IMG_THEME && !isDesktopView) {
            return (<div className="single-mobile-img-theme">
                {singleMobileImage && <FieldRenderer contents={singleMobileImage} />}
                {singleMobileContent && <RichText content={singleMobileContent} />}
            </div>);
        }
    };

    return (<div id={fieldId}
        className={`${theme} grid-container content-container`} >
        {topContainer && <FieldRenderer contents={topContainer} inlineClass="grid-top-container" />}
        { imageGridContainer && loadImageGridBasedOnTheme() }
        { bottomContainer && <FieldRenderer
            contents={bottomContainer}
            onButtonClick={theme === SHOW_MORE_LESS_THEME ? toggleButtonClick : null}
            toggleButton={!showLess}/> }
    </div>
    );
};
ImageGridContentContainer.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    isDesktopView: PropTypes.bool,
    RichText: PropTypes.func,
    FieldRenderer: PropTypes.func,
};