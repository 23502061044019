import React from "react";
import PropTypes from "prop-types";
import "./loader.scss";
export const Loader = ({ className, isToShowLoader }) => {
    return (
        <div className={className}>
            {isToShowLoader && (
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            )}
        </div>
    );
};
Loader.propTypes = {
    className: PropTypes.string,
    isToShowLoader: PropTypes.bool,
};
