import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Loader } from "../../common";
import { Interstitial } from "./interstitial";

export function QuizInterstitialUpsellPage({ contentData, onButtonClick }) {
    const { surveyDetails } = useSelector((state) => state.pageRouter);
    const { basePageCSS } = surveyDetails;
    const {
        fields: { maxWidth, upsellContent, backgroundColor, bannerImage, customCss },
        sys: { id },
    } = contentData || {
        fields: { maxWidth: 1170, upsellContent: [], backgroundColor: "#171819", bannerImage: "", customCss: "" },
        sys: { id: "" },
    };
    const {
        fields: { contents },
    } = upsellContent[0] || { fields: { contents: [] } };
    const sectionStyle = {
        backgroundColor,
    };
    const containerStyle = {
        maxWidth: `${maxWidth}px`,
    };

    return (
        <>
            {contentData ? (
                <Interstitial
                    sectionStyle={sectionStyle}
                    basePageCSS={basePageCSS}
                    customCss={customCss}
                    bannerImage={bannerImage}
                    contents={contents}
                    onButtonClick={onButtonClick}
                    containerStyle={containerStyle}
                    id={id}
                />
            ) : (
                <Loader className="loader-wrapper d-flex justify-content-center align-items-center vh-100" isToShowLoader={true} />
            )}
        </>
    );
}

QuizInterstitialUpsellPage.propTypes = {
    contentData: PropTypes.object,
    onButtonClick: PropTypes.func,
};
