import React, { Fragment, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../../contentful/component-renderer";
import "./sticky-container.scss";

export const StickyContainer = ({ fieldId, fields }) => {
    const { container, isBottom = false } = fields;
    const stickyReference = useRef(null);
    const [height, setHeight] = useState(0);
    const [top, setTop] = useState(0);
    const isClient = process.env.BROWSER && document;

    function activateFixedHeader() {
        if (window.pageYOffset > top) {
            stickyReference.current?.classList.add("stickyTop");
            isClient ? (document.getElementsByTagName("html")[0].style.paddingTop = height + "px") : null;
        } else {
            stickyReference.current?.classList.remove("stickyTop");
            isClient ? (document.getElementsByTagName("html")[0].style.paddingTop = "0") : null;
        }
    }

    useEffect(() => {
        activateFixedHeader();
        setHeight(stickyReference.current?.offsetHeight);
        setTop(stickyReference.current?.offsetTop);
        window.addEventListener("scroll", activateFixedHeader);
        return () => window.removeEventListener("scroll", activateFixedHeader);
    }, []);

    return (
        <Fragment>
            <div id={fieldId} className={`bb-sc${isBottom ? " stickyBottom" : ""}`} ref={stickyReference}>
                {<FieldRenderer contents={container} />}
            </div>
        </Fragment>
    );
};

StickyContainer.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
};
