import React from "react";
import PropTypes from "prop-types";
import { BaseMediaContainer } from "./components";
import { FieldRenderer } from "../../../contentful/component-renderer";


export const CoverImageContentCenter = ({ ...props }) => {
    return (<BaseMediaContainer type="cover-image-content-center-theme col-12" {...props} >
        {(data) => {
            const { textContainer,hideFieldCallback } = data;
            return (textContainer ? <FieldRenderer contents={textContainer} hideFieldCallback={hideFieldCallback}/> : null);
        }}
    </BaseMediaContainer>
    );
};
CoverImageContentCenter.propTypes = {
    fields: PropTypes.object,
    isDesktopView: PropTypes.bool,
};