import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "../create-account-page-content.scss";
import { CTAButton } from "../../../common";
import { useHistory } from "react-router";
import { CREATE_ACCOUNT_PATH } from "../../../constants/urls";
import { BaseCreateAccountWithoutLogin } from "../base-create-acct-without-login";
import { Constants, PasswordPanel } from "../components";
import { useSelector } from "react-redux";
import { getUrlParam } from "../../../helper/util";
import { redirectWithParam } from "../../common/util";
import { PasswordValidation } from "../components/password-validation";

export function CreatePassword(props) {
    const history = useHistory();
    const { userDetail, enforceBBDirectLoginSecurity } = useSelector((state) => state.pageRouter);

    const continueBtn = "continue-btn";
    const createPassword = "create-password";
    const edit = "edit";
    const email = "email";

    useEffect(() => {
        if ((!userDetail || !(userDetail && userDetail.email) || enforceBBDirectLoginSecurity) && !getUrlParam(email)) {
            redirectWithParam(history, CREATE_ACCOUNT_PATH);
        }
    }, []);
    return (
        <BaseCreateAccountWithoutLogin {...props} page={Constants.CREATE_PASSWORD}>
            {({ emailEdit, onContinueBtn, onEnter, onChange, showPageLoader, t, disableBtn, ...formProps }) => {
                return (
                    <section className="password-section">
                        <div className="password-section-heading">
                            <h2>{t(createPassword)}</h2>
                        </div>
                        <div className="d-flex justify-content-between align-items-center email-edit-section mb-4">
                            <div className="entered-email">
                                <label className="m-0">{t("email-address-text")}</label>
                                <p>{userDetail && userDetail.email}</p>
                            </div>
                            <CTAButton buttonName={t(edit)} onButtonClick={emailEdit} innerBtnClass="btn edit-btn p-0" />
                        </div>
                        <PasswordPanel {...props} {...formProps} onKeyPress={onEnter} onChange={onChange} />
                        <PasswordValidation {...formProps} t={t} />
                        <CTAButton
                            outerBtnClass="continue-btn"
                            buttonName={t(continueBtn)}
                            onButtonClick={onContinueBtn}
                            innerBtnClass={disableBtn ? "btn edit-btn" : "btn edit-btn valid"}
                            icon={showPageLoader ? "fa-refresh fa-spin" : ""}
                        />
                    </section>
                );
            }}
        </BaseCreateAccountWithoutLogin>
    );
}
CreatePassword.propTypes = {
    props: PropTypes.object,
};
