import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextInput } from "../../../common";
import { useDispatch } from "react-redux";
import { checkoutActions } from "../data/checkout-page-action";
import { createPartialProfile } from "../../common/util";
import { offersActions } from "../../offers-page/data/offers-page-action";
import { RadioButtonGroup } from "../../../common";
import { COACH } from "../constants";
import { useSelector } from "react-redux";
import { extractFieldValues } from "../../../helper/util";

export function CoachRefferal({
    t,
    formErrors,
    setFormErrors,
    onChange,
    validateField,
    formData,
    abondonedUserData,
    doFieldValidation,
    order,
    isPCUser,
    coachRefferalRef,
    setIsFetchingCoachDetails,
    textContent,
}) {
    const dispatch = useDispatch();
    // eslint-disable-next-line no-use-before-define
    const [coach, setCoach] = useState(formData["email"] === coach && coach.email ? coach : null);
    const [coachId, setCoachId] = useState(formData["coachId"]);
    const [showInfoModal, setShowInfoModal] = useState();
    const refRepId = sessionStorage.getItem("refRepId");
    const assignedType = order && order?.profile?.assignedCoachType;
    const { referringCoachDetails } = useSelector((state) => state.checkout);
    const {
        coach_conflict_yes_answer = "",
        coach_conflict_no_answer = "",
        coach_referral_failed = "",
        beachbody_coach = "",
        coach_conflict_tooltip_modal = "",
        coach_referral_conflict_info = "",
        referred_by_any_coach = "",
        coach_info_optional = "",
        coach_referral_confirmation = "",
    } = extractFieldValues(textContent, "textValue");
    const previousCoach = order && order?.profile?.coach;
    const coachConflictOptions = [
        {
            buttonName: coach_conflict_yes_answer
                ?.replace("{{firstNameB}}", coach?.firstName)
                .replace("{{lastNameB}}", coach?.lastName)
                .replace("{{coachID}}", coach?.gncCoachID),
            buttonKey: coach?.gncCoachID,
        },
        {
            buttonName: coach_conflict_no_answer
                ?.replace("{{firstNameA}}", previousCoach?.firstName)
                .replace("{{lastNameA}}", previousCoach?.lastName)
                .replace("{{coachID}}", previousCoach?.id),
            buttonKey: previousCoach?.id,
        },
    ];

    const coachDetailsFetch = (result) => {
        const { status, searchStatus, searchUser } = result || {};
        if (status === "SUCCESS") {
            if (searchStatus === "USER_FOUND") {
                const id = searchUser && searchUser?.gncCoachID ? searchUser.gncCoachID : coachId;
                setCoach({ ...searchUser, email: formData["email"] });
                onChange("coachId", id);
                const payload = { ...formData, coachId };
                if (!formErrors["email"]) {
                    const { profile } = abondonedUserData || {};
                    if (profile) {
                        const { firstName, lastName, email } = profile || {};
                        const AbData = {
                            firstName: formData["firstName"] || firstName || "",
                            lastName: formData["lastName"] || lastName || "",
                            email,
                            coachId,
                        };
                        dispatch({
                            type: offersActions.UPDATE_ABANDONED_PROFILE,
                            payload: {
                                abondonedUserData: { profile: { ...AbData } },
                            },
                        });
                    }
                }
                !formErrors["email"] ? createPartialProfile({ dispatch, payload }) : null;
            } else if (searchStatus === "USER_NOT_FOUND") {
                setCoach(coach_referral_failed);
                onChange("coachId", "");
            }
        } else if (status === "FAILED") {
            setCoach(coach_referral_failed);
            onChange("coachId", "");
        }
        setIsFetchingCoachDetails(false);
    };
    const fetchCoachDetails = ({ name, value, referringCoachId = false }) => {
        const error = !isNaN(value) && typeof Number(value) === "number" ? false : validateField({ name, value });
        if (error) {
            setFormErrors(name, error);
            setCoach();
            onChange("coachId", "");
            setIsFetchingCoachDetails(false);
        } else {
            setFormErrors(name, "");
            dispatch({ type: checkoutActions.FETCH_COACH_DETAILS, payload: value, callback: !referringCoachId && coachDetailsFetch });
        }
    };
    const onCoachInfoChange = (name, value) => {
        if (!value.length) {
            setFormErrors(name, "");
            setCoach();
            onChange("coachId", "");
            setIsFetchingCoachDetails(false);
        } else setIsFetchingCoachDetails(true);
        setCoachId(value);
    };
    const onRadioBtnClick = (name, value) => {
        doFieldValidation && validateField({ name, value: !formData[name], callback: setFormErrors });
        const data = { coachId: value, coachConflict: value };
        onChange("", "", data);
        setCoachId(value);
    };
    useEffect(() => {
        referringCoachDetails && coachDetailsFetch(referringCoachDetails);
    }, [referringCoachDetails]);
    useEffect(() => {
        dispatch({ type: checkoutActions.REFERRING_COACH_DETAILS, payload: null });
        if (refRepId && !isPCUser) {
            if (assignedType !== COACH) {
                setCoachId(refRepId);
            }
            fetchCoachDetails({ name: "coachId", value: refRepId, referringCoachId: true });
        }
    }, []);

    useEffect(() => {
        if (order && coach) {
            if (assignedType === COACH && coach?.isCoach && coach?.gncCoachID !== previousCoach?.id && !isPCUser) {
                order?.validateUserConfirmations.push("coachConflict");
            }
        }
    }, [order, coach]);
    return (
        <div className="coach-referral mt-4 mb-1" ref={coachRefferalRef}>
            {assignedType === COACH ? (
                coach &&
                coach?.isCoach &&
                coach?.gncCoachID !== previousCoach?.id &&
                !isPCUser && (
                    <div className="coach-conflict-section">
                        <div className="coach-conflict-row">
                            <h3 className="heading">{beachbody_coach}</h3>
                            <span className="conflict-tooltip-icon" onClick={() => setShowInfoModal(true)}>
                                <i className="fa fa-info-circle ml-1" aria-hidden="true"></i>
                            </span>
                        </div>
                        {showInfoModal && (
                            <div className="conflict-tooltip-modal">
                                <div className="close-icon">
                                    <span onClick={() => setShowInfoModal(false)}>
                                        <i className="fa fa-times"></i>
                                    </span>
                                </div>
                                <div className="conflict-tooltip-info">{coach_conflict_tooltip_modal}</div>
                            </div>
                        )}
                        <p
                            className="coach-info-item"
                            dangerouslySetInnerHTML={{
                                __html: coach_referral_conflict_info
                                    ?.replace("{{firstNameA}}", previousCoach?.firstName)
                                    .replace("{{lastNameA}}", previousCoach?.lastName)
                                    .replace("{{firstNameB}}", coach?.firstName)
                                    .replace("{{lastNameB}}", coach?.lastName),
                            }}
                        ></p>
                        <RadioButtonGroup outerCss="coach-info-item" propertyName="coachConflict" buttons={coachConflictOptions} onClick={onRadioBtnClick} />
                        {formErrors["coachConflict"] && <div className="error-msg text-left">{t(formErrors["coachConflict"])}</div>}
                    </div>
                )
            ) : (
                <>
                    <h3 className="heading mb-3">{referred_by_any_coach}</h3>
                    <TextInput
                        name="coachId"
                        id="coachId"
                        onChange={onCoachInfoChange}
                        onBlur={fetchCoachDetails}
                        error={formErrors["coachId"]}
                        dValue={coachId}
                        setFormErrors={setFormErrors}
                        floatLabel={coach_info_optional}
                    />
                    {coach && coach.isCoach ? (
                        <p
                            className="text-success"
                            dangerouslySetInnerHTML={{
                                __html: coach_referral_confirmation?.replace("{{firstName}}", coach?.firstName).replace("{{lastName}}", coach?.lastName),
                            }}
                        ></p>
                    ) : (
                        coach && <p className="error-msg optional-field">{coach}</p>
                    )}
                </>
            )}
        </div>
    );
}
CoachRefferal.propTypes = {
    t: PropTypes.func,
    onChange: PropTypes.func,
    setFormErrors: PropTypes.func,
    formErrors: PropTypes.object,
    formData: PropTypes.object,
    validateField: PropTypes.func,
    createPartialProfile: PropTypes.func,
    abondonedUserData: PropTypes.object,
    order: PropTypes.object,
    doFieldValidation: PropTypes.bool,
    isPCUser: PropTypes.bool,
    setIsFetchingCoachDetails: PropTypes.func,
    coachRefferalRef: PropTypes.shape({
        current: PropTypes.any,
    }),
    textContent: PropTypes.array,
};
