import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./radio-button-group.scss";

export const RadioButtonGroup = ({ outerCss, propertyName, onClick, buttons }) => {
    const onBtnClick = (event, buttonKey) => {
        onClick(propertyName, buttonKey);
    };
    return (
        <div className={`col-xs-12 d-flex justify-content-start ${outerCss}`}>
            <form action="#" className="btn-group radio-buttons-group d-flex flex-column">
                {buttons &&
                    buttons.map((button, index) => {
                        return (
                            <Fragment key={index}>
                                <p>
                                    <input
                                        type="radio"
                                        id={propertyName + "-radio-" + index}
                                        name={propertyName}
                                        value={button.buttonKey}
                                        onClick={(event) => onBtnClick(event, button.buttonKey)}
                                    />
                                    <label
                                        htmlFor={propertyName + "-radio-" + index}
                                        dangerouslySetInnerHTML={{
                                            __html: button.buttonName,
                                        }}
                                    ></label>
                                </p>
                            </Fragment>
                        );
                    })}
            </form>
        </div>
    );
};
RadioButtonGroup.propTypes = {
    propertyName: PropTypes.string,
    onClick: PropTypes.func,
    buttons: PropTypes.array,
    outerCss: PropTypes.string,
};
