import React from "react";

export function PayPalLogo() {
    return (
        <svg width="100" height="25" viewBox="0 0 100 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.4275 10.575C38.127 12.5488 36.6195 12.5488 35.1617 12.5488H34.3321L34.9138 8.86366C34.9492 8.641 35.1412 8.47691 35.3666 8.47691H35.7469C36.7393 8.47691 37.6769 8.47691 38.1601 9.04233C38.4495 9.38065 38.5369 9.88206 38.4275 10.575ZM37.7931 5.42566H32.2943C31.9184 5.42566 31.5984 5.69923 31.5398 6.07065L29.3162 20.1699C29.2725 20.4479 29.4875 20.6995 29.7689 20.6995H32.3944C32.7703 20.6995 33.0905 20.4259 33.1491 20.0547L33.7491 16.251C33.8075 15.8795 34.1277 15.606 34.5036 15.606H36.2436C39.8652 15.606 41.9557 13.8531 42.5021 10.3793C42.7479 8.85995 42.5119 7.66609 41.8005 6.83032C41.0183 5.91151 39.6324 5.42566 37.7931 5.42566Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50.5583 15.6367C50.304 17.1411 49.1096 18.1511 47.5864 18.1511C46.8227 18.1511 46.2113 17.9055 45.8184 17.4404C45.429 16.9793 45.2824 16.3224 45.4057 15.5909C45.643 14.1 46.8563 13.0574 48.3564 13.0574C49.1042 13.0574 49.7111 13.3055 50.112 13.7748C50.5153 14.2476 50.6737 14.9089 50.5583 15.6367ZM54.2276 10.512H51.5947C51.3691 10.512 51.1771 10.6759 51.142 10.899L51.0261 11.6352L50.842 11.3683C50.2719 10.5407 49.0011 10.2642 47.7322 10.2642C44.8232 10.2642 42.3384 12.4685 41.8548 15.5603C41.603 17.1026 41.9603 18.5765 42.8349 19.6052C43.6383 20.5505 44.7849 20.9439 46.1511 20.9439C48.4958 20.9439 49.7961 19.4377 49.7961 19.4377L49.6785 20.1694C49.6345 20.4477 49.8495 20.6995 50.1312 20.6995H52.5024C52.8783 20.6995 53.1983 20.4262 53.2569 20.0548L54.6803 11.0416C54.7243 10.7634 54.5093 10.512 54.2276 10.512Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M68.2506 10.512H65.6041C65.3511 10.512 65.1143 10.6375 64.9722 10.8468L61.3217 16.2235L59.7744 11.0569C59.6778 10.7336 59.3803 10.512 59.0427 10.512H56.4412C56.1271 10.512 55.9059 10.8209 56.0073 11.1182L58.9214 19.6716L56.1805 23.5391C55.9653 23.8426 56.1825 24.2625 56.5544 24.2625H59.1984C59.449 24.2625 59.6835 24.1397 59.8263 23.9336L68.6274 11.2316C68.838 10.9276 68.6205 10.512 68.2506 10.512Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M77.6458 10.575C77.3453 12.5488 75.8378 12.5488 74.3798 12.5488H73.5502L74.1322 8.86366C74.1673 8.641 74.3593 8.47691 74.5849 8.47691H74.9652C75.9574 8.47691 76.895 8.47691 77.3784 9.04233C77.6675 9.38065 77.755 9.88206 77.6458 10.575ZM77.0112 5.42566H71.5124C71.1365 5.42566 70.8165 5.69923 70.7579 6.07065L68.5345 20.1699C68.4905 20.4479 68.7055 20.6995 68.9873 20.6995H71.8084C72.0716 20.6995 72.2958 20.5079 72.3366 20.248L72.9672 16.251C73.0258 15.8795 73.3458 15.606 73.7217 15.606H75.4617C79.0836 15.606 81.1737 13.8531 81.7201 10.3793C81.966 8.85995 81.73 7.66609 81.0185 6.83032C80.2366 5.91151 78.8508 5.42566 77.0112 5.42566Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M89.7759 15.6367C89.5216 17.1411 88.3275 18.1511 86.8043 18.1511C86.0404 18.1511 85.4292 17.9055 85.0361 17.4404C84.6466 16.9793 84.5001 16.3224 84.6236 15.5909C84.8606 14.1 86.074 13.0574 87.5743 13.0574C88.3218 13.0574 88.929 13.3055 89.3296 13.7748C89.7329 14.2476 89.8913 14.9089 89.7759 15.6367ZM93.4452 10.512H90.8124C90.5867 10.512 90.3947 10.6759 90.3596 10.899L90.2437 11.6352L90.0599 11.3683C89.4895 10.5407 88.2188 10.2642 86.9498 10.2642C84.0411 10.2642 81.5561 12.4685 81.0724 15.5603C80.8209 17.1026 81.1782 18.5765 82.0525 19.6052C82.8562 20.5505 84.0026 20.9439 85.3687 20.9439C87.7134 20.9439 89.0138 19.4377 89.0138 19.4377L88.8964 20.1694C88.8522 20.4477 89.0672 20.6995 89.3491 20.6995H91.7201C92.0959 20.6995 92.416 20.4262 92.4748 20.0548L93.898 11.0416C93.942 10.7634 93.727 10.512 93.4452 10.512Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M96.549 5.8129L94.2923 20.1699C94.2485 20.4481 94.4635 20.6995 94.745 20.6995H97.0149C97.3907 20.6995 97.7108 20.4259 97.7693 20.0547L99.9944 5.95549C100.038 5.67723 99.8234 5.42566 99.5417 5.42566H97.0018C96.7761 5.42566 96.5841 5.59 96.549 5.8129Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.72849 6.18924C7.77816 5.87366 7.98056 5.61542 8.25314 5.48469C8.37719 5.42538 8.51558 5.39226 8.66064 5.39226H14.5716C15.272 5.39226 15.9249 5.43823 16.5219 5.53436C16.6924 5.56179 16.8583 5.59342 17.0196 5.62926C17.181 5.66509 17.3374 5.70488 17.4889 5.74936C17.5648 5.77135 17.6392 5.79458 17.7126 5.81905C18.0057 5.91642 18.2785 6.03108 18.5296 6.16403C18.8256 4.27674 18.5276 2.99243 17.5067 1.82922C16.3828 0.548617 14.3524 0 11.7546 0H4.21363C3.68281 0 3.23082 0.386256 3.14803 0.910655L0.00806387 20.8222C-0.0539645 21.2152 0.249752 21.5703 0.647376 21.5703H5.30271L7.72849 6.18924Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.4892 5.74943C17.3377 5.70495 17.1813 5.66516 17.0199 5.62933C16.8585 5.59349 16.6927 5.56186 16.5222 5.53443C15.9251 5.4383 15.2722 5.39233 14.5719 5.39233H8.6609C8.51584 5.39233 8.37745 5.42545 8.25339 5.48476C7.98081 5.61549 7.77842 5.87373 7.72875 6.18931L6.47236 14.1551L6.43604 14.3877C6.51882 13.8633 6.96711 13.4768 7.49793 13.4768H9.71069C14.0561 13.4768 17.4585 11.7116 18.4527 6.60572C18.4824 6.45472 18.5073 6.30818 18.5298 6.1641C18.2788 6.03115 18.0059 5.91649 17.7128 5.81912C17.6394 5.79465 17.5651 5.77142 17.4892 5.74943Z"
                fill="#E8F6FC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5298 6.16394C18.5073 6.30801 18.4823 6.45456 18.4527 6.60555C17.4585 11.7114 14.0561 13.4766 9.71061 13.4766H7.49786C6.96703 13.4766 6.51875 13.8631 6.43596 14.3875L4.98163 23.608C4.92751 23.9515 5.19317 24.2624 5.54087 24.2624H9.46497C9.92932 24.2624 10.3247 23.9246 10.3971 23.4656L10.4357 23.2662L11.1748 18.5787L11.2225 18.3198C11.2949 17.8609 11.6903 17.523 12.1547 17.523H12.7416C16.5431 17.523 19.5197 15.9785 20.3896 11.512C20.7529 9.64568 20.5651 8.08781 19.604 6.99255C19.3134 6.6614 18.9516 6.38734 18.5298 6.16394Z"
                fill="#B2E1F5"
            />
        </svg>
    );
}
