import React from "react";
import { ContentRenderer } from "../contentful/component-renderer";


export const OneColumnTemplate = (props) => {
    // eslint-disable-next-line react/prop-types
    const path = process.env.BROWSER && props.location && props.location.pathname;
    const id = path ? path.split("\/")[1] : "";
    return (
        // eslint-disable-next-line react/prop-types
        <div id={props.primaryProductCode || ""} className={`one-column-template ${id}`} >
            <ContentRenderer name="PageContent" {...props} />
        </div>
    );
};

export default OneColumnTemplate;
