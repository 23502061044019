import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../../../contentful/component-renderer";
const getButtonStyle = (isDesktopView, displaySignupButtonsOnMobile) => {
    return !isDesktopView && displaySignupButtonsOnMobile ? "button-container" : `button-container${!isDesktopView ? " display-no-buttons" : ""}`;
};

export const TopBarSignUPLeftLoginRight = ({ fields, isDesktopView }) => {
    const { companyLogo, displaySignupButtonsOnMobile = false, localeSwitcher, loginLink, signUpButton } = fields;

    return (<div className={`top-bar-signup-login${!isDesktopView && !displaySignupButtonsOnMobile ? " button-center" : ""}`} >
        <div className="company-logo"><FieldRenderer contents={companyLogo} /> </div>
        <div className={getButtonStyle(isDesktopView, displaySignupButtonsOnMobile)}>
            <FieldRenderer contents={localeSwitcher} />
            <FieldRenderer contents={signUpButton} />
            <FieldRenderer contents={loginLink} />
        </div>
    </div>);
};
TopBarSignUPLeftLoginRight.propTypes = {
    fields: PropTypes.object,
    isDesktopView: PropTypes.bool
};

export const TopBarLoginLeftSignUPRight = ({ fields, isDesktopView }) => {
    const { companyLogo, displaySignupButtonsOnMobile = false, localeSwitcher, loginLink, signUpButton } = fields;

    return (<div className={`top-bar-signup-login${!isDesktopView && !displaySignupButtonsOnMobile ? " button-center" : ""}`} >
        <div className="company-logo"><FieldRenderer contents={companyLogo} /> </div>
        <div className={getButtonStyle(isDesktopView, displaySignupButtonsOnMobile)}>
            <FieldRenderer contents={localeSwitcher} />
            <FieldRenderer contents={loginLink} />
            <FieldRenderer contents={signUpButton} />
        </div>
    </div>);
};
TopBarLoginLeftSignUPRight.propTypes = {
    fields: PropTypes.object,
    isDesktopView: PropTypes.bool
};
export const TopBarSingleCTA = ({ fields, isDesktopView, onButtonClick }) => {
    const { companyLogo, displaySignupButtonsOnMobile = false, localeSwitcher, loginLink, signUpButton } = fields;

    return (<div className="top-bar-single-cta">
        <div className="company-logo"><FieldRenderer contents={companyLogo} /> </div>
        <div className={getButtonStyle(isDesktopView, displaySignupButtonsOnMobile)}>
            {localeSwitcher && <FieldRenderer contents={localeSwitcher} />}
            {loginLink && <FieldRenderer contents={loginLink} />}
            {signUpButton && <FieldRenderer contents={signUpButton} onButtonClick={onButtonClick} />}
        </div>
    </div>);
};
TopBarSingleCTA.propTypes = {
    fields: PropTypes.object,
    isDesktopView: PropTypes.bool,
    onButtonClick: PropTypes.func,
};
