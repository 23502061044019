import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { CTAButton } from "../../../../common";
import { checkoutActions } from "../../../checkout-page/data/checkout-page-action";
import { PopupEmitter, TRIGGER_POPUP } from "../../../../common/popup-emitter";
import { ResetPasswordSuccessPopup } from "./reset-password-success-popup";
import { DEFAULT_PRODUCT_CODE } from "../../../../constants/urls";

export const ForgotPasswordPopup = (props) => {
    const { t, email, cancelResetPassword, closePopup } = props;
    const [disableSendBtn, setDisableSendBtn] = useState(false);
    const [disableCancelBtn, setDisableCancelBtn] = useState(false);
    const dispatch = useDispatch();

    const onForgotPasswordEmailSuccess = () => {
        PopupEmitter.emit(TRIGGER_POPUP, {
            modalClass: "reset-email-conf-modal",
            closeButton: false,
            showPopup: true,
            modalId: "forgot-password:email-sent",
            modalHeader: t("reset-password-email-sent-header"),
            customizedContent: <ResetPasswordSuccessPopup t={t} closePopup={closePopup} />,
        });
    };

    const onForgotPasswordEmailFailure = (errorResponse) => {
        setDisableCancelBtn(false);
        setDisableSendBtn(false);
        console.log("Error forgot password email" + errorResponse);
    };
    const sendForgotPasswordEmail = () => {
        setDisableCancelBtn(true);
        setDisableSendBtn(true);
        const productCode = localStorage.getItem("productCode") || DEFAULT_PRODUCT_CODE;
        dispatch({
            type: checkoutActions.SEND_FORGOT_PASSWORD_EMAIL,
            payload: { email: email.trim(),
                productCode },
            callbacks: { success: onForgotPasswordEmailSuccess, failure: onForgotPasswordEmailFailure },
        });
    };

    return (
        <div className="pb-5">
            <div className="modal-text pb-5">
                <p className="text-justify forgot-paswword-message">{t("forgot-password-confirmation-message")}</p>
            </div>
            <div className="modal-text">
                <div className="d-flex flex-sm-row flex-column-reverse justify-content-between">
                    <div className=" pb-2">
                        <CTAButton
                            buttonName={t("cancel")}
                            onButtonClick={() => cancelResetPassword({ name: "email", value: email })}
                            innerBtnClass="cancel-btn"
                            disableButton={disableCancelBtn ? { disabled: "disabled" } : null}
                        />
                    </div>
                    <div className="pb-2">
                        <CTAButton
                            disableButton={disableSendBtn ? { disabled: "disabled" } : null}
                            icon={disableSendBtn ? "fa fa-spinner fa-spin d-inline-block" : ""}
                            buttonName={t("send")}
                            onButtonClick={() => sendForgotPasswordEmail()} />
                    </div>
                </div>
            </div>
        </div>
    );
};

ForgotPasswordPopup.propTypes = {
    t: PropTypes.func,
    email: PropTypes.string,
    cancelResetPassword: PropTypes.func,
    closePopup: PropTypes.any,
};
