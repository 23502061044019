import React from "react";
import PropTypes from "prop-types";

export function Multipay({ orderTotal, isFreeTrailProduct = false, creditCardLabelDisclaimer, creditCardPriceDisclaimer, freeTrailProductDisclaimer }) {
    return (
        <section id="multipay" className="multipay">
            <p>
                <strong>{creditCardLabelDisclaimer}</strong>
            </p>
            {creditCardPriceDisclaimer && <p dangerouslySetInnerHTML={{
                __html: creditCardPriceDisclaimer.replace("{{orderTotal}}", orderTotal),
            }}>
            </p>}
            {isFreeTrailProduct && <p>{freeTrailProductDisclaimer}</p>}
        </section>
    );
}
Multipay.propTypes = {
    t: PropTypes.func,
    orderTotal: PropTypes.string,
    isFreeTrailProduct: PropTypes.bool,
    creditCardLabelDisclaimer: PropTypes.string,
    creditCardPriceDisclaimer: PropTypes.string,
    freeTrailProductDisclaimer: PropTypes.string
};
