import "isomorphic-fetch";
import { fetchPostWithFormData, fetchPostWithJSONData, fetchGet } from "./service";

import {
    EXPRESS_CHECKOUT_URL,
    VALIDATE_ENROLL_EMAIL,
    GET_ZIPCODE_DETAILS,
    TAX_CALCULATOR,
    COACH_LOOKUP,
    USER_LOGIN,
    PARTIAL_PROFILE,
    SEND_FORGOT_PASSWORD_EMAIL,
    RESET_PASSWORD,
    VALIDATE_TOKEN,
    SESSION_USER_DETAILS,
    USER_LOGIN_SYNCUP_ATG,
    CYBER_SOURCE_KEY,
    CYBERSOURCE_PERMANENT_TOKEN,
    GLOBAL_CONFIGURATIONS,
    CYBERSOURCE_3DS_SETUP_AUTH,
    CYBERSOURCE_3DS_ENROLL_AUTH,
    ATG_PAYPAL_TOKEN_URL,
    ASSIGN_TO_LEADWHEEL_URL,
} from "../api/url";
import { ZIPDetails } from "../model/checkout/ZIP-code";
import { getOffersPageId } from "../helper/util";

export const generatePaypalClientToken = async (dynSessConf, locale, pushSite) => {
    const response = await fetch(`${ATG_PAYPAL_TOKEN_URL}?pushSite=${pushSite}&locale=${locale}&_dynSessConf=${dynSessConf}`);
    return response;
};

export const placeCheckoutOrder = async (expressCheckout) => {
    const bypassCaptchaParam = sessionStorage.getItem("bypassCaptcha") ? `?bypassCaptcha=${sessionStorage.getItem("bypassCaptcha")}` : "";
    const serviceUrl = `${EXPRESS_CHECKOUT_URL}${bypassCaptchaParam}`;
    const response = await fetchPostWithJSONData(serviceUrl, expressCheckout);
    return response;
};
export const fetchUserDetails = async (action) => {
    const { payload } = action;
    const criteria = payload.criteria ? payload.criteria : "email";
    const criteriaValue = payload.criteriaValue ? payload.criteriaValue : payload.email;
    const apiUrl = VALIDATE_ENROLL_EMAIL + `?criteria=${criteria}&criteriaValue=${encodeURIComponent(criteriaValue)}`;
    const result = await fetchGet(apiUrl, true);
    return result;
};
export async function fetchZipocodeDetails(action) {
    const { payload } = action;
    const apiUrl = GET_ZIPCODE_DETAILS + `?zipcode=${payload}`;
    const response = await fetchGet(apiUrl);
    return response;
}
export async function fetchTaxForOrderSummary(data) {
    const payload = new ZIPDetails(data);
    const response = await fetchPostWithJSONData(TAX_CALCULATOR, payload);
    return response;
}
export async function fetchCoachDetails(payload) {
    const criteria = !isNaN(payload) && typeof Number(payload) === "number" ? "gncCoachID" : "email";
    const url = COACH_LOOKUP + `?criteria=${criteria}&criteriaValue=${payload}`;
    const response = await fetchGet(url);
    return response;
}
export async function loginService(payload) {
    const url = USER_LOGIN;
    const response = await fetchPostWithFormData(url, payload);
    return response;
}
export async function partialProfile(payload) {
    const formData = { ...payload, offersPageId: getOffersPageId() };
    const response = await fetchPostWithFormData(PARTIAL_PROFILE, formData);
    return response;
}
export async function sendForgotPasswordEmail(action) {
    const { payload } = action;
    const serviceUrl = SEND_FORGOT_PASSWORD_EMAIL;
    const response = await fetchPostWithFormData(serviceUrl, { ...payload, offersPageId: getOffersPageId() });
    return response;
}
export async function getSessionUserDetails() {
    const serviceUrl = SESSION_USER_DETAILS;
    const response = await fetchGet(serviceUrl);
    return response;
}
export const sendResetPassword = async (action) => {
    const { payload } = action;
    const serviceUrl = RESET_PASSWORD;
    const response = await fetchPostWithFormData(serviceUrl, payload);
    return response;
};
export const validateToken = async (action) => {
    const { payload } = action;
    const serviceUrl = VALIDATE_TOKEN;
    const response = await fetchPostWithFormData(serviceUrl, payload);
    return response;
};
export async function doUserLoginSyncup(action) {
    const { payload } = action;
    const serviceUrl = USER_LOGIN_SYNCUP_ATG;
    const response = await fetchPostWithFormData(serviceUrl, payload);
    return response;
}
export async function fetchCyberSourceKey() {
    const serviceUrl = CYBER_SOURCE_KEY;
    const response = await fetchGet(serviceUrl);
    return response;
}
export async function fetchCyberSourcePermanentKey(payload) {
    const serviceUrl = CYBERSOURCE_PERMANENT_TOKEN + payload;
    const response = await fetchGet(serviceUrl);
    return response;
}
export async function fetchGlobalConfigurations() {
    const serviceUrl = GLOBAL_CONFIGURATIONS;
    const response = await fetchGet(serviceUrl);
    return response;
}
export async function doSetUpAuth(data) {
    const serviceUrl = CYBERSOURCE_3DS_SETUP_AUTH;
    const response = await fetchPostWithFormData(serviceUrl, data, true);
    return response;
}
export async function doEnrollAuth(data) {
    const serviceUrl = CYBERSOURCE_3DS_ENROLL_AUTH;
    const response = await fetchPostWithFormData(serviceUrl, data, true);
    return response;
}
export async function assignToLeadwheel(action) {
    const { payload } = action;
    const serviceUrl = ASSIGN_TO_LEADWHEEL_URL;
    const response = await fetchPostWithFormData(serviceUrl, payload);
    return response;
}
