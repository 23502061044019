/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dompurify from "dompurify";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PlansList } from "./components";
import { offersActions } from "./data/offers-page-action";
import { redirectWithParam } from "../common/util";
import { Loader } from "../../common";
import { getUrlParam, checkIfODPFlow, isAccountCreated } from "../../helper/util";
import { RichText } from "../../field";
import "./offers-page-content.scss";
import { logProductData } from "../../web-analytics";
import { pageRouterActions } from "../../component/page-router/data/page-router-action";
import { CHECKOUT_PATH, CREATE_ACCOUNT_PATH, INTERSTITIAL_PAGE } from "../../constants/urls";
import { addItemToCart } from "../common/util";
import { triggerConflictModal } from "../common/util/conflict-modal";
import { OFFERS_PAGE, ERROR_CODE_PROGRAM_ENTITLEMENT, PROGRAM_ENTITLEMENT } from "../create-account/components/constants";

export const OffersPageContent = ({ fields, interstitialUpsellPages, ...props }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isDesktopView, userDetail, digitalData } = useSelector((state) => state.pageRouter);
    const { isLoading, offersDetails, order, sessionId, productId } = useSelector((state) => state.offers);
    const { backgroundColor, membershipDetails } = fields;
    const [disableSelect, setDisableSelect] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const sanitizer = dompurify.sanitize;
    const config = { ADD_ATTR: ["href", "target"] };
    const containerStyle = { backgroundColor: backgroundColor };
    const [isValidSku, setValidSku] = useState(false);
    const skuId = sessionStorage.getItem("skuId");
    const [autoAddSkuId, setAutoAddSkuId] = useState(skuId);
    const upsellLocation = sessionStorage.getItem("upsell_location");
    const onCartDetailCallback = () => {
        !offersDetails && dispatch({ type: offersActions.GET_OFFERS_DETAILS });
        redirectWithParam(props.history, "offers");
    };
    const offersCount = offersDetails && offersDetails.length > 0 ? offersDetails.length : 0;
    let offersRowHeightCssClass = "";
    const offerId = localStorage.getItem("offerId");
    const isODPFlow = checkIfODPFlow(offerId);
    switch (offersCount) {
        case 1:
        case 2:
        case 3:
            offersRowHeightCssClass = "offers-detail";
            break;
        case 4:
            offersRowHeightCssClass = "offers-detail-4";
            break;
        case 5:
            offersRowHeightCssClass = "offers-detail-5";
            break;
        default:
            offersRowHeightCssClass = "offers-detail";
    }
    const clearInterstitial = () => {
        sessionStorage.removeItem("upsell_page");
        dispatch({ type: pageRouterActions.SET_INTERSTITIAL_PAGE, payload: null });
    };
    const renderinterstitialUpsellPage = (sku) => {
        const id = `${sku}_${offerId}`;
        if (interstitialUpsellPages?.length) {
            const page = interstitialUpsellPages.find((page) => {
                const {
                    fields: { skuId },
                } = page || {};
                if (skuId === id) {
                    dispatch({ type: pageRouterActions.SET_INTERSTITIAL_PAGE, payload: { ...page, basePageCSS: props.basePageCSS } });
                    sessionStorage.setItem("upsell_page", skuId);
                    return true;
                }
            });
            const {
                fields: { skuId, pageUrl },
            } = page || { fields: {} };
            !page && clearInterstitial();
            return page ? { skuId, pageUrl } : null;
        }
        return null;
    };
    const redirectPage = () => {
        const url = isAccountCreated(userDetail) ? CHECKOUT_PATH : CREATE_ACCOUNT_PATH;
        redirectWithParam(props.history, url);
    };
    const onAddItemToCartSuccess = (addedSku) => {
        const page = renderinterstitialUpsellPage(addedSku);
        if (page) {
            redirectWithParam(props.history, `${INTERSTITIAL_PAGE}${page.pageUrl}`);
        } else if (props.history.action !== "POP") {
            redirectPage();
        }
    };
    const onAddItemToCartFailure = (response) => {
        if (response.formExceptions[0].errorCode === "removalCommerceItemDoesNotExist" || response.formExceptions[0].errorCode === "maxQuantityForSkuReached") {
            redirectPage();
        } else if (response?.formExceptions[0]?.errorCode === ERROR_CODE_PROGRAM_ENTITLEMENT) {
            setShowLoader(false);
            triggerConflictModal({
                t,
                programEntitlement: response?.formExceptions[0]?.localizedMessage,
                props,
                page: OFFERS_PAGE,
                modalType: PROGRAM_ENTITLEMENT,
                offersList: false,
            });
        }
    };

    useEffect(() => {
        if (sessionId) {
            !order
                ? dispatch({ type: offersActions.GET_CART_DETAILS, callbacks: { postGetOrder: onCartDetailCallback } })
                : !offersDetails && dispatch({ type: offersActions.GET_OFFERS_DETAILS });
        }
    }, [sessionId]);

    useEffect(() => {
        const offerId = getUrlParam("offerId");
        localStorage.setItem("offerId", offerId);
        !upsellLocation && sessionStorage.removeItem("upsell_page");
    }, []);

    useEffect(() => {
        if (offersDetails && (offersDetails.length === 1 || autoAddSkuId)) {
            const skuFound = autoAddSkuId && offersDetails.find((item) => item?.id === autoAddSkuId);
            !skuFound && setAutoAddSkuId(null);
            if (skuFound || offersDetails.length === 1) {
                autoAddSkuId && skuFound && setValidSku(true);
                const extractOffersDetails = skuFound ? skuFound : offersDetails["0"];
                logProductData(productId, extractOffersDetails?.id, extractOffersDetails, { ...digitalData, orderID: order?.id });
                const dataId = extractOffersDetails && extractOffersDetails?.id ? extractOffersDetails.id : "";
                const catalogRefIds = dataId ? dataId : "";
                const cartDetails = { order, catalogRefIds, onAddItemToCartSuccess: onAddItemToCartSuccess, onAddItemToCartFailure: onAddItemToCartFailure };
                addItemToCart({ dispatch, cartDetails });
            }
        }
    }, [offersDetails]);

    const displayPlans = () => {
        if (!isLoading && offersDetails) {
            return (
                <Fragment>
                    {offersDetails.map((plan, index) => {
                        return (
                            <PlansList
                                key={plan.id}
                                plan={plan}
                                fields={fields}
                                disableSelect={disableSelect}
                                t={t}
                                setDisableSelect={setDisableSelect}
                                dispatch={dispatch}
                                {...props}
                                index={index}
                                renderinterstitialUpsellPage={renderinterstitialUpsellPage}
                                clearInterstitial={clearInterstitial}
                                isAccountCreated={isAccountCreated(userDetail)}
                            />
                        );
                    })}
                </Fragment>
            );
        } else {
            return <Loader className="loader-wrapper" isToShowLoader={true} />;
        }
    };

    return (
        <section className="offers-page-content">
            <div className="m-auto w-100" style={containerStyle}>
                {!isODPFlow && sessionId && !autoAddSkuId && !isValidSku ? (
                    <div className="container offers-page-content-container">
                        {fields.disclaimerText && (
                            <div className="row justify-content-end">
                                <div
                                    className="px-2 disclaimer-text"
                                    style={{
                                        background: isDesktopView ? fields.disclaimerBackgroundColor : fields.disclaimerBackgroundColorMobile,
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizer(fields.disclaimerText ? fields.disclaimerText : "", config),
                                    }}
                                ></div>
                            </div>
                        )}
                        <div className={`${offersRowHeightCssClass} offers-detail-row row`}>
                            <div className={`${isDesktopView ? "col-6" : ""} offers-container px-2`}>{displayPlans()}</div>
                            <div className="member-details-container">
                                {membershipDetails && <RichText className="footer" content={membershipDetails.fields.disclaimer} />}
                            </div>
                        </div>
                    </div>
                ) : (
                    <Loader className="loader-wrapper" isToShowLoader={showLoader} />
                )}
            </div>
        </section>
    );
};

OffersPageContent.propTypes = {
    fields: PropTypes.object,
    history: PropTypes.any,
    basePageCSS: PropTypes.string,
};
