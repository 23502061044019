import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";
import { IShowWithAccessFields } from "../../types/contentful/generated/contentful.d";
import { useSelector } from "react-redux";

export interface ShowWithAccessProps {
    fields: IShowWithAccessFields;
}

export const ShowWithAccess: React.FC<ShowWithAccessProps> = ({ fields, ...data }) => {
    const { digitalData } = useSelector((state: any) => state.pageRouter);
    const {
        page: {
            pageInfo: { queryParams },
        },
    } = digitalData;
    const { contents, fallbackContents, filterByUrlParams } = fields;

    if (!contents) return null;

    if (!Object.keys(queryParams).length || !filterByUrlParams.length) {
        return fallbackContents ? <FieldRenderer contents={fallbackContents} {...data} /> : null;
    }

    // check for a matching key:value between the CMS and the url query params
    const showContents = filterByUrlParams.some(function (param: Record<string, string>) {
        for (const [key, value] of Object.entries(queryParams)) {
            if (param["key"] === key && param["value"] === value) {
                return true;
            } else if (Array.isArray(value)) {
                return value.some(function (val: string) {
                    return param["key"] === key && param["value"].includes(val);
                });
            }
        }
    });

    if (showContents) return <FieldRenderer contents={contents} {...data} />;
    if (fallbackContents) return <FieldRenderer contents={fallbackContents} {...data} />;
    return null;
};

ShowWithAccess.propTypes = {
    fields: PropTypes.exact({
        title: PropTypes.string,
        contents: PropTypes.any,
        fallbackContents: PropTypes.any,
        filterByUrlParams: PropTypes.array,
    }).isRequired,
};
