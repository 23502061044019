import React from "react";
import PropTypes from "prop-types";
import { BaseAccountModal } from "./base-account-modal";

export const CheckoutEmailLookupModal = (props) => {
    const { t, signInUrl, signInLabel } = props;
    return (
        <BaseAccountModal {...props} signInUrl={signInUrl}>
            {() => {
                return <p className="px-2">{t(signInLabel)}</p>;
            }}
        </BaseAccountModal>
    );
};
CheckoutEmailLookupModal.propTypes = {
    t: PropTypes.func,
    signInLabel: PropTypes.string,
    signInUrl: PropTypes.string
};
