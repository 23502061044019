/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import "./create-account-page-content.scss";

import { CreateAccountWithLoginHandler, CreateAccountWithoutLogin } from "./create-account-page";
import { Loader } from "../../common";
import { SurveyIntroOutro } from "../survey/intro-page";
import { decodeURIHelper, getUrlParam } from "../../helper/util";
import { useTranslation } from "react-i18next";

export const CreateAccountPageContent = (props) => {
    const { enforceBBDirectLoginSecurity, merlineConfig } = useSelector(state => state.checkout);
    const { outroPage } = useSelector(state => state.pageRouter);
    const oid = decodeURIHelper(getUrlParam("oid")) || (sessionStorage.getItem("oid") || "");
    const { t } = useTranslation();

    return (
        <Fragment>
            {outroPage && enforceBBDirectLoginSecurity && <SurveyIntroOutro fields={outroPage.fields} className="outro" />}
            {
                merlineConfig
                    ?
                    <Fragment>
                        {
                            enforceBBDirectLoginSecurity
                                ?
                                <CreateAccountWithLoginHandler {...props} />
                                :
                                <CreateAccountWithoutLogin {...props} />
                        }
                    </Fragment>
                    :
                    <div className="merlin-loader flex-column">
                        <Loader isToShowLoader={true} />
                        {oid && <h3>{t("loader-sub-text")}</h3>}
                    </div>
            }
        </Fragment >

    );
};
CreateAccountPageContent.propTypes = {
    props: PropTypes.object,
};
