import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../../../contentful/component-renderer";

export const FlexHeader = ({ fields }) => {
    const {
        brandLogo,
        brandLogoPosition = "right",
        companyLogo,
        companyLogoPosition = "left",
        localeSwitcher,
        localeSwitcherPosition = "right",
        loginLink,
        loginLinkPosition = "right",
        signUpButton,
        signUpButtonPosition = "right"
    } = fields;

    return (
        <div className="bb-fh">
            <div className="bb-fh-left">
                {companyLogo && companyLogoPosition === "left" && <FieldRenderer contents={companyLogo} />}
                {brandLogo && brandLogoPosition === "left" && <FieldRenderer contents={brandLogo} />}
                {localeSwitcher && localeSwitcherPosition === "left" && <FieldRenderer contents={localeSwitcher} />}
                {signUpButton && signUpButtonPosition === "left" && <FieldRenderer contents={signUpButton} />}
                {loginLink && loginLinkPosition === "left" && <FieldRenderer contents={loginLink} />}
            </div>
            <div className="bb-fh-center">
                {companyLogo && companyLogoPosition === "center" && <FieldRenderer contents={companyLogo} />}
                {brandLogo && brandLogoPosition === "center" && <FieldRenderer contents={brandLogo} />}
            </div>
            <div className="bb-fh-right">
                {companyLogo && companyLogoPosition === "right" && <FieldRenderer contents={companyLogo} />}
                {brandLogo && brandLogoPosition === "right" && <FieldRenderer contents={brandLogo} />}
                {localeSwitcher && localeSwitcherPosition === "right" && <FieldRenderer contents={localeSwitcher} />}
                {signUpButton && signUpButtonPosition === "right" && <FieldRenderer contents={signUpButton} />}
                {loginLink && loginLinkPosition === "right" && <FieldRenderer contents={loginLink} />}
            </div>
        </div>
    );
};
FlexHeader.propTypes = {
    fields: PropTypes.object,
};
