import React, { Fragment, createContext } from "react";
import PropTypes from "prop-types";
import Carousels from "./component";
import { RichText } from "../rich-text";
import { FieldRenderer } from "../../contentful/component-renderer";
export const CarouselRefContext = createContext(null);
export const CarouselContainer = ({ fields, ...data }) => {
    const { carouselType, ...props } = fields;
    const CarouselRenderer = Carousels[carouselType];
    return <Fragment>{CarouselRenderer ? <CarouselRenderer fields={props} {...data} RichText={RichText} FieldRenderer={FieldRenderer} /> : null}</Fragment>;
};
CarouselContainer.propTypes = {
    fields: PropTypes.object,
};
