export const defaultLanguages = {
    ca: "en_CA",
    fr: "fr_FR",
    gb: "en_GB",
    mx: "es_MX",
    us: "en_US",
    au: "en_AU",
};

export const getDefaultLanguageForCountry = (country) => {
    const defaultLanguage = defaultLanguages[country] ? defaultLanguages[country] : "en_US";
    return defaultLanguage;
};
