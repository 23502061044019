import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "./carousel-infinite-slider.scss";
import { CarouselRefContext } from "../index";
import { logCarouselEvents, applyCloudinaryTransformations } from "../../../helper/util";
import { logCustomEvents } from "../../../web-analytics";
import { carousel_view_more } from "../../../constants/tealium-tags";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import { AnimateSection } from "../../common/animation";

const DEFAULT_IMAGE_NEXT_ARROW = "//images.ctfassets.net/imp192nihlxo/4a8u0aj1fdM5j2SzNim2GK/112d7d49e8f19b4a9e93313c9216de20/Next.png";
const DEFAULT_IMAGE_PREVIOUS_ARROW = "//images.ctfassets.net/imp192nihlxo/4X5KuvYJDZwQ71fDVTQIRr/57f9e214de719f2163e854faa06418dd/Previous.png";
let instancesCount = 0;

export const CarouselInfiniteSlider = ({ fields, fieldId, FieldRenderer, isDesktopView }) => {
    const {
        carouselContent,
        topContainer,
        title,
        centered = false,
        isArrowsVisible = false,
        invertArrowColor,
        carouselPreviousArrowBackgroundImgUrl,
        carouselNextArrowBackgroundImgUrl,
        arrowWidth = 64,
        isDotsVisible,
        carouselPreviousArrowBackgroundImg,
        carouselNextArrowBackgroundImg,
        enableAnalyticsTracking,
        animationClass,
        setCarouselBGtoSlideBG,
        fallbackCarouselBg,
        enableMagicScroll,
    } = fields;
    const [carouselContainerRef] = useIntersectionObserver(
        {
            root: null,
            rootMargin: "100px",
            threshold: 1,
        },
        () => {
            if (!enableAnalyticsTracking) {
                return;
            }
        },
    );
    const sliderRef = useRef(null);
    const sliderContents = [];
    carouselContent.forEach((item) => {
        const { fields: { slideContent = {} } = {} } = item;
        sliderContents.push(slideContent);
    });
    const [carouselIndex, setCarouselIndex] = useState(0);
    const settings = {
        dots: isDotsVisible,
        arrows: isArrowsVisible,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: centered,
        centerPadding: "60px",
        className: enableMagicScroll ? "magic-scroll center" : "center",
        focusOnSelect: false,
        touchThreshold: 8,
        swipe: true,
        beforeChange: (currentSlide, nextSlide) => {
            if (nextSlide !== currentSlide) {
                pauseVideos(nextSlide);
                setCarouselIndex(nextSlide);
            }
        },
        responsive: [
            {
                breakpoint: 767.98,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false,
                    swipe: true,
                },
            },
        ],
    };
    const handleArrowsClickTracking = (direction) => {
        if (!enableAnalyticsTracking) {
            return;
        }

        const eventId = `${title.replace(/ /g, "")}:${direction}`;
        const eventData = logCarouselEvents(eventId);
        logCustomEvents(carousel_view_more, direction, eventData, true, "link_tracking");
    };
    const onPrevClick = () => {
        sliderRef.current.slickPrev();
        handleArrowsClickTracking("left");
    };
    const onNextClick = () => {
        sliderRef.current.slickNext();
        handleArrowsClickTracking("right");
    };
    function PrevArrow() {
        const backButtonBackgroundImagesProps = {
            ...(!carouselPreviousArrowBackgroundImg && {
                style: {
                    backgroundSize: `${arrowWidth}px`,
                    backgroundImage: `url(${carouselPreviousArrowBackgroundImgUrl || DEFAULT_IMAGE_PREVIOUS_ARROW})`,
                    filter: `invert(${invertArrowColor ? 1 : 0})`,
                },
            }),
        };
        return (
            <div className="carousel-control carousel-control-prev">
                <button onClick={() => onPrevClick()} className="carousel-prev-arrow" {...backButtonBackgroundImagesProps}>
                    {carouselPreviousArrowBackgroundImg && <FieldRenderer contents={carouselPreviousArrowBackgroundImg} />}
                </button>
            </div>
        );
    }
    function NextArrow() {
        const nextButtonBackgroundImagesProps = {
            ...(!carouselNextArrowBackgroundImg && {
                style: {
                    backgroundSize: `${arrowWidth}px`,
                    backgroundImage: `url(${carouselNextArrowBackgroundImgUrl || DEFAULT_IMAGE_NEXT_ARROW})`,
                    filter: `invert(${invertArrowColor ? 1 : 0})`,
                },
            }),
        };
        return (
            <div className="carousel-control carousel-control-next">
                <button onClick={() => onNextClick()} className="carousel-next-arrow" {...nextButtonBackgroundImagesProps}>
                    {carouselNextArrowBackgroundImg && <FieldRenderer contents={carouselNextArrowBackgroundImg} />}
                </button>
            </div>
        );
    }
    const getURL = (ref) => {
        let url;
        const isCloudinary = ref.fields.hasOwnProperty("cloudinaryDesktopImage") || ref.fields.hasOwnProperty("cloudinaryMobileImage") ? true : false;
        if (isDesktopView || ref.fields.hasOwnProperty("cloudinaryMobileImage") === false) {
            url = isCloudinary
                ? applyCloudinaryTransformations(
                      ref.fields.cloudinaryDesktopImage[0].secure_url,
                      ref.fields.cloudinaryOptimizationEnabled,
                      ref.fields.cloudinaryUrlOptimization,
                  )
                : ref.fields.desktopImage.fields.file.url;
        } else {
            url = isCloudinary
                ? applyCloudinaryTransformations(
                      ref.fields.cloudinaryMobileImage[0].secure_url,
                      ref.fields.cloudinaryOptimizationEnabled,
                      ref.fields.cloudinaryUrlOptimization,
                  )
                : ref.fields.mobileImage.fields.file.url;
        }
        return url;
    };
    const currentBGUrl =
        setCarouselBGtoSlideBG && fields.carouselContent[carouselIndex].fields.thumbnailImage
            ? getURL(fields.carouselContent[carouselIndex].fields.thumbnailImage)
            : fallbackCarouselBg
            ? getURL(fallbackCarouselBg)
            : "";
    const pauseVideos = (except) => {
        const videos = document.querySelectorAll(".isPlaying");
        let exception = false;
        if (!isNaN(except)) {
            exception = sliderRef.current.innerSlider.list.querySelectorAll("video")[except];
        }
        for (let index = 0; index < videos.length; index++) {
            const element = videos[index];

            if (!element.paused && !element.muted && !element.loop && !element.autoplay) {
                if (!exception || element !== exception) element.pause();
            }
        }
    };

    const handleMagicScroll = (element, position, direction, sliderWidth, displayRatio) => {
        const translateValue = ((window.pageYOffset - position.top) / (sliderWidth / displayRatio)) * 100;
        element.style.transform = `translate3d(${translateValue * direction * 2.5}%, 0, 0)`;
    };

    useEffect(() => {
        if (enableMagicScroll) {
            const { current: slider } = sliderRef;
            if (slider && slider.props.className.includes("magic-scroll")) {
                const { list, track } = slider.innerSlider;
                const { offsetWidth } = track.node;
                const direction = instancesCount % 2 === 0 ? -1 : 1;
                const elementPosition = list.getBoundingClientRect();
                const displayRatio = window.innerWidth <= 820 ? 1 : 2;
                const listener = () => handleMagicScroll(list, elementPosition, direction, offsetWidth, displayRatio);
                document.addEventListener("scroll", listener);
                instancesCount += 1;
                return () => {
                    document.removeEventListener("scroll", listener);
                    instancesCount = 0;
                };
            }
        }
    }, []);

    return (
        <AnimateSection animationClass={animationClass} refProp={carouselContainerRef}>
            {({ containerRef }) => (
                <div
                    id={fieldId}
                    ref={containerRef}
                    className={`carousel-infinite-slider ${setCarouselBGtoSlideBG ? "withBG" : ""}`}
                    style={{ backgroundImage: currentBGUrl ? "url(" + currentBGUrl + ")" : "" }}
                >
                    <FieldRenderer contents={topContainer} />
                    <CarouselRefContext.Provider value={sliderRef}>
                        <Slider ref={sliderRef} {...settings}>
                            {sliderContents.map((item, index) => (
                                <FieldRenderer key={index} index={index} contents={item} applyLazyLoading={false} />
                            ))}
                        </Slider>
                    </CarouselRefContext.Provider>
                </div>
            )}
        </AnimateSection>
    );
};

CarouselInfiniteSlider.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    FieldRenderer: PropTypes.func,
    isDesktopView: PropTypes.bool,
};
