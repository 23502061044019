import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { CreditCardIconUrl, CreditCardIconLabel } from "../../enums/credit-card-icon";
import { CreditCardDetails } from "./components";
import "./accordion-radio-buttons.scss";
import { PAYPAL_ICON } from "../../constants/urls";
import { logCustomEvents } from "../../web-analytics";
import * as tealiumTags from "../../constants/tealium-tags";
import { validateEligibleCards } from "../../helper/util";

const AccordionRadioButtons = ({ t, formProps, paypalFeatureFlagEnabled, twoColumnsLayoutEnabled }) => {
    const { isPaypal, setIsPaypal } = formProps;
    const { acceptedCards } = useSelector((state) => state.checkout);

    const ToggleIcon = () => (
        <div className="payment-methods__method__toggle__icon">
            <div className="payment-methods__method__toggle__icon__inner-circle"></div>
        </div>
    );

    const handlePaypalClick = () => {
        setIsPaypal(true);
        logCustomEvents(
            tealiumTags.digital_checkout_paypal_selected,
            tealiumTags.digital_checkout_paypal_selected,
            tealiumTags.digital_checkout_paypal_selected,
        );
    };

    const handleCreditCardClick = () => {
        const { digital_checkout_creditcard_selected } = tealiumTags;
        logCustomEvents(digital_checkout_creditcard_selected, digital_checkout_creditcard_selected, digital_checkout_creditcard_selected);
    };

    const acceptedCreditCards = acceptedCards.replace(/[\[\]\s]+/g, "").split(",");
    const validCreditCards = acceptedCreditCards.filter((card) => validateEligibleCards(card));

    return paypalFeatureFlagEnabled ? (
        <div className={`payment-methods ${twoColumnsLayoutEnabled ? "payment-methods--two-columns" : ""}`}>
            <div className="payment-methods__method">
                <button
                    className={`payment-methods__method__toggle payment-methods__method__toggle--first ${
                        !isPaypal ? "payment-methods__method__toggle--active" : ""
                    }`}
                    onClick={() => {
                        setIsPaypal(false);
                        handleCreditCardClick();
                    }}
                >
                    <ToggleIcon />
                    <div className="payment-methods__method__toggle__label">
                        <span>{t("checkout-radio-buttons-cc-title")}</span>
                        <div className="payment-methods__method__toggle__icons">
                            {validCreditCards.map((card) => {
                                return (
                                    <img
                                        className="payment-methods__method__credit-cards__card__icon"
                                        key={card}
                                        src={CreditCardIconUrl[card]}
                                        alt={CreditCardIconLabel[card]}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </button>
                {!isPaypal && (
                    <div className="payment-methods__method__content">
                        <CreditCardDetails {...formProps} alternateStyle={paypalFeatureFlagEnabled} />
                    </div>
                )}
            </div>
            <div className="payment-methods__method">
                <div className="payment-methods__method">
                    <button
                        className={`payment-methods__method__toggle ${isPaypal ? "payment-methods__method__toggle--active" : ""}`}
                        onClick={handlePaypalClick}
                    >
                        <ToggleIcon />
                        <div className="payment-methods__method__toggle__label">
                            <img src={PAYPAL_ICON} alt="PayPal Icon" />
                        </div>
                    </button>
                </div>
            </div>
        </div>
    ) : (
        <CreditCardDetails {...formProps} />
    );
};

AccordionRadioButtons.propTypes = {
    t: PropTypes.func,
    formProps: PropTypes.object,
    paypalFeatureFlagEnabled: PropTypes.bool,
    twoColumnsLayoutEnabled: PropTypes.bool,
};

export default AccordionRadioButtons;
