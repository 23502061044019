import React, { useState, Fragment, useEffect } from "react";
import "./reset-password.scss";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { CTAButton, Loader, TextInput } from "../../common";
import { checkoutActions } from "../checkout-page/data/checkout-page-action";
import { getUrlParam } from "../../helper/util";
import { redirectWithParam } from "../common/util";
import { validateField, validateForm } from "./validator";

import { ResetPwd } from "../../model";
import { CREATE_ACCOUNT_PATH } from "../../constants/urls";
export function ResetPassword(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(new ResetPwd());
    const [showPassword, setShowPassword] = useState(false);
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState();
    const [validToken, setValidToken] = useState(false);
    const sessionId = useSelector((state) => state.offers.sessionId);
    const [formErrors, setResetFormErrors] = useState(new ResetPwd());
    const createAccountUrl = redirectWithParam(props.history, CREATE_ACCOUNT_PATH, true);
    const [disableBtn, setDisableBtn] = useState(false);
    useEffect(() => {
        sessionId &&
            dispatch({
                type: checkoutActions.VALIDATE_TOKEN,
                payload: { token: getUrlParam("token") },
                callbacks: { success: onValidateTokenSuccess, failure: onValidateTokenFailure },
            });
    }, [sessionId]);

    const onValidateTokenSuccess = () => {
        setValidToken(true);
    };

    const onValidateTokenFailure = () => {
        setValidToken(false);
        redirectWithParam(props.history, CREATE_ACCOUNT_PATH);
    };

    const onSubmitRestPassword = () => {
        setDisableBtn(true);
        const errors = validateForm(formData, t);
        if (errors) {
            setResetFormErrors({ ...formErrors, ...errors });
            return true;
        } else {
            setFormErrors(new ResetPwd());
            dispatch({
                type: checkoutActions.RESET_PASSWORD,
                payload: { ...formData, token: getUrlParam("token") },
                callbacks: { success: onSubmitRestPasswordSuccess, failure: onSubmitRestPasswordFailure },
            });
        }
    };

    const onSubmitRestPasswordSuccess = () => {
        setResetPasswordSuccess(true);
    };

    const onSubmitRestPasswordFailure = (errorResponse) => {
        setDisableBtn(true);
        console.error("Error forgot password email" + errorResponse);
    };
    const setFormErrors = (name, value) => {
        setResetFormErrors({ ...formErrors, [name]: value ? t(value) : "" });
    };
    const onChange = (name, value, fieldValues) => {
        if (fieldValues && typeof fieldValues === "object") {
            const resetData = { ...formData, ...fieldValues };
            setFormData(resetData);
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    const preventCopyText = (e) => {
        e.preventDefault();
    };
    const onMaskClick = () => {
        showPassword ? setShowPassword(false) : setShowPassword(true);
    };

    return (
        <Fragment>
            <div className="rest-password-page d-flex flex-column align-items-center">
                {validToken ? (
                    <div className="rest-password-details mb-4">
                        <div className="d-flex justify-content-center">
                            {!resetPasswordSuccess && (
                                <section className="reset-password col-sm-10 offset-sm-1 pb-5 pt-5 my-5">
                                    <h3>Reset Password</h3>
                                    <span className="title">
                                        Your password must be 8-20 characters in length and contain at least 1 uppercase letter, 1 lowercase letter, and 1
                                        number.
                                    </span>
                                    <div className="password-box mt-4 mb-4">
                                        <TextInput
                                            name="password"
                                            id="password"
                                            placeholder="New Password"
                                            type={showPassword ? "text" : "password"}
                                            onChange={onChange}
                                            dValue={formData["password"]}
                                            error={formErrors["password"]}
                                            maxLength={20}
                                            onBlur={validateField}
                                            hybridValidation={false}
                                            setFormErrors={setFormErrors}
                                            onCopy={preventCopyText}
                                            onKeyPress={(e) => {
                                                if (e.charCode === 13 || e.which === 13) {
                                                    const value = e.target.value;
                                                    const name = e.target.name;
                                                    value && validateField({ name, value, callback: setFormErrors });
                                                }
                                            }}
                                        />
                                        {!showPassword && (
                                            <span className="password-mask-link" onClick={onMaskClick}>
                                                SHOW
                                            </span>
                                        )}
                                        {showPassword && (
                                            <span className="password-mask-link" onClick={onMaskClick}>
                                                HIDE
                                            </span>
                                        )}
                                    </div>
                                    <div className="password-box mt-4 mb-5">
                                        <TextInput
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            placeholder="Re-enter Password"
                                            type={showPassword ? "text" : "password"}
                                            maxLength={20}
                                            onChange={onChange}
                                            dValue={formData["confirmPassword"]}
                                            error={formErrors["confirmPassword"]}
                                            onBlur={validateField}
                                            setFormErrors={setFormErrors}
                                            hybridValidation={false}
                                            formData={formData}
                                            onCopy={preventCopyText}
                                        />
                                        {!showPassword && (
                                            <span className="password-mask-link" onClick={onMaskClick}>
                                                SHOW
                                            </span>
                                        )}
                                        {showPassword && (
                                            <span className="password-mask-link" onClick={onMaskClick}>
                                                HIDE
                                            </span>
                                        )}
                                    </div>
                                    <CTAButton
                                        outerBtnClass="text-center mt-4"
                                        innerBtnClass="submit-btn text-center"
                                        buttonName={t("SUBMIT")}
                                        disableButton={disableBtn ? { disabled: "disabled" } : null}
                                        icon={disableBtn ? "fa fa-spinner fa-spin d-inline-block" : ""}
                                        onButtonClick={() => onSubmitRestPassword()}
                                    />
                                </section>
                            )}
                            {resetPasswordSuccess && (
                                <section className="reset-password col-sm-10 offset-sm-1 pb-5 pt-5 my-5">
                                    <h3 className="mb-3 text-center">Your password has been reset.</h3>
                                    <span className="title">
                                        You can now finish your checkout by returning to your previous window, or click <a href={createAccountUrl}>here</a> to
                                        see our Beachbody On Demand membership options again.
                                    </span>
                                </section>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="reset-password-loader d-flex align-items-center">
                        <Loader isToShowLoader={true} />
                    </div>
                )}
            </div>
        </Fragment>
    );
}

ResetPassword.propTypes = {
    history: PropTypes.object,
    props: PropTypes.object,
};
