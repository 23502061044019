require("dotenv-defaults").config();

export const Configuration = () => {
    return {
        AKAMAI: {
            clientId: "skjqjyeub6xx7q58gc9szfbbzzndkj2u",
            host: "https://beachbody-qa.us-dev.janraincapture.com",
            maxAge: "6000",
            redirectHost: process.env.BROWSER_PROXY,
        },
        ATG_PROXY_URL: "https://www-tbbqa2.beachbody.com",
        BEACHBODY_ON_DEMAND: "https://stage.beachbodyondemand.com",
        BEACHBODY_ON_DEMAND_API_URL: "https://api.qa.cd.beachbodyondemand.com",
        BEACHBODY_ON_DEMAND_API_URL_API_KEY: "RVDpxtITDvaljcNbsv01e16X6Liv2W3T9KSkwjcf",
        BEACHBODY_ON_DEMAND_MESSAGE_API_URL: "https://message-api.qa.cd.beachbodyondemand.com",
        BEACHBODY_ON_DEMAND_MESSAGE_API_KEY: "Frene7gx11EOBdKpSym2ji20xZtLa6an",
        CAPTCHA: {
            siteKey: "6LdWSu8UAAAAACyqUXVvsU4fnNHU7CrqdPWSMFUK",
        },
        CC_IFRAME_URL: "https://beachbody-uat.cardconnect.com/itoke/ajax-tokenizer.html",
        CONTENTFULCMS: {
            environment: "qa",
            host: {
                master: "https://cdn.contentful.com",
                preview: "preview.contentful.com",
            },
            spaceId: "imp192nihlxo",
            useKey: "master",
        },
        CYBERSOURCE: {
            host: "https://qa2.harmony.beachbody.com",
            authUrl: "https://creditcard-auth.qa2.gateway.beachbody.com/cc/flexKey",
        },
        TEALIUM: {
            environment: "qa",
            profile: "main",
        },
        WCS: {
            host: "http://wcsauth01.qa3.dc2.beachbody.com:13300",
            page: {
                privacyPolicy: "/privacy",
                termsofuse: "/terms-of-use",
            },
        },
        GTAG: {
            key: "UA-336852-1",
            ukKey: "UA-336852-17",
            INCLUDE_GOOGLE_ANALYTICS_TAG_IN_HOME_PAGE: false,
        },
        APPLE_APP_SITE_ASSOCIATION_CONFIG: {
            webcredentials: {
                apps: ["MHDLZCPG9V.com.beachbody.bod"],
            },
        },
        OPTIMIZELY: {
            sdkKey: "JYyq3oM4iJ7htnTdDkpsN",
        },
        DATADOG: {
            applicationId: "95508111-5b76-4f36-b130-8b335aca9139",
            clientToken: "pub5bf2cdfbb0b8c0d0b64ea9c5f138cb20",
            env: "qa2",
            enableRUM: false,
        },
        DIGIOH: {
            enableDigioh: true,
            url: "https://www.lightboxcdn.com/vendor/a8311464-70d5-4858-8075-c78362632740/lightbox_inline_qa.js",
        },
        PAYPAL: {
            clientId: {
                GB: "ATQ2GxjOOBDk6WOHWiSwAqRw-bBBYoEa5BkWJ-xxo6Y7C_auQWUz65RnlbSa8r-1cVdcb5bU-KXpDboI",
                CA: "AUYhpRvR55F6Uss0h79csFNX94Hjl8WyiMifRDVFfOVyjSAo2F9LUGhx9kpljCl9fHeNyefmD_5ySnKQ",
                US: "AeW7GNqfxWHDzOWh0XWV6e1I7tKyaXhxO-Ry7OhgYXCfkwV_YV3F-ZtbieNpDltuLFExdy3hxJ-l1O-C",
            },
        },
    };
};
