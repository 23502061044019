import React, { useState, Fragment } from "react";
import { TextInput } from "../../../common";
import PropTypes, { bool } from "prop-types";
import { PWD_EYE_INVISIBLE, PWD_EYE_VISIBLE } from "../../../constants/urls";
export function PasswordPanel({
    t,
    onChange,
    setFormErrors,
    formErrors,
    formData,
    validateField,
    isRegisteredUser,
    onKeyPress,
    floatLabel,
    onBlur,
    showError,
}) {
    const [showPassword, setShowPassword] = useState(false);
    const checkPageError = typeof showError === "boolean" ? showError : formErrors["password"];

    return !isRegisteredUser ? (
        <Fragment>
            <TextInput
                name="password"
                id="password"
                floatLabel={floatLabel || t("password")}
                type={showPassword ? "text" : "password"}
                onBlur={onBlur || validateField}
                maxLength={20}
                onChange={onChange}
                error={checkPageError}
                dValue={formData["password"]}
                setFormErrors={setFormErrors}
                disableElement={isRegisteredUser}
                autoComplete="off"
                hintElement={
                    <img
                        name="show-password"
                        id="show-password"
                        className="pwd-icon"
                        src={showPassword ? PWD_EYE_VISIBLE : PWD_EYE_INVISIBLE}
                        onClick={() => setShowPassword(!showPassword)}
                    />
                }
                onKeyPress={onKeyPress}
            />
        </Fragment>
    ) : null;
}
PasswordPanel.propTypes = {
    t: PropTypes.func,
    onChange: PropTypes.func,
    setFormErrors: PropTypes.func,
    formErrors: PropTypes.object,
    formData: PropTypes.object,
    validateField: PropTypes.func,
    doFieldValidation: PropTypes.bool,
    hidePassword: PropTypes.bool,
    isRegisteredUser: PropTypes.bool,
    onKeyPress: PropTypes.func,
    floatLabel: PropTypes.string,
    onBlur: PropTypes.func,
    showError: bool,
    page: PropTypes.string,
};
