import { CHECKOUT_PATH, CONFIRMATION_PASSWORD_PATH, CREATE_ACCOUNT_PATH, CREATE_PASSWORD_PATH, INTERSTITIAL_PAGE, OFFERS_PATH } from "./urls";

export const DYNAMIC_FUNNEL_PAGE_CONFIG = Object.freeze([
    { path: "survey", canReVisit: false, requiredInfo: () => (sessionStorage.getItem("survey_id") ? true : false) },
    { path: INTERSTITIAL_PAGE, canReVisit: true, requiredInfo: () => (sessionStorage.getItem("upsell_page") ? true : false) },
    { path: CREATE_ACCOUNT_PATH, canReVisit: false, requiredInfo: () => true },
    { path: CREATE_PASSWORD_PATH, canReVisit: false, requiredInfo: () => true },
    { path: OFFERS_PATH, canReVisit: true, requiredInfo: () => true },
    { path: CHECKOUT_PATH, canReVisit: true, requiredInfo: () => true },
    { path: "confirmation", canReVisit: false, requiredInfo: () => true },
    { path: CONFIRMATION_PASSWORD_PATH, canReVisit: false, requiredInfo: () => true },
]);
