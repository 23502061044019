import { DEFAULT_PRODUCT_CODE } from "../../constants/urls";

export class PartialProfile {
    constructor(data = {}) {
        this.firstName = data.firstName ? data.firstName.trim() : "";
        this.lastName = data.lastName ? data.lastName.trim() : "";
        this.email = data.email ? data.email.trim() : "";
        this.primaryProductCode = data.primaryProductCode || DEFAULT_PRODUCT_CODE;
        this.referringCoachId = data.coachId || data.refRepId || "";
    }
}
