import validator from "validator";
import * as regex from "../../constants/regex";
export const validateForm = (formData, t) => {
    let hasError = false;
    let formErrors = {};
    for (let property in formData) {
        const error = validateField({ name: property, value: formData[property], formData });
        error && (hasError = true);
        formErrors = { ...formErrors, [property]: t(error) };
    }
    return hasError ? formErrors : null;
};

export const validateField = ({ name, value, callback = null, formData = null }) => {
    let validators = getValidations()[name];
    //Spaces are not allowed in password field, so skipping trim for passward value
    const error =
        validators.length &&
        validators.find((validate) => {
            if (validate.method(value, formData)) {
                return validate;
            }
        });
    if (callback && callback instanceof Function) {
        callback(name, error ? error.message : "");
    } else {
        return error ? error.message : "";
    }
};

const getValidations = () => {
    return {
        firstName: [
            {
                method: (value) => !validator.matches(value, regex.NAME_VALIDATION),
                message: "only-allowed-char-msg",
            },
        ],
        lastName: [
            {
                method: (value) => !validator.matches(value, regex.NAME_VALIDATION),
                message: "only-allowed-char-msg",
            },
        ],
        password: [
            {
                method: (value) => validator.isEmpty(value),
                message: "field-required",
            },
            {
                method: (value) => validator.matches(value, /\s/u),
                message: "pwd-space-err-msg",
            },
            {
                method: (value) => !validator.matches(value, /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?!.*[\s])[A-Za-z0-9~@#$%^&*+=`|{}:;!.?"()\[\]-]{8,20}$/u),
                message: "pwd-err-msg",
            },
        ],
    };
};
