import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from ".";
import { replaceAlternateLandingPageNavigation } from "../../module/common/util";
export const FooterRenderer = ({ contents, hideFieldCallback = null, ...props }) => {
    return <FieldRenderer contents={replaceAlternateLandingPageNavigation(contents)} hideFieldCallback={hideFieldCallback} {...props} />;
};

FooterRenderer.propTypes = {
    contents: PropTypes.any,
    hideFieldCallback: PropTypes.func,
};
