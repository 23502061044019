import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CTAButton, Loader } from "../../common";
import { ResetPwd } from "../../model";
import "./confirmation-password.scss";
import { checkoutActions } from "../checkout-page/data/checkout-page-action";
import { clearCookie, getSiteLocale, getUrlParam } from "../../helper/util";
import usePasswordValidation from "../../hooks/usePasswordValidation";
import { validateField } from "../common/validator";
import { FieldRenderer } from "../../contentful/component-renderer";
import { PasswordPanel } from "../create-account/components";
import { OrderConfirmation } from "./confirmation-block";
import { EventTags, UserAttributes } from "@optimizely/optimizely-sdk";
import { getCookie, convertParamsToObject } from "../../helper/util";
import { COACH_NO_UPSELL_CONFIRMATION, COACH_UPSELL_CONFIRMATION } from "../../constants/urls";
import { redirectWithParam } from "../common/util";
import { offersActions } from "../offers-page/data/offers-page-action";
import { PasswordValidation } from "../create-account/components/password-validation";
import { pageRouterActions } from "../../component/page-router/data/page-router-action";

export function ConfirmationPasswordContent(props) {
    const {
        contents: {
            fields: { content: confirmationPasswordContent },
        },
        optimizely,
        history,
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { sessionId } = useSelector((state) => state.offers);
    const { orderConfirmationDetails } = useSelector((state) => state.checkout);
    const { isDesktopView, featureFlagContent } = useSelector((state) => state.pageRouter);
    const [formData, setFormData] = useState(new ResetPwd());
    const [validToken, setValidToken] = useState(false);
    const [showError, setShowError] = useState(false);
    const [formErrors, setResetFormErrors] = useState(new ResetPwd());
    const [disableBtn, setDisableBtn] = useState(false);
    const { passwordValidations, validatePassword, displayPasswordError } = usePasswordValidation();
    const { hasCharCount, hasLowerCaseChar, hasNoSpaces, hasNoSpecialChars, hasNumber, hasUpperCaseChar } = passwordValidations;
    const { checkoutTwoColumnsLayout: checkoutTwoColumnsLayoutFeatureFlag }: { checkoutTwoColumnsLayout: boolean } = featureFlagContent && featureFlagContent;
    const twoColumnsLayoutFeatureEnabled = checkoutTwoColumnsLayoutFeatureFlag && optimizely.isFeatureEnabled("bb_com__checkout_two_column_layout");
    const siteLocale = getSiteLocale();
    const envName = window.harmonyEnvironment;

    const onValidateTokenSuccess = () => {
        setValidToken(true);
    };
    const onValidateTokenFailure = () => {
        setValidToken(false);
    };
    const onEnter = (event) => {
        const { keyCode, charCode } = event || {};
        (keyCode || charCode) === 13 ? onContinueBtn() : null;
    };

    const onSubmitResetPasswordSuccess = () => {
        setDisableBtn(false);
        const confirmationPageId = sessionStorage.getItem("confirmationPageId");
        const url = confirmationPageId === "COACH_UPSELL_CONFIRMATION" ? COACH_UPSELL_CONFIRMATION : COACH_NO_UPSELL_CONFIRMATION;

        dispatch({ type: pageRouterActions.SESSION_LOGOUT, payload: {} });
        sessionStorage.removeItem("sessionId");
        localStorage.removeItem("offerId");
        localStorage.removeItem("productCode");
        localStorage.removeItem("landingPage");
        sessionStorage.removeItem("oid");
        sessionStorage.removeItem("refRepId");
        sessionStorage.removeItem("upsell_page");
        sessionStorage.removeItem("upsell_location");
        clearCookie(["webSourceCode", "userData"]);
        document.cookie = "webSourceCode=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        redirectWithParam(history, url, true, false, true);
    };
    const onSubmitRestPasswordFailure = (errorResponse) => {
        setDisableBtn(true);
        console.error("Error forgot password email" + errorResponse);
    };
    const setFormErrors = (name, value) => {
        setResetFormErrors({ ...formErrors, [name]: value ? t(value) : "" });
    };
    const validateFormErrors = (name, value) => {
        const error = validateField({ name, value, hybridValidator: true });
        if (error) {
            setFormErrors(name, error);
            return true;
        } else {
            setFormErrors(name, "");
            return false;
        }
    };
    const onChange = (name, value, fieldValues) => {
        const resetData = { ...formData, ...fieldValues };
        if (fieldValues && typeof fieldValues === "object") {
            setFormData(resetData);
        } else {
            validatePassword(value);
            setFormData({ ...resetData, [name]: value });
            validateFormErrors(name, value);
            if (value) {
                const hasError = validateFormErrors("password", value);
                setShowError(hasError);
            }
            setFormData({ ...formData, [name]: value });
        }
    };

    const onContinueBtn = () => {
        if (!validateFormErrors("password", formData["password"])) {
            setDisableBtn(true);
            setShowError(false);
            dispatch({
                type: checkoutActions.RESET_PASSWORD,
                payload: { ...formData, confirmPassword: formData["password"], token: getUrlParam("token") },
                callbacks: { success: onSubmitResetPasswordSuccess, failure: onSubmitRestPasswordFailure },
            });
        } else {
            setShowError(true);
        }
    };

    const onBlur = (password) => {
        if (password.value && displayPasswordError) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    };

    const formProps = {
        t,
        onChange,
        formErrors,
        setFormErrors,
        showError,
        formData,
        validateField,
        hasLowerCaseChar,
        hasCharCount,
        hasUpperCaseChar,
        hasNumber,
        hasNoSpaces,
        hasNoSpecialChars,
        onBlur,
    };

    const trackOrderDetails = (orderConfirmationDetails) => {
        if (orderConfirmationDetails) {
            const rawPrice = orderConfirmationDetails?.order?.commerceItems[0].priceInfo?.rawTotalPrice;
            const offersPageID = orderConfirmationDetails?.order?.offersPageId;
            const priceInCents = rawPrice * 100;
            const productID = orderConfirmationDetails?.order?.commerceItems[0].productId;
            const productSKU = orderConfirmationDetails?.order?.commerceItems[0].catalogRefId;

            // Optimizely - Track Event - Purchase Conversion
            if (twoColumnsLayoutFeatureEnabled) {
                const currentEventTags: EventTags = { offersPageID, productID, productSKU, revenue: priceInCents };
                const queryParams = getCookie("query");
                const utmObject = convertParamsToObject(queryParams, "utm_");
                let utmParams = "";
                const utmObjectArray = Object.keys(utmObject);
                utmObjectArray.map((obj, index) => {
                    const lastIndex = index + 1 === utmObjectArray.length;
                    utmParams += `${obj}=${utmObject[obj]}${lastIndex ? "" : "&"}`;
                });
                const currentUserAttributes: UserAttributes = {
                    mobile: !isDesktopView,
                    locale: siteLocale,
                    environment: envName,
                    offersPageID,
                    productID,
                    productSKU,
                    utmParams,
                    ...utmObject,
                };
                optimizely.track("bb_com_two_columns_layout_purchase_conversion", sessionId, currentUserAttributes, currentEventTags);
            }
        }
        // Optimizely - Track Event - Page View: Order Confirmation Page
        if (twoColumnsLayoutFeatureEnabled) {
            optimizely.track("bb_com_page_view_order_confirmation_page");
        }
    };

    useEffect(() => {
        sessionStorage.removeItem("sessionId");
        localStorage.removeItem("offerId");
        localStorage.removeItem("productCode");
        localStorage.removeItem("landingPage");
        sessionStorage.removeItem("oid");
        sessionStorage.removeItem("refRepId");
        sessionStorage.removeItem("upsell_page");
        sessionStorage.removeItem("upsell_location");
        clearCookie(["webSourceCode", "userData"]);
        document.cookie = "webSourceCode=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        !sessionId && dispatch({ type: offersActions.GET_SESSION_ID });
        dispatch({ type: offersActions.SET_OFFERS_DETAILS, payload: { order: null } });

        window.scroll(0, 0);
        trackOrderDetails(orderConfirmationDetails);
    }, []);

    useEffect(() => {
        function handleBackButton(event) {
            event.preventDefault();
            dispatch({ type: pageRouterActions.SESSION_LOGOUT });
            sessionStorage.removeItem("sessionId");
        }

        window.addEventListener("popstate", handleBackButton);

        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, []);

    useEffect(() => {
        sessionId &&
            dispatch({
                type: checkoutActions.VALIDATE_TOKEN,
                payload: { token: getUrlParam("token") },
                callbacks: { success: onValidateTokenSuccess, failure: onValidateTokenFailure },
            });
    }, [dispatch, sessionId]);

    return (
        <Fragment>
            <div className="d-flex flex-column align-items-center">
                {validToken ? (
                    <section className="confirmation-password-content">
                        <FieldRenderer contents={confirmationPasswordContent}></FieldRenderer>
                        <div className="password-container pb-4">
                            <h5 className="pb-2">{t("create-password-get-started")}</h5>
                            <PasswordPanel {...formProps} onKeyPress={onEnter} />
                            <PasswordValidation {...formProps} />
                            <CTAButton
                                outerBtnClass="continue-btn pt-2"
                                innerBtnClass="btn edit-btn"
                                buttonName={t("CONTINUE")}
                                disableButton={disableBtn ? { disabled: "disabled" } : null}
                                icon={disableBtn ? "fa fa-spinner fa-spin d-inline-block" : ""}
                                onButtonClick={() => onContinueBtn()}
                            />
                        </div>
                        {orderConfirmationDetails && <OrderConfirmation orderConfirmationDetails={orderConfirmationDetails} />}
                    </section>
                ) : (
                    <div className="reset-password-loader d-flex align-items-center">
                        <Loader isToShowLoader={true} />
                    </div>
                )}
            </div>
        </Fragment>
    );
}

ConfirmationPasswordContent.propTypes = {
    optimizely: PropTypes.object,
    contents: PropTypes.object,
    history: PropTypes.object,
};
