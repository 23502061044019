import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { CTAButton, Loader, TextInput } from "../../common";
import { pageRouterActions } from "../../component/page-router/data/page-router-action";
import { MYX_ACTIVATE_BIKE_PATH, MYX_CHECKOUT_PATH, MYX_LOGIN_PATH } from "../../constants/urls";
import { decodeURIHelper, getProductDetails, getUrlParam, setCookie } from "../../helper/util";
import { MyxCreateAccount } from "../../model";
import { createPartialProfile, encryptData, redirectWithParam } from "../common/util";
import { validateField, validateForm } from "../common/validator";
import { EmailLookupStatus, EmailPanel, PasswordPanel } from "../create-account/components";
import { offersActions } from "../offers-page/data/offers-page-action";
import { logProductData, logLinkTracking } from "../../web-analytics";
import { myxTranslations } from "../../constants/translations";
import * as tealiumTags from "../../constants/tealium-tags";
import usePasswordValidation from "../../hooks/usePasswordValidation";

export const MyxCreateAccountPage = (props) => {
    const { content, contents, t } = props;
    const { pageUrl, primaryProductCode } = content.fields;
    const productId = getUrlParam("offerId");
    const emailRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isRegisteredUser, setRegisteredUser] = useState(false);
    const { offersDetails, order } = useSelector((state) => state.offers);
    const sessionId = sessionStorage.getItem("sessionId");
    const { userDetail, editEmail, digitalData } = useSelector((state) => state.pageRouter);
    const { enableOneBodi } = useSelector((state) => state.checkout);
    const [emailLookupStatus, setEmailLookupStatus] = useState(EmailLookupStatus.NOT_TRIGGED);
    const [doEmailLookup, setEmailLookup] = useState(false);
    const [formData, setFormData] = useState(new MyxCreateAccount());
    const [formErrors, setAccountFormErrors] = useState(new MyxCreateAccount());
    const [showPageLoader] = useState(false);
    const [isCartAbandonmentFlow, setCartAbandonmentFlow] = useState(false);
    const [goToNextPage, redirectToNextPage] = useState(false);
    const oid = decodeURIHelper(getUrlParam("oid"));
    const [disableBtn, setBtnDisable] = useState(false);
    const { passwordValidations, validatePassword, clearPasswordErrors } = usePasswordValidation();
    const { hasCharCount, hasLowerCaseChar, hasNoSpaces, hasNoSpecialChars, hasNumber, hasUpperCaseChar } = passwordValidations;

    const onChange = (name, value) => {
        if (name === "password") {
            validatePassword(value);
        }
        setFormData({ ...formData, [name]: value });
    };
    const onEnter = (event) => {
        const isOnlyEmail = event && event.target && event.target.name === "email" ? true : false;
        const { keyCode, charCode } = event || {};
        ((keyCode || charCode) === 13 && isValidEntries) || ((keyCode || charCode) === 13 && isOnlyEmail) ? onContinueBtn(isOnlyEmail) : null;
    };
    const setFormErrors = (name, value) => {
        setAccountFormErrors({ ...formErrors, [name]: value ? t(value) : "" });
    };
    const removeCartAbondonementId = () => {
        sessionStorage.removeItem("oid");
        setCartAbandonmentFlow(false);
    };
    const validateFormErrors = (isOnlyEmail) => {
        const { email, password } = formData;
        const errors = validateForm({ email, password }, t, isOnlyEmail);
        if (errors) {
            setAccountFormErrors({ ...formErrors, ...errors });
            return true;
        } else {
            setAccountFormErrors(new MyxCreateAccount());
            return false;
        }
    };
    const validateNameErrors = (fieldName) => {
        const fieldValue = formData[fieldName];
        const errors = validateForm({ [fieldName]: fieldValue }, t);
        if (errors) {
            setAccountFormErrors({ ...formErrors, ...errors });
            return true;
        } else {
            setAccountFormErrors({ ...formErrors, [fieldName]: "" });
            return false;
        }
    };
    const encryptUserData = () => {
        sessionStorage.removeItem("email");
        const { email, password, firstName, lastName } = formData;
        const userDetails = { ...userDetail, email, password, firstName, lastName };
        dispatch({ type: pageRouterActions.SET_USER_DETAILS, payload: userDetails });
        const userData = JSON.stringify(userDetails);
        const encipheredData = encryptData(userData);
        const date = new Date();
        date.setTime(date.getTime() + 60 * 60 * 1000);
        setCookie("userData", encipheredData, date);
    };
    const goEditEmail = () => {
        setEmailLookupStatus(EmailLookupStatus.NOT_TRIGGED);
        clearPasswordErrors();
        dispatch({ type: pageRouterActions.EDIT_EMAIL, payload: true });
    };
    const goToActivateBike = () => {
        props.history.push(MYX_ACTIVATE_BIKE_PATH);
    };
    const goToMyxCheckout = () => {
        encryptUserData();
        redirectWithParam(history, MYX_CHECKOUT_PATH.substring(1));
    };
    const goToMyxLogin = () => {
        encryptUserData();
        redirectWithParam(history, MYX_LOGIN_PATH.substring(1));
    };
    const redirectPage = () => {
        if (emailLookupStatus === EmailLookupStatus.COMPLETED && userDetail && !userDetail.isNewUser) {
            goToMyxLogin();
        } else {
            dispatch({ type: pageRouterActions.EDIT_EMAIL, payload: false });
            createPartialProfile({ dispatch, payload: { ...formData, primaryProductCode: primaryProductCode } });
            getProductDetails(userDetail, dispatch);
            setBtnDisable(false);
            setFormData({ ...formData, password: "" });
            redirectWithParam(history, pageUrl);
        }
    };
    const onCartDetailsFetchSuccess = (profile = {}) => {
        if (profile) {
            const { email } = profile || {};
            if (email) {
                onChange("email", email);
                emailRef.current.triggerEmailLookup(email);
            }
        }
    };
    const onCartDetailFetchFailure = () => {
        console.log("Failure to fetch cart contents.");
    };
    const onAddItemToCartSuccess = () => {
        goToMyxCheckout();
    };
    const onAddItemToCartFailure = () => {
        console.log("Failed to add item to cart.");
        goToMyxCheckout();
    };

    const addMyxProductToCart = () => {
        const dataId = offersDetails && offersDetails[0].id ? offersDetails[0].id : "";
        const catalogRefIds = dataId ? dataId : "";
        dataId && logProductData(productId, catalogRefIds, offersDetails["0"], digitalData);
        const removalCommerceIds =
            order &&
            order.commerceItems
                .map((item) => {
                    return item.id;
                })
                .join();
        const payload = {
            productId,
            catalogRefIds,
            removalCommerceIds,
            isToUpdateCart: removalCommerceIds ? true : false,
            primaryProductCode: primaryProductCode,
        };
        productId &&
            catalogRefIds &&
            dispatch({
                type: offersActions.ADD_ITEM_TO_CART,
                payload,
                callbacks: { success: onAddItemToCartSuccess, failure: onAddItemToCartFailure },
            });
    };
    const onContinueBtn = (isOnlyEmail) => {
        const hasError = validateFormErrors(isOnlyEmail);
        if (!hasError && isOnlyEmail && emailLookupStatus !== EmailLookupStatus.COMPLETED) {
            setFormData(new MyxCreateAccount({ email: formData["email"] }));
            setAccountFormErrors(new MyxCreateAccount());
            setBtnDisable(true);
            !doEmailLookup && setEmailLookup(true);
            emailRef && emailRef.current && emailRef.current.triggerEmailLookup(formData["email"]);
        }
        if (!isOnlyEmail && userDetail && userDetail.isNewUser) {
            enableOneBodi ? goToMyxCheckout() : addMyxProductToCart();
            setBtnDisable(true);
        }
    };
    const getPageTitle = () => {
        if (editEmail) {
            return t(myxTranslations.editEmail);
        } else {
            return t(myxTranslations.accountInformation);
        }
    };
    useEffect(() => {
        if (sessionId) {
            const activationCodeParam = getUrlParam("activationCode") || localStorage.getItem("activationCode");
            const email = getUrlParam("email") || sessionStorage.getItem("email") || (userDetail && userDetail.email);
            const productCodeParam = getUrlParam("productCode") || localStorage.getItem("productCode") || primaryProductCode;
            !activationCodeParam ? goToActivateBike() : localStorage.setItem("activationCode", activationCodeParam);
            email && setFormData({ ...formData, email });
            productId && localStorage.setItem("offerId", productId);
            productCodeParam && localStorage.setItem("productCode", productCodeParam);
            !order &&
                dispatch({
                    type: offersActions.GET_CART_DETAILS,
                    callbacks: { success: onCartDetailsFetchSuccess, failure: onCartDetailFetchFailure },
                });
        }
    }, [sessionId]);

    useEffect(() => {
        redirectWithParam(history, pageUrl);
    }, [pageUrl]);

    useEffect(() => {
        if (goToNextPage) {
            redirectPage();
            redirectToNextPage(false);
        }
    }, [goToNextPage]);

    const formProps = {
        emailLookupStatus,
        formData: formData,
        formErrors: formErrors,
        isCartAbandonmentFlow,
        isRegisteredUser,
        oid,
        onCartDetailFetchFailure,
        onCartDetailsFetchSuccess,
        onChange,
        redirectToNextPage,
        removeCartAbondonementId,
        setCartAbandonmentFlow,
        setEmailLookupStatus,
        setFormErrors,
        setRegisteredUser,
        t,
        validateField,
    };

    const emailFieldHasValue = validateField({
        name: "email",
        value: formData.email,
    })
        ? null
        : true;
    const isValidFirstName =
        validateField({
            name: "firstName",
            value: formData.firstName,
            formData: formData.firstName,
            hybridValidator: true,
        }) || formData.firstName === ""
            ? false
            : true;
    const isValidLastName =
        validateField({
            name: "lastName",
            value: formData.lastName,
            formData: formData.lastName,
            hybridValidator: true,
        }) || formData.lastName === ""
            ? false
            : true;
    const onEditEmail = () => {
        logLinkTracking(tealiumTags.myx_account_email_edit);
        goEditEmail();
    };
    const isValidPassword = validateField({ name: "password", value: formData.password }) ? false : true;
    const isValidEntries = isValidPassword && isValidFirstName && isValidLastName ? true : false;
    const accountFieldsHaveValue = isValidPassword && isValidFirstName && isValidLastName ? true : null;

    useEffect(() => {
        dispatch({ type: pageRouterActions.SET_USER_DETAILS, payload: null });
    }, []);
    return (
        <section className="myx-create-account container">
            <div className="myx-container w-100">
                <div className="myx-panel">
                    {!showPageLoader ? (
                        <Fragment>
                            <h1>{getPageTitle()}</h1>

                            {!userDetail ||
                            (userDetail && !userDetail.email && emailLookupStatus !== EmailLookupStatus.COMPLETED) ||
                            emailLookupStatus !== EmailLookupStatus.COMPLETED ? (
                                <div>
                                    <EmailPanel
                                        ref={emailRef}
                                        {...formProps}
                                        loginModalContent={contents}
                                        onKeyPress={onEnter}
                                        className={emailFieldHasValue ? "has-value" : ""}
                                    />
                                    {emailLookupStatus === EmailLookupStatus.FAILED && doEmailLookup && (
                                        <p
                                            className="error-msg"
                                            dangerouslySetInnerHTML={{
                                                __html: t("email-lookup-fail"),
                                            }}
                                        ></p>
                                    )}
                                    <CTAButton
                                        outerBtnClass={"continue-btn " + accountFieldsHaveValue}
                                        innerBtnClass={emailFieldHasValue ? "has-value" : ""}
                                        buttonName={t(myxTranslations.saveAndContinue)}
                                        onButtonClick={() => {
                                            onContinueBtn(true);
                                        }}
                                        disableButton={
                                            !emailFieldHasValue || (emailLookupStatus === EmailLookupStatus.COMPLETED && !emailFieldHasValue)
                                                ? { disabled: "disabled" }
                                                : null
                                        }
                                        icon={disableBtn ? "fa fa-spinner fa-spin" : ""}
                                    />
                                </div>
                            ) : (
                                disableBtn && !userDetail.isNewUser && <Loader className="loader-wrapper" isToShowLoader={true} />
                            )}
                            {userDetail && userDetail.email && userDetail.isNewUser && emailLookupStatus === EmailLookupStatus.COMPLETED ? (
                                <div className={"password-entry "}>
                                    <div className="email">
                                        <div className="email-label">{t(myxTranslations.email)}</div>
                                        <div className="current-email">
                                            {userDetail.email}
                                            <a className="edit-email-link" onClick={onEditEmail}>
                                                {t("edit")}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="password-box mt-6">
                                        <PasswordPanel {...formProps} floatLabel={t(myxTranslations.password)} />
                                    </div>
                                    <ul className={"password-validation " + (formData["password"] !== "" ? "filled" : "")}>
                                        <li className={hasLowerCaseChar ? "valid " : ""}>{t(myxTranslations.passwordErrors.lowercase)}</li>
                                        <li className={hasCharCount ? "valid" : ""}>{t(myxTranslations.passwordErrors.count)}</li>
                                        <li className={hasUpperCaseChar ? "valid" : ""}>{t(myxTranslations.passwordErrors.uppercase)}</li>
                                        <li className={hasNumber ? "valid" : ""}>{t(myxTranslations.passwordErrors.number)}</li>
                                        <li className={hasNoSpecialChars ? "valid" : ""}>{t(myxTranslations.passwordErrors.specialChars)}</li>
                                        <li className={hasNoSpaces ? "valid" : ""}>{t(myxTranslations.passwordErrors.space)}</li>
                                    </ul>
                                    <div className="name-box mt-6">
                                        <div className="first-name-field">
                                            <TextInput
                                                dValue={formData["firstName"]}
                                                error={formErrors["firstName"]}
                                                floatLabel={t(myxTranslations.firstName)}
                                                hybridValidation={false}
                                                id="firstName"
                                                maxLength={30}
                                                name="firstName"
                                                onChange={onChange}
                                                onKeyPress={onEnter}
                                                onBlur={() => validateNameErrors("firstName")}
                                                setFormErrors={setFormErrors}
                                                type="text"
                                                analyticsId={tealiumTags.myx_account_first_name}
                                            />
                                        </div>
                                        <div className="last-name-field">
                                            <TextInput
                                                dValue={formData["lastName"]}
                                                error={formErrors["lastName"]}
                                                floatLabel={t(myxTranslations.lastName)}
                                                hybridValidation={false}
                                                id="lastName"
                                                maxLength={30}
                                                name="lastName"
                                                onChange={onChange}
                                                onKeyPress={onEnter}
                                                onBlur={() => validateNameErrors("lastName")}
                                                setFormErrors={setFormErrors}
                                                type="text"
                                                analyticsId={tealiumTags.myx_account_last_name}
                                            />
                                        </div>
                                    </div>
                                    <CTAButton
                                        outerBtnClass={"continue-btn " + accountFieldsHaveValue}
                                        innerBtnClass={accountFieldsHaveValue ? "has-value" : ""}
                                        buttonName={t(myxTranslations.saveAndContinue)}
                                        onButtonClick={() => {
                                            onContinueBtn(false);
                                        }}
                                        disableButton={
                                            !accountFieldsHaveValue || (emailLookupStatus === EmailLookupStatus.COMPLETED && !accountFieldsHaveValue)
                                                ? { disabled: "disabled" }
                                                : null
                                        }
                                        icon={disableBtn ? "fa fa-spinner fa-spin" : ""}
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                            {emailLookupStatus === EmailLookupStatus.COMPLETED && userDetail && userDetail.isNewUser ? (
                                <div
                                    className="user-acceptance-text"
                                    dangerouslySetInnerHTML={{
                                        __html: t("create-acct-disclaimer-myx"),
                                    }}
                                ></div>
                            ) : (
                                ""
                            )}
                        </Fragment>
                    ) : (
                        <Loader className="loader-wrapper" isToShowLoader={true} />
                    )}
                </div>
            </div>
        </section>
    );
};

MyxCreateAccountPage.propTypes = {
    content: PropTypes.object,
    contents: PropTypes.object,
    history: PropTypes.object,
    props: PropTypes.object,
    t: PropTypes.func,
};
