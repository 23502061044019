export const checkoutActions = {
    ASSIGN_TO_LEADWHEEL: "ASSIGN_TO_LEADWHEEL",
    CHECKOUT_FORM_ERROR: "CHECKOUT_FORM_ERROR",
    CREATE_PARTIAL_PROFILE: "CREATE_PARTIAL_PROFILE",
    CYBERSOURCE_PERMANENT_TOKEN: "CYBERSOURCE_PERMANENT_TOKEN",
    FETCH_COACH_DETAILS: "FETCH_COACH_DETAILS",
    GET_CONFIGURATIONS: "GET_CONFIGURATIONS",
    GET_CYBERSOURCE_KEY: "GET_CYBERSOURCE_KEY",
    GET_SESSION_USER_DATA: "GET_SESSION_USER_DATA",
    GET_USER_DETAILS: "GET_USER_DETAILS",
    GET_ZIPCODE_DETAILS: "GET_ZIPCODE_DETAILS",
    LOAD_PAGE: "LOAD_PAGE",
    MICROFORM_ERROR: "MICROFORM_ERROR",
    ORDER_CONFIRMATION_DETAILS: "ORDER_CONFIRMATION_DETAILS",
    PLACE_CHECKOUT_ORDER: "PLACE_CHECKOUT_ORDER",
    PLACE_MYX_CHECKOUT_ORDER: "PLACE_MYX_CHECKOUT_ORDER",
    RESET_PASSWORD: "RESET_PASSWORD",
    SEND_FORGOT_PASSWORD_EMAIL: "SEND_FORGOT_PASSWORD_EMAIL",
    SET_CHECKOUT_STATE: "SET_CHECKOUT_STATE",
    SET_CYBERSOURCE_KEY: "SET_CYBERSOURCE_KEY",
    SET_MERLIN_CONFIG: "SET_MERLIN_CONFIG",
    SET_ZIPCODE_DETAILS: "SET_ZIPCODE_DETAILS",
    USER_LOGIN_SYNCUP_ATG: "USER_LOGIN_SYNCUP_ATG",
    USER_LOGIN: "USER_LOGIN",
    VALIDATE_TOKEN: "VALIDATE_TOKEN",
    CYBERSOURCE_3DS_SETUP_AUTH: "CYBERSOURCE_3DS_SETUP_AUTH",
    CYBERSOURCE_3DS_ENROLL_AUTH: "CYBERSOURCE_3DS_ENROLL_AUTH",
    UPDATE_ENROLL_AUTH_3DS_RESULT: "UPDATE_ENROLL_AUTH_3DS_RESULT",
    CYBS_DEVICE_DATA_COLLECTION: "CYBS_DEVICE_DATA_COLLECTION",
    STEPUP_RESPONSE: "STEPUP_RESPONSE",
    ACTIVATE_MYX_BIKE: "ACTIVATE_MYX_BIKE",
    DEFAULT_CREDIT_CARD: "DEFAULT_CREDIT_CARD",
    REFERRING_COACH_DETAILS: "REFERRING_COACH_DETAILS",
};
