import React, { useState } from "react";
import PropTypes from "prop-types";
import { configureInlineCss, convertSCSSToCSS, logCarouselEvents } from "../../../helper/util";
import { useSwipeable } from "react-swipeable";
import "./carousel-slider-on-thumbnail.scss";
import { logCustomEvents } from "../../../web-analytics";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import { carousel_card_click, carousel_view_more } from "../../../constants/tealium-tags";
import { AnimateSection } from "../../common/animation";

export const CarouselSliderOnThumbnail = ({ fields, fieldId, FieldRenderer, isDesktopView }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => onNextClick(),
        onSwipedRight: () => onPrevClick(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });
    const [carouselContainerRef] = useIntersectionObserver(
        {
            root: null,
            rootMargin: "100px",
            threshold: 1,
        },
        () => {
            if (!enableAnalyticsTracking) {
                return;
            }
        },
    );
    const {
        topContainer = null,
        carouselPreviousArrowBackgroundImg = null,
        carouselNextArrowBackgroundImg = null,
        carouselContent,
        thumbnailActiveBorderColor = "",
        carousalBoxCss = "",
        title,
        enableAnalyticsTracking = false,
        animationClass,
    } = fields;

    const slideCount = carouselContent.length;

    const handleArrowsClickTracking = (direction) => {
        if (!enableAnalyticsTracking) {
            return;
        }

        const eventId = `${title.replace(/ /g, "")}:${direction}`;
        const eventData = logCarouselEvents(eventId);
        logCustomEvents(carousel_view_more, direction, eventData, true, "link_tracking");
    };

    const handleCardClickTracking = (cardTitle) => {
        if (!enableAnalyticsTracking) {
            return;
        }

        const eventId = `${title.replace(/ /g, "")}:${cardTitle.replace(/ /g, "")}`;
        const eventData = logCarouselEvents(eventId);
        logCustomEvents(carousel_card_click, "click", eventData, true, "link_tracking");
    };

    const onPrevClick = () => {
        setActiveIndex(activeIndex === 0 ? slideCount - 1 : activeIndex - 1);
        handleArrowsClickTracking("left");
    };
    const onNextClick = () => {
        setActiveIndex(activeIndex === slideCount - 1 ? 0 : activeIndex + 1);
        handleArrowsClickTracking("right");
    };
    const onThumbnailClick = (index) => {
        setActiveIndex(index);
    };
    const renderThumbnail = () => {
        const previousArrow = carouselPreviousArrowBackgroundImg && configureInlineCss(carouselPreviousArrowBackgroundImg, isDesktopView, true);
        const nextArrow = carouselNextArrowBackgroundImg && configureInlineCss(carouselNextArrowBackgroundImg, isDesktopView, true);

        return (
            <div className="carousel-thumbnail-slider">
                {previousArrow && previousArrow.css ? <style>{previousArrow.css}</style> : null}
                {nextArrow && nextArrow.css ? <style>{nextArrow.css}</style> : null}
                <a id={previousArrow?.id} onClick={onPrevClick} data-slide="prev"></a>
                <ul className="carousel-thumbnais">
                    {carouselContent.map((slide, index) => {
                        const { thumbnailImage } = slide.fields;
                        const activeStyle = thumbnailActiveBorderColor ? { borderColor: thumbnailActiveBorderColor } : {};
                        return (
                            <li data-slide-to={index} className={activeIndex === index ? "active" : ""} key={index} onClick={() => onThumbnailClick(index)}>
                                <FieldRenderer
                                    contents={thumbnailImage}
                                    inlineStyle={activeIndex === index ? activeStyle : null}
                                    handleCardClickTracking={handleCardClickTracking}
                                    isActive={activeIndex === index}
                                />
                            </li>
                        );
                    })}
                </ul>
                <a id={nextArrow?.id} onClick={onNextClick} data-slide="next"></a>
            </div>
        );
    };
    const renderSlideContent = () => {
        return (
            <div className="carousel-inner" {...swipeHandlers}>
                {carouselContent.map((slide, index) => {
                    const { slideContent } = slide.fields;
                    return (
                        <div key={index} className={activeIndex === index ? "carousel-item active" : "carousel-item"}>
                            <FieldRenderer contents={slideContent} />
                        </div>
                    );
                })}
            </div>
        );
    };
    const configureCarousalBox = !process.env.BROWSER ? convertSCSSToCSS({ fieldId: `carousel-box-${fieldId}`, customCss: carousalBoxCss }) : null;
    return (
        <AnimateSection animationClass={animationClass} refProp={carouselContainerRef}>
            {({ containerRef }) => (
                <div id={fieldId} className="carousel-container" ref={containerRef}>
                    <FieldRenderer contents={topContainer} />
                    {configureCarousalBox ? <style>{configureCarousalBox}</style> : null}
                    <div id={`carousel-box-${fieldId}`} className="carousel-on-thumbail carousel slide" data-ride="carousel">
                        {renderThumbnail()}
                        {renderSlideContent()}
                    </div>
                </div>
            )}
        </AnimateSection>
    );
};
CarouselSliderOnThumbnail.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    FieldRenderer: PropTypes.func,
    isDesktopView: PropTypes.bool,
};
