import "isomorphic-fetch";
import { SessionEmitter, TRIGGER_SESSION_MODAL } from "../common/popup-emitter";
import { checkSupportedLanguage } from "../constants/app-languages";
import { defaultLanguage } from "../constants/urls";
import { getPushSite, getSiteId } from "../helper/util";
import { datadogRum } from "@datadog/browser-rum";

const fetchCommonQueryParams = (params) => {
    const pushSite = getPushSite();
    const sessionId = sessionStorage.getItem("sessionId");
    const locale = localStorage.getItem("i18nextLng");
    // Varies based on condition
    return `${params === -1 ? "" : "&"}_dynSessConf=${sessionId}&t=${new Date().getTime()}&locale=${locale}&pushSite=${pushSite}`;
};
export const prepareHeaders = (addtlHeaders) => {
    const headers = addtlHeaders || {};
    return Object.keys(headers).length ? headers : {};
};
export const fetchApi = (serviceUrl, options, callee) => {
    try {
        const response = fetch(serviceUrl, options)
            .then((res) => {
                if (res.status === 409) {
                    SessionEmitter.emit(TRIGGER_SESSION_MODAL, true);
                } else {
                    updateUserSession();
                    if (res.status >= 500 && res.status < 600) {
                        datadogRum.addError(`Fetch API Error: ${callee} ${serviceUrl} - HTTP Status: ${res.status}`);
                    }
                    return res.json();
                }
            })
            .catch((e) => {
                console.error(`${callee} ${serviceUrl}::::`, e);
                datadogRum.addError(`Fetch API Error: ${callee} ${serviceUrl} - Error: ${e}`);
            });
        return response;
    } catch (e) {
        console.error(`${callee} ${serviceUrl}::::`, e);
        datadogRum.addError(`Fetch API Error: ${callee} ${serviceUrl} - Error: ${e}`);
    }
};
export const myxFetchApi = (serviceUrl, options, callee) => {
    try {
        const response = fetch(serviceUrl, options)
            .then((res) => {
                if (res.status === 409) {
                    SessionEmitter.emit(TRIGGER_SESSION_MODAL, true);
                } else {
                    updateUserSession();
                    if (res.status >= 500 && res.status < 600) {
                        datadogRum.addError(`MYX Fetch API Error: ${callee} ${serviceUrl} - HTTP Status: ${res.status}`);
                    }
                    // res.json() will not work for "activationcode" GET API, will throw an "SyntaxError: Unexpected end of JSON input" error
                    return res.text();
                }
            })
            .catch((e) => {
                console.error(`${callee} ${serviceUrl}::::`, e);
                datadogRum.addError(`MYX Fetch API Error: ${callee} ${serviceUrl} - Error: ${e}`);
            });
        return response;
    } catch (e) {
        console.error(`${callee} ${serviceUrl}::::`, e);
        datadogRum.addError(`MYX Fetch API Error: ${callee} ${serviceUrl} - Error: ${e}`);
    }
};
export const fetchPost = async (apiUrl, globalParams, body) => {
    const params = apiUrl.indexOf("?");
    const commonQueryParams = fetchCommonQueryParams(params, globalParams);
    const serviceUrl = apiUrl + commonQueryParams;
    const options = {
        method: "POST",
        credentials: "include",
    };
    const headers = prepareHeaders();
    if (headers) {
        options.headers = headers;
    }
    options.headers["Origin"] = window.origin;
    if (body) {
        if (!options["headers"]) {
            options["headers"] = {};
        }
        options["headers"]["Content-Type"] = "application/json; charset=utf-8";
    }
    const response = fetchApi(serviceUrl, options, "fetchPost");
    return response;
};
export const myxFetchPost = async (apiUrl, globalParams, body) => {
    const params = apiUrl.indexOf("?");
    const commonQueryParams = fetchCommonQueryParams(params, globalParams);
    const serviceUrl = apiUrl + commonQueryParams;
    const options = {
        method: "POST",
        body: JSON.stringify(body),
    };
    const headers = prepareHeaders();
    if (headers) {
        options.headers = headers;
    }
    if (body) {
        if (!options["headers"]) {
            options["headers"] = {};
        }
        options["headers"]["Content-Type"] = "application/json; charset=utf-8";
    }
    const response = fetchApi(serviceUrl, options, "myxFetchPost");
    return response;
};

export const fetchGet = (apiUrl, addtlHeaders) => {
    const params = apiUrl.indexOf("?");
    const commonQueryParams = fetchCommonQueryParams(params);
    const param = {
        method: "GET",
        credentials: "include",
    };
    const headers = prepareHeaders(addtlHeaders);
    if (headers) {
        param.headers = headers;
    }
    const serviceUrl = `${apiUrl}${params === -1 ? "?" : ""}${commonQueryParams}`;
    const response = fetchApi(serviceUrl, param, "fetchGet");
    return response;
};
export const myxFetchGet = (apiUrl, addtlHeaders) => {
    const params = apiUrl.indexOf("?");
    const commonQueryParams = fetchCommonQueryParams(params);
    const param = {
        method: "GET",
    };
    const headers = prepareHeaders(addtlHeaders);
    if (headers) {
        param.headers = headers;
    }
    const serviceUrl = `${apiUrl}${params === -1 ? "?" : ""}${commonQueryParams}`;
    const response = myxFetchApi(serviceUrl, param, "myxFetchGet");
    return response;
};
export const createSession = async (apiUrl) => {
    const response = fetch(apiUrl, {
        method: "GET",
        credentials: "include",
    });
    return response;
};
const getCommonParams = () => {
    const sessionId = sessionStorage.getItem("sessionId");
    const siteId = getSiteId();
    let localeParam = localStorage.getItem("i18nextLng");
    if (!localeParam.split("-")[1] && !localeParam.split("_")[1]) {
        const defaultParam = defaultLanguage; // change during IP detection
        //localStorage.setItem("i18nextLng", defaultParam);
        localeParam = defaultParam;
    }
    localeParam = localeParam.replace("-", "_");
    localeParam = checkSupportedLanguage(localeParam);
    //localStorage.setItem("i18nextLng", localeParam);
    const params = {
        _dynSessConf: sessionId,
        t: new Date().getTime(),
        pushSite: siteId,
        locale: localeParam,
    };
    return params;
};
export const fetchPostWithFormData = async (apiUrl, body, sendOrigin?) => {
    const serviceUrl = apiUrl;
    const commonParams = getCommonParams();
    let formBody = [];
    const formData = { ...body, ...commonParams };

    for (const property in formData) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(formData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const options = {
        method: "POST",
        credentials: "include",
        body: formBody,
    };
    const headers = prepareHeaders();
    if (headers) {
        options.headers = headers;
    }
    sendOrigin ? (options.headers["Origin"] = window.origin) : "";
    if (body) {
        if (!options["headers"]) {
            options["headers"] = {};
        }
        options["headers"]["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
    }
    const response = fetchApi(serviceUrl, options, "fetchPost");

    return response;
};
export const myxFetchPostWithFormData = async (apiUrl, body, addtlHeaders) => {
    const serviceUrl = apiUrl;
    const commonParams = getCommonParams();
    let formBody = [];
    const formData = { ...body, ...commonParams };

    for (const property in formData) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(formData[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const options = {
        method: "POST",
        body: formBody,
    };

    const headers = prepareHeaders(addtlHeaders);
    if (headers) {
        options.headers = headers;
    }
    if (body) {
        if (!options["headers"]) {
            options["headers"] = {};
        }
    }
    const response = fetchApi(serviceUrl, options, "myxFetchPost");

    return response;
};
export const myxFetchPostWithJsonData = async (apiUrl, body, addtlHeaders) => {
    const serviceUrl = apiUrl;
    const options = {
        method: "POST",
        body: JSON.stringify(body),
    };

    const headers = prepareHeaders(addtlHeaders);
    if (headers) {
        options.headers = headers;
    }
    if (body) {
        if (!options["headers"]) {
            options["headers"] = {};
        }
    }
    const response = fetchApi(serviceUrl, options, "myxFetchPost");

    return response;
};

export const fetchPostWithJSONData = async (apiUrl, body) => {
    const params = apiUrl.indexOf("?");
    const commonQueryParams = fetchCommonQueryParams(params);
    const serviceUrl = apiUrl + (params !== -1 ? "" : "?") + commonQueryParams;
    const commonParams = getCommonParams();
    const jsonData = { ...body, ...commonParams };
    const options = {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(jsonData),
    };
    const headers = prepareHeaders();
    if (headers) {
        options.headers = headers;
    }
    if (body) {
        if (!options["headers"]) {
            options["headers"] = {};
        }
        options["headers"]["Content-Type"] = "application/json";
    }
    const response = fetchApi(serviceUrl, options, "fetchPost");

    return response;
};

export const fetchDirect = async (apiUrl, method = "GET", body = null, additionalParams = {}) => {
    const serviceUrl = `${apiUrl}`;
    const options = {
        method: method,
        credentials: "include",
        ...additionalParams,
    };
    body && (options.body = JSON.stringify(body));
    const response = fetch(serviceUrl, options);
    return response;
};
export function updateUserSession() {
    localStorage.setItem("sessionExpired", false);
    localStorage.setItem("userLastActiveTime", new Date().getTime());
    SessionEmitter.emit(TRIGGER_SESSION_MODAL, false);
}
