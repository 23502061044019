import React from "react";
import PropTypes from "prop-types";
import { AnimateSection } from "../../../common/animation";

export const BaseMediaContainer = ({ type, children, ...props }) => {
    const { fields, fieldId, inlineTheme = "", isDesktopView, inlineOuterStyle = {}, onButtonClick } = props;
    const { imageContainer, textContainer, mobileAlignment = "", theme, hideCtaButtonInMobileView = false, animationClass } = fields;

    const hideCtaButtonInMobileCallBack = (contentType) => {
        return !isDesktopView && contentType === "ctaButton" && hideCtaButtonInMobileView;
    };

    const alignment = !isDesktopView ? (mobileAlignment && mobileAlignment !== "none" ? mobileAlignment : "") : "";

    return (
        <AnimateSection animationClass={animationClass}>
            {({ containerRef }) => (
                <div
                    id={fieldId}
                    className={`${theme === "none" ? "" : theme} ${alignment} ${type} ${inlineTheme}`}
                    style={inlineOuterStyle}
                    ref={containerRef}
                >
                    {children({
                        imageContainer,
                        textContainer,
                        hideFieldCallback: hideCtaButtonInMobileView ? hideCtaButtonInMobileCallBack : null,
                        onButtonClick: onButtonClick ? onButtonClick : null,
                    })}
                </div>
            )}
        </AnimateSection>
    );
};
BaseMediaContainer.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    type: PropTypes.string,
    children: PropTypes.func,
    inlineTheme: PropTypes.string,
    isDesktopView: PropTypes.bool,
    inlineOuterStyle: PropTypes.object,
    onButtonClick: PropTypes.func,
};
