export class User {
    constructor(data = {}) {
        this.firstName = data.firstName || "";
        this.lastName = data.lastName || "";
        this.email = data.email || "";
        this.guid = data.guid || "";
        this.accessToken = data.accessToken || "";
        this.customerType = data.customerType || "";
    }
}
