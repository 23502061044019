import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";

export const TableRow = ({ fields, fieldId, isDesktopView, inlineClass, ...props }) => {
    const { content, isHeader = false } = fields;

    return (
        <tr id={fieldId} className={inlineClass ? `table-row ${inlineClass}` : "table-row-container"}>
            <FieldRenderer contents={content} isHeader={isHeader} isDessktopView={isDesktopView} {...props} />
        </tr>
    );
};

TableRow.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    RichText: PropTypes.func,
    hideFieldCallback: PropTypes.func,
    isDesktopView: PropTypes.bool,
    inlineClass: PropTypes.string,
};
