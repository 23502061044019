import React from "react";
import PropTypes from "prop-types";
import dompurify from "dompurify";

export const ActivateBikeForm = ({ formTitle, formDescription, onChange, formData }) => {
    const sanitizer = dompurify.sanitize;

    const focusFirstEmptyInput = (event) => {
        if (event.target.value !== "") {
            return;
        }
        const inputs = document.querySelectorAll("form input.digit");
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].value === "") {
                inputs[i].focus();
                break;
            }
        }
    };

    return (
        <section className="activate-bike-panel">
            <h2 className="title">{formTitle}</h2>
            <p
                className="description"
                dangerouslySetInnerHTML={{
                    __html: sanitizer(formDescription),
                }}
            ></p>
            <form>
                <div className="d-flex align-items-center justify-content-center">
                    {formData &&
                        Object.keys(formData)
                            .sort()
                            .map((key) => {
                                return (
                                    <input
                                        key={key.toString()}
                                        type="text"
                                        name={key}
                                        onChange={onChange}
                                        onFocus={focusFirstEmptyInput}
                                        autoFocus={key.toString() === "digit1"}
                                        autoComplete="off"
                                        maxLength={1}
                                        className="digit"
                                        value={formData[key] || ""}
                                        required
                                    />
                                );
                            })}
                </div>
            </form>
        </section>
    );
};

ActivateBikeForm.propTypes = {
    formTitle: PropTypes.string,
    formDescription: PropTypes.string,
    onChange: PropTypes.func,
    formData: PropTypes.object,
};
