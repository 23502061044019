import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";

export const TableContainer = ({ fields, fieldId, inlineClass = "", ...props }) => {
    const { content } = fields;
    const isDesktopView = useSelector((state) => state.pageRouter.isDesktopView);

    return (
        <Fragment>
            <div id={fieldId} className={inlineClass ? `table-container ${inlineClass}` : "table-container"}>
                <table>
                    <FieldRenderer contents={content} isDesktopView={isDesktopView} {...props} />
                </table>
            </div>
        </Fragment>
    );
};

TableContainer.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    isDesktopView: PropTypes.bool,
    inlineClass: PropTypes.string,
};
