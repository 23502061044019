import { pageRouterActions } from "./page-router-action";
import { checkForWindow, extractFieldValues } from "../../../helper/util";
import { DigitalData, AccountDetails } from "../../../model";
import { decipherUserData } from "../../../module/common/util";

const initialState = {
    pageId: "",
    page: null,
    pageTemplateName: "OneColumnTemplate",
    isDesktopView: checkForWindow(),
    isCheckoutLandscape: checkForWindow(1023),
    digitalData: new DigitalData(),
    modalContent: null,
    userDetail: decipherUserData(),
    popupContent: null,
    basePageCSS: "",
    sitesAllowed: [],
    featureFlagContent: {},
};

const pageRouter = (state = initialState, action) => {
    switch (action.type) {
        case pageRouterActions.SET_PAGE_ROUTER_STATE:
            return {
                ...state,
                ...action.payload,
            };
        case pageRouterActions.HANDLE_WINDOW_RESIZE:
            const isDesktopDimension = checkForWindow();
            const isCheckoutDimension = checkForWindow(1023);
            let updatedState = { ...state };
            if (state.isDesktopView !== isDesktopDimension) {
                updatedState = { ...updatedState, isDesktopView: isDesktopDimension };
            }
            if (state.isCheckoutLandscape !== isCheckoutDimension) {
                updatedState = { ...updatedState, isCheckoutLandscape: isCheckoutDimension };
            }
            return updatedState;
        case pageRouterActions.MODAL_CONTENT:
            return { ...state, popupContent: { ...action.payload } };
        case pageRouterActions.SET_USER_DETAILS:
            return {
                ...state,
                userDetail: action.payload ? new AccountDetails({ ...state.userDetail, ...action.payload }) : null,
            };
        case pageRouterActions.SET_SURVEY_INFO:
            return { ...state, surveyDetails: action.payload };
        case pageRouterActions.EDIT_EMAIL:
            return { ...state, editEmail: action.payload };
        case pageRouterActions.OUTRO_PAGE_CONTENT:
            return { ...state, outroPage: action.payload };
        case pageRouterActions.SET_INTERSTITIAL_PAGE:
            return { ...state, interstitialPage: action.payload };
        case pageRouterActions.FEATURE_FLAGS_CONTENT:
            const data = extractFieldValues(action.payload, "enableFeature");
            return { ...state, featureFlagContent: data };

        default:
            return state;
    }
};
export default pageRouter;
