import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { ActivateBikeForm } from "./components/ActivateBikeForm";
import { ActivateBikeError } from "./components/ActivateBikeError";
import { activationActions } from "./data/activation-page-action";
import { redirectWithParam } from "../common/util";
import { Loader } from "../../common";
import { MYX_CREATE_ACCOUNT_PATH, WSC_MYX_BOD_BIKE, MYX_LOGIN_PATH } from "../../constants/urls";
import { checkWebSourcecodeCookie, clearCookie, getUrlParam } from "../../helper/util";
import { ActivationCode } from "../../model";
import { pageRouterActions } from "../../component/page-router/data/page-router-action";
import { logCustomEvents } from "../../web-analytics";
import * as tealiumTags from "../../constants/tealium-tags";
import { countryLanguageCodes } from "../../constants/app-languages";
import { defaultLanguages } from "../../helper/language-supported";
export function ActivationPageContent(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const autoToken = getUrlParam("at");
    const { contents, content } = props;
    const { fields } = contents;
    const { primaryProductCode, offerId, footerHomeLandingPage } = content && content.fields;
    const { title, description, errorTitle, errorDescription, errorButtonLabel } = fields;
    const [isError, setIsError] = useState(false);
    const [formData, setFormData] = useState(new ActivationCode());
    const [showPageLoader, setPageLoader] = useState(true);
    const sessionId = sessionStorage.getItem("sessionId");

    const goToMyxCreateAccount = () => {
        redirectWithParam(history, MYX_CREATE_ACCOUNT_PATH.substring(1));
    };
    const goToMyxLogin = () => {
        redirectWithParam(history, MYX_LOGIN_PATH.substring(1));
    };
    const onVerifyActivationCodeSuccess = (activationCodeParam) => {
        logCustomEvents(tealiumTags.myx_activation_code, tealiumTags.myx_activation_code, activationCodeParam);
        localStorage.setItem("activationCode", activationCodeParam);
        setFormData(new ActivationCode());
        setIsError(false);
        goToMyxCreateAccount();
    };

    const onVerifyActivationCodeFailure = () => {
        sessionStorage.removeItem("bikeLoginCheckout");
        setPageLoader(false);
        localStorage.removeItem("activationCode");
        setFormData(new ActivationCode());
        setIsError(true);
        dispatch({
            type: activationActions.SET_ACTIVATION_CODE,
            payload: { activationCode: "" },
        });
    };
    const onVerifyActivationCodeSuccessAutoLogin = () => {
        setFormData(new ActivationCode());
        setIsError(false);
        goToMyxLogin();
    };

    const onResetForm = (event) => {
        event.preventDefault();
        setFormData(new ActivationCode());
        setIsError(false);
    };

    const onChange = (event) => {
        const { value, nextSibling } = event.target;
        const filteredAlphaNumValue = value.replace(/[^a-zA-Z0-9]/gi, "");
        if (value && !filteredAlphaNumValue) {
            return;
        }
        event.persist();
        setFormData((prevState) => ({
            ...prevState,
            [event.target.name]: filteredAlphaNumValue,
        }));
        if (nextSibling && filteredAlphaNumValue !== "") {
            nextSibling.focus();
        }
    };

    const lwFunction = (lwLangCode) => {
        const appLanguage = localStorage.getItem("i18nextLng");
        const countryCode = appLanguage?.split("_")[1];
        const countryCodeLower = appLanguage?.split("_")[1]?.toLowerCase();
        const tabletCode = lwLangCode + "_" + countryCode;
        const coachLanguage = countryLanguageCodes[countryCodeLower]?.includes(tabletCode) ? tabletCode : defaultLanguages[countryCodeLower];
        sessionStorage.setItem("lwOrderLang", coachLanguage);
    };

    const getActivationCode = (formData) => {
        let activationCode = "";
        Object.keys(formData).forEach(function (key) {
            activationCode += formData[key];
        });
        return activationCode;
    };
    const validateActivationCode = ({ activationCode, successCallBack }) => {
        const code = activationCode || localStorage.getItem("activationCode");
        dispatch({
            type: activationActions.VERIFY_ACTIVATION_CODE,
            payload: { activationCode: code },
            callbacks: { success: successCallBack ? successCallBack : onVerifyActivationCodeSuccess, failure: onVerifyActivationCodeFailure },
        });
    };
    const removeDuplicateParams = (search: string) => {
        const searchParam = search !== "" ? search.split("&") : [];
        const hasParam = searchParam && searchParam.length > 0;
        const query =
            hasParam &&
            searchParam.filter((param) => {
                const [key] = param.split("=") || [];
                const params = ["productCode", "rc", "offerId", "offersPageId", "lp"];
                return params.indexOf(key) === -1 ? true : false;
            });
        return hasParam ? `?${query.join("&")}` : "";
    };
    useEffect(() => {
        const activationCode = getActivationCode(formData);
        const canDispatch = formData && activationCode && activationCode.length === 5;
        if (canDispatch) {
            setPageLoader(true);
            validateActivationCode({ activationCode });
        }
    }, [formData]);

    useEffect(() => {
        if (sessionId) {
            setPageLoader(true);
            const activationCodeParam = getUrlParam("activationCode");
            const lwLangParam = getUrlParam("lwLang");
            const authToken = getUrlParam("authToken");
            const guid = getUrlParam("guid");
            const emailParam = getUrlParam("email");
            const productId = getUrlParam("offerId") || offerId;
            const newProductCode = getUrlParam("productCode") || primaryProductCode;
            const newFooterHomeLandingPage = getUrlParam("footerHomeLandingPage") || footerHomeLandingPage;
            if (autoToken) {
                const decryptedToken = atob(autoToken);
                decryptedToken.split("&").map(function (c) {
                    if (c.split("=")[0] === "activationCode") {
                        localStorage.setItem(c.split("=")[0], c.split("=")[1]);
                    } else {
                        sessionStorage.setItem(c.split("=")[0], c.split("=")[1]);
                    }
                });
                sessionStorage.setItem("bikeLoginCheckout", true);
                const lwLang = sessionStorage.getItem("lwLang");
                if (lwLang) {
                    lwFunction(lwLang);
                }
                validateActivationCode({ successCallBack: onVerifyActivationCodeSuccessAutoLogin });
            }
            if (lwLangParam) {
                lwFunction(lwLangParam);
            }
            if (emailParam) {
                sessionStorage.setItem("email", emailParam);
                dispatch({
                    type: activationActions.SET_USER_EMAIL,
                    payload: { userEmail: emailParam },
                });
            }
            if ((activationCodeParam && !authToken) || (activationCodeParam && !guid)) {
                setPageLoader(true);
                validateActivationCode({ activationCode: activationCodeParam });
            }
            if (!activationCodeParam && !autoToken) {
                setPageLoader(false);
            }
            productId && localStorage.setItem("offerId", productId);
            newProductCode && localStorage.setItem("productCode", newProductCode);
            newFooterHomeLandingPage && localStorage.setItem("landingPage", newFooterHomeLandingPage);
        }
    }, [sessionId]);
    useEffect(() => {
        localStorage.setItem("offerId", offerId);
        const path = props.location.pathname.substring(1) + removeDuplicateParams(props.location.search.replace("?", ""));
        footerHomeLandingPage && localStorage.setItem("landingPage", footerHomeLandingPage);
        redirectWithParam(history, path);
    }, [primaryProductCode]);
    useEffect(() => {
        const code = getUrlParam("code") || WSC_MYX_BOD_BIKE;
        checkWebSourcecodeCookie(code);
        dispatch({ type: pageRouterActions.SET_USER_DETAILS, payload: null });
        clearCookie(["userData"]);
    }, []);
    return (
        <Fragment>
            {!showPageLoader ? (
                <section className="myx-activate-bike container">
                    <div className="myx-container w-100">
                        <div className="myx-panel">
                            {isError ? (
                                <ActivateBikeError
                                    errorTitle={errorTitle}
                                    errorDescription={errorDescription}
                                    errorButtonLabel={errorButtonLabel}
                                    onSubmit={onResetForm}
                                />
                            ) : (
                                <ActivateBikeForm formTitle={title} formDescription={description} onChange={onChange} formData={formData} />
                            )}
                        </div>
                    </div>
                </section>
            ) : (
                <section className="myx-loader container">
                    <div className="myx-container w-100">
                        <div className="myx-panel">
                            <Loader className="loader-wrapper" isToShowLoader={true} />
                        </div>
                    </div>
                </section>
            )}
        </Fragment>
    );
}

ActivationPageContent.propTypes = {
    content: PropTypes.object,
    contents: PropTypes.object,
    location: PropTypes.object,
    props: PropTypes.object,
};
