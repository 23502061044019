import React from "react";
import PropTypes from "prop-types";

export function OrderSummaryCard({ t, order, enableOneBodi }) {
    const {
        priceInfo: { rawSubtotal, tax, total, amount: proratedAmount, promotions },
        taxPriceInfo: { amount, country, state },
    } = order || { priceInfo: {}, taxPriceInfo: {} };
    const isNotCanada = localStorage.getItem("i18nextLng") !== "en_CA" && localStorage.getItem("i18nextLng") !== "fr_CA";
    const displayTax = (tax) => {
        const { amount, label } = tax || {};
        return (
            <div className="cart-summary d-flex justify-content-between mb-2 mb-sm-1">
                <span className="cart-sum-type">{label}:</span>
                <span className="cart-sum-amt">{amount}</span>
            </div>
        );
    };
    const isValidPromotions = () => {
        const currencyRegex = /\D*(\d+|\d.*?\d)(?:\D+(\d{2}))?\D*$/;
        const result = currencyRegex.exec(promotions);
        const amount = result && parseFloat(result[1].replace(/\D/, "") + "." + (result[2] ? result[2] : "00"));
        if (amount) return true;
        else return false;
    };
    return (
        <section id="order-summary-card" className="order-summary-card d-flex justify-content-between">
            <div className="order-summary-card-left">
                <h2 className="order-sum-header">{t("order-summary")}</h2>
            </div>
            <div className="order-summary-card-right">
                {enableOneBodi ? (
                    <>
                        {promotions && isValidPromotions() && (
                            <>
                                <div className="cart-summary d-flex justify-content-between mb-2 mb-sm-1">
                                    <span className="cart-sum-type">{t("price")}:</span>
                                    <span className="cart-sum-amt">{rawSubtotal}</span>
                                </div>
                                <div className="cart-summary divider d-flex justify-content-between mb-2 mb-sm-1">
                                    <span className="cart-sum-type">{t("prorated-credit")}:</span>
                                    <span className="cart-sum-amt">{`(-${promotions})`}</span>
                                </div>
                            </>
                        )}
                        <div className="cart-summary prorated-total d-flex justify-content-between mb-2 mb-sm-1">
                            <span className="cart-sum-type">{t("sub-total")}:</span>
                            <span className="cart-sum-amt">{proratedAmount}</span>
                        </div>
                        {country && displayTax(country)}
                        {state && displayTax(state)}
                        {isNotCanada && (
                            <div className="cart-summary d-flex justify-content-between">
                                <span className="cart-sum-type">{t("estimated-taxes")}: </span>
                                <span className="cart-sum-amt">{tax === -1 ? t("not-calculated") : amount && amount.amount}</span>
                            </div>
                        )}
                        <div className="cart-summary today-total d-flex justify-content-between mb-2 mb-sm-1">
                            <span className="cart-sum-type">{t("today-total")}:</span>
                            <span className="cart-sum-amt">{total}</span>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="cart-summary d-flex justify-content-between mb-2 mb-sm-1">
                            <span className="cart-sum-type">{t("sub-total")}:</span>
                            <span className="cart-sum-amt">{rawSubtotal}</span>
                        </div>
                        {country && displayTax(country)}
                        {state && displayTax(state)}
                        {isNotCanada && (
                            <div className="cart-summary d-flex justify-content-between">
                                <span className="cart-sum-type">{t("estimated-tax")}: </span>
                                <span className="cart-sum-amt">{tax === -1 ? t("not-calculated") : amount && amount.amount}</span>
                            </div>
                        )}
                        <div className="cart-summary d-flex justify-content-between total-sum">
                            <span className="cart-sum-type">{t("total")}:</span>
                            <span className="cart-sum-amt">
                                <span>{total}</span>
                            </span>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
}
OrderSummaryCard.propTypes = {
    t: PropTypes.func,
    order: PropTypes.object,
    enableOneBodi: PropTypes.bool,
};
