import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../../../contentful/component-renderer";
export const SecurityContent = ({
    fields
}) => {
    const { securityLogos, securityMessages } = fields;
    return (
        <div className="security-content">
            <FieldRenderer contents={securityLogos} inlineClass="security-logo"/>
            <FieldRenderer contents={securityMessages} />
        </div>
    );
};

SecurityContent.propTypes = {
    fields: PropTypes.object,
};