/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { CHECKOUT_PATH, CREATE_ACCOUNT_PATH, DEFAULT_PRODUCT_CODE, OFFERS_PATH } from "../../constants/urls";
import { redirectWithParam } from "../common/util";
import { offersActions } from "../offers-page/data/offers-page-action";
import { pageRouterActions } from "../../component/page-router/data/page-router-action";
import { Loader } from "../../common";
import { Interstitial } from "./interstitial";
import { logProductData } from "../../web-analytics";
import { createAccount } from "../create-account/components/constants";

export function InterstitialUpsellPage(props) {
    const dispatch = useDispatch();
    const { interstitialPage, digitalData } = useSelector((state) => state.pageRouter);
    const { order, offersDetails, interstitialOffers, sessionId } = useSelector((state) => state.offers);
    const [offers, getOffers] = useState(null);
    const productCode = localStorage.getItem("royaltyCode");
    const upsellLocation = sessionStorage.getItem("upsell_location");
    const gotoCheckout = () => {
        const url = upsellLocation === createAccount ? CREATE_ACCOUNT_PATH : CHECKOUT_PATH;
        redirectWithParam(props.history, url);
    };
    const onAddItemToCartFailure = () => {
        //TODO
    };

    const {
        fields: { maxWidth, upsellContent, backgroundColor, bannerImage, customCss, title },
        sys: { id },
        basePageCSS,
    } = interstitialPage || {
        fields: { maxWidth: 1170, upsellContent: [], backgroundColor: "#171819", bannerImage: "", customCss: "" },
        sys: { id: "" },
        basePageCSS: "",
    };

    const {
        fields: { contents },
    } = upsellContent[0] || { fields: { contents: [] } };

    const addItemToCart = (productId, catalogRefIds, sku, btnName) => {
        const removalCommerceIds =
            order &&
            order.commerceItems
                .map((item) => {
                    return item.id;
                })
                .join();
        const payload = {
            productId,
            catalogRefIds,
            removalCommerceIds,
            isToUpdateCart: removalCommerceIds ? true : false,
            primaryProductCode: productCode || DEFAULT_PRODUCT_CODE,
        };
        logProductData(productId, catalogRefIds, sku, { ...digitalData, offer_description: title, link_label: btnName, orderID: order?.id });
        productId &&
            catalogRefIds &&
            dispatch({
                type: offersActions.ADD_ITEM_TO_CART,
                payload,
                callbacks: { success: gotoCheckout, failure: onAddItemToCartFailure },
            });
    };

    const updateCart = (id: string, buttonText): void => {
        const [skuId, productId] = id?.split("-")?.[0].split("_") || [];
        const offerId = localStorage.getItem("offerId");
        sessionStorage.removeItem("isBrowserArrow");
        if (productId && skuId) {
            if (offerId === productId) {
                addItemToCart(
                    productId,
                    skuId,
                    offersDetails?.find((sku) => sku.id === skuId),
                    buttonText,
                );
            } else {
                dispatch({ type: offersActions.GET_OFFERS_DETAILS, payload: productId, interstitial: true });
                getOffers({ productId, skuId, buttonText });
            }
        }
    };
    const updateInterstitialPageContent = (response) => {
        const id = sessionStorage.getItem("upsell_page");
        const {
            fields: { pageUrl },
            basePageCSS,
        } = response || {};
        if (pageUrl === id) {
            dispatch({ type: pageRouterActions.SET_INTERSTITIAL_PAGE, payload: { ...response, basePageCSS } });
            sessionStorage.setItem("upsell_page", pageUrl);
        }
    };
    const redirectToOffersPage = () => {
        if (upsellLocation) gotoCheckout();
        else redirectWithParam(props.history, OFFERS_PATH, true);
    };
    useEffect(() => {
        if (interstitialOffers && offers) {
            const index = interstitialOffers?.findIndex((sku) => sku.id === offers.skuId);
            index > -1 ? addItemToCart(offers.productId, offers.skuId, interstitialOffers?.[index], offers.buttonText) : gotoCheckout();
        }
    }, [interstitialOffers]);

    useEffect(() => {
        sessionId && !order && dispatch({ type: offersActions.GET_CART_DETAILS, callbacks: {} });
        if (!interstitialPage) {
            const id = sessionStorage.getItem("upsell_page");
            id &&
                dispatch({
                    type: pageRouterActions.GET_PAGE_DATA,
                    payload: {
                        isToGetConfiguration: true,
                        contentTypeId: "interstitialUpsellContent",
                        field: "pageUrl",
                        value: id,
                        csr: true,
                    },
                    callbacks: { success: updateInterstitialPageContent, failure: redirectToOffersPage },
                });
            !id && redirectToOffersPage();
        }
        return () => {
            dispatch({ type: offersActions.SET_OFFERS_DETAILS, payload: { interstitialOffers: null } });
        };
    }, [sessionId]);
    const sectionStyle = {
        backgroundColor,
    };

    const containerStyle = {
        maxWidth: `${maxWidth}px`,
    };

    return (
        <>
            {interstitialPage && sessionId ? (
                <Interstitial
                    sectionStyle={sectionStyle}
                    basePageCSS={basePageCSS}
                    customCss={customCss}
                    bannerImage={bannerImage}
                    contents={contents}
                    onButtonClick={updateCart}
                    containerStyle={containerStyle}
                    id={id}
                />
            ) : (
                <Loader className="loader-wrapper d-flex justify-content-center align-items-center vh-100" isToShowLoader={true} />
            )}
        </>
    );
}

InterstitialUpsellPage.propTypes = {
    props: PropTypes.object,
};
