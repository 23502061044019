import { User } from "./user-model";
export class AccountDetails extends User {
    constructor(obj = {}) {
        super(obj);
        this.password = obj.password || "";
        this.isExistingFreeUser = obj.isExistingFreeUser || false;
        this.isNewUser = obj.isNewUser || false;
        this.createPartialProfile = obj.createPartialProfile || false;
        this.oid = obj.oid || "";
        this.entitlements = obj.entitlements || "";
        this.isClubUserCanPurchase = obj.isClubUserCanPurchase || "";
    }
}


