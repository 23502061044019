import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../../contentful/component-renderer";
import "./navigation-menu-item.scss";

export const NavigationMenuItem = ({ fieldId, fields }) => {
    const { isMobileOnly, mainMenuItem, subMenuItems } = fields;

    const getSubMenu = () => {
        const menuItems = [];

        if (subMenuItems) {
            for (const item in subMenuItems) {
                const menuItem = <li key={item}>{<FieldRenderer contents={subMenuItems[item]} />}</li>;
                menuItems.push(menuItem);
            }

            return <ul>{menuItems}</ul>;
        }
    };

    return (
        <Fragment>
            <li id={fieldId} className="bb-nmi" data-mobile-only={isMobileOnly}>
                {<FieldRenderer contents={mainMenuItem} />}
                {getSubMenu()}
            </li>
        </Fragment>
    );
};

NavigationMenuItem.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
};
