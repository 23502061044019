import React from "react";
import PropTypes from "prop-types";

const MinusIcon = ({ fill }) => {
    const styles = {
        fill: fill || "",
    };
    return (
        <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="2" style={styles} />
        </svg>
    );
};

MinusIcon.propTypes = {
    fill: PropTypes.string,
};

MinusIcon.defaultProps = {
    fill: "",
};

export default MinusIcon;
