import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { Popup } from "../../common/modal";
import { configureInlineCss } from "../../helper/util";
import videoSources from "./video-sources";
import { CloseButton } from "../cta-button/close-button";
import "./index.scss";

export const VideoContainer = ({ fields, fieldId, IsRenderVideo, isDesktopView, imageId = "", renderVideo, videoData = null, ...props }) => {
    const { videoSource, closeButton, videoInPopup = true } = fields;
    let reactPlayerLoader = null;
    const handleClose = () => {
        IsRenderVideo(false);
        handleVideoPopup(false);
    };
    const renderVideoPlayer = () => {
        const VideoSources = videoSources[videoSource];
        reactPlayerLoader = <VideoSources fields={fields} fieldId={fieldId} {...props} />;
        return reactPlayerLoader;
    };
    const handleVideoPopup = (show) => {
        //eslint-disable-next-line react/no-render-return-value
        const customCloseButton = closeButton ? <CloseButton content={closeButton} onButtonClick={handleClose} /> : null;
        reactPlayerLoader = process.env.BROWSER
            ? ReactDOM.render(
                  <Popup
                      show={show}
                      header={false}
                      customCloseButton={customCloseButton}
                      modalSize="md"
                      modalId={fieldId}
                      customModalClass="video-modal-popup"
                      width="100%"
                      height="100%"
                      handleClose={handleClose}
                      backdropClassName="video-backdrop"
                      customizedContent={renderVideoPlayer()}
                  />,
                  document.getElementById("video-container"),
              )
            : null;
    };
    const handleVideoRender = () => {
        //eslint-disable-next-line react/no-render-return-value
        reactPlayerLoader = process.env.BROWSER
            ? ReactDOM.render(
                  <div id={fieldId} className="video-player-container">
                      {renderVideoPlayer()}
                  </div>,
                  document.getElementById(imageId),
              )
            : null;
    };

    process.env.BROWSER && videoInPopup && handleVideoPopup(true);
    process.env.BROWSER && !videoInPopup && videoSource && renderVideoPlayer();
    process.env.BROWSER && !videoInPopup && renderVideo && handleVideoRender();
    !process.env.BROWSER && closeButton && configureInlineCss(closeButton, isDesktopView, true);
    !process.env.BROWSER && !videoInPopup && videoData && configureInlineCss(videoData, isDesktopView, true);

    return process.env.BROWSER ? <>{reactPlayerLoader}</> : <div></div>;
};

VideoContainer.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    renderVideo: PropTypes.bool,
    IsRenderVideo: PropTypes.func,
    imageId: PropTypes.string,
    isDesktopView: PropTypes.bool,
    videoData: PropTypes.object,
};
