/* eslint-disable no-unused-vars */
import { VISA_ICON, AMEX_ICON, DISCOVER_ICON, MASTERCARD_ICON } from "../constants/urls";

export enum CreditCardIconUrl {
    visa = VISA_ICON,
    amex = AMEX_ICON,
    discover = DISCOVER_ICON,
    mastercard = MASTERCARD_ICON,
}

export enum CreditCardIconLabel {
    visa = "Visa",
    amex = "American Express",
    discover = "Discover",
    mastercard = "Mastercard",
}
