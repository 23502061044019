import { DigitalData } from "../model";
import { getPageType } from "./util";

export const createDigitalData = ({ dd, path, environment = "" }) => {
    const env = environment ? environment : global.window ? window.environment : "dev";
    const digitalData = {
        ...dd,
        site: {
            env: env,
        },
        page: {
            pageInfo: { ...dd.page.pageInfo, pageName: path, pageID: path },
            category: {
                ...dd.page.category,
                pageType: getPageType(path),
            },
            attribute: {
                ...dd.page.attribute,
            },
        },
        pageInstanceID: `beachbody-${env}-${path}`,
    };
    return digitalData;
};
export const logProductTracking = (digitalData, isCheckout = false, productID = "", skuID = "") => {
    const data = createDigitalData({ dd: digitalData, path: "landing:bod:web:addtocart" });
    data.isCheckout = isCheckout;
    trackEvent(data, isCheckout, productID, skuID);
};

export const logCustomEvents = (name: string, action: string, id: any = "", data?: boolean, type?: string) => {
    const currentPath = process.env.BROWSER ? window.location.pathname : "";
    const pageData = "bod:web" + currentPath.split("/").join(":") + ":";
    const event = {
        eventInfo: {
            eventName: name,
            eventAction: action,
        },
        attributes: {
            source: data ? id : pageData + id,
        },
        event_name: name,
        event_type: type ? type : "click",
    };
    if (data) Object.assign(event, id);
    try {
        window.utag.link(event);
        console.log("Tealium : Custom Event Tracking : Data : ", pageData + id);
    } catch (e) {
        console.log("Tealium : Custom Event Tracking : ERROR : ", e);
    }
};
export const logVideoEvents = (event_name: string, video_id: string, video_length: number, video_platform: string, video_playhead?: number) => {
    const event = {
        tealium_event: event_name,
        video_id: video_id,
        video_length: video_length,
        video_platform: video_platform,
        video_playhead: video_playhead ? video_playhead : 0,
    };

    try {
        window.utag.link(event);
        console.log("Tealium : Video Event Tracking : Video : ", video_id);
    } catch (e) {
        console.log("Tealium : Video Event Tracking : ERROR : ", e);
    }
};
export const logLinkTracking = (source) => {
    const currentPath = process.env.BROWSER ? window.location.pathname : "";
    const pageData = "bod:web" + currentPath.split("/").join(":") + ":";
    const event = {
        eventInfo: {
            eventName: "link",
            eventAction: "link_tracking",
        },
        attributes: {
            source: pageData + source,
        },
    };
    try {
        window.utag && window.utag.link(event);
        console.log("Tealium Link Tracking : Source : ", pageData + source);
    } catch (e) {
        console.log("Link Tracking : ERROR : ", e);
    }
};

export const logDigitalData = (digitalData) => {
    if (!digitalData) {
        digitalData = new DigitalData();
    }
    const logData = () => {
        try {
            console.log("Page View Tracking : digitalData :", digitalData);
            digitalData.isView = true;
            window?.utag?.view(digitalData);
            window.digitalData = digitalData;
        } catch (e) {
            console.log("Page View Tracking : ERROR : ", e);
        }
    };

    window.utag && window.s ? logData() : setTimeout(() => logData(), 400);
};

export const trackEvent = (digitalData, isCheckout, productID, skuID) => {
    let event = {
        eventInfo: {
            eventName: isCheckout ? "cart" : "product",
            eventAction: isCheckout ? "add" : "view",
        },
        attributes: {},
    };
    event = isCheckout ? { ...event, attributes: { productID, skuID } } : event;

    digitalData.event = [event];

    console.log("Tealium Event Tracking: ", digitalData.event);

    logDigitalData(digitalData);
};

export const trackUserEmail = (emailAddress) => {
    utag.data._email = emailAddress;
    console.log("DigitalData : Capturing Email Address : ", emailAddress);
    //perform email validity check and trigger utag.link if email is valid
    if (utag.ut.validateEmail(emailAddress) && utag.data.cart_email !== "true") {
        utag.link({
            _email: emailAddress,
            customer_email_capture_point: "Cart",
        });
        utag.loader.SC("utag_main", {
            _email: emailAddress,
        });
    }
};
export const logProductData = (productID, skuID, plan, digitalData) => {
    const { offer_description, link_label } = digitalData;
    if (productID && skuID) {
        const dData = new DigitalData({ ...digitalData, cart: { item: [{ productInfo: { ...plan, productID, offer_description, link_label } }] } });
        dData && logProductTracking(dData, true, productID, skuID);
    }
};
