import React, { useEffect, useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { CarouselRefContext } from "../../carousel/index";
import { FieldRenderer } from "../../../contentful/component-renderer";
import { applyCloudinaryTransformations } from "../../../helper/util";
import { logVideoEvents } from "../../../web-analytics";

export const HostedContainer = ({ fields, fieldId, isActive, index }) => {
    const [isPlaying, setPlaying] = useState(false);
    const videoRef = useRef();
    const infiniteCarouselRef = useContext(CarouselRefContext);
    const {
        videoId,
        loopVideo = true,
        playInline = true,
        muteAudio = true,
        autoPlayVideo,
        playOnMouseOver,
        playButton,
        pauseButton,
        cloudinaryOptimizationEnabled,
        cloudinaryUrlOptimization,
        videoCoverImage,
        videoPoster,
        videoPosterCloudinary,
        videoCoverImageContentful,
    } = fields;

    const video_secure_url = fields.cloudinaryVideoSource ? fields.cloudinaryVideoSource[0].secure_url : "";
    const cloudinaryTransformedURL = video_secure_url
        ? applyCloudinaryTransformations(video_secure_url, cloudinaryOptimizationEnabled, cloudinaryUrlOptimization)
        : "";
    let videoPosterUrl = videoPoster ? "https://" + videoPoster.fields.file.url : "";
    if (videoPosterCloudinary) {
        videoPosterUrl = applyCloudinaryTransformations(videoPosterCloudinary[0].secure_url, true, "/q_auto");
    }
    let videoCoverImageUrl;
    if (videoCoverImageContentful) {
        videoCoverImageUrl = "https://" + videoCoverImageContentful.fields.file.url;
    } else if (videoCoverImage) {
        const cover_secure_url = fields.videoCoverImage ? fields.videoCoverImage[0].secure_url : "";
        videoCoverImageUrl = cover_secure_url ? applyCloudinaryTransformations(cover_secure_url, true, "/q_auto") : "";
    }
    const activateVideo = (onActive) => {
        const videos = document.getElementsByTagName("video");
        for (let index = 0; index < videos.length; index++) {
            const element = videos[index];
            if (!element.paused && !element.muted && !element.loop && !element.autoplay) {
                element.pause();
            }
        }
        onActive ? null : isPlaying ? videoRef?.current?.pause() : videoRef?.current?.play();
        if (!isPlaying) logVideoEvents("video_start", fields.cloudinaryVideoSource[0].public_id, fields.cloudinaryVideoSource[0].duration, "cloudinary", 0);
        setPlaying(!isPlaying);
    };

    const playVideo = (event) => {
        if (infiniteCarouselRef && infiniteCarouselRef.current.props.centerMode === true) {
            infiniteCarouselRef.current.slickGoTo(index);
        }

        if (event.type === "mouseenter" && playOnMouseOver) {
            !isPlaying && event.target.click();
            // playOnMouseOver && videoRef?.current?.play();
        } else if (event.type === "click") {
            if (
                (event.target === videoRef.current && typeof playButton === "undefined") ||
                (event.target === videoRef.current && typeof pauseButton === "undefined")
            ) {
                activateVideo();
            } else if (event.target !== videoRef.current) {
                activateVideo();
            }
        } else if (event.type === "mouseleave") {
            !isPlaying && videoRef?.current?.pause();
        }
    };
    useEffect(() => {
        isActive !== undefined ? (isActive ? videoRef?.current?.play() : videoRef?.current?.pause()) : null;
        setPlaying(isActive);
    }, [isActive]);
    useEffect(() => {
        setPlaying(!videoRef?.current?.paused);
    }, [videoRef?.current?.paused]);
    useEffect(() => {
        const handlePause = () => {
            setPlaying(!videoRef?.current?.paused);
        };

        const element = videoRef.current;
        element.addEventListener("pause", handlePause);

        return () => {
            element.removeEventListener("pause", handlePause);
        };
    }, []);
    return (
        <div className="hosted-video-container">
            <video
                id={fieldId}
                className={isPlaying ? "isPlaying" : ""}
                controls={false}
                autoPlay={autoPlayVideo}
                playsInline={playInline}
                muted={muteAudio}
                loop={loopVideo}
                onMouseEnter={(event) => playVideo(event)}
                onMouseLeave={(event) => playVideo(event)}
                onClick={(event) => playVideo(event)}
                ref={videoRef}
                style={{ opacity: isPlaying || !videoCoverImage ? "1" : "0" }}
                poster={videoPosterUrl}
            >
                <source src={cloudinaryTransformedURL ? cloudinaryTransformedURL : videoId} type="video/mp4" />
            </video>
            {videoCoverImageUrl && <img className={`video-cover-img ${isPlaying ? "hidden" : ""}`} src={videoCoverImageUrl} />}
            {!isPlaying && <FieldRenderer contents={playButton} onClick={playVideo} clientContext={true} />}
            {isPlaying && <FieldRenderer contents={pauseButton} onClick={playVideo} clientContext={true} />}
        </div>
    );
};

HostedContainer.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    isActive: PropTypes.bool,
    index: PropTypes.number,
};
