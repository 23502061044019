import { BEACHBODY_ON_DEMAND, TBB_SITE_URL } from "../../../../constants/urls";

const config = [
    {
        roles: ["REGISTEREDUSER", "COACH"],
        modal: "coachWithoutBOD",
        header: "tbb-coach-header",
        isExistingCoach: "true",
        signInUrl: TBB_SITE_URL,
        signInLabel: "sign-in-ttb-txt",
    },
    {
        roles: ["REGISTEREDUSER"],
        modal: "customerWithoutBOD",
        isExistingCoach: "false",
        header: "tbb-user-header",
        signInUrl: "",
        signInLabel: "label-bb-account-signin",
    },
    {
        roles: ["REGISTEREDUSER", "CLUB"],
        modal: "customerWithBOD",
        isExistingCoach: "false",
        header: "tbb-user-header",
        signInUrl: BEACHBODY_ON_DEMAND + "/hosted-login-page",
        signInLabel: "label-bod-account-signin",
    },
    {
        roles: ["CLUB", "COACH", "REGISTEREDUSER"],
        modal: "coachWithBOD",
        isExistingCoach: "true",
        header: "tbb-user-header",
        signInUrl: BEACHBODY_ON_DEMAND + "/hosted-login-page",
        signInLabel: "label-bod-account-signin",
    },
    {
        roles: ["PROGRAM_ENTITLEMENT"],
        modal: "customerWithPE",
        isExistingCoach: "false",
        header: "program-entitlement",
        signInUrl: BEACHBODY_ON_DEMAND + "/hosted-login-page",
        signInLabel: "label-bod-account-signin",
    },
];
// eslint-disable-next-line no-unused-vars
export const getConfig = (role, isExistingCoach) => {
    // const item = config.find((item) => {
    //     const roles = role.split(",");
    //     if (roles.length === item.roles.length) {
    //         return roles.every(role => {
    //             return item.roles.indexOf(role) >= 0 && isExistingCoach === item.isExistingCoach;
    //         });
    //     } else {
    //         return false;
    //     }
    // });
    // return item;
    const modalType = getConfigModal(role);
    const modal = config.find((item) => {
        return item.modal === modalType;
    });
    return modal;
};

export const getConfigModal = (role) => {
    if (role.indexOf("COACH") !== -1 && role.indexOf("CLUB") === -1) return "coachWithoutBOD";
    else if (role.indexOf("COACH") === -1 && role.indexOf("CLUB") !== -1) return "customerWithBOD";
    else if (role.indexOf("COACH") !== -1 && role.indexOf("CLUB") !== -1) return "coachWithBOD";
    else if (role.indexOf("PROGRAM_ENTITLEMENT") !== -1) return "customerWithPE";
    else return "customerWithoutBOD";
};
