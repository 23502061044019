import React, { Fragment } from "react";
import { CTAButton } from "../../common";
import PropTypes from "prop-types";
import { ErrorPanel, PayPalLogo } from "./components";

export function MyxCheckoutAction(props) {
    const {
        t,
        zipCodeLookUpError,
        apiFormErrors,
        APIFormErrMsgs,
        isCheckoutLandscape,
        btnDisable,
        onPlaceOrder,
        expressCheckout,
        securityContentModule,
        buttonText,
        isPaypalDefault,
        isCreditCardSelected,
    } = props || {};
    const showButtonInOrange = expressCheckout.continuity && expressCheckout.legalTermsCondition;
    const showPayPalLogo = isPaypalDefault && !isCreditCardSelected;
    const renderErrors = () => {
        return (
            <ul className="form-errors error-msg p-0" style={{ listStyle: APIFormErrMsgs && APIFormErrMsgs.length > 1 ? "inherit" : "none" }}>
                {APIFormErrMsgs &&
                    APIFormErrMsgs.map((error, index) => {
                        const { localizedMessage } = error || {};
                        return (
                            <li
                                key={index}
                                dangerouslySetInnerHTML={{
                                    __html: localizedMessage,
                                }}
                            ></li>
                        );
                    })}
            </ul>
        );
    };

    return (
        <Fragment>
            {zipCodeLookUpError && (
                <p
                    className="error-msg"
                    dangerouslySetInnerHTML={{
                        __html: t("cant-place-order"),
                    }}
                ></p>
            )}
            {apiFormErrors && <ErrorPanel errorMessage={renderErrors()} t={t} />}
            <Fragment>
                {isCheckoutLandscape && (
                    <CTAButton
                        buttonName={buttonText ? buttonText : t("place-order")}
                        outerBtnClass="place-order"
                        innerBtnClass={showButtonInOrange ? "orange-button" : ""}
                        onButtonClick={() => onPlaceOrder()}
                        disableButton={btnDisable ? { disabled: "disabled" } : null}
                        icon={btnDisable ? "fa fa-spinner fa-spin" : ""}
                        {...(showPayPalLogo && {
                            appendElem: (
                                <span className="ml-2">
                                    <PayPalLogo />
                                </span>
                            ),
                        })}
                    />
                )}
                {!isCheckoutLandscape && (
                    <CTAButton
                        buttonName={buttonText ? buttonText : t("place-order")}
                        outerBtnClass="place-order"
                        innerBtnClass={showButtonInOrange ? "orange-button" : ""}
                        onButtonClick={() => onPlaceOrder()}
                        disableButton={btnDisable ? { disabled: "disabled" } : null}
                        icon={btnDisable ? "fa fa-spinner fa-spin" : ""}
                        {...(showPayPalLogo && {
                            appendElem: (
                                <span className="ml-2">
                                    <PayPalLogo />
                                </span>
                            ),
                        })}
                    />
                )}
            </Fragment>
            {isCheckoutLandscape ? securityContentModule : null}
        </Fragment>
    );
}
MyxCheckoutAction.propTypes = {
    props: PropTypes.object,
    securityContentModule: PropTypes.node,
};
