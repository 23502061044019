import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../../../contentful/component-renderer";
import { CheckBox, CTAButton, TextInput } from "../../../../common";
import { PopupEmitter, TRIGGER_POPUP } from "../../../../common/popup-emitter";
import { ForgotPasswordPopup } from "./forgot-password-popup";
import { logLinkTracking } from "../../../../web-analytics";

export function UserLoginModal(props) {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [disableBtn, setDisableBtn] = useState(false);
    const [errorMessage, setError] = useState();
    const {
        loginModalContent: { fields },
        t,
        closePopup,
        onSignIn,
        email,
        cancelResetPassword,
    } = props || {};
    const {
        modalHeaderContent,
        modalBrandImg,
        modalContent,
        signInButtonText,
        signInButtonTextColor,
        signInButtonBgColor,
        useDifferentEmailButtonBgColor,
        useDifferentEmailButtonText,
        useDifferentEmailButtonTextColor,
    } = fields || {};
    const singnStyle = {
        backgroundColor: signInButtonBgColor,
        color: signInButtonTextColor,
    };
    const useDiffEmailStyle = {
        backgroundColor: useDifferentEmailButtonBgColor,
        color: useDifferentEmailButtonTextColor,
        border: `1px solid ${useDifferentEmailButtonTextColor}`,
    };
    const doSignIn = () => {
        if (password) {
            setDisableBtn(true);
            onSignIn(password, passwordMessage);
        } else {
            setError(t("password-required"));
            setDisableBtn(false);
        }
    };
    const onShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const onPasswordChange = (name, value) => {
        setError(null);
        setPassword(value);
    };
    const passwordMessage = (error, errormsg) => {
        setDisableBtn(false);
        error ? setError(errormsg === "invalid_credentials" ? t("invalid-credentials") : null) : null;
    };
    const triggerForgotPasswordConfirmationPopup = () => {
        logLinkTracking("login-modal:forgot-password-link");
        PopupEmitter.emit(TRIGGER_POPUP, {
            modalClass: "forgot-password-modal",
            closeButton: false,
            showPopup: true,
            modalId: "forgot-password:viewed",
            modalHeader: t("forgot-password-header"),
            customizedContent: (
                <ForgotPasswordPopup
                    t={t}
                    email={email}
                    cancelResetPassword={cancelResetPassword}
                    closePopup={closePopup}
                />
            ),
        });
    };
    return (
        <Fragment>
            <div>
                {modalBrandImg && (
                    <div className="brand-image">
                        <FieldRenderer contents={modalBrandImg} />
                    </div>
                )}
            </div>
            <div className="user-login-modal py-4">
                {modalHeaderContent && (
                    <div className="header-content">
                        <FieldRenderer contents={modalHeaderContent} />
                    </div>
                )}
                {
                    <div className="customer-without-bod text-left">
                        <p className="reg-email">{email}</p>
                        <TextInput
                            name="password"
                            id="password"
                            placeholder={t("password")}
                            type={showPassword ? "text" : "password"}
                            maxLength={20}
                            onChange={onPasswordChange}
                            dValue={password}
                        />
                        {errorMessage && (
                            <div className="error-msg paswword-error d-flex align-items-center mt-n1">
                                <span className="mb-n1">
                                    <i className="fa fa-times-circle mr-2" aria-hidden="true"></i>
                                </span>
                                {errorMessage}
                            </div>
                        )}
                        <div className="d-flex justify-content-between">
                            <div className="d-flex show-password">
                                <CheckBox name="show-password" id="show-password" onChange={onShowPassword} />
                                <p className="ml-2 show-password-text">{t("show-password")}</p>
                            </div>
                            <a onClick={() => triggerForgotPasswordConfirmationPopup()} className="forgot-password">
                                Forgot Password
                            </a>
                        </div>
                    </div>
                }
                {signInButtonText && (
                    <CTAButton
                        buttonName={signInButtonText}
                        onButtonClick={doSignIn}
                        disableButton={disableBtn ? { disabled: "disabled" } : null}
                        icon={disableBtn ? "fa fa-spinner fa-spin d-inline-block" : ""}
                        style={singnStyle}
                        trackId="login-modal"
                    />
                )}
                {modalContent && (
                    <div className="login-modal-content">
                        <FieldRenderer contents={modalContent} />
                    </div>
                )}
                {useDifferentEmailButtonText && (
                    <CTAButton
                        buttonName={useDifferentEmailButtonText}
                        onButtonClick={closePopup}
                        style={useDiffEmailStyle}
                        trackId="login-modal"
                    />
                )}
            </div>
        </Fragment>
    );
}
UserLoginModal.propTypes = {
    t: PropTypes.func,
    signInLabel: PropTypes.string,
    signInUrl: PropTypes.string,
};
