import React from "react";
import { FieldRenderer } from "../contentful/component-renderer";


export const PreviewRenderer = (props) => {
    // eslint-disable-next-line react/prop-types
    const path = process.env.BROWSER && props.location && props.location.pathname;
    const id = path ? path.split("\/")[1] : "";
    const { content } = props || {};
    return (
        // eslint-disable-next-line react/prop-types
        <div id={props.primaryProductCode || ""} className={`preview-template ${id}`} >
            <FieldRenderer contents={content} name="PageContent" {...props} />
        </div>
    );
};

export default PreviewRenderer;
