import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";
import "./scroll-snapping-wrapper.scss";

export const ScrollSnappingWrapper = ({ contents, ...props }) => {
    const { enableScrollSnap } = props?.data?.fields;
    const scrollSnapWrapperRef = useRef(null);
    let scrollSnap = enableScrollSnap;
    if (typeof scrollSnap === "undefined") scrollSnap = false;

    const normalObserverCallbackFn = (entries) => {
        entries?.forEach((entry) => {
            if (entry.isIntersecting) {
                if (scrollSnapWrapperRef?.current?.classList.contains("mandatory")) {
                    scrollSnapWrapperRef?.current?.classList.remove("mandatory");
                }
            }
        });
    };

    const snapObserverCallbackFn = (entries) => {
        entries?.forEach((entry) => {
            if (entry.isIntersecting && !scrollSnapWrapperRef?.current?.classList.contains("mandatory")) {
                scrollToSnapSection(entry?.target?.offsetTop);
            }
        });
    };

    function scrollToSnapSection(position) {
        const regularSections = document.querySelectorAll(".section-regular");
        return new Promise<void>((resolve) => {
            regularSections?.forEach((section: any) => {
                section.style.scrollSnapAlign = "none";
            });
            const scrollListener = (e) => {
                if ("undefined" === typeof e) {
                    return;
                }
                const target = e.currentTarget;
                if (target.scrollTop.toFixed() === position.toFixed()) {
                    target.removeEventListener("scroll", scrollListener);
                    resolve();
                }
            };
            scrollSnapWrapperRef?.current?.addEventListener("scroll", scrollListener);
            scrollSnapWrapperRef?.current?.scrollTo({
                top: position,
                behavior: "smooth",
            });
        }).then(() => {
            scrollSnapWrapperRef?.current?.classList.add("mandatory");
            regularSections?.forEach((section: any) => {
                section.style.scrollSnapAlign = "start";
            });
        });
    }

    useEffect(() => {
        if (contents && scrollSnap) {
            const regularSections = document?.querySelectorAll(".regular-observer");
            const snapSections = document?.querySelectorAll(".snap-observer");
            const normalObserver = new IntersectionObserver(normalObserverCallbackFn, { threshold: 0.01 });
            const snapObserver = new IntersectionObserver(snapObserverCallbackFn, { threshold: 0.2 });
            regularSections.forEach((section) => normalObserver.observe(section));
            snapSections.forEach((section) => snapObserver.observe(section));
        }
    }, []);

    return (
        <div className="scroll-snap-wrapper" ref={scrollSnapWrapperRef}>
            {contents &&
                contents.map((item, index) => {
                    const isFullScreenContent = item?.sys?.contentType?.sys?.id === "fullScreenContent";
                    return (
                        <section
                            key={index}
                            className={isFullScreenContent ? "section-snap snap-observer" : "section-regular regular-observer"}
                            id={item?.fields?.id}
                        >
                            <FieldRenderer contents={isFullScreenContent ? item?.fields?.content : item} />
                        </section>
                    );
                })}
        </div>
    );
};

ScrollSnappingWrapper.propTypes = {
    contents: PropTypes.any,
    data: PropTypes.any,
};
