import React from "react";
import { Fragment } from "react";
import PropTypes from "prop-types";
import "./message-toaster.scss";
export function ErrorMessageToaster({ message = "", messageHeading = "", showIcon = false }) {
    return (
        <Fragment>
            <div className="message-toaster">
                {showIcon && (
                    <div className="icon">
                        <i className="fa fa-times-circle"></i>
                    </div>
                )}
                <div className="message-container">
                    {messageHeading && <h6 className="message-heading">{messageHeading}</h6>}
                    <div className="message">{message}</div>
                </div>
            </div>
        </Fragment>
    );
}
ErrorMessageToaster.propTypes = {
    message: PropTypes.required,
    messageHeading: PropTypes.string,
    showIcon: PropTypes.bool,
};
