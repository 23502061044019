/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { CTAButton } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import { CreateAccount } from "../../../model";
import { validateField, validateForm } from "../../common/validator";
import { pageRouterActions } from "../../../component/page-router/data/page-router-action";
import { offersActions } from "../../offers-page/data/offers-page-action";
import { clearCookie, decodeURIHelper, getCookie, getUrlParam, setCookie } from "../../../helper/util";
import { encryptData, createPartialProfile, submitSurveyResponse, redirectWithParam } from "../../common/util";
import { EmailPanel, PasswordPanel, EmailLookupStatus } from "../components";
import { CHECKOUT_PATH, CREATE_ACCOUNT_PATH, OFFERS_PATH } from "../../../constants/urls";

export const CreateAccountWithLoginHandler = (props) => {
    const { t, contents } = props;
    const emailRef = useRef();
    const dispatch = useDispatch();
    const [isRegisteredUser, setRegisteredUser] = useState(false);
    const { sessionId } = useSelector((state) => state.offers);
    const { userDetail } = useSelector((state) => state.checkout);
    const [emailLookupStatus, setEmailLookupStatus] = useState(EmailLookupStatus.NOT_TRIGGED);
    const [doEmailLookup, setEmailLookup] = useState(false);
    const [accountFormData, setAccountFormData] = useState(new CreateAccount());
    const [formErrors, setAccountFormErrors] = useState(new CreateAccount());
    const [showPageLoader, setPageLoader] = useState(false);
    const [isCartAbandonmentFlow, setCartAbandonmentFlow] = useState(false);
    const [goToNextPage, redirectToNextPage] = useState(false);
    const oid = decodeURIHelper(getUrlParam("oid"));
    const onChange = (name, value) => {
        setAccountFormData({ ...accountFormData, [name]: value });
    };

    const setFormErrors = (name, value) => {
        setAccountFormErrors({ ...formErrors, [name]: value ? t(value) : "" });
    };
    const onCartDetailsFetchSuccess = (order, profile = {}) => {
        const { commerceItems } = order || {};
        if (profile) {
            const { email } = profile || {};
            if (email) {
                onChange("email", email);
                emailRef.current.triggerEmailLookup(email);
            }
        } else if (commerceItems instanceof Array && commerceItems.length === 0) {
            //if no commerceId passed, User will remain in create account page
            removeCartAbondonementId();
        }
    };
    const onCartDetailFetchFailure = () => {
        removeCartAbondonementId();
        window.location = "/";
    };
    const removeCartAbondonementId = () => {
        sessionStorage.removeItem("oid");
        setCartAbandonmentFlow(false);
    };

    const validateFormErrors = () => {
        const { email, password } = accountFormData;
        const errors = validateForm({ email, password }, t);
        if (errors) {
            setAccountFormErrors({ ...formErrors, ...errors });
            return true;
        } else {
            setAccountFormErrors(new CreateAccount());
            return false;
        }
    };
    const encryptUserData = () => {
        setPageLoader(true);
        const { email, password } = accountFormData;
        const userDetails = { ...userDetail, email, password };
        dispatch({ type: pageRouterActions.SET_USER_DETAILS, payload: userDetails });
        const userData = JSON.stringify(userDetails);
        const encipheredData = encryptData(userData);
        const date = new Date();
        date.setTime(date.getTime() + 60 * 60 * 1000);
        setCookie("userData", encipheredData, date);
    };
    const goToOffers = () => {
        encryptUserData();
        redirectWithParam(props.history, OFFERS_PATH);
    };
    const goToCheckout = () => {
        encryptUserData();
        props.history.push(`/${CHECKOUT_PATH}`);
    };
    const redirectPage = () => {
        if (isCartAbandonmentFlow) {
            goToCheckout();
        } else {
            goToOffers();
        }
    };
    const submitSurveyData = () => {
        submitSurveyResponse(dispatch);
    };
    const onContinueBtn = () => {
        if (emailLookupStatus === EmailLookupStatus.FAILED) {
            !doEmailLookup && setEmailLookup(true);
            emailRef.current.triggerEmailLookup(accountFormData["email"]);
            return;
        }
        const hasError = validateFormErrors();
        if (!hasError && emailLookupStatus === EmailLookupStatus.COMPLETED) {
            if (isCartAbandonmentFlow) {
                goToCheckout();
            } else {
                userDetail?.isNewUser ? createPartialProfile({ dispatch, payload: accountFormData, callback: submitSurveyData }) : submitSurveyData();
                goToOffers();
            }
        }
    };
    useEffect(() => {
        if (sessionId) {
            if (oid) {
                sessionStorage.setItem("oid", oid);
                dispatch({ type: pageRouterActions.SET_USER_DETAILS, payload: { oid } });
                setCartAbandonmentFlow(true);
                //logUserAction({ dispatch, payload: { abandonmentOrderId: oid, message: "Cart Abondonment Flow" } });
                dispatch({
                    type: offersActions.GET_CART_ABANDONED_ORDER_DETAILS,
                    payload: { oid },
                    callbacks: { success: onCartDetailsFetchSuccess, failure: onCartDetailFetchFailure },
                });
            } else {
                sessionStorage.removeItem("oid");
            }
        }
    }, [sessionId]);
    useEffect(() => {
        if (!oid) {
            redirectWithParam(props.history, CREATE_ACCOUNT_PATH);
        }
        //Remove older user data from session
        const userData = getCookie("userData");
        if (userData) {
            clearCookie(["userData"]);
            dispatch({ type: pageRouterActions.SET_USER_DETAILS, payload: null });
        }
    }, []);
    useEffect(() => {
        if (goToNextPage) {
            redirectPage();
        }
    }, [goToNextPage]);

    const formProps = {
        t,
        onChange,
        setFormErrors,
        formErrors: formErrors,
        formData: accountFormData,
        validateField,
        setEmailLookupStatus,
        onCartDetailsFetchSuccess,
        onCartDetailFetchFailure,
        isRegisteredUser,
        setRegisteredUser,
        redirectToNextPage,
        isCartAbandonmentFlow,
        setCartAbandonmentFlow,
        oid,
        removeCartAbondonementId,
        emailLookupStatus,
    };
    return (
        <section className="create-account-content d-flex">
            <h2 className="heading">{t("create-account")}</h2>
            <div className=" account-container w-100">
                <EmailPanel ref={emailRef} {...formProps} loginModalContent={contents} />
                <PasswordPanel {...formProps} />
            </div>
            {emailLookupStatus === EmailLookupStatus.FAILED && doEmailLookup && (
                <p
                    className="error-msg"
                    dangerouslySetInnerHTML={{
                        __html: t("email-lookup-fail"),
                    }}
                ></p>
            )}
            <CTAButton
                outerBtnClass="continue-btn"
                buttonName={t("continue-btn")}
                onButtonClick={onContinueBtn}
                icon={showPageLoader ? "fa fa-spinner fa-spin" : ""}
            />
            <div
                className="user-acceptance-text"
                dangerouslySetInnerHTML={{
                    __html: t("create-acct-disclaimer"),
                }}
            ></div>
        </section>
    );
};
CreateAccountWithLoginHandler.propTypes = {
    props: PropTypes.object,
};
