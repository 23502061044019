import React from "react";
import PropTypes from "prop-types";
import { BaseOffersTile } from "./base-offer-tile";
import { useLocalizationLookup } from "../../../localization";
export const PlansList = (props) => {
    const { plan = {}, t } = props;
    const { stamp, subtitle1, marketingValue, subtitle2 } = plan;
    const { showCurrencyInPrefix } = useLocalizationLookup();
    const stampStyle = {
        border: stamp ? "3px solid rgb(252, 232, 96)" : "none",
        borderRadius: "3px",
    };
    const marketingValueDecimal = marketingValue && marketingValue.split(".")[0];
    const marketingValueSubDecimal = marketingValue && marketingValue.split(".")[1];
    return (
        <BaseOffersTile outerDivStyle="free-trial-box" {...props}>
            {({ showCTAButton }) => {
                return (
                    <div className={`offer-plan-tile ${stamp ? "stamp-tile" : ""}`} style={stampStyle}>
                        <div className="price-details">
                            {stamp && <div className="stamp">{plan.stamp.altText}</div>}
                            <div className={`d-flex flex-column ${stamp ? "mt-n3" : ""}`}>
                                <div className="price-container">
                                    {marketingValue && (
                                        <div className="price">
                                            {showCurrencyInPrefix && <div className="dollar">{t("currency-symbol")}</div>}
                                            <div className="dollars">
                                                {marketingValueDecimal}
                                                {marketingValueSubDecimal && <span className="dec-amt">.{marketingValueSubDecimal}</span>}
                                                <span className="term-amt">{t("plan-tenure").replace("month", "mo")}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="title-container">
                                    {subtitle2 && (
                                        <div
                                            className="description"
                                            dangerouslySetInnerHTML={{
                                                __html: subtitle2,
                                            }}
                                        ></div>
                                    )}
                                </div>
                                <div className="cta-container">{showCTAButton()}</div>
                                {subtitle1 && (
                                    <div
                                        className="description"
                                        dangerouslySetInnerHTML={{
                                            __html: subtitle1,
                                        }}
                                    ></div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            }}
        </BaseOffersTile>
    );
};

PlansList.propTypes = {
    fields: PropTypes.object,
    plan: PropTypes.object,
    t: PropTypes.func,
};
