import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

const ImageZoom = ({ img }) => {
    const [zoom, setZoom] = useState(false);
    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);
    const imageRef = useRef(null);

    const handleMouseOver = () => {
        setZoom(true);
    };

    const handleMouseOut = () => {
        setZoom(false);
    };

    const handleMouseMovement = (e) => {
        const { left: offsetLeft, top: offsetTop } = imageRef.current.getBoundingClientRect();
        let x = ((e.pageX - offsetLeft) / 500) * 100;
        const y = ((e.pageY - offsetTop) / 500) * 100;
        if (x > 100) x = 100;
        setMouseX(x);
        setMouseY(y);
    };

    return (
        <div className="img-outer-container" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onMouseMove={handleMouseMovement} ref={imageRef}>
            <div
                style={{
                    backgroundImage: `url('${img}')`,
                    transformOrigin: `${mouseX}% ${mouseY}%`,
                    transform: zoom ? "scale(2.5)" : "scale(1.0)",
                }}
                className="img-inner-container"
            />
        </div>
    );
};

ImageZoom.propTypes = {
    img: PropTypes.string.isRequired,
    zoomScale: PropTypes.number.isRequired,
};

export default ImageZoom;
