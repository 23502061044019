import React, { Fragment } from "react";
import PropTypes from "prop-types";
import footer from "./templates";

export const Footer = ({ fields }) => {
    const { template, ...props } = fields;
    const FooterRenderer = footer[template];
    return <Fragment>{FooterRenderer ? <FooterRenderer fields={props} /> : null}</Fragment>;
};
Footer.propTypes = {
    fields: PropTypes.object,
};
