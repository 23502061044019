import configData from "../../tools/config";

export const BEACHBODY_ON_DEMAND = configData.BEACHBODY_ON_DEMAND;
export const BEACHBODY_ON_DEMAND_API_URL = configData.BEACHBODY_ON_DEMAND_API_URL;
export const BEACHBODY_ON_DEMAND_API_URL_API_KEY = configData.BEACHBODY_ON_DEMAND_API_URL_API_KEY;
export const BEACHBODY_ON_DEMAND_MESSAGE_API_URL = configData.BEACHBODY_ON_DEMAND_MESSAGE_API_URL;
export const BEACHBODY_ON_DEMAND_MESSAGE_API_KEY = configData.BEACHBODY_ON_DEMAND_MESSAGE_API_KEY;
export const CC_ICON_URL = "https://img1.beachbodyimages.com/teambeachbody/image/upload/v1604999267/BODDirect/icons/cc-icon.png";
export const CC_IFRAME_URL = configData.CC_IFRAME_URL;
export const CREATE_ACCOUNT_PATH = "create-account";
export const CREATE_PASSWORD_PATH = "create-password";
export const CHECKOUT_PATH = "checkout";
export const COACH_OPT_IN_CONFIRMATION = "confirmation/coach-confirmed";
export const COACH_UPSELL_CONFIRMATION = "confirmation/coach-support";
export const COACH_NO_UPSELL_CONFIRMATION = "welcome-confirmed";
export const CONFIRMATION_PASSWORD_PATH = "confirmation-password";
export const CONFIRMATION_PATH = "confirmation";
export const CONFIRMATIONPATHS = [
    COACH_OPT_IN_CONFIRMATION,
    COACH_UPSELL_CONFIRMATION,
    COACH_NO_UPSELL_CONFIRMATION,
    CONFIRMATION_PASSWORD_PATH,
    CONFIRMATION_PATH,
];
export const DEFAULT_MYX_PRODUCT_CODE = "BODiStandaloneTablet";
export const DEFAULT_OFFER_CODE = "BODStandaloneDirect";
export const DEFAULT_ODP_CODE = "BODiOneDayPassDR";
export const DEFAULT_PRODUCT_CODE = "GEN";
export const defaultLanguage = "en_US";
export const FLAG_CA = "https://img1.beachbodyimages.com/teambeachbody/image/upload/t_en_CA/flag_icons/World-Flags.png";
export const FLAG_FR = "https://img1.beachbodyimages.com/teambeachbody/image/upload/t_fr_FR/flag_icons/World-Flags.png";
export const FLAG_GB = "https://img1.beachbodyimages.com/teambeachbody/image/upload/t_en_GB/flag_icons/World-Flags.png";
export const FLAG_MX = "https://img1.beachbodyimages.com/teambeachbody/image/upload/t_es_MX/flag_icons/World-Flags.png";
export const FLAG_US = "https://img1.beachbodyimages.com/teambeachbody/image/upload/t_en_US/flag_icons/World-Flags.png";
export const MYX_ACTIVATE_BIKE_PATH = "/activatebike";
export const MYX_ACTIVATION_SUCCESS = "/activatebike/activation-success";
export const MYX_CHECKOUT_PATH = "/activatebike/checkout";
export const MYX_CREATE_ACCOUNT_PATH = "/activatebike/create-account";
export const MYX_EDIT_EMAIL_PATH = "/activatebike/edit-email";
export const MYX_FORGOT_PASSWORD_PATH = "/activatebike/forgot-password";
export const MYX_LOGIN_PATH = "/activatebike/login";
export const MYX_ORDER_CONFIRMATION_PATH = "/activatebike/order-confirmation";
export const MYX_RESET_PASSWORD_PATH = "/activatebike/reset-password";
export const MYX_SESSION_EXPIRED_PATH = "/activatebike/session-expired";
export const INTERSTITIAL_PAGE = "special";
export const NON_CLUB_MYX_PRODUCT_CODE = "BODStandaloneTablet";
export const ODP_FLOW = "ODP_FLOW";
export const OFFERS_PATH = "offers";
export const PWD_EYE_INVISIBLE = "https://img1.beachbodyimages.com/beachbody/image/upload/v1634662739/rand-eng/eye-no-slash.svg";
export const PWD_EYE_VISIBLE = "https://img1.beachbodyimages.com/beachbody/image/upload/v1634662739/rand-eng/eye-with-slash.svg";
export const SESSION_TIME_LIMIT = 3600000;
export const SITE_ID = "BB-USA";
export const TABLET_FLOW = "TABLET_FLOW";
export const TABLET_FLOW_ORIGIN_SYSTEM = "BBMyxTablet";
export const TBB_SITE_URL = "https://teambeachbody.com";
export const WSC_MYX_BOD_BIKE = "MYX_BOD_BIKE";
export const PAYPAL_ICON = "https://img1.beachbodyimages.com/beachbody/image/upload/v1635183461/rand-eng/paypal_icon.png";
export const VISA_ICON = "https://img1.beachbodyimages.com/beachbody/image/upload/v1638204678/rand-eng/commerce_payment_visa.svg";
export const AMEX_ICON = "https://img1.beachbodyimages.com/beachbody/image/upload/v1638204678/rand-eng/commerce_payment_american-express.svg";
export const MASTERCARD_ICON = "https://img1.beachbodyimages.com/beachbody/image/upload/v1638204678/rand-eng/commerce_payment_mastercard.svg";
export const DISCOVER_ICON = "https://img1.beachbodyimages.com/beachbody/image/upload/v1638204678/rand-eng/commerce_payment_discover.svg";
export const YOUTUBE_EMBED_URL = "https://www.youtube.com/embed/";
export const YOUTUBE_EMBED_URL_PARAMS = "&autoplay=1&showinfo=0&loop=1&playsinline=1&mute=1&controls=0&loop=1&origin=http://teambeachbody.com";
export const CONTENTFUL_CONTENT_ID_BB = "appDefaultPage";
export const CONTENTFUL_CONTENT_ID_BBBIKES = "bikesDefaultPage";
export const DOMAIN_BBBIKES = "beachbodybikes";
export const DOMAIN_BBBIKES_LOCAL = "bbbikes";
export const BIKES_PDP_PATH = "products";
export const BIKES_PDP_IMG_PATH = "https://img1.beachbodyimages.com/teambeachbody/image/upload/";
export const REGION_CONFLICT_POPUP = "region_conflict_popup";
export const DEFAULT_FUNNEL = "default-funnel";
