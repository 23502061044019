import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { PopupEmitter, TRIGGER_POPUP } from "../../common/popup-emitter";
import { useSelector } from "react-redux";
import { FieldRenderer } from "../../contentful/component-renderer";
import { configureInlineCss, convertSCSSToCSS, customClick, renderBaseName } from "../../helper/util";
import "./index.scss";
import { ModalContentElement } from "../../module/create-account/components/modal/footer-modal-content";
import { click_get_app_link } from "../../constants/tealium-tags";
import { logCustomEvents } from "../../web-analytics";

export const ReferenceLinks = ({ fields, fieldId, RichText }) => {
    const userContinent = window.$userContinent || "na";
    const { isDesktopView, popupContent, locale } = useSelector((state) => state.pageRouter);
    const {
        linkId,
        linkOnClick,
        linkText,
        linkTextColor,
        linkTextInnerHtml,
        linkTitle,
        modalCloseButton,
        modalContent,
        modalContentCss,
        modalSize,
        osanoName,
        osanoRegion,
        popupModalContent,
        renderModalContentFromExternal = false,
        target,
        urlPath,
        title,
    } = fields;
    const popupFieldId = `popup-${fieldId}`;
    const triggerLinkEvent = (event) => {
        const path = process.env.BROWSER ? window.location.pathname : "";
        if (path.indexOf("/product/") !== -1) {
            const node = event.target.closest(`${"#" + fieldId}`);
            const eventData = customClick(node, fieldId, title, linkId);
            logCustomEvents(click_get_app_link, "click", eventData, true, "link_tracking");
        }
    };
    const onBaseLinkClick = (event) => {
        triggerLinkEvent(event);
        eval(linkOnClick);
    };
    const onPopupLinkClick = (event) => {
        triggerLinkEvent(event);
        renderModalContentFromExternal ? modalContentFromExternal() : renderModalContent();
    };
    const modalContentFromExternal = () => {
        const content = linkId && popupContent && popupContent[linkId] ? <ModalContentElement content={popupContent[linkId]} /> : null;
        PopupEmitter.emit(TRIGGER_POPUP, {
            closeButton: true,
            customizedContent: content,
            modalClass: "conditions-and-policy",
            modalContent,
            modalHeader: linkTitle,
            modalId: linkTitle ? linkTitle.split(" ").join("-").toLowerCase() : popupFieldId,
            modalSize,
            showPopup: true,
        });
    };
    const renderModalContent = () => {
        const handleClose = () => {
            PopupEmitter.emit(TRIGGER_POPUP, { showPopup: false });
        };
        const content = popupModalContent ? <FieldRenderer contents={popupModalContent} /> : null;
        const customCloseButton = modalCloseButton ? <FieldRenderer contents={modalCloseButton} onButtonClick={handleClose} /> : null;
        PopupEmitter.emit(TRIGGER_POPUP, {
            backdrop: true,
            backdropClassName: "content-managed-popup-backdrop",
            customCloseButton: customCloseButton,
            customizedContent: content,
            header: false,
            modalClass: "content-managed-popup",
            modalId: popupFieldId,
            showPopup: true,
        });
    };
    const createBaseLink = () => {
        return (
            <Fragment>
                <a
                    className="reference-links"
                    href={urlPath && urlPath.startsWith("/") ? `${renderBaseName(locale)}${urlPath}` : urlPath}
                    id={fieldId}
                    onClick={onBaseLinkClick}
                    style={{ color: linkTextColor ? linkTextColor : "" }}
                    target={target}
                    title={linkTitle}
                    data-osano-name={osanoName}
                    data-osano-region={osanoRegion}
                >
                    {linkTextInnerHtml ? <RichText content={linkTextInnerHtml} /> : linkText}
                </a>
            </Fragment>
        );
    };
    const createDoNotSellLink = () => {
        let linkPrinted = false;
        if (!linkPrinted && userContinent === "eu" && osanoRegion && osanoRegion[0] === userContinent) {
            linkPrinted = true;
            return <Fragment>{createBaseLink()}</Fragment>;
        } else if (!linkPrinted && userContinent !== "eu" && !osanoRegion) {
            linkPrinted = true;
            return <Fragment>{createBaseLink()}</Fragment>;
        } else {
            linkPrinted = false;
            return <Fragment></Fragment>;
        }
    };
    const createPopupLink = () => {
        const styles = {
            color: linkTextColor ? linkTextColor : "",
            cursor: "pointer",
        };
        return (
            <Fragment>
                <a
                    className="reference-links"
                    id={fieldId}
                    onClick={onPopupLinkClick}
                    style={styles}
                    target={target}
                    title={linkTitle}
                    data-osano-name={osanoName}
                    data-osano-region={osanoRegion}
                >
                    {linkTextInnerHtml ? <RichText content={linkTextInnerHtml} /> : linkText}
                </a>
                {!process.env.BROWSER && popupModalContent && (
                    <div style={{ display: "none" }}>
                        <FieldRenderer contents={popupModalContent} />
                    </div>
                )}
            </Fragment>
        );
    };
    //configuring styles on page load
    !process.env.BROWSER && popupModalContent && convertSCSSToCSS({ fieldId: popupFieldId, customCss: modalContentCss });
    !process.env.BROWSER && modalCloseButton && configureInlineCss(modalCloseButton, isDesktopView, true);
    return <Fragment>{osanoName === "DoNotSell" ? createDoNotSellLink() : target === "modal" ? createPopupLink() : createBaseLink()}</Fragment>;
};

ReferenceLinks.propTypes = {
    fieldId: PropTypes.string,
    fields: PropTypes.object,
    RichText: PropTypes.func,
};
