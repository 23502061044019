import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import "./locale-switcher.scss";
import { languagesSupported } from "../../constants/app-languages";

export const LocaleSwitcher = ({ fieldId, fields }) => {
    const { display, localeOptions = [], theme } = fields;
    const [toggleLanguageMenu, setToggleForLanguageMenu] = useState(false);
    const { hostUrl, locale, sites = [] } = useSelector((state) => state.pageRouter);
    const isMYX = theme === "myx-theme" ? true : false;
    const toggleSitesDropDown = (e) => {
        typeof e.target.matches === "function" && !e.target.matches(".bb-ls-btn") && setToggleForLanguageMenu(false);
    };
    function getLocale() {
        try {
            return locale || localStorage.getItem("i18nextLng");
        } catch (exception) {
            console.log("[:EXCEPTION:] Locale Switcher component", exception);
        }
    }
    function getSelectedLanguage() {
        const language = languagesSupported && languagesSupported.find((language) => language.code === getLocale());
        return language.flag;
    }
    function showDropdown() {
        return (localeOptions.length > 0 && localeOptions.some((option: any) => sites.indexOf(option) !== -1) && isMYX) || !isMYX;
    }
    function getSiteURL(code, topLevelDomain) {
        let url = "";
        if (hostUrl.indexOf("localhost") === -1) {
            const urlSplit = hostUrl && hostUrl.split(".");
            urlSplit.pop();
            if (locale === "en_GB") urlSplit.pop();
            urlSplit.push(topLevelDomain);
            url = urlSplit instanceof Array ? urlSplit.join(".") : "";
        }
        const { pathname, search } = window.location;
        const isProductLanding = pathname.indexOf("/product") !== -1;
        const language = code && code.split("_")[0];
        const currentLanguage = getLocale().split("_")[0];
        let path = isProductLanding ? pathname.split("/product") : pathname.split(`/${currentLanguage}`);
        path = `${isProductLanding ? "/product" : ""}${path && path.length && path.length > 1 ? `${path[1]}` : `${path[0]}`}`;
        return `${url ? url : hostUrl}/${language ? language : ""}${path ? `${path}${search}` : ""}`;
    }
    useEffect(() => {
        window.addEventListener("click", toggleSitesDropDown);
        return () => {
            window.removeEventListener("click", toggleSitesDropDown);
        };
    }, []);
    return (
        <div id={fieldId} className={display ? "bb-ls" : "bb-ls hide"}>
            {showDropdown() && (
                <button
                    className="bb-ls-btn"
                    onClick={() => {
                        setToggleForLanguageMenu(!toggleLanguageMenu);
                    }}
                    style={{ backgroundImage: `url(${getSelectedLanguage()})` }}
                ></button>
            )}
            {toggleLanguageMenu && (
                <div id={`localeDropdown-${fieldId}`} className={`bb-ls-dd ${theme || "bb-standard-theme"}`}>
                    {languagesSupported &&
                        languagesSupported.map((languageDetails) => {
                            const { language, code, flag, topLevelDomain } = languageDetails || {};
                            const element = (
                                <a key={language.replace(" ", "_")} href={`${getSiteURL(code, topLevelDomain)} `} target="_self">
                                    <img src={flag} alt={language} width="25" />
                                    {language}
                                </a>
                            );
                            return (localeOptions.length > 0 && localeOptions.indexOf(code) !== -1 && sites.indexOf(code) !== -1) ||
                                (localeOptions.length <= 0 && sites.indexOf(code) !== -1)
                                ? element
                                : null;
                        })}
                </div>
            )}
        </div>
    );
};

LocaleSwitcher.propTypes = {
    fieldId: PropTypes.string,
    fields: PropTypes.object,
};
