import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import useMedia from "use-media";
import { CarouselProvider, Slider, ButtonBack, ButtonNext, DotGroup, Dot } from "pure-react-carousel";
import { useSwipeable } from "react-swipeable";
import { LoadMore } from "./load-more";
import { convertSCSSToCSS, getSiteLocale, logCarouselEvents, applyCloudinaryTransformations } from "../../../helper/util";
import { defaultLanguage } from "../../../constants/urls";
import { languagesSupported } from "../../../constants/app-languages";
import "./carousel-basic-slider.scss";
import { logCustomEvents } from "../../../web-analytics";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import { carousel_card_click, carousel_view_more } from "../../../constants/tealium-tags";
import { AnimateSection } from "../../common/animation";
const DEFAULT_IMAGE_NEXT_ARROW = "//images.ctfassets.net/imp192nihlxo/4a8u0aj1fdM5j2SzNim2GK/112d7d49e8f19b4a9e93313c9216de20/Next.png";
const DEFAULT_IMAGE_PREVIOUS_ARROW = "//images.ctfassets.net/imp192nihlxo/4X5KuvYJDZwQ71fDVTQIRr/57f9e214de719f2163e854faa06418dd/Previous.png";

export const CarouselBasicSlider = ({ fields, fieldId, FieldRenderer, isDesktopView }) => {
    const {
        topContainer,
        isArrowsVisible = false,
        invertArrowColor,
        carouselPreviousArrowBackgroundImgUrl,
        carouselNextArrowBackgroundImgUrl,
        arrowWidth = 64,
        isDotsVisible = true,
        defaultSlideHeight,
        mobileSlideHeight,
        carouselContent,
        carouselStyleType,
        automaticScrollTimer = 5,
        enableInfiniteScroll = true,
        whatSizeLoadMoreWillAppear,
        maximumSlidesNumberToDisplayInDesktop = 5,
        title,
        enableAnalyticsTracking = false,
        carouselPreviousArrowBackgroundImg,
        carouselNextArrowBackgroundImg,
        controlDotActiveImage,
        controlDotInActiveImage,
        carouselDotCss,
        controlDotActiveImageUrl,
        controlDotInactiveImageUrl,
        animationClass,
        setCarouselBGtoSlideBG,
        fallbackCarouselBg,
    } = fields;
    const [activeIndex, setActiveIndex] = useState(0);
    const [isPlaying, setPlaying] = useState(!!automaticScrollTimer);
    const [countryLanguage, setCountryLanguage] = useState(defaultLanguage);
    const carouselRef = useRef(null);
    const [carouselContainerRef] = useIntersectionObserver(
        {
            root: null,
            rootMargin: "100px",
            threshold: 1,
        },
        () => {
            if (!enableAnalyticsTracking) {
                return;
            }
        },
    );
    useEffect(() => {
        function onChange() {
            if (activeIndex !== carouselRef.current.carouselStore.getStoreState().currentSlide) {
                setActiveIndex(carouselRef.current.carouselStore.getStoreState().currentSlide);
                if (automaticScrollTimer === 0) pauseVideos();
            }
        }
        carouselRef.current.carouselStore.subscribe(onChange);
        return () => carouselRef.current.carouselStore.unsubscribe(onChange);
    }, [activeIndex, carouselRef]);
    useEffect(() => {
        setCountryLanguage(getSiteLocale());
    }, []);
    const { ref: swipeRef } = useSwipeable({
        onSwipedLeft: () => {
            handleArrowsClickTracking("right");
        },
        onSwipedRight: () => {
            handleArrowsClickTracking("left");
        },
    });

    const dotId = `dots-${fieldId}`;

    const isLessThanExtraSmall = useMedia({ maxWidth: "480px" });
    const isLessThanSmall = useMedia({ minWidth: "481px", maxWidth: "768px" });
    const isLessThanMedium = useMedia({ minWidth: "769px", maxWidth: "992px" });
    const mediumOrLess = useMedia({ maxWidth: "992px" });
    const isLessThanLarge = useMedia({ minWidth: "993px", maxWidth: "1024px" });
    const isLessThanExtraLarge = useMedia({ minWidth: "1025px", maxWidth: "1200px" });
    const isLessThanHuge = useMedia({ minWidth: "1201px" });
    const isMultipleSlidesPerView = carouselStyleType === "multiple-slides-per-view";

    const visibleSlidesValue = () => {
        if (isLessThanHuge && isMultipleSlidesPerView) return maximumSlidesNumberToDisplayInDesktop;
        if (isLessThanExtraLarge && isMultipleSlidesPerView) return maximumSlidesNumberToDisplayInDesktop;
        if (isLessThanLarge && isMultipleSlidesPerView) return maximumSlidesNumberToDisplayInDesktop;
        if (isLessThanMedium && isMultipleSlidesPerView) return 3;
        if (isLessThanSmall && isMultipleSlidesPerView) return 2;
        if (isLessThanExtraSmall && isMultipleSlidesPerView) return 1;
        return 1;
    };

    const getMinusPercentage = () => {
        if (isLessThanMedium && isMultipleSlidesPerView) return 10;
        if (isLessThanSmall && isMultipleSlidesPerView) return 5;
        if (isLessThanExtraSmall && isMultipleSlidesPerView) return 150;
        return 1;
    };

    const loadMoreBreakpoint = () => {
        if (whatSizeLoadMoreWillAppear === "Huge") return isLessThanHuge;
        if (whatSizeLoadMoreWillAppear === "Extra Large") return isLessThanExtraLarge;
        if (whatSizeLoadMoreWillAppear === "Large") return isLessThanLarge;
        if (whatSizeLoadMoreWillAppear === "Medium") return isLessThanMedium;
        if (whatSizeLoadMoreWillAppear === "Small") return isLessThanSmall;
        if (whatSizeLoadMoreWillAppear === "Extra Small") return isLessThanExtraSmall;
        return false;
    };
    const slideItem = { height: isDesktopView ? defaultSlideHeight : mobileSlideHeight || defaultSlideHeight };

    const orderedSlides = useMemo(
        () =>
            carouselContent
                .filter((slide) => {
                    if (slide.sys.contentType.sys.id === "carouselContent" || slide.sys.contentType.sys.id === "programItem") {
                        const locales = languagesSupported.map((language) => language.code);
                        const allowedFields = slide.fields.filterByLocale || locales;
                        return allowedFields.includes(countryLanguage);
                    }
                    return true;
                })
                .sort((slideAfter, slideBefore) => (slideBefore.fields.orderPriorityValue || 0) - (slideAfter.fields.orderPriorityValue || 0)),
        [carouselContent],
    );

    if (loadMoreBreakpoint()) {
        return <LoadMore fields={fields} fieldId={fieldId} />;
    }

    const onTouchCarousel = () => {
        if (isPlaying && isLessThanExtraSmall) {
            setPlaying(false);
        }
    };
    const pauseVideos = () => {
        const videos = document.getElementsByTagName("video");

        for (let index = 0; index < videos.length; index++) {
            const element = videos[index];

            if (!element.paused && !element.muted && !element.loop && !element.autoplay) {
                element.pause();
            }
        }
    };

    const handleArrowsClickTracking = (direction) => {
        if (!enableAnalyticsTracking) {
            return;
        }

        const eventId = `${title.replace(/ /g, "")}:${direction}`;
        const eventData = logCarouselEvents(eventId);
        logCustomEvents(carousel_view_more, direction, eventData, true, "link_tracking");
    };
    const handleCardClickTracking = (cardTitle) => {
        if (!enableAnalyticsTracking) {
            return;
        }
        const eventId = `${title.replace(/ /g, "")}:${cardTitle.replace(/ /g, "")}`;
        const eventData = logCarouselEvents(eventId);
        logCustomEvents(carousel_card_click, "click", eventData, true, "link_tracking");
    };
    const slideCount = carouselContent.length;
    const onPrevClick = () => {
        setActiveIndex(activeIndex === 0 ? slideCount - 1 : activeIndex - 1);
        handleArrowsClickTracking("left");
    };
    const onNextClick = () => {
        setActiveIndex(activeIndex === slideCount - 1 ? 0 : activeIndex + 1);
        handleArrowsClickTracking("right");
    };
    const changeActiveSlide = (index) => {
        setActiveIndex(index);
    };
    const shouldShowArrows = isArrowsVisible && !mediumOrLess && orderedSlides.length !== 1;
    const shouldShowDots = isDotsVisible && orderedSlides.length !== 1;

    const renderButtons = () => {
        const backButtonBackgroundImagesProps = {
            ...(!carouselPreviousArrowBackgroundImg && {
                style: {
                    backgroundSize: `${arrowWidth}px`,
                    backgroundImage: `url(${carouselPreviousArrowBackgroundImgUrl || DEFAULT_IMAGE_PREVIOUS_ARROW})`,
                    filter: `invert(${invertArrowColor ? 1 : 0})`,
                },
            }),
        };

        const nextButtonBackgroundImagesProps = {
            ...(!carouselNextArrowBackgroundImg && {
                style: {
                    backgroundSize: `${arrowWidth}px`,
                    backgroundImage: `url(${carouselNextArrowBackgroundImgUrl || DEFAULT_IMAGE_NEXT_ARROW})`,
                    filter: `invert(${invertArrowColor ? 1 : 0})`,
                },
            }),
        };

        return (
            <Fragment>
                <div className="carousel-control carousel-control-prev">
                    <ButtonBack onClick={() => onPrevClick()} className="carousel-prev-arrow" {...backButtonBackgroundImagesProps}>
                        {carouselPreviousArrowBackgroundImg && <FieldRenderer contents={carouselPreviousArrowBackgroundImg} />}
                    </ButtonBack>
                </div>
                <div className="carousel-control carousel-control-next">
                    <ButtonNext onClick={() => onNextClick()} className="carousel-next-arrow" {...nextButtonBackgroundImagesProps}>
                        {carouselNextArrowBackgroundImg && <FieldRenderer contents={carouselNextArrowBackgroundImg} />}
                    </ButtonNext>
                </div>
            </Fragment>
        );
    };

    const renderDots = (props) => {
        if (!props) {
            return [];
        }
        // eslint-disable-next-line react/prop-types
        const currentSlide = activeIndex;
        // eslint-disable-next-line react/prop-types
        const { totalSlides, visibleSlides, disableActiveDots, showAsSelectedForCurrentSlideOnly } = props;
        const activeImageUrl = controlDotActiveImageUrl || controlDotActiveImage?.fields?.file?.url;
        const inactiveImageUrl = controlDotInactiveImageUrl || controlDotInActiveImage?.fields?.file?.url;
        convertSCSSToCSS({ fieldId: dotId, customCss: carouselDotCss, contentType: null });

        const dots = [];
        for (let i = 0; i < totalSlides; i += 1) {
            const multipleSelected = i >= currentSlide && i < currentSlide + visibleSlides;
            const singleSelected = i === currentSlide;
            const selected = showAsSelectedForCurrentSlideOnly ? singleSelected : multipleSelected;
            const slide = i >= totalSlides - visibleSlides ? totalSlides - visibleSlides : i;
            dots.push(
                <Dot key={i} slide={slide} disabled={disableActiveDots ? selected : false} onClick={() => changeActiveSlide(i)}>
                    {activeImageUrl && inactiveImageUrl ? (
                        <img src={singleSelected ? activeImageUrl : inactiveImageUrl} className="carousel-dot--image" />
                    ) : null}
                </Dot>,
            );
        }
        return dots;
    };

    const getURL = (ref) => {
        let url;
        const isCloudinary = ref.fields.hasOwnProperty("cloudinaryDesktopImage") || ref.fields.hasOwnProperty("cloudinaryMobileImage") ? true : false;
        if (isDesktopView || ref.fields.hasOwnProperty("cloudinaryMobileImage") === false) {
            url = isCloudinary
                ? applyCloudinaryTransformations(
                      ref.fields.cloudinaryDesktopImage[0].secure_url,
                      ref.fields.cloudinaryOptimizationEnabled,
                      ref.fields.cloudinaryUrlOptimization,
                  )
                : ref.fields.desktopImage.fields.file.url;
        } else {
            url = isCloudinary
                ? applyCloudinaryTransformations(
                      ref.fields.cloudinaryMobileImage[0].secure_url,
                      ref.fields.cloudinaryOptimizationEnabled,
                      ref.fields.cloudinaryUrlOptimization,
                  )
                : ref.fields.mobileImage.fields.file.url;
        }
        return url;
    };
    const currentBGUrl =
        setCarouselBGtoSlideBG && fields.carouselContent[activeIndex].fields.thumbnailImage
            ? getURL(fields.carouselContent[activeIndex].fields.thumbnailImage)
            : fallbackCarouselBg
            ? getURL(fallbackCarouselBg)
            : "";
    return (
        <AnimateSection animationClass={animationClass} refProp={carouselContainerRef}>
            {({ containerRef }) => (
                <div
                    id={fieldId}
                    className={`carousel-basic-slider ${setCarouselBGtoSlideBG ? "withBG" : ""}`}
                    onTouchStart={onTouchCarousel}
                    ref={containerRef}
                    style={{ backgroundImage: currentBGUrl ? "url(" + currentBGUrl + ")" : "" }}
                >
                    <FieldRenderer contents={topContainer} />
                    <div ref={swipeRef}>
                        {orderedSlides?.length > 0 && (
                            <CarouselProvider
                                ref={carouselRef}
                                visibleSlides={visibleSlidesValue() > orderedSlides.length ? orderedSlides.length : visibleSlidesValue()}
                                id={`carousel-box-${fieldId}`}
                                totalSlides={orderedSlides.length}
                                infinite={enableInfiniteScroll}
                                interval={automaticScrollTimer ? automaticScrollTimer * 1000 : 0}
                                isPlaying={isPlaying}
                                touchEnabled={true}
                                dragEnabled={true}
                                naturalSlideHeight={undefined}
                                naturalSlideWidth={undefined}
                            >
                                {isMultipleSlidesPerView && mediumOrLess && (
                                    <style>
                                        {`#carousel-box-${fieldId} > div > div > ul{
                                background-color: #000;
                                width: ${
                                    (orderedSlides.length / (visibleSlidesValue() > orderedSlides.length ? orderedSlides.length : visibleSlidesValue())) * 100 -
                                    getMinusPercentage()
                                }% !important;
                            }`}
                                    </style>
                                )}
                                <Slider id="slider-comp" className={`carousel-inner ${shouldShowArrows ? "carousel-slider-buttons-size" : null}`}>
                                    {orderedSlides.map((item, index) => (
                                        <FieldRenderer
                                            key={index}
                                            index={index}
                                            contents={item}
                                            itemStyle={slideItem}
                                            handleCardClickTracking={handleCardClickTracking}
                                        />
                                    ))}
                                </Slider>
                                {shouldShowArrows && renderButtons()}
                                {shouldShowDots && <DotGroup id={dotId} className="carousel-dots" renderDots={renderDots}></DotGroup>}
                            </CarouselProvider>
                        )}
                    </div>
                </div>
            )}
        </AnimateSection>
    );
};

CarouselBasicSlider.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    FieldRenderer: PropTypes.func,
    isDesktopView: PropTypes.bool,
};
