import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Fields from "../../field";
import { useSelector } from "react-redux";
import { convertSCSSToCSS, backgroundImageStyleCreator, processClientCSS } from "../../helper/util";
import { RichText } from "../../field";

export const FieldRenderer = ({ contents, hideFieldCallback = null, clientContext = false, ...props }) => {
    const isDesktopView = useSelector((state) => state.pageRouter.isDesktopView);
    const getField = ({ contentType, key, ...data }) => {
        if (hideFieldCallback && hideFieldCallback(contentType)) {
            return null;
        }
        const Field = Fields[contentType];
        const { fields, entryId } = data;
        const { title, id = "", customCss = "", backgroundImage = null } = fields;
        const fieldId = id ? `${id.trim()}-${entryId}` : `element-${entryId}`;
        const convertedCSS = !process.env.BROWSER ? convertSCSSToCSS({ fieldId, customCss, title, contentType }) : null;
        const backgroundImageCSS =
            !process.env.BROWSER && backgroundImage && backgroundImage.fields
                ? backgroundImageStyleCreator(fieldId, backgroundImage, isDesktopView, title, contentType)
                : null;
        const clientCSS = clientContext && customCss ? processClientCSS({ fieldId, customCss, title, contentType }) : null;
        if (Field) {
            return (
                <Fragment key={key}>
                    {convertedCSS && <style>{convertedCSS}</style>}
                    {backgroundImageCSS && <style>{backgroundImageCSS}</style>}
                    {clientCSS && <style>{clientCSS}</style>}
                    <Field
                        {...props}
                        {...data}
                        hideFieldCallback={hideFieldCallback}
                        fieldId={fieldId}
                        isDesktopView={isDesktopView}
                        RichText={RichText}
                        sectionType={key ? "array" : "object"}
                    />
                </Fragment>
            );
        }
    };

    return (
        <Fragment>
            {contents instanceof Array
                ? contents.map((item, index) => {
                      if (item && item.sys && item.sys.contentType) {
                          return getField({
                              contentType: item.sys.contentType.sys.id,
                              entryId: item.sys.id,
                              fields: item.fields,
                              key: index,
                          });
                      }
                  })
                : contents && contents.sys && contents.sys.contentType
                ? getField({
                      contentType: contents.sys.contentType.sys.id,
                      entryId: contents.sys.id,
                      fields: contents.fields,
                  })
                : null}
        </Fragment>
    );
};

FieldRenderer.propTypes = {
    contents: PropTypes.any,
    hideFieldCallback: PropTypes.func,
    isDesktopView: PropTypes.bool,
    clientContext: PropTypes.bool,
};
