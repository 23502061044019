import { all, put, takeLatest } from "redux-saga/effects";
import { datadogRum } from "@datadog/browser-rum";
import { offersActions } from "./offers-page-action";
import { addSubSkuToCart, removeSubSkuFromCart, addItemToCart, fetchCartDetails, fetchOffersPlan, getSessionId, updateItemInCart } from "../../../api";
import { AbondonedUserData, CartDetails, DefaultCreditCard, SKU } from "../../../model";
import { fetchCartAbondonedOrderDetails, submitSurveyResponse } from "../../../api/offersApi";
import { DEFAULT_OFFER_CODE } from "../../../constants/urls";
import { checkoutActions } from "../../checkout-page/data/checkout-page-action";
import { getOffersPageId } from "../../../helper/util";

export function* fetchOffersDetails(action) {
    const { payload, interstitial = false, callback } = action || {};
    try {
        yield put({
            type: offersActions.SET_IS_LOADING,
            payload: {
                isLoading: true,
            },
        });
        const response = yield fetchOffersPlan(payload);
        if (response && response.status === "ERROR") {
            const customError = new Error("Error in fetchOffersPlan in fetchOffersDetails");
            console.error({ message: customError.message, error: response });
            datadogRum.addError(customError, {
                error: response,
            });
            return;
        } else {
            const payload = { isLoading: false, productDetails: response.product };
            if (response) {
                payload[`${interstitial ? "interstitialOffers" : "offersDetails"}`] = response.product.skus.map((sku) => new SKU(sku));
                yield put({
                    type: offersActions.SET_OFFERS_DETAILS,
                    payload,
                });
                callback && callback.success ? callback.success(response) : "";
            }
        }
    } catch (error) {
        const customError = new Error("Error in fetchOffersDetails");
        console.error({ message: customError.message, error });
        datadogRum.addError(customError, {
            error,
        });
    }
}

export function* addSelectedSubSkuToCart(action) {
    try {
        const { payload, callbacks } = action;
        const { isToUpdateCart } = payload;
        const response = isToUpdateCart ? yield removeSubSkuFromCart(payload) : yield addSubSkuToCart(payload);
        const { status, order, formError } = response || {};
        if (status === "ERROR" || formError === "true") {
            const customError = new Error("");
            if (isToUpdateCart) {
                customError.message = "Error in removeSubSkuFromCart in addSelectedSubSkuToCart";
            } else {
                customError.message = "Error in addSubSkuToCart in addSelectedSubSkuToCart";
            }
            console.error({ message: customError.message, error: response });
            datadogRum.addError(customError, {
                error: response,
            });
            callbacks && callbacks.failure ? callbacks.failure(response) : "";
            return;
        } else {
            if (order) {
                yield put({
                    type: offersActions.SET_OFFERS_DETAILS,
                    payload: { order: new CartDetails(order) },
                });
                callbacks && callbacks.success ? callbacks.success(order) : "";
            }
        }
    } catch (error) {
        const customError = new Error("Error in addSelectedSubSkuToCart");
        console.error({ message: customError.message, error });
        datadogRum.addError(customError, {
            error,
        });
    }
}

export function* addSelectedItemToCart(action) {
    try {
        const { payload, callbacks } = action;
        const { catalogRefIds, isToUpdateCart } = payload;
        const response = isToUpdateCart ? yield updateItemInCart(payload) : yield addItemToCart(payload);
        const { status, order, formError } = response || {};
        if (status === "ERROR" || formError === "true") {
            const customError = new Error("");
            if (isToUpdateCart) {
                customError.message = "Error in updateItemInCart in addSelectedItemToCart";
            } else {
                customError.message = "Error in addItemToCart in addSelectedItemToCart";
            }
            console.error({ message: customError.message, error: response });
            datadogRum.addError(customError, {
                error: response,
            });
            callbacks && callbacks.failure ? callbacks.failure(response) : "";
            return;
        } else {
            if (order) {
                yield put({
                    type: offersActions.SET_OFFERS_DETAILS,
                    payload: { order: new CartDetails(order), userSelectedProductId: catalogRefIds },
                });
                callbacks && callbacks.success ? callbacks.success(catalogRefIds) : "";
            }
        }
    } catch (error) {
        const customError = new Error("Error in addSelectedItemToCart");
        console.error({ message: customError.message, error });
        datadogRum.addError(customError, {
            error,
        });
    }
}
export function* fetchCartDetail(action) {
    const {
        callbacks: { failure, success, postGetOrder },
        payload,
    } = action || { callbacks: {} };
    const { noLoader } = payload || {};
    try {
        noLoader
            ? null
            : yield put({
                  type: offersActions.SET_IS_LOADING,
                  payload: {
                      isLoading: true,
                  },
              });
        const response = yield fetchCartDetails();
        const { status, order, defaultCreditCard } = response || {};
        let creditCardData;
        if (defaultCreditCard) {
            const { billingAddress, ...rest } = defaultCreditCard || {};
            creditCardData = new DefaultCreditCard({ ...billingAddress, ...rest });
        }
        if (defaultCreditCard && creditCardData) yield put({ type: checkoutActions.DEFAULT_CREDIT_CARD, payload: creditCardData });
        if (status === "ERROR") {
            const customError = new Error("Error in fetchCartDetails in fetchCartDetail");
            console.error({ message: customError.message, error: response });
            datadogRum.addError(customError, {
                error: response,
            });
            failure && failure();
            return;
        } else {
            if (order) {
                yield put({
                    type: offersActions.SET_OFFERS_DETAILS,
                    payload: { order: new CartDetails(order), isLoading: false },
                });
                success && success(order);
            }
        }
        postGetOrder && postGetOrder();
    } catch (error) {
        const customError = new Error("Error in fetchCartDetail");
        console.error({ message: customError.message, error });
        datadogRum.addError(customError, {
            error,
        });
    }
}

function* fetchSessionId(action) {
    try {
        const ipAddress = ""; //yield fetchIPAddress();
        ipAddress && sessionStorage.setItem("ipAddress", ipAddress);
        const sessionId = yield getSessionId();
        const id = sessionId ? sessionId : sessionStorage.getItem("sessionId");
        sessionStorage.setItem("sessionId", id);
        yield put({ type: offersActions.SET_OFFERS_DETAILS, payload: { sessionId: id, ipAddress: ipAddress } });
        if (action.callbacks) {
            if (sessionId) {
                action.callbacks.success();
            } else {
                action.callbacks.failure();
            }
        }
    } catch (error) {
        const customError = new Error("Error in fetchSessionId");
        console.error({ message: customError.message, error });
        datadogRum.addError(customError, {
            error,
        });
    }
}

function* getCartAbondonedOrder(action) {
    const { callbacks } = action || {};
    const { success, failure } = callbacks || {};
    try {
        yield put({
            type: offersActions.SET_IS_LOADING,
            payload: {
                isLoading: true,
            },
        });
        const response = yield fetchCartAbondonedOrderDetails(action);
        const { STATUS, formError, abandonedOrder, profile } = response || {};
        if (formError || !STATUS || STATUS === "FAILED") {
            const customError = new Error("Error in fetchCartAbondonedOrderDetails in getCartAbondonedOrder");
            console.error({ message: customError.message, error: response });
            datadogRum.addError(customError, {
                error: response,
            });
            failure && failure();
        } else if (abandonedOrder || profile) {
            yield put({
                type: offersActions.SET_OFFERS_DETAILS,
                payload: {
                    order: new CartDetails(abandonedOrder),
                    abondonedUserData: new AbondonedUserData(profile),
                    isLoading: false,
                },
            });
            success && success(abandonedOrder, profile);
        }
    } catch (error) {
        const customError = new Error("Error in getCartAbondonedOrder");
        console.error({ message: customError.message, error });
        datadogRum.addError(customError, {
            error,
        });
    }
}

function* saveSurveyResponse(action) {
    const { payload, callback } = action || {};
    const offerId = localStorage.getItem("offerId");
    try {
        const formData = { ...payload, offersPageId: getOffersPageId() };
        const response = yield submitSurveyResponse(formData);
        const { OfferProduct } = response || {};
        const { id } = OfferProduct || {};
        const offerId = id || payload.offerId || DEFAULT_OFFER_CODE;
        offerId && localStorage.setItem("offerId", offerId);
        sessionStorage.removeItem("surveyPageIds");
        callback instanceof Function && callback();
    } catch (error) {
        localStorage.setItem("offerId", offerId);
        callback instanceof Function && callback();
        console.error("SAVE SURVEY RESPONSE", error);
    }
}

function* actionWatcher() {
    yield all([
        takeLatest(offersActions.ADD_ITEM_TO_CART, addSelectedItemToCart),
        takeLatest(offersActions.GET_CART_ABANDONED_ORDER_DETAILS, getCartAbondonedOrder),
        takeLatest(offersActions.GET_CART_DETAILS, fetchCartDetail),
        takeLatest(offersActions.GET_OFFERS_DETAILS, fetchOffersDetails),
        takeLatest(offersActions.GET_SESSION_ID, fetchSessionId),
        takeLatest(offersActions.SAVE_SURVEY_RESPONSE, saveSurveyResponse),
        takeLatest(offersActions.ADD_SUBSKU_TO_CART, addSelectedSubSkuToCart),
    ]);
}

export default function* offersSaga() {
    yield all([actionWatcher()]);
}
