import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { TextInput } from "../../../common";
import { validateField } from "../../common/validator";
import { createPartialProfile, submitSurveyResponse, triggerUserEmailLookup } from "../../common/util";
import { getConfig } from "../../create-account/components/modal/config";
import { pageRouterActions } from "../../../component/page-router/data/page-router-action";
import { checkoutActions } from "../data/checkout-page-action";
import { checkFlowType } from "../../../helper/util";
import { DEFAULT_OFFER_CODE, ODP_FLOW, TBB_SITE_URL } from "../../../constants/urls";
import { BODINT_ENTITLEMENT } from "../../create-account/components/constants";
import { BEACHBODY_ON_DEMAND } from "../../../constants/urls";

const EmailInput = ({ onChange, formData, formErrors, setFormErrors, setIsEmailAbleToPurchase, setIsFetchingEmailDetails }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const productId = localStorage.getItem("offerId") || DEFAULT_OFFER_CODE;

    const userLoginSuccess = () => {
        submitSurveyResponse(dispatch);
    };
    const userLoginFailure = () => {
        setFormErrors("email", "Please re-enter your email address");
    };

    const onGetUserDetailsSuccess = (userDetails) => {
        const { entitlements, customerType, isCoach } = userDetails || {};
        const isBODUser = entitlements?.split(",").includes("BOD");
        const isBODiUser = entitlements?.split(",").includes(BODINT_ENTITLEMENT);
        const isCoachUser = isCoach && isCoach !== "false";
        const flowType = checkFlowType(productId);
        setIsFetchingEmailDetails(false);
        if (flowType === ODP_FLOW) {
            if (isCoachUser) {
                setFormErrors("email", "tbb-coach-header-sign-in", null, { signInUrl: TBB_SITE_URL });
                setIsEmailAbleToPurchase(false);
                return;
            }
            if (isBODiUser) {
                setFormErrors("email", "tbb-user-header-sign-in", null, { signInUrl: BEACHBODY_ON_DEMAND + "/hosted-login-page" });
                setIsEmailAbleToPurchase(false);
                return;
            }
            setFormErrors("email", "");
            setIsEmailAbleToPurchase(true);
        } else {
            if (isCoachUser) {
                setFormErrors("email", "tbb-coach-header-sign-in", null, { signInUrl: TBB_SITE_URL });
                setIsEmailAbleToPurchase(false);
                return;
            }
            if (isBODUser) {
                setFormErrors("email", "tbb-user-header-sign-in", null, { signInUrl: BEACHBODY_ON_DEMAND + "/hosted-login-page" });
                setIsEmailAbleToPurchase(false);
                return;
            }
            setFormErrors("email", "");
            setIsEmailAbleToPurchase(true);
        }

        if (customerType) {
            const config = getConfig(customerType, isCoach);
            const { modal } = config || {};
            const refRepId = sessionStorage.getItem("refRepId");
            dispatch({
                type: pageRouterActions.SET_USER_DETAILS,
                payload: {
                    isExistingFreeUser: modal === "customerWithoutBOD",
                    isNewUser: false,
                    ...userDetails,
                },
            });
            dispatch({
                type: checkoutActions.USER_LOGIN_SYNCUP_ATG,
                payload: { email: formData["email"], referringCoachId: refRepId ? refRepId : "" },
                callbacks: { success: userLoginSuccess, failure: userLoginFailure },
            });
        } else {
            createPartialProfile({ dispatch, payload: { email: formData["email"] }, callback: submitSurveyResponse(dispatch) });
            dispatch({
                type: pageRouterActions.SET_USER_DETAILS,
                payload: {
                    isExistingFreeUser: false,
                    isNewUser: true,
                    ...userDetails,
                },
            });
        }
    };
    const onGetUserDetailsFailure = () => {
        setFormErrors("email", t("email-lookup-fail"));
        setIsFetchingEmailDetails(false);
    };

    const validateFormErrors = (name, value) => {
        const error = validateField({ name, value, hybridValidator: true });
        if (error) {
            setFormErrors(name, error);
            return true;
        } else {
            return false;
        }
    };

    const onBlur = () => {
        setIsEmailAbleToPurchase(false);
        setFormErrors("email", "");
        const formErrors = validateFormErrors("email", formData["email"]);
        if (!formErrors) {
            triggerUserEmailLookup(formData["email"], dispatch, onGetUserDetailsSuccess, onGetUserDetailsFailure);
        } else setIsFetchingEmailDetails(false);
    };

    return (
        <TextInput
            name="email"
            id="email"
            floatLabel={t("email")}
            maxLength={100}
            onChange={onChange}
            onBlur={onBlur}
            error={formErrors["email"]}
            dValue={formData["email"]}
            autoFocus={true}
        />
    );
};

export default EmailInput;

EmailInput.propTypes = {
    onChange: PropTypes.func,
    formData: PropTypes.object,
    formErrors: PropTypes.object,
    setFormErrors: PropTypes.func,
    setIsEmailAbleToPurchase: PropTypes.func,
    setIsFetchingEmailDetails: PropTypes.func,
};
