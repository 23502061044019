export const getPageType = path => {
    if (path.indexOf("checkout") !== -1) {
        return "cart";
    } else if (path.indexOf("offers") !== -1) {
        return "product";
    } else if (path.indexOf("confirmatioin") !== -1) {
        return "order";
    }
    return "product";
};
