import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextInput } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import { checkoutActions } from "../data/checkout-page-action";
import { createPartialProfile } from "../../common/util";
import { offersActions } from "../../offers-page/data/offers-page-action";
import useSessionStorageState from "../../../hooks/useSessionStorageState";

export function MyxCoachRefferal({
    formErrors,
    setFormErrors,
    onChange,
    validateField,
    formData,
    abondonedUserData,
    showCoachInfo,
    updateCoachOptInAnalytics,
    checkCoachInputType,
    coachRefferalRef,
    setIsFetchingCoachDetails,
    textFieldValues,
}) {
    const dispatch = useDispatch();
    const [coach, setCoach] = useState();
    const [coachId, setCoachId] = useSessionStorageState("coachId");
    const { coach_referral_failed = "", myx_coach_placeholder = "", coach_referral_confirmation = "", cease_leadwheel_placeholder = "" } = textFieldValues;
    const { disableLeadwheel } = useSelector((state) => state.checkout);

    const coachDetailsFetch = (result) => {
        const { status, searchStatus, searchUser } = result || {};
        if (status === "SUCCESS") {
            if (showCoachInfo && searchStatus === "USER_FOUND") {
                const id = isNaN(coachId) ? searchUser && searchUser.gncCoachID : coachId;
                setCoach({ ...searchUser, email: formData["email"] });
                onChange("coachId", id);
                const coachIdType = checkCoachInputType(coachId);
                updateCoachOptInAnalytics("", "", coachIdType.coachOptInId, coachIdType.coachOptInEmail);
                const payload = { ...formData, coachId };
                if (!formErrors["email"]) {
                    const { profile } = abondonedUserData || {};
                    if (profile) {
                        const { firstName, lastName, email } = profile || {};
                        const AbData = {
                            firstName: formData["firstName"] || firstName,
                            lastName: formData["lastName"] || lastName,
                            email,
                            coachId,
                        };
                        dispatch({
                            type: offersActions.UPDATE_ABANDONED_PROFILE,
                            payload: {
                                abondonedUserData: { profile: { ...AbData } },
                            },
                        });
                    }
                }
                !formErrors["email"] ? createPartialProfile({ dispatch, payload }) : null;
            } else if (searchStatus === "USER_NOT_FOUND") {
                setCoach(coach_referral_failed);
                onChange("coachId", "");
            }
        } else if (status === "FAILED") {
            setCoach(coach_referral_failed);
            onChange("coachId", "");
        }
        setIsFetchingCoachDetails(false);
    };
    const clearCoachErrors = () => {
        setCoach(null);
        setFormErrors("coachId", "");
    };
    const fetchCoachDetails = ({ name, value }) => {
        const error = !isNaN(value) && typeof Number(value) === "number" ? false : validateField({ name, value });
        if (error) {
            setFormErrors(name, error);
            setCoach();
            onChange("coachId", "");
            setIsFetchingCoachDetails(false);
        } else {
            setFormErrors(name, "");
            onChange("coachId", value);
            dispatch({ type: checkoutActions.FETCH_COACH_DETAILS, payload: value, callback: coachDetailsFetch });
        }
    };
    const onCoachInfoChange = (name, value) => {
        if (!value.length) {
            setFormErrors(name, "");
            setCoach();
            onChange("coachId", "");
            setIsFetchingCoachDetails(false);
        } else setIsFetchingCoachDetails(true);
        setCoachId(value);
    };
    useEffect(() => {
        if (!showCoachInfo) {
            clearCoachErrors();
        }
    }, [showCoachInfo]);
    useEffect(() => {
        if (coachId.length && showCoachInfo) {
            fetchCoachDetails({ name: "coachId", value: coachId });
        }
    }, [showCoachInfo]);
    return (
        <div className="coach-referral my-2" ref={coachRefferalRef}>
            {showCoachInfo && (
                <TextInput
                    name="coachId"
                    id="coachId"
                    onChange={onCoachInfoChange}
                    onBlur={fetchCoachDetails}
                    error={(typeof coach === "string" && coach) || formErrors["coachId"]}
                    dValue={coachId}
                    setFormErrors={setFormErrors}
                    floatLabel={disableLeadwheel ? cease_leadwheel_placeholder : myx_coach_placeholder}
                />
            )}

            {coach && coach.isCoach && showCoachInfo && (
                <p
                    className="text-success text-left"
                    dangerouslySetInnerHTML={{
                        __html: coach_referral_confirmation?.replace("{{firstName}}", coach?.firstName).replace("{{lastName}}", coach?.lastName),
                    }}
                ></p>
            )}
        </div>
    );
}
MyxCoachRefferal.propTypes = {
    t: PropTypes.func,
    onChange: PropTypes.func,
    setFormErrors: PropTypes.func,
    formErrors: PropTypes.object,
    formData: PropTypes.object,
    validateField: PropTypes.func,
    createPartialProfile: PropTypes.func,
    abondonedUserData: PropTypes.object,
    showCoachInfo: PropTypes.bool,
    updateCoachOptInAnalytics: PropTypes.func,
    checkCoachInputType: PropTypes.func,
    setIsFetchingCoachDetails: PropTypes.func,
    coachRefferalRef: PropTypes.shape({
        current: PropTypes.any,
    }),
    textFieldValues: PropTypes.object,
};
