export const ADD_SUBSKU_CART_URL = "/rest/model/atg/commerce/order/purchase/CartModifierActor/addSubSkuToConfigurableItem/";
export const ADD_TO_ITEM_CART_URL = "/rest/model/atg/commerce/order/purchase/CartModifierActor/addItemToOrder/";
export const ASSIGN_TO_LEADWHEEL_URL = "/rest/model/com/beachbody/bbcom/checkout/CheckoutActor/assignToLeadWheel";
export const ATG_BASE_URL = "";
export const CART_ABANDONMENT_ORDER = "/rest/model/com/beachbody/bbcom/checkout/CheckoutActor/getAbandonedOrder";
export const COACH_LOOKUP = "/rest/model/atg/userprofiling/ProfileActor/coachLookup";
export const CONTENTFUL_CMS_PAGE_URL = "/api/v1/cms/page";
export const COUNTRY_CODE_URL = "/oidc/countryInfo";
//export const CYBER_SOURCE_KEY = "/api/v1/cybersouce/key"; // For Local Dev
export const CYBER_SOURCE_KEY = "/rest/model/atg/commerce/order/purchase/PaymentGroupActor/flexKey"; // For ATG
export const CYBERSOURCE_3DS_ENROLL_AUTH = "/rest/model/atg/commerce/order/purchase/PaymentGroupActor/enrollAuth?";
export const CYBERSOURCE_3DS_SETUP_AUTH = "/rest/model/atg/commerce/order/purchase/PaymentGroupActor/setupAuth?";
export const CYBERSOURCE_PERMANENT_TOKEN = "/rest/model/atg/commerce/order/purchase/PaymentGroupActor/swapTransientToken?transientToken=";
export const EXPRESS_CHECKOUT_URL = "/rest/model/com/beachbody/bbcom/checkout/CheckoutActor/expressCheckout";
export const GET_ACTIVATION_CODE = "/devices/activationcode/";
export const GET_CART_DETAILS = "/rest/model/atg/commerce/ShoppingCartActor/checkoutSummary/";
export const GET_OFFERS_URL = "/rest/model/atg/commerce/catalog/ProductCatalogActor/getProductDetails?productId=";
export const GET_SESSION_ID = "/rest/model/atg/rest/SessionConfirmationActor/getSessionConfirmationNumber";
export const GET_WCS_CONTENT = "/api/v1/cms/wcs/termsofuse?path=";
export const GET_ZIPCODE_DETAILS = "/rest/model/com/beachbody/bbcom/checkout/CheckoutActor/getDerivedAddress";
export const GLOBAL_CONFIGURATIONS = "/rest/model/com/beachbody/service/PageContentActor/getMerlinConfig";
export const IPIFY_API_URL = "https://api.ipify.org";
export const PARTIAL_PROFILE = "/rest/model/atg/userprofiling/ProfileActor/associate-email";
export const PLACE_CHECKOUT_ORDER_URL = "/check-out/placeOrder";
export const POST_ACTIVATE_BIKE = "/message/public/activate.user.device.";
export const POST_USER_LOG_URL = "/user-access-log";
export const REMOVE_CART_ITEM = "/rest/model/atg/commerce/order/purchase/CartModifierActor/removeAndAddItemToOrder";
export const REMOVE_SUBSKU_CART_URL = "/rest/model/atg/commerce/order/purchase/CartModifierActor/removeSubSkuFromConfigurableItem/";
export const RESET_PASSWORD = "/rest/model/com/beachbody/bbcom/checkout/CheckoutActor/reset-password";
export const SAVE_SURVEY_RESPONSE = "/rest/model/atg/userprofiling/ProfileActor/saveQuizResponses";
export const SEND_FORGOT_PASSWORD_EMAIL = "/rest/model/atg/userprofiling/ProfileActor/forgot-password";
export const SESSION_LOGOUT = "/api/v1/auth/user/logout";
export const SESSION_USER_DETAILS = "/api/v1/auth/user/session";
export const TAX_CALCULATOR = "/rest/model/com/beachbody/bbcom/checkout/CheckoutActor/addShippingAddressToShoppingCart";
export const USER_LOGIN = "/api/v1/auth/user/login";
export const USER_LOGIN_SYNCUP_ATG = "/rest/model/com/beachbody/bbcom/checkout/CheckoutActor/user-login";
export const VALIDATE_ENROLL_EMAIL = "/rest/model/atg/userprofiling/ProfileActor/userLookup";
export const VALIDATE_TOKEN = "/rest/model/com/beachbody/bbcom/checkout/CheckoutActor/validateToken";
export const ATG_PAYPAL_TOKEN_URL = "/rest/model/atg/commerce/order/purchase/PaymentGroupActor/generatePaypalClientToken";
