import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";
import { ScrollSnappingWrapper } from "../scroll-snapping-wrapper";
import { AnimateSection } from "../common/animation";

export const Content = ({ fields, inlineClass = "", fieldId, ...props }) => {
    const { contents, scrollSnapContent = null, animationClass } = fields;

    return (
        <AnimateSection animationClass={animationClass}>
            {({ containerRef }) => (
                <div id={fieldId} className={`content ${inlineClass}`} ref={containerRef}>
                    {!scrollSnapContent ? <FieldRenderer contents={contents} {...props} /> : <ScrollSnappingWrapper contents={scrollSnapContent} {...props} />}
                </div>
            )}
        </AnimateSection>
    );
};
Content.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    inlineClass: PropTypes.string,
    contents: PropTypes.object,
};
