import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Slide } from "pure-react-carousel";
import { FieldRenderer } from "../../contentful/component-renderer";
import { configureInlineCss } from "../../helper/util";
import "./carousel-content.scss";

export const CarouselContent = (item) => {
    const { isLoadMore, handleCardClickTracking, index } = item;
    const { slideContent, urlLink = "", title } = item?.fields;
    const slide = configureInlineCss(
        {
            ...item,
            sys: {
                id: item?.entryId,
                contentType: {
                    sys: {
                        id: "carouselContent",
                    },
                },
            },
        },
        true,
    );

    if (isLoadMore) {
        return (
            <Fragment>
                <FieldRenderer contents={slideContent} index={index} />
            </Fragment>
        );
    }

    const getSlideContent = () => (
        <Fragment>
            {slide && slide.css ? <style>{slide.css}</style> : null}
            <Slide id={slide.id} index={item?.index} className="carousel-content-item" style={item?.itemStyle}>
                {urlLink ? (
                    <a href={urlLink} target="_blank" onClick={() => handleCardClickTracking(title)}>
                        <FieldRenderer contents={slideContent} index={index} />
                    </a>
                ) : (
                    <FieldRenderer contents={slideContent} index={index} />
                )}
            </Slide>
        </Fragment>
    );

    return <Fragment>{getSlideContent()}</Fragment>;
};

CarouselContent.propTypes = {
    fields: PropTypes.shape({
        index: PropTypes.number,
        isLoadMore: PropTypes.bool,
        slideContent: PropTypes.any.isRequired,
    }).isRequired,
    itemStyle: PropTypes.shape({
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
};
