import React from "react";
import PropTypes from "prop-types";

export const StyledCheckBox = ({ id, name, checkBoxClass, onCheckboxClick, checked = "" }) => {
    return (
        <div className={`${checkBoxClass} ${checked}`}

            id={id}
            name={name}
            onClick={() => {
                onCheckboxClick(name);
            }}
        >
        </div>
    );
};
StyledCheckBox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string, // Input name attr
    onCheckboxClick: PropTypes.func,
    checkBoxClass: PropTypes.string,
    checked: PropTypes.string,
};
