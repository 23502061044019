import React, { Fragment, useEffect, useState } from "react";
import { StyledCheckBox } from "../../../common";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import dompurify from "dompurify";
import { checkBoxNames, validCheckBox } from "../../create-account/components/constants";
export function MyxMembershipAcceptance({
    checkboxes = [],
    doFieldValidation,
    formData,
    formErrors,
    onChange,
    sectionTitle,
    setFormErrors,
    t,
    validateField,
    isCoachConsent = false,
    showCoachInfo = false,
}) {
    const sanitizer = dompurify.sanitize;
    const [defaultcheckBoxes, setDefaultCheckBoxes] = useState();
    const { enableFusion } = useSelector((state) => state.checkout);
    const config = {
        ADD_ATTR: ["href", "target"],
    };
    const onCheckBoxClick = (name, invertedCheckbox) => {
        doFieldValidation && validateField({ name, value: !formData[name], callback: setFormErrors });
        const checkBoxName = `${name}_invertedCheckbox`;
        const data = invertedCheckbox ? { [name]: !formData[name], [checkBoxName]: !formData[checkBoxName] } : { [name]: !formData[name] };
        onChange("", "", data);
    };
    const updateCheckBoxToDefault = (initial) => {
        const defaultcheckBoxes = {};
        checkboxes &&
            checkboxes.map((checkbox) => {
                const { id, showCheckbox } = checkbox || {};
                const checkBoxName = checkBoxNames[id] || getCheckBoxId(checkbox) || id;
                const hasSponsor = checkBoxName === "coachConsent" && formData?.coachId;
                const { defaultState, invertedCheckbox } = hasSponsor ? (checkbox.sponsorCheckbox ? checkbox.sponsorCheckbox : checkbox) : checkbox || {};
                const value = invertedCheckbox ? !defaultState : defaultState;
                if (checkBoxName === "coachConsent") {
                    defaultcheckBoxes["coachConsent"] = value;
                    if (enableFusion) {
                        if (!hasSponsor) defaultcheckBoxes["coachConsent"] = false;
                        else if (hasSponsor === "leadwheel" && showCheckbox) defaultcheckBoxes["coachConsent"] = false;
                    }
                } else if (checkBoxName === "companyConsent" && invertedCheckbox && (initial || formData?.coachId)) {
                    defaultcheckBoxes["companyConsent"] = value;
                } else if (initial) {
                    const checkBoxName = checkBoxNames[id] || getCheckBoxId(checkbox) || id;
                    defaultcheckBoxes[checkBoxName] = defaultState;
                }
            });
        setDefaultCheckBoxes(defaultcheckBoxes);
    };
    const getCheckBoxId = (checkbox) => {
        const keys = Object.keys(checkbox).filter((key) => {
            if (validCheckBox.indexOf(key) !== -1 && checkbox[key]) {
                return true;
            } else {
                return false;
            }
        });
        return keys.length ? keys.join("__") : "";
    };
    const displayCheckbox = (checkBoxName: string) => {
        return isCoachConsent ? checkBoxName === "coachConsent" : checkBoxName !== "coachConsent";
    };
    const loadUserConfirmationCheckBoxes = () => {
        return (
            checkboxes &&
            checkboxes.map((checkbox, index) => {
                const { id } = checkbox || {};
                const checkBoxName = checkBoxNames[id] || getCheckBoxId(checkbox) || id;
                const isLeadWheel = enableFusion && formData?.coachId === "leadwheel";
                const hasSponsor = checkBoxName === "coachConsent" && isCoachConsent && ((formData?.coachId && !isLeadWheel) || showCoachInfo);
                const isTermsAndConditionsCheckbox = id === "termsAndConditions_checkbox";
                const {
                    showCheckbox,
                    priorityOrder,
                    textContent,
                    invertedCheckbox,
                    showText,
                    hasSponsor: showSponsorText,
                } = hasSponsor ? (checkbox.sponsorCheckbox ? checkbox.sponsorCheckbox : checkbox) : checkbox || {};
                return (
                    <div className="acceptance-item continuity-text d-flex flex-column mb-3" key={index} style={{ order: priorityOrder }}>
                        {displayCheckbox(checkBoxName) && (
                            <div className="w-100 d-flex">
                                {showCheckbox && !isTermsAndConditionsCheckbox ? (
                                    <StyledCheckBox
                                        name={checkBoxName}
                                        id={checkBoxName}
                                        checkBoxClass={`selection-checkbox ${
                                            formData[invertedCheckbox ? `${checkBoxName}_invertedCheckbox` : checkBoxName] ? "checked" : "un-checked"
                                        } ${formErrors[checkBoxName] && "error-msg"}`}
                                        onCheckboxClick={() => onCheckBoxClick(checkBoxName, invertedCheckbox)}
                                    />
                                ) : (
                                    <div className="empty-checkbox"></div>
                                )}
                                {(showText || showSponsorText || showCheckbox) && (
                                    <p
                                        className={showCheckbox ? "pl-4 w-100" : "w-100"}
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizer(textContent, config),
                                        }}
                                    ></p>
                                )}
                            </div>
                        )}
                        {formErrors[checkBoxName] && <div className="error-msg  text-left py-3">{t(formErrors[checkBoxName])}</div>}
                    </div>
                );
            })
        );
    };
    useEffect(() => {
        defaultcheckBoxes && onChange("", "", defaultcheckBoxes);
    }, [defaultcheckBoxes]);
    useEffect(() => {
        updateCheckBoxToDefault();
    }, [formData["coachId"]]);
    useEffect(() => {
        updateCheckBoxToDefault(true);
    }, []);
    return (
        <Fragment>
            {sectionTitle && <h3>{sectionTitle}</h3>}
            {checkboxes && (
                <section id="membership-acceptance" className={`membership-acceptance ${isCoachConsent ? "" : "mt-4"}`}>
                    <div className="section-content d-flex flex-column">{loadUserConfirmationCheckBoxes()}</div>
                </section>
            )}
        </Fragment>
    );
}
MyxMembershipAcceptance.propTypes = {
    checkboxes: PropTypes.array,
    content: PropTypes.array,
    doFieldValidation: PropTypes.bool,
    formData: PropTypes.object,
    formErrors: PropTypes.object,
    onChange: PropTypes.func,
    sectionTitle: PropTypes.string,
    setFormErrors: PropTypes.func,
    t: PropTypes.func,
    validateField: PropTypes.func,
    isCoachConsent: PropTypes.bool,
    showCoachInfo: PropTypes.bool,
};
