export class ResetPassword {
    constructor(obj = {}) {
        this.password = obj.password || "";
        this.confirmPassword = obj.confirmPassword || "";
    }
}

export class MyxResetPassword {
    constructor(obj = {}) {
        this.password = obj.password || "";
    }
}


