import React from "react";
import PropTypes from "prop-types";

const ErrorFallback = ({ error }) => {
    return (
        <div role="alert">
            <p className="error-msg">{error.message}</p>
        </div>
    );
};

ErrorFallback.propTypes = {
    error: PropTypes.object,
};

export default ErrorFallback;
