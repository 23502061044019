import "isomorphic-fetch";
import { checkFlowType, getOffersPageId, getUrlParam } from "../helper/util";
import { createSession, fetchDirect, fetchGet, fetchPostWithFormData, fetchPostWithJSONData } from "./service";
import {
    ADD_TO_ITEM_CART_URL,
    ATG_BASE_URL,
    CART_ABANDONMENT_ORDER,
    CONTENTFUL_CMS_PAGE_URL,
    COUNTRY_CODE_URL,
    GET_CART_DETAILS,
    GET_OFFERS_URL,
    GET_SESSION_ID,
    GET_WCS_CONTENT,
    IPIFY_API_URL,
    POST_USER_LOG_URL,
    REMOVE_CART_ITEM,
    SAVE_SURVEY_RESPONSE,
    ADD_SUBSKU_CART_URL,
    REMOVE_SUBSKU_CART_URL,
} from "./url";
import { DEFAULT_OFFER_CODE, TABLET_FLOW, TABLET_FLOW_ORIGIN_SYSTEM } from "../constants/urls";

export const addSubSkuToCart = async (payload) => {
    const serviceUrl = ATG_BASE_URL + ADD_SUBSKU_CART_URL;
    const { params } = payload;
    const { productId, subSkuId, quantity, skuCartTitle } = params;
    const formBody = { productId, subSkuId, quantity, skuCartTitle };
    const response = await fetchPostWithFormData(serviceUrl, formBody).then((res) => res);
    return response;
};

export const removeSubSkuFromCart = async (payload) => {
    const serviceUrl = ATG_BASE_URL + REMOVE_SUBSKU_CART_URL;
    const { params } = payload;
    const { productId, subSkuId, quantity, skuCartTitle } = params;
    const formBody = { productId, subSkuId, quantity, skuCartTitle };
    const response = await fetchPostWithFormData(serviceUrl, formBody).then((res) => res);
    return response;
};

export const fetchOffersPlan = async (productId) => {
    const offerId = productId || getUrlParam("offerId") || localStorage.getItem("offerId") || DEFAULT_OFFER_CODE;
    const serviceUrl = ATG_BASE_URL + GET_OFFERS_URL + offerId;
    const response = await fetchGet(serviceUrl).then((res) => res);
    return response;
};
async function addOrUpdateCart(formBody, serviceUrl) {
    const flowType = checkFlowType(formBody && formBody.productId);
    if (flowType === TABLET_FLOW) formBody["originSystem"] = TABLET_FLOW_ORIGIN_SYSTEM;
    formBody["offersPageId"] = getOffersPageId();
    const response = await fetchPostWithFormData(serviceUrl, formBody).then((res) => res);
    return response;
}
export const addItemToCart = async (payload) => {
    const { productId: offerId } = payload || {};
    const serviceUrl = ATG_BASE_URL + ADD_TO_ITEM_CART_URL;
    const productId = offerId || localStorage.getItem("offerId") || DEFAULT_OFFER_CODE;
    const { catalogRefIds, primaryProductCode, purchaseSource = "PAGE_PDP", quantity = 1 } = payload;
    const formBody = { catalogRefIds, primaryProductCode, productId, purchaseSource, quantity };
    return addOrUpdateCart(formBody, serviceUrl);
};
export const updateItemInCart = async (payload) => {
    const { productId: offerId } = payload || {};
    const apiUrl = ATG_BASE_URL + REMOVE_CART_ITEM;
    const productId = offerId || localStorage.getItem("offerId") || DEFAULT_OFFER_CODE;
    const { catalogRefIds, primaryProductCode, purchaseSource = "PAGE_PDP_UPDATE", quantity = 1, removalCommerceIds } = payload;
    const formBody = { catalogRefIds, primaryProductCode, productId, purchaseSource, quantity, removalCommerceIds };
    return addOrUpdateCart(formBody, apiUrl);
};

export const fetchCartDetails = async () => {
    const apiUrl = ATG_BASE_URL + GET_CART_DETAILS;
    const response = await fetchGet(apiUrl);
    return response;
};

export const getSessionId = async () => {
    if (sessionStorage.getItem("sessionId")) {
        return sessionStorage.getItem("sessionId");
    }
    const apiUrl = ATG_BASE_URL + GET_SESSION_ID;
    const result = await createSession(apiUrl).then((res) => res.text());
    if (result.indexOf("Error") === -1 && result.indexOf("sessionConfirmationNumber")) {
        const sessionId = result.substring(result.indexOf(":") + 1, result.indexOf("}"));
        sessionStorage.setItem("sessionId", sessionId);
        return sessionId;
    } else {
        return "";
    }
};

export async function fetchCartAbondonedOrderDetails(action) {
    const { payload } = action || {};
    const apiUrl = CART_ABANDONMENT_ORDER;
    const response = await fetchPostWithFormData(apiUrl, payload);
    return response;
}

export async function fetchWCSContent(path) {
    const serviceUrl = `${GET_WCS_CONTENT}${path}`;
    const response = await fetchGet(serviceUrl);
    return response;
}

export async function logUserAction(payload) {
    const serviceUrl = `${POST_USER_LOG_URL}`;
    const additionalParams = { headers: { "Content-Type": "application/json" } };
    const response = await fetchDirect(serviceUrl, "POST", payload, additionalParams);
    return response;
}

export async function fetchIPAddress() {
    const response = await fetch(IPIFY_API_URL).then((res) => res.text());
    return response;
}

export async function fetchSurveyInfo(value) {
    const locale = localStorage.getItem("i18nextLng");
    const serviceUrl = `${CONTENTFUL_CMS_PAGE_URL}?contentTypeId=programQuestions&field=programId&value=${value}&locale=${locale}&csr=true`;
    const response = await fetchDirect(serviceUrl).then((res) => res.json());
    return response;
}

export async function submitSurveyResponse(payload) {
    const response = await fetchPostWithJSONData(SAVE_SURVEY_RESPONSE, payload).then((res) => res);
    return response;
}
export async function fetchCountryCode(ip) {
    const serviceUrl = `${COUNTRY_CODE_URL}?ip=${ip ? ip : ""}`;
    const response = await fetch(serviceUrl)
        .then((res) => res.json())
        .then((resJson) => resJson);
    return response;
}
