export function reducer(state, action) {
    switch (action.type) {
        case "toggle-popup":
            return { ...state, showPopup: action.payload };
        case "content":
            return { ...state, modalContent: action.payload };
        case "modal-size":
            return { ...state, modalSize: action.payload };
        case "modal-header":
            return { ...state, modalHeader: action.payload };
        case "state-updation":
            return { ...state, ...action.payload };
        default:
            return state;
    }
}