import { usConfig } from "./en_US";

export const useLocalizationLookup = () => {
    const localizationMapper = {
        en_US: usConfig
    };
    let locale = localStorage.getItem("i18nextLng");
    locale = locale ? locale.replace("-","_") : "en_US";
    const localeConfig = localizationMapper[locale];
    const config = localeConfig ? localeConfig : usConfig;
    return config;
};