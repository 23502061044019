import React, { Fragment } from "react";
import PropTypes from "prop-types";

export const ModalContent = ({ customizedContent = null, content = "" }) => {
    return <Fragment>{customizedContent ? customizedContent : content ? content : ""}</Fragment>;
};
ModalContent.propTypes = {
    customizedContent: PropTypes.node,
    content: PropTypes.any,
};
