import { activationActions } from "./activation-page-action";

const initialState = {
    userEmail: "",
    activationCode: "",
    deviceActivationFailed: false,
};

const activation = (state = initialState, action) => {
    switch (action.type) {
        case activationActions.SET_ACTIVATION_CODE:
            return {
                ...state,
                ...action.payload,
            };
        case activationActions.SET_DEVICE_ACTIVATION_FAIL:
            return {
                ...state,
                ...action.payload,
            };
        case activationActions.SET_DEVICE_ACTIVATED:
            return {
                ...state,
                ...action.payload,
            };
        case activationActions.SET_USER_EMAIL:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
export default activation;
