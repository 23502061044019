import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";
import "./toggle-button.scss";
export const ToggleCTAButton = ({
    fields,
    fieldId,
}) => {
    const { toggleItem,
        toggleContent
    } = fields;
    const [toggle, setToggle] = useState(false);
    const onToggleEvent = () => {
        setToggle(!toggle);
    };
    return (
        <Fragment>
            <div id={fieldId} onClick={onToggleEvent} className="toggle-cta-button">
                <FieldRenderer contents={toggleItem} />
            </div>
            <div className={toggle ? "" : "hide-content"}style={{ display: "flex", visibility: toggle ? "visible" : "hidden" }}>
                <FieldRenderer contents={toggleContent} />
            </div>
        </Fragment>
    );
};

ToggleCTAButton.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    FieldRenderer: PropTypes.func,
    onButtonClick: PropTypes.func,
    toggleButton: PropTypes.bool
};
