import { ImageLeftContentRight,
    ImageRightContentLeft,
    ImageTopContentBottom,
    ImageBottomContentTop } from "./image-content-containers";
import { CoverImageContentLeft } from "./cover-image-content-left";
import { CoverImageContentCenter } from "./cover-image-content-center";

export default {
    ImageLeftContentRight,
    ImageRightContentLeft,
    ImageTopContentBottom,
    ImageBottomContentTop,
    CoverImageContentLeft,
    CoverImageContentCenter
};