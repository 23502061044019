import { useEffect } from "react";
import { getCookie, getFunnel, getLandingPageTarget, getOffersPageId, getProductCode, getRoyaltyCode, getUrlParam, setCookie } from "../../../helper/util";
import { PartialProfile, AccountDetails } from "../../../model";
import {
    DEFAULT_OFFER_CODE,
    DEFAULT_PRODUCT_CODE,
    MYX_CREATE_ACCOUNT_PATH,
    MYX_ACTIVATE_BIKE_PATH,
    CHECKOUT_PATH,
    CREATE_PASSWORD_PATH,
} from "../../../constants/urls";
import { checkoutActions } from "../../checkout-page/data/checkout-page-action";
import CryptoJS from "crypto-js";
import { offersActions } from "../../offers-page/data/offers-page-action";
import { LANDING_PAGES } from "../../create-account/components/constants";
import { DYNAMIC_FUNNEL_PAGE_CONFIG } from "../../../constants/dynamic-funnel";

export function myxCreateAccountPageUrl() {
    return MYX_CREATE_ACCOUNT_PATH;
}

export function activateBikePageUrl(email = "") {
    const activationCode = localStorage.getItem("activationCode") || "";
    return `${MYX_ACTIVATE_BIKE_PATH}?activationCode=${activationCode}&email=${email}`;
}

export function getNextPathIndex(funnelPath, index) {
    const skipFunnelFlow = sessionStorage.getItem("skipFunnelFlow");
    if (skipFunnelFlow) {
        const ind = funnelPath.findIndex(
            (path, i) => i > index && DYNAMIC_FUNNEL_PAGE_CONFIG.some((page) => page.path.includes(path) && page.canReVisit && page?.requiredInfo()),
        );
        index = ind !== -1 ? ind - 1 : index;
        funnelPath[index + 1] === skipFunnelFlow && sessionStorage.removeItem("skipFunnelFlow");
    }
    const { isExistingFreeUser, isClubUserCanPurchase } = decipherUserData() || {};
    index = funnelPath[index + 1].includes(CREATE_PASSWORD_PATH) && (isExistingFreeUser || isClubUserCanPurchase) ? index + 1 : index;
    return !DYNAMIC_FUNNEL_PAGE_CONFIG.find((page) => funnelPath[index + 1].includes(page?.path))?.requiredInfo() ? index + 1 : index;
}
function isFunnelPathSameAsPath(funnelPath, path) {
    return path?.includes(funnelPath) ? path : funnelPath;
}
function getNextRoute(history, funnelPath, path) {
    if (funnelPath) {
        let index = funnelPath?.findIndex((path) => history?.location?.pathname.substring(1).includes(path));
        index = getNextPathIndex(funnelPath, index);
        return index !== -1 && index < funnelPath.length ? isFunnelPathSameAsPath(funnelPath[index + 1], path) : null;
    }
    return null;
}

export function redirectWithParam(history, path, priorityPath?, returnPath?, withoutParams = false) {
    const funnelCookie = getCookie("funnels") || null;
    const isBrowserArrow = sessionStorage.getItem("isBrowserArrow");
    if (!path.includes(history?.location?.pathname.substring(1)) && !priorityPath && funnelCookie && !isBrowserArrow) {
        const funnels = JSON.parse(funnelCookie);
        const fId = sessionStorage.getItem("fId");
        const funnel = getFunnel(funnels, fId);
        path = getNextRoute(history, funnel?.fields?.funnelPath, path) || path;
    }
    sessionStorage.removeItem("isBrowserArrow");
    const productCode = getProductCode();
    const rc = getRoyaltyCode();
    const offersPageId = getOffersPageId();
    const paramSeparator = path.indexOf("?") === -1 ? true : false;
    const offerId = localStorage.getItem("offerId") || getUrlParam("offerId") || DEFAULT_OFFER_CODE;
    const lp = getUrlParam("lp") || localStorage.getItem("landingPage");
    localStorage.setItem("offerId", offerId);
    localStorage.setItem("productCode", productCode);
    localStorage.setItem("royaltyCode", rc);
    localStorage.setItem("landingPage", lp);
    const params = `${paramSeparator ? "?" : "&"}${
        path.includes("survey") ? `sId=${sessionStorage.getItem("survey_id")}&` : ""
    }productCode=${rc}&rc=${rc}&offersPageId=${offersPageId}&offerId=${offerId}${lp ? `&lp=${lp}` : ""}`;
    const url = `/${path}${path.includes(CHECKOUT_PATH) || withoutParams ? "" : params}`;
    if (returnPath) {
        return url;
    } else if (history) {
        history?.push(url);
    }
}

export const createPartialProfile = ({ dispatch, payload, callback }) => {
    const primaryProductCode = localStorage.getItem("royaltyCode");
    const refRepId = sessionStorage.getItem("refRepId");
    dispatch({
        type: checkoutActions.CREATE_PARTIAL_PROFILE,
        payload: new PartialProfile({ ...payload, primaryProductCode, refRepId }),
        callbacks: {
            success: callback,
            failure: callback,
        },
    });
};

const secretKey = "7uTbYMUHVUWGMn1VJj_6LceQKwZAAAAAOic6XvWj";

export const encryptData = (data) => {
    // Encrypt
    const encryptedText = CryptoJS.AES.encrypt(data, secretKey).toString();
    return encryptedText;
};

export const decryptData = (encryptedText) => {
    const bytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
    const decipherText = bytes.toString(CryptoJS.enc.Utf8);
    return decipherText;
};

export const decipherUserData = () => {
    const userDataFromCookie = process.env.BROWSER ? getCookie("userData") : null;
    if (!userDataFromCookie) {
        return null;
    }
    let userData = null;
    try {
        const decryptedData = decryptData(userDataFromCookie);
        userData = JSON.parse(decryptedData);
        userData = new AccountDetails(userData);
    } catch (e) {
        console.log("Error in user Data:::", e);
    }
    return userData;
};
export function submitSurveyResponse(dispatch, callback?) {
    const surveyId = sessionStorage.getItem("survey_id");
    let surveyResponse = surveyId ? getCookie(surveyId) : "[]";
    surveyResponse = surveyResponse ? JSON.parse(surveyResponse) : {};
    const answers = [];
    surveyResponse &&
        Object.keys(surveyResponse).map((key) => {
            surveyResponse[key] instanceof Array &&
                surveyResponse[key].map((option) => {
                    !answers.includes(option) && answers.push(option);
                });
        });
    const productCode = localStorage.getItem("royaltyCode") || DEFAULT_PRODUCT_CODE;
    const offerId = localStorage.getItem("offerId") || DEFAULT_OFFER_CODE;
    dispatch({ type: offersActions.SAVE_SURVEY_RESPONSE, payload: { quizResponses: answers, productCode, offerId }, callback: callback });
}
export function triggerUserEmailLookup(value, dispatch, onGetUserDetailsSuccess, onGetUserDetailsFailure) {
    const sessionId = sessionStorage.getItem("sessionId");
    sessionId &&
        dispatch({
            type: checkoutActions.GET_USER_DETAILS,
            payload: { email: value.trim() },
            callbacks: { success: onGetUserDetailsSuccess, failure: onGetUserDetailsFailure },
        });
}

export function useOnClickOutside(ref, handler) {
    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler]);
}

export function remapWithAlternateNavigation(contents, landingPageTarget) {
    const alternateNavigation = contents.fields[landingPageTarget];
    const hasAlternateNavigation = !!alternateNavigation;
    if (!hasAlternateNavigation) {
        return contents;
    }
    const defaultNavigation = LANDING_PAGES.default;
    contents.fields[defaultNavigation] = contents.fields[landingPageTarget];
    return contents;
}

export function replaceAlternateLandingPageNavigation(contents) {
    if (!(contents && contents.fields)) {
        return contents;
    }
    const landingPageCode = getUrlParam("lp") || localStorage.getItem("landingPage");
    const landingPageTarget = getLandingPageTarget(landingPageCode);
    if (landingPageTarget) {
        remapWithAlternateNavigation(contents, landingPageTarget);
    }
    return contents;
}
export const updateUserDetails = (userDetails) => {
    const userData = JSON.stringify(userDetails);
    const encipheredData = encryptData(userData);
    const date = new Date();
    date.setTime(date.getTime() + 60 * 60 * 1000);
    setCookie("userData", encipheredData, date);
};
export const addItemToCart = ({ dispatch, cartDetails }) => {
    const { order, catalogRefIds, onAddItemToCartSuccess, onAddItemToCartFailure } = cartDetails || {};
    const offerId = getUrlParam("offerId") || localStorage.getItem("offerId");
    const productCode = getUrlParam("royaltyCode");
    const removalCommerceIds =
        order &&
        order?.commerceItems
            .map((item) => {
                return item.id;
            })
            .join();
    const payload = {
        offerId,
        catalogRefIds,
        removalCommerceIds,
        isToUpdateCart: removalCommerceIds ? true : false,
        primaryProductCode: productCode || DEFAULT_PRODUCT_CODE,
    };
    offerId &&
        catalogRefIds &&
        dispatch({
            type: offersActions.ADD_ITEM_TO_CART,
            payload,
            callbacks: { success: onAddItemToCartSuccess, failure: onAddItemToCartFailure },
        });
};
