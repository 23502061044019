import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./text-input.scss";
import { Constants } from "../../module/create-account/components";
import { allowOnlyNumber, monthYearValidation } from "../../helper/util";
import { logCustomEvents } from "../../web-analytics";
import { SanitizeHTML } from "../sanitizeHTML";
export const TextInput = ({
    id,
    label,
    type = "text",
    inputType = "text",
    error = null,
    name,
    onBlur,
    onChange = null,
    onKeyPress,
    onKeyUp,
    maxLength,
    disableElement,
    dValue = "",
    placeholder,
    readOnly,
    floatLabel,
    inputStyle,
    textDivStyle,
    icon = null,
    required,
    minLength,
    onClick = null,
    hintElement = null,
    setFormErrors = null,
    hybridValidation = true,
    formData = null,
    onCopy = null,
    autoComplete = "",
    page,
    analyticsId,
    autoFocus,
    refProp = null,
}) => {
    const inputRef = refProp ? { ref: refProp } : {};
    const onChangeValue = (e) => {
        if (!onChange) {
            return;
        }
        try {
            const value = e.target.value;
            const name = e.target.name;
            onChange(name, value);
        } catch (err) {
            console.error("TextInput onChangeValue error::", err);
        }
    };
    const onBlurField = (e) => {
        const checkInputPassword = page === Constants.CREATE_PASSWORD;
        try {
            const name = e.target.name;
            const value = e.target.value;
            value && value.trim() && analyticsId && logCustomEvents(analyticsId.replaceAll(/_/g, ""), analyticsId, value);
            if (!onBlur) {
                return;
            }
            if (onBlur && checkInputPassword) {
                return onBlur;
            }
            hybridValidation && value && onBlur({ name, value, callback: setFormErrors });
            !hybridValidation && onBlur({ name, value, callback: setFormErrors, formData });
        } catch (err) {
            console.error("TextInput onBlurField error::", err);
        }
    };
    const validationType = (e) => {
        switch (inputType) {
            case "number":
                allowOnlyNumber(e);
                break;
            case "mm/yy":
                monthYearValidation(e);
            default:
                break;
        }
    };
    return (
        <Fragment>
            <div className={textDivStyle ? `${textDivStyle} text-input` : "text-input"}>
                {!floatLabel && label && (
                    <label htmlFor={id} className="mb-2 field-label">
                        {label}
                    </label>
                )}
                <input
                    type={type}
                    name={name}
                    className={inputStyle ? inputStyle : "form-control input-element " + (error ? "error-border" : "")}
                    id={id ? id : ""}
                    onBlur={onBlurField}
                    onChange={onChangeValue}
                    autoComplete={autoComplete}
                    onKeyPress={onKeyPress}
                    onKeyUp={onKeyUp}
                    onKeyDown={validationType}
                    maxLength={maxLength}
                    disabled={disableElement}
                    value={dValue}
                    placeholder={placeholder ? placeholder : " "}
                    readOnly={readOnly}
                    required={floatLabel || required}
                    minLength={minLength}
                    onCopy={onCopy}
                    autoFocus={autoFocus}
                    {...inputRef}
                />
                {!placeholder && floatLabel && (
                    <label htmlFor={id} className={floatLabel ? (disableElement ? " disable-input-label" : "floating-label") : ""}>
                        {floatLabel}
                    </label>
                )}
                {icon && <i className={`fa ${icon} align-self-center floating-icon`} onClick={onClick}></i>}
                {hintElement}
            </div>
            {/* {error && typeof error === "string" && <div className="error-msg">{error}</div>} */}
            {error && typeof error === "string" && <SanitizeHTML html={error} tag="div" className="error-msg" />}
        </Fragment>
    );
};
TextInput.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    error: PropTypes.any,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
    maxLength: PropTypes.number,
    disableElement: PropTypes.bool,
    dValue: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    floatLabel: PropTypes.string,
    htmlFor: PropTypes.string,
    inputStyle: PropTypes.string,
    textDivStyle: PropTypes.string,
    icon: PropTypes.string,
    required: PropTypes.bool,
    minLength: PropTypes.number,
    onClick: PropTypes.func,
    hintElement: PropTypes.any,
    setFormErrors: PropTypes.func,
    inputType: PropTypes.string,
    hybridValidation: PropTypes.bool,
    formData: PropTypes.object,
    onCopy: PropTypes.func,
    autoComplete: PropTypes.string,
    page: PropTypes.string,
    analyticsId: PropTypes.string,
    autoFocus: PropTypes.bool,
    refProp: PropTypes.oneOfType([PropTypes.shape({ current: PropTypes.any }), PropTypes.func]),
};
