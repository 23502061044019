import React, { Fragment } from "react";
import PropTypes from "prop-types";
import contentfulConfig from "../config";
import { convertSCSSToCSS } from "../../helper/util";

export const ContentRenderer = ({ content, ...props }) => {
    let convertedCSS = "";
    const renderModules = () => {
        const modules = [];

        const modulesForThisContent = content.fields;
        const { primaryProductCode, interstitialUpsellPages } = modulesForThisContent || {};
        if (modulesForThisContent === undefined) {
            console.error("Cannot render modules for zone . This does not appear to be a valid content zone for this page template.");
            return;
        }
        for (const moduleItem in modulesForThisContent) {
            if (typeof modulesForThisContent[moduleItem] === "object") {
                const ModuleComponentToRender = contentfulConfig.moduleComponents[moduleItem];
                if (ModuleComponentToRender) {
                    const item = modulesForThisContent[moduleItem];
                    const fields = item instanceof Array ? item : item.fields;
                    const entryId = item instanceof Array ? "" : item.sys.id;
                    const { id = "", customCss = "" } = fields;
                    const fieldId = id ? `${id}-${entryId}` : "";
                    !process.env.BROWSER && customCss && (convertedCSS = convertedCSS + convertSCSSToCSS({ fieldId, customCss }));
                    modules.push(
                        <ModuleComponentToRender
                            {...props}
                            fieldId={fieldId}
                            key={moduleItem}
                            id={moduleItem}
                            fields={fields}
                            data={item}
                            primaryProductCode={primaryProductCode}
                            interstitialUpsellPages={interstitialUpsellPages}
                        />,
                    );
                }
            }
        }
        return modules;
    };

    return (
        <Fragment>
            {!process.env.BROWSER && convertedCSS ? <style>{convertedCSS}</style> : null}
            {renderModules()}
        </Fragment>
    );
};
ContentRenderer.propTypes = {
    content: PropTypes.object,
};
