import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
export function BaseOrderSummary({ children, orderSummaryHeader, scrollToTop = false }) {
    const isCheckoutLandscape = useSelector((state) => state.pageRouter.isCheckoutLandscape);
    return <Fragment>{children(isCheckoutLandscape)}</Fragment>;
}
BaseOrderSummary.propTypes = {
    t: PropTypes.func,
    securityContent: PropTypes.any,
    children: PropTypes.func,
    orderSummaryHeader: PropTypes.any,
    scrollToTop: PropTypes.bool,
};
