import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";
import "./dynamic-container.scss";
export const DynamicContainer = ({
    fields, fieldId, isDesktopView, inlineTheme = "", inlineOuterStyle = {}
}) => {
    const {
        containerA,
        containerB,
        defaultAlignment,
        mobileAlignment } = fields;

    let alignment = isDesktopView ? defaultAlignment : mobileAlignment === "default" ? defaultAlignment : mobileAlignment;
    alignment = alignment === "none" ? "" : alignment;
    return (
        <div id={fieldId} className={`dynamic-container ${inlineTheme} ${alignment} content-container`}
            style={inlineOuterStyle}>
            <FieldRenderer contents={containerA} inlineClass="containerA"/>
            { containerB &&
                    <FieldRenderer contents={containerB} inlineClass="containerB"/>
            }
        </div>
    );
};

DynamicContainer.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    inlineTheme: PropTypes.string,
    isDesktopView: PropTypes.bool,
    FieldRenderer: PropTypes.func,
    inlineOuterStyle: PropTypes.object
};
