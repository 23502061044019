import React, { Fragment, useEffect, useRef } from "react";
import PropTypes from "prop-types";

export const AnimateSection = ({ children, animationClass, refProp }) => {
    const ref = useRef(null);
    const containerRef = refProp ? refProp : ref;
    let observer;

    const callBack = (entries) => {
        if (!entries) {
            return;
        }
        const [entry] = entries;
        if (entry && entry.isIntersecting) {
            const { target } = entry;
            if (target) {
                target.style.setProperty("visibility", "visible");
                target.classList.add("animate__animated", animationClass, "animate__slow");
                target.addEventListener("animationend", () => {
                    target.classList.remove("animate__animated", animationClass, "animate__slow");
                    observer.unobserve(target);
                });
            }
        }
    };

    const handlePageShow = (event) => {
        const isProductLanding = window.location?.pathname.indexOf("/product") !== -1;
        if (event?.persisted && isProductLanding) window.location.reload();
    };

    const setScrollAnimation = () => {
        containerRef.current?.style.setProperty("visibility", "hidden");
        observer = new IntersectionObserver(callBack, { threshold: 0.1 });
        if (containerRef.current) observer.observe(containerRef.current);
        if (document) window.addEventListener("pageshow", handlePageShow);
    };

    useEffect(() => {
        animationClass && setScrollAnimation();
    }, []);

    return <Fragment>{children({ containerRef })}</Fragment>;
};

AnimateSection.propTypes = {
    children: PropTypes.func,
    animationClass: PropTypes.string,
    refProp: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};
