import React from "react";
import PropTypes from "prop-types";
import { CTAButton } from "../../../../common";

export const ResetPasswordSuccessPopup = (props) => {
    const { t, closePopup } = props;
    return (
        <div className="pb-5">
            <div className="modal-text pb-4">
                <p className="text-justify reset-email-conf">{t("reset-password-email-sent-message")}</p>
            </div>
            <div className="modal-text">
                <CTAButton buttonName={t("close")} onButtonClick={closePopup} />
            </div>
        </div>
    );
};

ResetPasswordSuccessPopup.propTypes = {
    t: PropTypes.func,
    closePopup: PropTypes.func,
};
