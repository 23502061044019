export class CreateAccount {
    constructor(data = {}) {
        this.email = data.email || "";
        this.password = data.password || "";
    }
}

export class MyxCreateAccount {
    constructor(data = {}) {
        this.email = data.email || "";
        this.password = data.password || "";
        this.firstName = data.firstName || "";
        this.lastName = data.lastName || "";
    }
}
