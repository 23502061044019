import { offersActions } from "./offers-page-action";

const initialState = {
    abondonedUserData: {},
    isLoading: false,
    offersDetails: null,
    order: null,
    productId: "BODStandaloneDirect",
    sessionId: null,
    userSelectedProductId: "",
};

const offers = (state = initialState, action) => {
    switch (action.type) {
        case offersActions.SET_IS_LOADING:
            return {
                ...state,
                ...action.payload,
            };
        case offersActions.SET_OFFERS_DETAILS:
            return {
                ...state,
                ...action.payload,
            };
        case offersActions.UPDATE_ABANDONED_PROFILE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
export default offers;
