import React from "react";
import PropTypes from "prop-types";

export const PasswordValidation = ({ showError, formData, t, hasLowerCaseChar, hasCharCount, hasUpperCaseChar, hasNumber, hasNoSpecialChars, hasNoSpaces }) => {
    const pwdValidationLength = "pwd-validation-length";
    const pwdValidationLowercase = "pwd-validation-lowercase";
    const pwdValidationNumber = "pwd-validation-number";
    const pwdValidationSpace = "pwd-validation-space";
    const pwdValidationSpecialChars = "pwd-validation-special-chars";
    const pwdValidationUppercase = "pwd-validation-uppercase";

    return (
        <ul className={"password-validate " + (formData["password"] !== "" ? "filled" : "unfilled")}>
            <li className={hasLowerCaseChar ? "valid" : showError ? "invalid" : ""}>{t(pwdValidationLowercase)}</li>
            <li className={hasCharCount ? "valid" : showError ? "invalid" : ""}>{t(pwdValidationLength)}</li>
            <li className={hasUpperCaseChar ? "valid" : showError ? "invalid" : ""}>{t(pwdValidationUppercase)}</li>
            <li className={hasNumber ? "valid" : showError ? "invalid" : ""}>{t(pwdValidationNumber)}</li>
            <li className={hasNoSpecialChars ? "valid" : showError ? "invalid" : ""}>{t(pwdValidationSpecialChars)}</li>
            <li className={hasNoSpaces ? "valid" : showError ? "invalid" : ""}>{t(pwdValidationSpace)}</li>
        </ul>
    );
};

PasswordValidation.propTypes = {
    showError: PropTypes.bool,
    formData: PropTypes.object,
    t: PropTypes.func,
    hasLowerCaseChar: PropTypes.bool,
    hasCharCount: PropTypes.bool,
    hasUpperCaseChar: PropTypes.bool,
    hasNumber: PropTypes.bool,
    hasNoSpecialChars: PropTypes.bool,
    hasNoSpaces: PropTypes.bool,
};
