import React, { Fragment } from "react";
import PropTypes from "prop-types";
export const PlainTag = ({
    fields, fieldId
}) => {
    const { tag, innerText } = fields;

    const generateTag = () => {
        switch (tag) {
            case "div":
                return <div id={fieldId}>{innerText}</div>;
            case "span":
                return <span id={fieldId}>{innerText}</span>;
            case "sup":
                return <sup id={fieldId}>{innerText}</sup>;
            default:
                return null;
        }
    };

    return (
        <Fragment>
            {generateTag()}
        </Fragment>
    );
};

PlainTag.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,

};
