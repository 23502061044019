import "isomorphic-fetch";
import { myxFetchGet, myxFetchPostWithJsonData } from "./service";
import { GET_ACTIVATION_CODE, POST_ACTIVATE_BIKE } from "./url";
import { BEACHBODY_ON_DEMAND_API_URL, BEACHBODY_ON_DEMAND_API_URL_API_KEY, BEACHBODY_ON_DEMAND_MESSAGE_API_URL, BEACHBODY_ON_DEMAND_MESSAGE_API_KEY } from "../constants/urls";

export const validateActivationCode = async (payload) => {
    const { activationCode } = payload;
    const serviceUrl = BEACHBODY_ON_DEMAND_API_URL + GET_ACTIVATION_CODE + activationCode;
    const headers = {
        "Content-Type": "application/json",
        "x-api-key": BEACHBODY_ON_DEMAND_API_URL_API_KEY,
    };
    const response = await myxFetchGet(serviceUrl, headers).then((res) => res);
    // This API returns an empty body on successful response 
    return response;
};

export const postActivationCode = async (payload) => {
    const { profileId, activationCode, accessToken } = payload;
    const serviceUrl = BEACHBODY_ON_DEMAND_MESSAGE_API_URL + POST_ACTIVATE_BIKE + activationCode;
    const addtlHeaders = {
        "Content-Type": "text/plain;charset=UTF-8",
        "x-api-key": BEACHBODY_ON_DEMAND_MESSAGE_API_KEY,
    };
    const formBody = {
        "profileId": profileId,
        "activationCode": activationCode,
        "token": accessToken,
        //isOAuth: true // [optional, send true only if login is done through OAuth]
    };
    const response = await myxFetchPostWithJsonData(serviceUrl, formBody, addtlHeaders).then((res) => res);
    return response;
};
