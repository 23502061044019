import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { CTAButton } from "../../common";
import { pageRouterActions } from "../../component/page-router/data/page-router-action";
import { MYX_ACTIVATE_BIKE_PATH, MYX_ACTIVATION_SUCCESS, MYX_CHECKOUT_PATH, MYX_CREATE_ACCOUNT_PATH, MYX_FORGOT_PASSWORD_PATH } from "../../constants/urls";
import { getProductDetails, getUrlParam, setCookie, setProductid } from "../../helper/util";
import { CreateAccount } from "../../model";
import { checkoutActions } from "../checkout-page/data/checkout-page-action";
import { decipherUserData, encryptData, redirectWithParam } from "../common/util";
import { validateField } from "../common/validator";
import { Loader } from "../../common";
import { EmailLookupStatus, EmailPanel, PasswordPanel } from "../create-account/components";
import { offersActions } from "../offers-page/data/offers-page-action";
import { ErrorMessageToaster } from "../../common/message-toaster";
import { BODINT_ENTITLEMENT } from "../create-account/components/constants";
import { logProductData, logLinkTracking } from "../../web-analytics";
import * as tealiumTags from "../../constants/tealium-tags";
import { ActivateBikeError } from "../activation-page/components/ActivateBikeError";

export function MyxLoginPage({ ...props }) {
    const { t } = props;
    const emailRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const bikeLoginCheckout = sessionStorage.getItem("bikeLoginCheckout") ? true : false;
    const [isRegisteredUser, setRegisteredUser] = useState(false);
    const { userDetail, digitalData } = useSelector((state) => state.pageRouter);
    const { offersDetails, order } = useSelector((state) => state.offers);
    const { enableOneBodi } = useSelector((state) => state.checkout);
    const [emailLookupStatus, setEmailLookupStatus] = useState(EmailLookupStatus.NOT_TRIGGED);
    const [accountFormData, setAccountFormData] = useState(new CreateAccount({ ...userDetail }));
    const [formErrors, setAccountFormErrors] = useState(new CreateAccount());
    const [showPageLoader, setPageLoader] = useState(bikeLoginCheckout);
    const [goToNextPage, redirectToNextPage] = useState(false);
    const [isUserAuthorised, setUserAuthorised] = useState(false);
    const activationCode = localStorage.getItem("activationCode");
    const autoLoginGuid = sessionStorage.getItem("guid");
    const [errorMessage, setError] = useState();
    const [showPasswordPanel, setShowPassword] = useState();
    const [passwordTokenFailed, setPasswordTokenFailed] = useState(sessionStorage.getItem("resetPassValFail") || false);
    const [customerTypeRestriction, setCustomerTypeRestriction] = useState(false);
    const onChange = (name, value) => {
        const details = { ...accountFormData, ...userDetail };
        setAccountFormData({ ...new CreateAccount(details), [name]: value });
        setAccountFormErrors({ ["password"]: "" });
        setError(null);
    };
    const onEnter = (event) => {
        setPasswordTokenFailed(false);
        sessionStorage.removeItem("resetPassValFail");
        const { keyCode, charCode } = event || {};
        (keyCode || charCode) === 13 ? onContinueBtn() : null;
    };
    const setFormErrors = (name, value) => {
        setAccountFormErrors({ ...formErrors, [name]: value ? t(value) : "" });
    };
    const goEditEmail = () => {
        setAccountFormData({ ...accountFormData, password: "" });
        dispatch({ type: pageRouterActions.EDIT_EMAIL, payload: true });
        redirectWithParam(history, MYX_CREATE_ACCOUNT_PATH.substring(1));
    };

    const sessionId = sessionStorage.getItem("sessionId");

    const encryptUserData = (userInfo = {}) => {
        setPageLoader(true);
        const { email, password } = accountFormData;
        const userDetails = { ...userDetail, ...userInfo, email, password };
        dispatch({ type: pageRouterActions.SET_USER_DETAILS, payload: userDetails });
        const userData = JSON.stringify(userDetails);
        const encipheredData = encryptData(userData);
        const date = new Date();
        date.setTime(date.getTime() + 60 * 60 * 1000);
        setCookie("userData", encipheredData, date);
    };
    const onAddItemToCartSuccess = () => {
        goToMyxCheckout();
    };
    const onAddItemToCartFailure = (error) => {
        const { formExceptions, formError } = error || {};
        if (formError) {
            setCustomerTypeRestriction(formExceptions?.some((exception) => exception?.errorCode === "notEligibleItem"));
        }
    };
    const goToActivateBike = () => {
        setAccountFormData({ ...accountFormData, password: "" });
        props.history.push(MYX_ACTIVATE_BIKE_PATH);
    };
    const goToMyxCheckout = () => {
        const details = decipherUserData();
        accountFormData && accountFormData.email && encryptUserData(details);
        redirectWithParam(history, MYX_CHECKOUT_PATH.substring(1));
    };
    const goToMyxCreateAccount = () => {
        encryptUserData();
        setAccountFormData({ ...accountFormData, password: "" });
        redirectWithParam(history, MYX_CREATE_ACCOUNT_PATH.substring(1));
    };
    const goToForgotPass = () => {
        encryptUserData();
        setAccountFormData({ ...accountFormData, password: "" });
        redirectWithParam(history, MYX_FORGOT_PASSWORD_PATH.substring(1));
    };
    const onBikeActivationSuccess = () => {
        props.history.push(MYX_ACTIVATION_SUCCESS);
    };
    const onBikeActivationFailure = () => {
        console.log("Failure Activating Bike. Redirecting... ");
        props.history.push(MYX_ACTIVATION_SUCCESS);
    };
    const onCartDetailsFetchSuccess = () => {
        enableOneBodi ? goToMyxCheckout() : setUserAuthorised(true);
    };

    const onCartDetailFetchFailure = () => {
        console.log("Failure getting cart details.");
    };
    const userLoginSuccess = (result) => {
        const { guid, email, accessToken } = result || {};
        const fullUserDetail = { ...userDetail, ...result };
        const activationCode = localStorage.getItem("activationCode") || "";
        const entitlements = fullUserDetail && fullUserDetail.entitlements && fullUserDetail.entitlements.split(",");
        const isBODiUser = entitlements && entitlements.indexOf(BODINT_ENTITLEMENT) !== -1;

        encryptUserData(fullUserDetail);

        if (isBODiUser) {
            dispatch({
                type: checkoutActions.ACTIVATE_MYX_BIKE,
                payload: {
                    accessToken: accessToken,
                    activationCode: activationCode,
                    profileId: guid,
                    isOAuth: true,
                },
                callbacks: { success: onBikeActivationSuccess, failure: onBikeActivationFailure },
            });
        } else {
            dispatch({
                type: checkoutActions.USER_LOGIN_SYNCUP_ATG,
                payload: { guid, email, access_token: accessToken },
                callbacks: { success: onCartDetailsFetchSuccess, failure: onCartDetailFetchFailure },
            });
        }
    };
    const autoLoginSuccess = (result) => {
        const guid = sessionStorage.getItem("guid");
        const accessToken = sessionStorage.getItem("authToken");
        const fullUserDetail = { ...userDetail, ...result, accessToken: accessToken, guid: guid, email: "" };
        userLoginSuccess(fullUserDetail);
    };
    const userLoginFailure = () => {
        console.log("Login Failed!", t("incorrect-password"));
        setAccountFormData({ ...accountFormData, password: "" });
        setError(t("incorrect-password"));
    };
    const onSignIn = (password) => {
        if (password) {
            dispatch({
                type: checkoutActions.USER_LOGIN,
                payload: { email: accountFormData["email"], password: password },
                callbacks: { success: userLoginSuccess, failure: userLoginFailure },
            });
        }
    };
    const onContinueBtn = () => {
        const { email, password } = accountFormData;
        if ((!userDetail && email) || (userDetail && !userDetail.email && !password)) {
            emailRef.current.triggerEmailLookup(email);
        } else if (userDetail && userDetail.email && password) {
            onSignIn(password, passwordMessage);
        }
    };
    const passwordMessage = (error, errormsg) => {
        console.log("ERROR FROM LOGIN: ", error);
        error ? setError(errormsg === "invalid_credentials" ? t("incorrect-password") : null) : null;
    };
    const addMyxProductToCart = () => {
        const productId = localStorage.getItem("offerId");
        const productCode = localStorage.getItem("royaltyCode");
        const catalogRefIds = offersDetails && offersDetails[0].id ? offersDetails[0].id : "";
        catalogRefIds && logProductData(productId, catalogRefIds, offersDetails["0"], { ...digitalData, guid: userDetail && userDetail.guid });
        const removalCommerceIds =
            order &&
            order.commerceItems
                .map((item) => {
                    return item.id;
                })
                .join();
        const payload = {
            productId,
            catalogRefIds,
            removalCommerceIds,
            isToUpdateCart: removalCommerceIds ? true : false,
            primaryProductCode: productCode,
        };
        productId &&
            catalogRefIds &&
            dispatch({
                type: offersActions.ADD_ITEM_TO_CART,
                payload,
                callbacks: { success: onAddItemToCartSuccess, failure: onAddItemToCartFailure },
            });
    };
    const loadPassWordTokenFailMessage = () => {
        return passwordTokenFailed ? (
            <ErrorMessageToaster message={t("reset-password-validation-message")} messageHeading={t("reset-password-error-heading")} showIcon={true} />
        ) : (
            <></>
        );
    };
    const formProps = {
        emailLookupStatus,
        emailRef,
        formData: accountFormData,
        formErrors: formErrors,
        isRegisteredUser,
        onCartDetailFetchFailure,
        onCartDetailsFetchSuccess,
        onChange,
        onContinueBtn,
        onEnter,
        redirectToNextPage,
        setEmailLookupStatus,
        setFormErrors,
        setRegisteredUser,
        showPageLoader,
        t,
        validateField,
    };
    useEffect(() => {
        if (sessionId) {
            const activationCodeParam = getUrlParam("activationCode") || localStorage.getItem("activationCode");
            if (!activationCodeParam) {
                goToActivateBike();
            } else if (userDetail && !userDetail.email) {
                goToMyxCreateAccount();
            }
            setAccountFormData({ ...accountFormData, password: "" });
        }
    }, [sessionId]);
    useEffect(() => {
        if (isUserAuthorised) {
            setProductid(userDetail);
            offersDetails ? addMyxProductToCart() : getProductDetails(userDetail, dispatch, !bikeLoginCheckout);
        }
    }, [isUserAuthorised]);
    useEffect(() => {
        if (!enableOneBodi && offersDetails && isUserAuthorised) {
            addMyxProductToCart();
        }
    }, [offersDetails]);
    useEffect(() => {
        if (autoLoginGuid && activationCode) {
            dispatch({
                type: checkoutActions.GET_USER_DETAILS,
                payload: { criteria: "guid", criteriaValue: autoLoginGuid },
                callbacks: { success: autoLoginSuccess, failure: userLoginFailure },
            });
        }
    }, [activationCode, autoLoginGuid]);
    useEffect(() => {
        if (goToNextPage) {
            userDetail && userDetail.isNewUser ? goToMyxCreateAccount() : setShowPassword(true);
            redirectToNextPage(false);
        }
    }, [goToNextPage]);
    const validateInputs = () => {
        const validateEmail = validateField({ name: "email", value: accountFormData.email }) ? false : true;
        return accountFormData["password"] !== "" || (accountFormData.email && !showPasswordPanel && validateEmail) ? "has-value" : "";
    };
    useEffect(() => {
        showPasswordPanel && !bikeLoginCheckout && setPageLoader(false);
    }, [showPasswordPanel]);
    useEffect(() => {
        userDetail && setShowPassword(!userDetail.isNewUser && userDetail.email);
        offersDetails &&
            dispatch({
                type: offersActions.SET_OFFERS_DETAILS,
                payload: { offersDetails: null },
            });
        return () => {
            sessionStorage.removeItem("resetPassValFail");
        };
    }, []);
    const onForgotPassword = () => {
        logLinkTracking(tealiumTags.myx_account_password);
        goToForgotPass();
    };
    const getCustomerRestrictionError = () => {
        const { entitlements } = userDetail || {};
        const titles = entitlements?.split(",");
        return titles?.includes("BOD") && !titles?.includes("BODINT") ? t("bodi-customer-restriction") : t("bod+bodi-customer-restriction");
    };
    const edit = t("edit");
    const loginTitle = t("login-title");
    const forgotPassword = t("forgot-password");
    const continueBtn = t("continue-btn");
    const emailAddress = t("email");

    return (
        <Fragment>
            {!showPageLoader ? (
                <section className="myx-login-page container">
                    <div className="myx-container w-100">
                        <div className="myx-panel">
                            <h1>{loginTitle}</h1>
                            {loadPassWordTokenFailMessage()}
                            {(!userDetail || (userDetail && !userDetail.email) || !showPasswordPanel) && (
                                <EmailPanel {...props} {...formProps} ref={emailRef} isWithLoginFlow={false} onKeyPress={onEnter} />
                            )}
                            {showPasswordPanel ? (
                                <div className={`password-entry ${errorMessage ? "error" : ""}`}>
                                    <div className="email">
                                        <div className="email-label">{emailAddress}</div>
                                        <div className="current-email">
                                            {userDetail.email}
                                            <a className="edit-email-link" onClick={() => goEditEmail()}>
                                                {edit}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="password-entry-form">
                                        <PasswordPanel {...formProps} onKeyPress={onEnter} onBlur={validateField} />
                                        {errorMessage && (
                                            <Fragment>
                                                <span className="mb-n1 error-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g fill="none" fillRule="evenodd">
                                                            <g>
                                                                <g>
                                                                    <g>
                                                                        <g fill="#D50000">
                                                                            <path
                                                                                d="M11.813 0c.35 0 .638.03.864.09.226.06.42.157.584.292.163.136.346.315.548.538l3.654 4.144c.218.255.362.486.432.693.07.207.105.518.105.932v4.634c0 .406-.035.715-.105.926-.07.21-.214.444-.432.699l-3.654 4.132c-.202.223-.385.402-.548.538-.164.135-.358.233-.584.292-.226.06-.514.09-.864.09H6.198c-.358 0-.647-.03-.87-.09-.221-.06-.416-.157-.583-.292-.167-.136-.348-.315-.543-.538L.537 12.948c-.218-.255-.362-.488-.432-.7-.07-.21-.105-.519-.105-.925V6.69c0-.414.035-.725.105-.932.07-.207.214-.438.432-.693L4.202.92c.195-.223.376-.402.543-.538.167-.135.362-.233.584-.292.222-.06.511-.09.87-.09h5.614zM5.896 5.101c-.222-.154-.53-.132-.729.066-.223.224-.223.585 0 .808L8.192 9l-3.025 3.025-.066.079c-.154.222-.132.53.066.729.224.223.585.223.808 0L9 9.808l3.025 3.025.079.066c.222.154.53.132.729-.066.223-.224.223-.585 0-.808L9.808 9l3.025-3.025.066-.079c.154-.222.132-.53-.066-.729-.224-.223-.585-.223-.808 0L9 8.192 5.975 5.167z"
                                                                                transform="translate(-1048.000000, -338.000000) translate(596.000000, 322.000000) translate(452.000000, 16.000000) translate(3.000000, 3.000000)"
                                                                            />
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <div className="error-msg password-error d-flex align-items-center mt-n1">{errorMessage}</div>
                                            </Fragment>
                                        )}
                                    </div>
                                    <a onClick={onForgotPassword} className="forgot-password">
                                        {forgotPassword}
                                    </a>
                                </div>
                            ) : (
                                ""
                            )}
                            <CTAButton
                                buttonName={continueBtn}
                                icon={showPageLoader ? "fa fa-spinner fa-spin" : ""}
                                innerBtnClass={validateInputs()}
                                onButtonClick={onContinueBtn}
                                outerBtnClass="continue-btn"
                            />
                        </div>
                    </div>
                </section>
            ) : !customerTypeRestriction ? (
                <section className="myx-loader container">
                    <div className="myx-container w-100">
                        <div className="myx-panel">
                            <Loader className="loader-wrapper" isToShowLoader={true} />
                        </div>
                    </div>
                </section>
            ) : (
                <section className="myx-customer-restriction container">
                    <div className="myx-container w-100">
                        <div className="myx-panel">
                            <ActivateBikeError errorTitle={t("oops-msg")} errorDescription={getCustomerRestrictionError()} />
                        </div>
                    </div>
                </section>
            )}
        </Fragment>
    );
}

MyxLoginPage.propTypes = {
    history: PropTypes.object,
    props: PropTypes.object,
    t: PropTypes.func,
};
