export const noAutoComplete = "new-password";
export const zip = "zip";
export const addressLine1 = "address-line1";
export const addressLine2 = "address-line2";
export const COACH = "COACH";
export const cardErrorMessage =
    "Your credit card has been declined. Please try another method of payment or call your financial institution for more information."; // TODO: Should this be content managed?
export const defaultPostalCodeUK = "EH24AD";
export const paypalButtonStyles = {
    color: "gold",
    shape: "pill",
    label: "pay",
    tagline: "false",
};
