import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { BaseOffersTile } from "./base-offer-tile";
export const FreeTrailTile = (props) => {
    const { plan = {} } = props;

    return (
        <BaseOffersTile outerDivStyle="free-trial-box" {...props}>
            {({ showCTAButton }) => {
                return (
                    <Fragment>
                        <div className="d-flex row free-trial-box-desktop mt-3">
                            <div className="free-trial-left pl-3">
                                <div className="trial-title">{plan.localizedTitle}</div>
                                <hr className="trial-title-border" />
                                <div
                                    className="bill-terms"
                                >{plan.subtitle2}</div>
                            </div>
                            <div className="free-trial-right px-3">{showCTAButton()}</div>
                        </div>
                        <div className="free-trial-box-mobile">
                            <div className="free-trial-top w-100 py-1 px-2">
                                <div className="trial-title">{plan.localizedTitle}</div>
                            </div>
                            <div className="free-trial-bottom w-100">
                                <div className="spacer"></div>
                                <div
                                    className="bill-terms"
                                >{plan.subtitle2}</div>
                                {showCTAButton()}
                            </div>
                        </div>
                    </Fragment>
                );
            }}
        </BaseOffersTile>
    );
};

FreeTrailTile.propTypes = {
    plan: PropTypes.object,
};
