import React from "react";
import PropTypes from "prop-types";
import "./offers-header.scss";
import { useSelector } from "react-redux";
import { checkIfODPFlow } from "../../helper/util";

export const OffersHeader = ({ fields }) => {
    const isDesktopView = useSelector((state) => state.pageRouter.isDesktopView);
    const {
        companyLogo,
        brandLogo,
        companyLogoMobile,
        backgroundImage,
        backgroundImageMobile,
        headerTitleTextColor,
        headerTitleTextColorMobile,
        backgroundColor,
        backgroundColorMobile,
        headerTitleText = "",
        headerTitleTextMobile = "",
    } = fields;
    const offerId = localStorage.getItem("offerId");
    const isODPFlow = checkIfODPFlow(offerId);
    const backgroundImg = isDesktopView ? backgroundImage : backgroundImageMobile;
    const headerStyle = {
        background: backgroundImg && backgroundImg.fields.file.url ? `url(${backgroundImg.fields.file.url}) top center/cover no-repeat` : "",
        height: backgroundImg && backgroundImg.height ? `${backgroundImg.height} px` : "",
        backgroundColor: isDesktopView ? backgroundColor : backgroundColorMobile,
    };

    const headerTitleStyle = {
        color: isDesktopView ? headerTitleTextColor : headerTitleTextColorMobile,
    };
    const bbLogo = isDesktopView ? companyLogo : companyLogoMobile;
    const headerTitle = isDesktopView ? headerTitleText : headerTitleTextMobile;

    return (
        <header>
            <section>
                <div className={`offers-header align-items-center w-100 ${isODPFlow ? "hidden" : ""}`} style={headerStyle}>
                    <div className="container">
                        <div className="row">
                            <div className={`bb-logo col-xs-12 col-sm-12 ${!isDesktopView && brandLogo ? "show-brand" : "align-items-center"}`}>
                                <div>{bbLogo ? <img src={bbLogo.fields.file.url} /> : null}</div>
                                {!isDesktopView && brandLogo && (
                                    <div>
                                        <img className="brand-logo" src={brandLogo.fields.file.url} />
                                    </div>
                                )}
                            </div>
                            {!isDesktopView && headerTitle ? (
                                <div className="header-title col-xs-12 col-sm-12 text-center">
                                    <h1 style={headerTitleStyle} className="header-text">
                                        {headerTitle}
                                    </h1>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {isDesktopView && headerTitle ? (
                        <div className="container text-header-container">
                            <div className="header-title col-xs-12 col-sm-12 text-center">
                                <div className="row">
                                    <h1 style={headerTitleStyle} className="header-text">
                                        {headerTitle}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </section>
        </header>
    );
};
OffersHeader.propTypes = {
    fields: PropTypes.object,
};
