/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { CreateAccount } from "../../model";
import { validateField } from "../common/validator";
import { pageRouterActions } from "../../component/page-router/data/page-router-action";
import { offersActions } from "../offers-page/data/offers-page-action";
import {
    checkWebSourcecodeCookie,
    clearCookie,
    decodeURIHelper,
    getUrlParam,
    setCookie,
    checkIfODPFlow,
    getProductCode,
    getRoyaltyCode,
} from "../../helper/util";
import { createPartialProfile, encryptData, redirectWithParam, submitSurveyResponse, triggerUserEmailLookup } from "../common/util";
import { EmailLookupStatus, Constants } from "./components";
import "./create-account-page-content.scss";
import { useHistory } from "react-router";
import { CHECKOUT_PATH, CREATE_ACCOUNT_PATH, CREATE_PASSWORD_PATH, DEFAULT_OFFER_CODE, OFFERS_PATH } from "../../constants/urls";
import { Loader } from "../../common";
import { SurveyIntroOutro } from "../survey/intro-page";
import { CheckoutEmailLookupModal } from "./components/modal/checkout-email-lookup-modal";
import { PopupEmitter, TRIGGER_POPUP } from "../../common/popup-emitter";
import { getConfig } from "./components/modal/config";
import usePasswordValidation from "../../hooks/usePasswordValidation";
import { addItemToCart } from "../common/util";
import { ERROR_CODE_PROGRAM_ENTITLEMENT } from "./components/constants";

export const BaseCreateAccountWithoutLogin = ({ page, children, ...props }) => {
    const { t } = props;
    const emailRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isRegisteredUser, setRegisteredUser] = useState(false);
    const { userDetail, editEmail, outroPage } = useSelector((state) => state.pageRouter);
    const { sessionId, order, offersDetails } = useSelector((state) => state.offers);
    const [emailLookupStatus, setEmailLookupStatus] = useState(EmailLookupStatus.NOT_TRIGGED);
    const [accountFormData, setAccountFormData] = useState(new CreateAccount({ ...userDetail }));
    const [formErrors, setAccountFormErrors] = useState(new CreateAccount());
    const [showPageLoader, setPageLoader] = useState(false);
    const [isCartAbandonmentFlow, setCartAbandonmentFlow] = useState(false);
    const [emailQueryParam, setEmailQueryParam] = useState(false);
    const [goToNextPage, redirectToNextPage] = useState(false);
    const { passwordValidations, validatePassword, displayPasswordError } = usePasswordValidation();
    const { hasCharCount, hasLowerCaseChar, hasNoSpaces, hasNoSpecialChars, hasNumber, hasUpperCaseChar } = passwordValidations;
    const [showError, setShowError] = useState(false);
    const [disableBtn, setDisableBtn] = useState(true);
    const oid = decodeURIHelper(getUrlParam("oid")) || sessionStorage.getItem("oid") || "";
    const autoAddSkuId = sessionStorage.getItem("skuId");
    const [programConflictLookup, setProgramConflictLookup] = useState(autoAddSkuId ? true : false);
    const [programEntitlementConflict, setProgramEntitlementConflict] = useState();

    const onChange = (name, value) => {
        const details = { ...accountFormData, ...userDetail };
        const checkInputName = {
            PASSWORD: "password",
        };
        const isPasswordInput = name === checkInputName.PASSWORD;
        setAccountFormData({ ...new CreateAccount(details), [name]: value });
        if (isPasswordInput) {
            validatePassword(value);
            setAccountFormData({ ...details, [name]: value });
            validateFormErrors(name, value);
            if (value) {
                const hasError = validateFormErrors("password", value);
                setShowError(hasError);
                hasError ? setDisableBtn(true) : setDisableBtn(false);
            }
        }
    };
    const onBlur = (password) => {
        if (password.value && displayPasswordError) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    };
    const onEnter = (event) => {
        const { keyCode, charCode } = event || {};
        (keyCode || charCode) === 13 ? onContinueBtn() : null;
    };
    const setFormErrors = (name, value) => {
        setAccountFormErrors({ ...formErrors, [name]: value ? t(value) : "" });
    };
    const onCartDetailsFetchSuccess = (order, profile = {}) => {
        const { commerceItems } = order || {};
        const cartItem = commerceItems && commerceItems.length && order.commerceItems[0];
        const { productId } = cartItem || {};
        oid && productId && localStorage.setItem("offerId", productId);
        if (profile) {
            const { email } = profile || {};
            if (email) {
                onChange("email", email);
                emailRef.current.triggerEmailLookup(email);
            }
        } else if (commerceItems instanceof Array && commerceItems.length === 0) {
            //if no commerceId passed, User will remain in create account page
            removeCartAbondonementId();
        }
    };
    const onCartDetailFetchFailure = () => {
        removeCartAbondonementId();
        window.location = "/";
    };
    const removeCartAbondonementId = () => {
        sessionStorage.removeItem("oid");
        setCartAbandonmentFlow(false);
    };
    const validateFormErrors = (name, value) => {
        const error = validateField({ name, value, hybridValidator: true });
        if (error) {
            setFormErrors(name, error);
            return true;
        } else {
            setFormErrors(name, "");
            return false;
        }
    };
    const encryptUserData = () => {
        const { email, password } = accountFormData;
        const userDetails = { ...userDetail, email, password };
        dispatch({ type: pageRouterActions.SET_USER_DETAILS, payload: userDetails });
        const userData = JSON.stringify(userDetails);
        const encipheredData = encryptData(userData);
        const date = new Date();
        date.setTime(date.getTime() + 60 * 60 * 1000);
        setCookie("userData", encipheredData, date);
    };
    const goToOffers = () => {
        encryptUserData();
        const url = order?.commerceItems?.length > 0 ? CHECKOUT_PATH : OFFERS_PATH;
        redirectWithParam(props.history, url);
    };
    const goToCreatePassword = (payload) => {
        payload && dispatch({ type: pageRouterActions.SET_USER_DETAILS, payload: payload });
        redirectWithParam(history, CREATE_PASSWORD_PATH);
    };
    const goToCheckout = () => {
        encryptUserData();
        props.history.push(`/${CHECKOUT_PATH}`);
    };
    const redirectToPasswordPage = () => {
        encryptUserData();
        const { email } = accountFormData;
        goToCreatePassword({ email });
    };
    const submitSurveyData = () => {
        submitSurveyResponse(dispatch, redirectToPasswordPage);
    };
    const redirectPage = () => {
        if (isCartAbandonmentFlow) {
            if (userDetail && userDetail.isNewUser) {
                page === Constants.CREATE_ACCOUNT && goToCreatePassword();
                page === Constants.CREATE_PASSWORD && goToCheckout();
            } else if ((userDetail && userDetail.isExistingFreeUser) || userDetail.isClubUserCanPurchase) {
                goToCheckout();
            } else {
                window.location = "/";
            }
        } else {
            const offerId = localStorage.getItem("offerId") || DEFAULT_OFFER_CODE;
            const isODPFlow = checkIfODPFlow(offerId);

            if (page === Constants.CREATE_ACCOUNT && userDetail && userDetail.isNewUser) {
                createPartialProfile({ dispatch, payload: accountFormData, callback: submitSurveyData });
            } else if (page === Constants.CREATE_ACCOUNT && userDetail && userDetail.isExistingFreeUser) {
                page === Constants.CREATE_ACCOUNT && submitSurveyResponse(dispatch, goToOffers);
            } else if (
                (page === Constants.CREATE_ACCOUNT && userDetail && userDetail.isClubUserCanPurchase && isODPFlow) ||
                page === Constants.CREATE_PASSWORD
            ) {
                goToOffers();
            } else {
                setPageLoader(false);
            }
        }
    };
    const emailEdit = () => {
        dispatch({ type: pageRouterActions.EDIT_EMAIL, payload: true });
        redirectWithParam(history, CREATE_ACCOUNT_PATH, true);
    };
    const onContinueBtn = () => {
        if (page === Constants.CREATE_ACCOUNT) {
            if (!validateFormErrors("email", accountFormData["email"])) {
                setPageLoader(true);
                emailRef.current.triggerEmailLookup(accountFormData["email"]);
            }
        } else if (page === Constants.CREATE_PASSWORD) {
            if (!validateFormErrors("password", accountFormData["password"])) {
                setShowError(false);
                setPageLoader(true);
                redirectPage();
            } else {
                setShowError(true);
            }
        }
    };
    const updateUserDetails = (newUser, existingUser, userDetails) => {
        dispatch({
            type: pageRouterActions.SET_USER_DETAILS,
            payload: {
                isExistingFreeUser: existingUser,
                isNewUser: newUser,
                createPartialProfile: newUser,
                ...userDetails,
            },
        });
    };
    const redirectToCreateAccount = () => {
        redirectWithParam(history, CREATE_ACCOUNT_PATH);
    };
    const onQueryParamEmailLookupSuccess = (userDetails) => {
        const { customerType, isCoach } = userDetails || {};
        if (customerType) {
            const config = getConfig(customerType, isCoach);
            if (config) {
                const { header, signInUrl, signInLabel, modal } = config || {};
                if (modal === "customerWithoutBOD") {
                    updateUserDetails(false, true, userDetails);
                    emailEdit();
                } else {
                    redirectWithParam(history, CREATE_ACCOUNT_PATH);
                    PopupEmitter.emit(TRIGGER_POPUP, {
                        closeButton: false,
                        showPopup: true,
                        modalId: `login-modal:with-bod:${customerType.split(",").join(":").toLowerCase()}`,
                        modalHeader: t(header),
                        customizedContent: (
                            <CheckoutEmailLookupModal
                                t={t}
                                closePopup={redirectToCreateAccount}
                                signInUrl={signInUrl}
                                signInLabel={signInLabel}
                                customerWithoutBOD={false}
                            />
                        ),
                    });
                }
            }
        } else {
            updateUserDetails(true, false, userDetails);
            createPartialProfile({ dispatch, payload: userDetails });
        }
        setEmailQueryParam(false);
    };
    const onQueryParamEmailLookupFail = () => {
        redirectToCreateAccount();
    };
    const checkSkuIdMapped = (offers) => {
        const skuData = Array.isArray(offers) ? offers : offers?.product?.skus;
        const skuFound = skuData?.find((item) => item?.id === autoAddSkuId);
        if (skuFound && !order) dispatch({ type: offersActions.GET_CART_DETAILS, callbacks: { success: onCartDetailCallback } });
        else setProgramConflictLookup(false);
    };
    const onCartDetailCallback = (order) => {
        const catalogRefIds = autoAddSkuId || "";
        const cartDetails = { order, catalogRefIds, onAddItemToCartFailure: onAddItemToCartFailure };
        addItemToCart({ dispatch, cartDetails });
        setProgramConflictLookup(false);
    };
    const onAddItemToCartFailure = (response) => {
        if (response?.formExceptions[0]?.errorCode === ERROR_CODE_PROGRAM_ENTITLEMENT) {
            setProgramEntitlementConflict(response?.formExceptions[0]?.localizedMessage);
        }
    };
    const validateEmailClientSide = (e) => {
        const currentValue = e.target.value;
        const filter =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        currentValue.match(filter) ? setDisableBtn(false) : setDisableBtn(true);
    };
    useEffect(() => {
        if (sessionId) {
            checkWebSourcecodeCookie();
            if (oid) {
                setCartAbandonmentFlow(true);
                sessionStorage.setItem("oid", oid);
                dispatch({ type: pageRouterActions.SET_USER_DETAILS, payload: { oid } });
                !editEmail &&
                    page === Constants.CREATE_ACCOUNT &&
                    dispatch({
                        type: offersActions.GET_CART_ABANDONED_ORDER_DETAILS,
                        payload: { oid },
                        callbacks: { success: onCartDetailsFetchSuccess, failure: onCartDetailFetchFailure },
                    });
            } else {
                sessionStorage.removeItem("oid");
            }
            const emailQParam = getUrlParam("email");
            const productCode = getProductCode();
            const royaltyCode = getRoyaltyCode();
            const offerId = getUrlParam("offerId") || DEFAULT_OFFER_CODE;
            productCode && localStorage.setItem("productCode", productCode);
            royaltyCode && localStorage.setItem("royaltyCode", royaltyCode);
            if (offerId) {
                localStorage.setItem("offerId", offerId);
            }
            emailQParam && triggerUserEmailLookup(decodeURIComponent(emailQParam), dispatch, onQueryParamEmailLookupSuccess, onQueryParamEmailLookupFail);
        }
    }, [sessionId]);
    useEffect(() => {
        const emailQParam = getUrlParam("email");
        if (!oid && page !== Constants.CREATE_ACCOUNT) {
            !emailQParam && (!userDetail || (userDetail && !userDetail.email)) && redirectWithParam(props.history, CREATE_ACCOUNT_PATH);
        }
        const hasParam = getUrlParam("productCode") && getUrlParam("rc") && getUrlParam("offersPageId") && getUrlParam("offerId");
        if (!hasParam && !emailQParam) {
            redirectWithParam(props.history, props.history.location.pathname.substring(1));
        }
        if (page === Constants.CREATE_PASSWORD) {
            onChange("password", "");
        } else if (page === Constants.CREATE_ACCOUNT && !editEmail) {
            clearCookie(["userData"]);
            dispatch({ type: pageRouterActions.SET_USER_DETAILS, payload: null });
            onChange("email", "");
        }
        getUrlParam("email") ? setEmailQueryParam(true) : setEmailQueryParam(false);
        const offerId = getUrlParam("offerId") || DEFAULT_OFFER_CODE;
        localStorage.setItem("offerId", offerId);
    }, []);
    useEffect(() => {
        if (accountFormData) {
            encryptUserData();
        }
    }, [accountFormData]);
    useEffect(() => {
        if (goToNextPage) {
            redirectPage();
            redirectToNextPage(false);
        }
    }, [goToNextPage]);
    useEffect(() => {
        if (autoAddSkuId) {
            !offersDetails ? dispatch({ type: offersActions.GET_OFFERS_DETAILS, callback: { success: checkSkuIdMapped } }) : checkSkuIdMapped(offersDetails);
        }
    }, [autoAddSkuId]);

    useEffect(() => {
        return () => {
            PopupEmitter.emit(TRIGGER_POPUP, { showPopup: false });
        };
    }, []);
    const formProps = {
        t,
        onChange,
        setFormErrors,
        showError,
        disableBtn,
        formErrors: formErrors,
        formData: accountFormData,
        validateField,
        setEmailLookupStatus,
        onCartDetailsFetchSuccess,
        onCartDetailFetchFailure,
        isRegisteredUser,
        setRegisteredUser,
        redirectToNextPage,
        isCartAbandonmentFlow,
        setCartAbandonmentFlow,
        oid,
        removeCartAbondonementId,
        showPageLoader,
        emailLookupStatus,
        onContinueBtn,
        emailRef,
        onEnter,
        emailEdit,
        hasLowerCaseChar,
        hasCharCount,
        hasUpperCaseChar,
        hasNumber,
        hasNoSpaces,
        hasNoSpecialChars,
        onBlur,
        page,
        programEntitlementConflict,
        validateEmailClientSide,
    };
    return (
        <section className="create-account-content d-flex without-login position-relative">
            {(page === Constants.CREATE_ACCOUNT && isCartAbandonmentFlow && !editEmail) ||
                (((emailQueryParam && programConflictLookup) || emailQueryParam || programConflictLookup) && (
                    <div className="cart-abandonment-loader">
                        <Loader isToShowLoader={true} />
                        <h3>{t("loader-sub-text")}</h3>
                    </div>
                ))}
            {outroPage && <SurveyIntroOutro fields={outroPage.fields} />}
            {children(formProps)}
        </section>
    );
};
BaseCreateAccountWithoutLogin.propTypes = {
    props: PropTypes.object,
    page: PropTypes.string,
    children: PropTypes.func,
};
