import React from "react";
import PropTypes from "prop-types";

const PlusIcon = ({ fill }) => {
    const styles = {
        fill: fill || "",
    };
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 7H16V9H0V7Z" style={styles} />
            <path d="M7 0H9V16H7V0Z" style={styles} />
        </svg>
    );
};

PlusIcon.propTypes = {
    fill: PropTypes.string,
};

PlusIcon.defaultProps = {
    fill: "",
};

export default PlusIcon;
