import { EventEmitter } from "fbemitter";
import i18next from "i18next";
import { datadogRum } from "@datadog/browser-rum";
import { compile, serialize, stringify } from "stylis";
import { defaultLanguages } from "./language-supported";
import { pageRouterActions } from "../component/page-router/data/page-router-action";
import { countryCodeForDomain, countryLanguageCodes } from "../constants/app-languages";
import {
    CONTENTFUL_CONTENT_ID_BBBIKES,
    DEFAULT_MYX_PRODUCT_CODE,
    defaultLanguage,
    MYX_ACTIVATE_BIKE_PATH,
    NON_CLUB_MYX_PRODUCT_CODE,
    ODP_FLOW,
    TABLET_FLOW,
    DEFAULT_PRODUCT_CODE,
    DEFAULT_FUNNEL,
} from "../constants/urls";
import { checkoutActions } from "../module/checkout-page/data/checkout-page-action";
import { cardsAccepted, LANDING_PAGES, MYX_FLOW_SKUS, ODP_FLOW_SKUS } from "../module/create-account/components/constants";
import { offersActions } from "../module/offers-page/data/offers-page-action";
import configData from "../../tools/config";
import validator from "validator";

let caprun = false;

export const CaptchaEmitter = new EventEmitter();
export const TRIGGER_CAPTCHA = "TRIGGER:CAPTCHA";
export const convertSCSSToCSS = ({ fieldId = "", customCss = "", title = "", contentType }) => {
    if (!process.env.BROWSER) {
        let sass = require("sass");
        try {
            let cssObj = customCss && sass ? sass.compileString(fieldId ? `#${fieldId} {\n${customCss}\n}` : customCss, { style: "expanded" }) : "";
            if (cssObj) {
                const convertedCSS = cssObj.css.toString();

                if (global.pageStyle) {
                    global.pageStyle.push(convertedCSS);
                } else {
                    global.pageStyle = [convertedCSS];
                }
                cssObj = "";
                sass = "";
                return convertedCSS;
            }
        } catch (e) {
            console.log(`Exception occured at convertSCSSToCSS::fieldId::${fieldId}`);
            console.log(`Exception occured at convertSCSSToCSS::title::${title}::contentType::${contentType}:: error::`, e);
            return "";
        }
    }
    return "";
};

export const validateVideoContainer = (videoContainer) => {
    const validBackgroundVideo = videoContainer?.fields?.videoId?.trim();
    return validBackgroundVideo;
};

export const applyCloudinaryTransformations = (srcUrl, optimizationEnabled, optimization) => {
    const cloudinaryURL = "res.cloudinary.com";
    const bbAlias = "img1.beachbodyimages.com";
    const imageArray = srcUrl ? srcUrl.split("upload/") : "";
    const hasExistingTransforms = srcUrl.includes("f_auto") || srcUrl.includes("q_auto") || srcUrl.includes("w_auto");
    const optimizationOptions = optimization ? optimization : "/f_auto,q_auto:eco,w_auto";
    const transforms = optimizationEnabled ? optimizationOptions : "";
    const optimizedImage = srcUrl.includes(cloudinaryURL) && !hasExistingTransforms ? `${imageArray[0]}upload${transforms}/${imageArray[1]}` : srcUrl;
    const aliasTransform = optimizedImage.includes(cloudinaryURL) ? optimizedImage.replace(cloudinaryURL, bbAlias) : "";
    return aliasTransform;
};

export const getImageSource = (cloudinaryImageSource, contentfulImageSource, cloudinarySourceOptimizationEnabled, cloudinaryOptimization) => {
    if (cloudinaryImageSource && cloudinaryImageSource[0]?.url) {
        return applyCloudinaryTransformations(cloudinaryImageSource[0].url, cloudinarySourceOptimizationEnabled, cloudinaryOptimization);
    } else if (contentfulImageSource && contentfulImageSource?.fields?.file?.url) {
        return contentfulImageSource.fields.file.url;
    } else {
        return "";
    }
};

export const backgroundImageStyleCreator = (fieldId, backgroundImage, isDesktopView, title, contentType) => {
    const { fields } = backgroundImage;
    const { desktopImage, mobileImage, cloudinaryDesktopImage, cloudinaryMobileImage, cloudinaryOptimizationEnabled, cloudinaryUrlOptimization } = fields;
    let customCSS = fields.customCss ? fields.customCss : "";
    const desktopImageSrc = getImageSource(cloudinaryDesktopImage, desktopImage, cloudinaryOptimizationEnabled, cloudinaryUrlOptimization);
    const mobileImageSrc = getImageSource(cloudinaryMobileImage, mobileImage, cloudinaryOptimizationEnabled, cloudinaryUrlOptimization);
    const imageSrc = isDesktopView ? desktopImageSrc : mobileImageSrc;
    if (!imageSrc) {
        return "";
    }
    const backgroundStyle = imageSrc ? `background: url(${imageSrc}) top center/cover no-repeat;\n` : "";
    if (customCSS) {
        customCSS = customCSS.trim();
    }
    customCSS = contentType === "ctaButton" ? ` .button { ${backgroundStyle}${customCSS}}` : backgroundStyle + customCSS;
    const convertedCSS = convertSCSSToCSS({ fieldId, customCss: customCSS, title, contentType });
    return convertedCSS;
};
export const configureInlineCss = (item, isDesktopView, backgroundStyle = false) => {
    const { id, title, customCss, backgroundImage = null } = item.fields;
    const entryId = item.sys.id;
    const elementId = `${id}-${entryId}`;
    const contentType = item.sys.contentType.sys.id;
    const backgroundImageContent = contentType === "imageBox" ? item : backgroundImage;
    const css =
        !process.env.BROWSER && item && item.fields
            ? backgroundStyle
                ? backgroundImageStyleCreator(elementId, backgroundImageContent, isDesktopView, title, "ImageBox")
                : convertSCSSToCSS({ fieldId: elementId, customCss: customCss })
            : null;
    return {
        id: elementId,
        css: css,
    };
};

const pageHasScroll = () => {
    const docuElement = document.documentElement;
    const body = document.body;
    return (docuElement.scrollHeight || body.scrollHeight) - docuElement.clientHeight > 0;
};

export const getInitialBackgroundPositionY = () => {
    const backgroundDefinitions = {
        defaultInitialPosition: "92px",
        mobilePositionNoScroll: "100%",
    };
    const isDesktopView = checkForWindow();
    if (isDesktopView) {
        return backgroundDefinitions.defaultInitialPosition;
    } else if (!isDesktopView && pageHasScroll()) {
        return backgroundDefinitions.defaultInitialPosition;
    } else if (!isDesktopView && !pageHasScroll()) {
        return backgroundDefinitions.mobilePositionNoScroll;
    }
};

const getBackgroundScrollPercent = () => {
    const h = document.documentElement,
        b = document.body,
        st = "scrollTop",
        sh = "scrollHeight";
    const appHeader = document.getElementsByClassName("beachbody-header")[0];
    const headerOffset = appHeader.offsetHeight;
    return ((h[st] || b[st]) / ((h[sh] || b[sh]) + headerOffset - h.clientHeight)) * 100;
};

export const getCSSBackgroundImagePositionY = () => {
    const imageOffset = 92;
    return `calc(${getBackgroundScrollPercent()}% + ${imageOffset}px)`;
};

export const checkForWindow = (width = 767) => {
    if (!process.env.BROWSER && !global.window) {
        global.window = {}; // Temporarily define window for server-side
        return false;
    }
    return window.innerWidth > width ? true : false;
};

export const allowOnlyNumber = (e) => {
    const key = e.keyCode ? e.keyCode : e.which;
    if (
        !(
            [8, 9, 13, 27].indexOf(key) !== -1 ||
            (key === 65 && (e.ctrlKey || e.metaKey)) ||
            (key >= 35 && key <= 40) ||
            (key >= 48 && key <= 57 && !(e.shiftKey || e.altKey)) ||
            (key >= 96 && key <= 105)
        )
    )
        e.preventDefault();
};

export const getUrlParam = (name) => {
    const results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.href);
    if (results == null) {
        return "";
    } else {
        const decodedUri = decodeURI(results[1]) || 0;
        return validator.escape(decodedUri || "");
    }
};
export const convertParamsToObject = (query: string, queryIncludes: string): Record<string, string> => {
    const queryObject = query.split("&").filter((param) => param.includes(queryIncludes));
    const paramsToObject = {};
    queryObject.forEach((obj) => {
        const splittedParam = obj.split("=");
        paramsToObject[splittedParam[0]] = splittedParam[1];
    });
    return paramsToObject;
};
export const queryString = (object) => {
    const values = Object.keys(object).filter((key) => {
        if (object[key]) {
            return true;
        }
    });
    return (
        values &&
        values
            .map((key) => {
                if (object[key]) {
                    return key + "=" + object[key].replace(/\s/g, "");
                }
            })
            .join("&")
            .replace("&&", "&")
            .replace(/&$/, "")
    );
};
export function generateMonths(t) {
    return [
        { displayKey: "", displayName: t("exp-month") },
        { displayKey: "01", displayName: t("jan") },
        { displayKey: "02", displayName: t("feb") },
        { displayKey: "03", displayName: t("mar") },
        { displayKey: "04", displayName: t("apr") },
        { displayKey: "05", displayName: t("may") },
        { displayKey: "06", displayName: t("jun") },
        { displayKey: "07", displayName: t("jul") },
        { displayKey: "08", displayName: t("aug") },
        { displayKey: "09", displayName: t("sept") },
        { displayKey: "10", displayName: t("oct") },
        { displayKey: "11", displayName: t("nov") },
        { displayKey: "12", displayName: t("dec") },
    ];
}
export function generateYearList(t) {
    const currentYear = new Date().getFullYear();
    const list = [{ displayKey: "", displayName: t("exp-year") }];
    for (let i = 0; i < 15; i++) {
        list.push({ displayKey: currentYear + i, displayName: currentYear + i });
    }
    return list;
}

export function intializeGoogleCaptchaV3(action) {
    const siteKey = configData.CAPTCHA.siteKey;
    const ac = action && typeof action === "string" ? action : "homepage";
    if (!caprun) {
        caprun = true;
        window.grecaptcha &&
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(siteKey, {
                        action: ac,
                    })
                    .then((token) => {
                        const gCaptcha = getCookie("g-captcha-data");
                        const captcha = {
                            "g-recaptcha-response": token,
                            submitForm: gCaptcha === "false" ? false : true,
                            // score: data.score,
                        };
                        CaptchaEmitter.emit(TRIGGER_CAPTCHA, captcha);
                        const date = new Date();
                        date.setTime(date.getTime() + 110 * 1000);
                        setCookie("g-captcha-data", "true", date); //Expires at 1M:50sec
                        caprun = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        caprun = false;
                    });
            });
    }
}

export function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
export function setCookie(cname, cvalue, expTime) {
    document.cookie = expTime ? cname + "=" + cvalue + ";" + "expires=" + expTime.toGMTString() + ";path=/" : cname + "=" + cvalue + ";path=/";
}

export function decodeURIHelper(value) {
    return value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
}
export function clearCookie(names) {
    if (names && names instanceof Array) {
        names.forEach((name) => {
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        });
    }
}
export function createWebSourcecode(cookie, code) {
    const cookieList = cookie.split("*|*");
    const index = cookieList.indexOf(code);
    index >= 0 && cookieList.splice(index, 1);
    return cookieList.indexOf(code) === -1 ? cookieList.join("*|*") : createWebSourcecode(cookieList.join("*|*"), code);
}
function setWebSourcecodeCookie(cname, cvalue) {
    document.cookie = cname + "=" + cvalue + ";path=/";
}
export function checkWebSourcecodeCookie(wsccode) {
    const webSourceCode = getCookie("webSourceCode");
    const code = getUrlParam("code") || wsccode || "";
    if (code && webSourceCode) {
        const webSource = createWebSourcecode(webSourceCode, code);
        setWebSourcecodeCookie("webSourceCode", webSource ? webSource + "*|*" + code : code);
    } else if (code) {
        setWebSourcecodeCookie("webSourceCode", code);
    }
}
export function getLandingPageTarget(code) {
    return LANDING_PAGES[code];
}
export function checkIfODPFlow(sku) {
    return ODP_FLOW_SKUS.includes(sku);
}
export function checkFlowType(productId) {
    if (ODP_FLOW_SKUS.includes(productId)) {
        return ODP_FLOW;
    } else if (MYX_FLOW_SKUS.includes(productId)) {
        return TABLET_FLOW;
    } else {
        return "";
    }
}
export function decodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
        atob(str)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(""),
    );
}
export function checkForCaptchaByPass() {
    const urlParam = getUrlParam("bypassCaptcha");
    sessionStorage.setItem("bypassCaptcha", urlParam);
}

//Call this logging function on the client side rendering
export function logUserAction({ dispatch, payload }) {
    const ipAddress = sessionStorage.getItem("ipAddress");
    const offerId = localStorage.getItem("offerId");
    const path = window.location.path;
    const primaryProductCode = localStorage.getItem("productCode");
    const sessionId = sessionStorage.getItem("sessionId");

    dispatch({
        type: pageRouterActions.LOG_USER_ACCESS,
        payload: { urlPath: path, ...payload, ipAddress, offerId, primaryProductCode, sessionId },
    });
}
export function monthYearValidation(e) {
    const key = e.keyCode ? e.keyCode : e.which;
    if (
        !(
            [8, 9, 13, 27, 191, 46].indexOf(key) !== -1 ||
            (key === 65 && (e.ctrlKey || e.metaKey)) ||
            (key >= 35 && key <= 40) ||
            (key >= 48 && key <= 57 && !(e.shiftKey || e.altKey)) ||
            (key >= 96 && key <= 105)
        )
    )
        e.preventDefault();
}
export function validateMMYYFormat(value) {
    if (value) {
        const number = parseInt(value);
        const index = value.indexOf("/");
        const monthYear = value.split("/");
        let month = Number(monthYear[0]);
        const year = Number(monthYear[1]);
        if (value.length === 2 && !isNaN(number)) {
            if (number > 12) {
                const array = value.match(/.{1,1}/g);
                return `0${array[0]}/${array[1]}`;
            } else if (number <= 12 && number > 0) {
                return index !== -1 ? value.replace(/\//g, "") : value + "/";
            }
        } else if (value.length > 2) {
            if (index !== 2 && index !== -1) {
                if (month < 10 && month > 0) {
                    month = `0${month}`;
                } else if (month > 12) {
                    const monthValue = Number(month);
                    const array = monthValue && String(monthValue).match(/.{1,1}/g);
                    return `0${array[0]}/${array[1]}`;
                } else {
                    month = month ?? 0;
                }

                if (month === 0) return "0";

                if (year.length > 2) {
                    year.slice(0, 2);
                }
                return `${month}/${year && year && String(year).replace(/\//g, "")}`;
            } else if (index === -1) {
                return `${value.slice(0, 2)}/${value.slice(2, 4)}`;
            } else if (index === 2) {
                return `${value.slice(0, 2)}/${value.slice(3, 5).replace(/\//g, "")}`;
            }
        } else if (isNaN(number)) {
            if (index !== 2 && index !== -1) {
                if (month.length === 0) {
                    month = "0";
                }
                return `${month}`;
            }
        }
    }
    return value;
}

export function ExpiryDate(year, month) {
    if (year && month) {
        const today = new Date();
        const expiry = new Date(`20${year}`, month);
        return today.getTime() > expiry.getTime() ? true : false;
    } else {
        return false;
    }
}
export function renderBaseName(locale) {
    const i18nextLng = locale || getSiteLocale();
    const languages = i18nextLng && i18nextLng.replace("-", "_").split("_");
    return `/${languages[0]}`;
}
export function getSiteLocale() {
    const siteLocale = localStorage.getItem("i18nextLng") || defaultLanguage;
    return siteLocale;
}
export function getSiteId() {
    const siteLocale = getSiteLocale();
    const siteIds =
        window.$contentIdValue === CONTENTFUL_CONTENT_ID_BBBIKES
            ? {
                  en_CA: "BIKE-CAN",
                  en_GB: "BIKE-GB",
                  en_US: "BIKE-USA",
                  es_MX: "BIKE-MX",
                  es_US: "BIKE-USA",
                  fr_CA: "BIKE-CAN",
                  fr_FR: "BIKE-FR",
              }
            : {
                  en_CA: "BB-CAN",
                  en_GB: "BB-GB",
                  en_US: "BB-USA",
                  es_MX: "BB-MX",
                  es_US: "BB-USA",
                  fr_CA: "BB-CAN",
                  fr_FR: "BB-FR",
                  en_AU: "BB-AUS",
              };
    return siteLocale && siteIds[siteLocale];
}
export function validateEligibleCards(cardType) {
    const locale = localStorage.getItem("i18nextLng");
    const country = locale && locale.split("_")[1] && locale.split("_")[1].toLowerCase();
    const isValid = cardsAccepted && cardsAccepted[country] && cardsAccepted[country].findIndex((type) => type === cardType);
    return isValid !== -1;
}

export function findDomain(domain) {
    return window.location.hostname.indexOf(domain);
}
function redirectToOrigin(url) {
    window.location.href = url;
}
export function setLocaleByDomain(languageCode) {
    let userDefinedLocale = getUrlParam("locale") || localStorage.getItem("i18nextLng");
    // eslint-disable-next-line no-unused-vars
    const host = window.location.host || "";
    const domain = countryCodeForDomain.find((domain) => host.indexOf(domain.site) !== -1);
    const countryCode = domain ? domain["code"] : "us";
    userDefinedLocale = languageCode ? `${languageCode}_${countryCode && countryCode.toUpperCase()}` : userDefinedLocale;
    const locale =
        countryLanguageCodes && countryLanguageCodes[countryCode].indexOf(userDefinedLocale) !== -1 ? userDefinedLocale : defaultLanguages[countryCode];
    localStorage.setItem("i18nextLng", locale);
    return countryCode;
}
export function validateDomain() {
    const path = window.location.pathname.startsWith("/") ? window.location.pathname.substring(1) : window.location.pathname;
    const pageArray = path.split("/");
    const abandonedOrder = getUrlParam("oid") || sessionStorage.getItem("oid") || getUrlParam("email");
    const isBODRedirect = getUrlParam("bodcheckout") || "";
    const isBikeFlow = path && path.indexOf(MYX_ACTIVATE_BIKE_PATH) !== -1 ? true : false;
    if (pageArray.length > 0 && path) {
        if (pageArray[0].length === 2) {
            const languageCode = pageArray[0];
            const countryCode = setLocaleByDomain(languageCode);
            if (countryCode) {
                const locale = localStorage.getItem("i18nextLng");
                const storedLanguageCode = locale && locale.split("_")[0];
                const localeBycodes = `${languageCode}_${countryCode.toUpperCase()}`;
                const isUrlLanguageChanged =
                    !isBODRedirect && locale && locale.indexOf(languageCode) === -1 && localeBycodes !== locale && pageArray.length !== 1;
                if (abandonedOrder) {
                    const isContentLocalized = localStorage.getItem("contentLocalized");
                    localStorage.setItem("i18nextLng", locale.replace("-", "_"));
                    if (isUrlLanguageChanged) redirectToOrigin(window.location.href.replace(`/${languageCode}/`, `/${storedLanguageCode}/`));
                    else if (!isContentLocalized) {
                        localStorage.setItem("contentLocalized", "true");
                        redirectToOrigin(window.location.href.replace(`/${languageCode}/`, `/${storedLanguageCode}/`));
                    }
                } else if (isUrlLanguageChanged && !isBikeFlow) {
                    localStorage.removeItem("i18nextLng");
                    redirectToOrigin(window.location.origin);
                } else if (isBikeFlow && isUrlLanguageChanged) {
                    window.location.pathname = MYX_ACTIVATE_BIKE_PATH;
                } else {
                    localStorage.setItem("i18nextLng", localeBycodes.replace("-", "_"));
                    i18next.changeLanguage();
                }
            }
        } else {
            localStorage.removeItem("i18nextLng");
        }
    }
}
export function setProductid(userDetail) {
    const entitlements = userDetail && userDetail.entitlements && userDetail.entitlements.split(",");
    const isClubUser = entitlements && entitlements.indexOf("BOD") !== -1;
    const productId = isClubUser ? DEFAULT_MYX_PRODUCT_CODE : NON_CLUB_MYX_PRODUCT_CODE;
    localStorage.setItem("offerId", productId);
    return productId;
}

export function getProductDetails(userDetail, dispatch, resetDefaultCC?: boolean) {
    const bikeLoginCheckout = sessionStorage.getItem("bikeLoginCheckout"); // To Skip the DEFAULT_CREDIT_CARD to NULL
    !bikeLoginCheckout && !resetDefaultCC && dispatch({ type: checkoutActions.DEFAULT_CREDIT_CARD, payload: null });
    const productId = setProductid(userDetail);
    dispatch({ type: offersActions.GET_OFFERS_DETAILS, payload: productId });
}

// eslint-disable-next-line eqeqeq
export const getPropValue = (obj, key) => key.split(".").reduce((o, x) => (o == undefined ? o : o[x]), obj);
export const checkForValidArray = (arr, length = 0) => Array.isArray(arr) && arr.length > length;

export function getPushSite() {
    const pushSite = getSiteId();
    return pushSite;
}
export function getProductCode() {
    return getUrlParam("productCode") || localStorage.getItem("productCode") || DEFAULT_PRODUCT_CODE;
}
export function getRoyaltyCode() {
    return getUrlParam("rc") || localStorage.getItem("royaltyCode") || getProductCode();
}
export function getOffersPageId() {
    return getUrlParam("offersPageId") || localStorage.getItem("offersPageId") || getProductCode();
}
export function setOffersPageId(id) {
    id && localStorage.setItem("offersPageId", id);
}
export const extractFieldValues = (content, attribute) => {
    const obj = {};
    content.forEach((data) => {
        if (data.fields) {
            const key = data.fields["id"];
            const value = data.fields[attribute];
            obj[key] = value;
        }
    });
    return obj;
};
export const isAccountCreated = (userDetail) => {
    return userDetail && userDetail.email && (userDetail.isNewUser ? (userDetail.password ? true : false) : true);
};

export const checkPageType = (path) => {
    try {
        if (path.indexOf("/product/") !== -1) return "Landing";
        else {
            const basePath = path.split("/")[2].replace(/-/g, " ");
            return basePath[0].toUpperCase() + basePath.slice(1);
        }
    } catch (err) {
        console.log(err);
        return "Landing";
    }
};

export const logCarouselEvents = (eventId) => {
    const path = process.env.BROWSER ? window.location.pathname : "";
    const pageData = "bod:web" + path.split("/").join(":");
    const pageType = checkPageType(path);
    const pageName = pageType.toLowerCase() + ":" + pageData;
    const eventData = {
        link_name: pageData + ":" + eventId,
        page_type: `${pageType} Page`,
        site_section: `${pageType} Page`,
        page_name: pageName,
    };
    return eventData;
};

export const logCTAEvents = (sectionId, linkItem, pageType, title, link_text, store_platform?) => {
    const path = process.env.BROWSER ? window.location.pathname : "";
    const pageData = "bod:web" + path.split("/").join(":");
    const pageName = pageType.toLowerCase() + ":" + pageData;
    const eventId = sectionId
        ? sectionId
              .toLowerCase()
              .split(":")
              .map((id) => id.charAt(0).toUpperCase() + id.slice(1))
              .join(":")
        : "";
    const ctaButtonTitle = title
        ? title
              .split(":")
              .map((item) => item.trim())
              .join(":")
        : "";
    const eventData = {
        link_name: pageData + ":" + eventId + ":" + ctaButtonTitle,
        page_type: `${pageType} Page`,
        site_section: `${pageType} Page`,
        page_name: pageName,
        link_item: linkItem,
        ui_context_path: eventId + ":" + ctaButtonTitle,
        module_id: sectionId || "",
        store_platform,
        link_text,
    };
    return eventData;
};
export function getFunnel(funnels, fid) {
    const { funnelList, defaultFunnel } = funnels || {};
    const result = (funnelList && funnelList?.find((funnel) => funnel?.fields?.funnelId === fid)) || null;
    if (!result && fid === DEFAULT_FUNNEL) {
        return defaultFunnel;
    }
    return result;
}
export function getFunnelFromStorage() {
    const funnelCookie = getCookie("funnels") || null;
    const funnels = JSON.parse(funnelCookie);
    const fId = sessionStorage.getItem("fId");
    const funnel = getFunnel(funnels, fId);
    return funnel;
}
export function getFunnelPath() {
    const funnel = getFunnelFromStorage();
    const funnelPath = funnel?.fields?.funnelPath;
    return funnelPath || [];
}
export function comparePageOrderInFunnel(page1, page2) {
    // checks the funnelList array and returns true if page1 comes before page2, or page2 does not exist
    const funnel = getFunnelFromStorage();
    if (!funnel) {
        return false;
    }
    const funnelArray = funnel?.fields.funnelPath;
    if (!funnelArray.includes(page2)) {
        return true;
    }
    const reverseFunnel = funnelArray.reverse();
    const page2Index = reverseFunnel.indexOf(page2);
    return reverseFunnel.includes(page1, page2Index);
}
export function customClick(node, buttonText, title, storeId?) {
    const contentType = node.getAttribute("data-type");
    let id;
    if (contentType === "array") id = node.parentElement.id;
    else id = node.parentElement.parentElement.id;
    const sectionId = id && id.indexOf("-") !== -1 ? id.split("-").slice(0, -1).join(":") : id;
    const linkItem = buttonText && buttonText.toLowerCase().split(" ").join("-");
    const eventData = logCTAEvents(sectionId, linkItem, "Landing", title, buttonText, storeId);
    return eventData;
}
export function initializeDataDogRUM(samplingRate) {
    const { DATADOG } = configData || {};
    const { version } = require("../../package");
    let sampleRate = Number(samplingRate);
    if (isNaN(sampleRate) || sampleRate <= 0) {
        // Use a default value if samplingRate is invalid.
        sampleRate = 5;
    }
    datadogRum.init({
        applicationId: DATADOG.applicationId,
        clientToken: DATADOG.clientToken,
        site: "datadoghq.com",
        service: "beachbody.com",
        env: DATADOG.env,
        version: version,
        sessionSampleRate: sampleRate,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask",
        silentMultipleInit: true,
    });
}
export const processClientCSS = ({ fieldId = "", customCss = "", title = "", contentType }) => {
    if (process.env.BROWSER) {
        try {
            const clientCSS = serialize(compile(fieldId ? `#${fieldId} {\n${customCss}\n}` : customCss), stringify);
            return clientCSS;
        } catch (e) {
            console.log(`Exception occured at processClientCSS::fieldId::${fieldId}::title::${title}::contentType::${contentType}:: error::`, e);
            return "";
        }
    }
    return "";
};
export const isGBLocale = () => {
    const locale = getSiteLocale();
    return countryLanguageCodes.gb.includes(locale);
};

export const getPayPalClientId = () => {
    const locale = getSiteLocale().split("_")[1];

    return configData.PAYPAL.clientId[locale];
};

export const shouldFetchSessionId = (isServerSide) => {
    const path = process.env.BROWSER ? window.location.pathname : "";
    const isProductPage = path.includes("/product/");
    const hasNonEmptyPath = path.split("/").filter(Boolean).length > 1;
    return isServerSide ? isProductPage : hasNonEmptyPath && !isProductPage;
};
