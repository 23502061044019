import React from "react";
import PropTypes from "prop-types";
import "./footer.scss";
import { BeachbodyFooter } from "../common/footer";
import { replaceAlternateLandingPageNavigation } from "../common/util";

export const OffersFooter = (props) => {
    const fields = replaceAlternateLandingPageNavigation(props).fields;
    return <BeachbodyFooter fields={fields} />;
};

OffersFooter.propTypes = {
    fields: PropTypes.object,
};
