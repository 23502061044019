export const faq_expanded_event_name = "lo_landing-faq-app-expanded";
export const faq_collapsed_event_name = "lo_landing-faq-app-collapsed";
export const myx_activation_code = "myx_activation_code";
export const myx_account_first_name = "myx_account_first_name";
export const myx_account_last_name = "myx_account_last_name";
export const myx_account_password = "myx_account_password";
export const myx_account_email = "myx_account_email";
export const myx_checkout_zipcode = "myx_checkout_zipcode";
export const myx_account_email_edit = "myx_account_email_edit";
export const myx_error_message = "myx_error_message";
export const myx_cc_change = "myx_cc_change";
export const myx_cc_last_name = "myx_cc_last_name";
export const myx_cc_first_name = "myx_cc_first_name";
export const myx_yes_coach = "myx_yes_coach";
export const digital_checkout_paypal_selected = "digital_checkout_paypal_selected";
export const digital_checkout_error = "digital_checkout_error";
export const carousel_view_more = "Carousel View More";
export const carousel_card_click = "Carousel Card Click";
export const coachoptinselection = "Coach Opt In Selection";
export const click_cta = "Click CTA";
export const click_get_app_link = "Click Get BOD App Link";
export const credit_card = "credit card";
export const paypal = "paypal";
export const digital_checkout_creditcard_selected = "digital_checkout_creditcard_selected";
