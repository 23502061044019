import React from "react";
import { RadioButtonGroup } from "../../../common";
import PropTypes from "prop-types";

export function ShippingOptions({ t }) {
    const shippingOption = [
        {
            buttonName: t("standard-shipping"),
            buttonKey: "selectedShipMethodCode1",
        },
        { buttonName: t("express-shipping"), buttonKey: "selectedShipMethodCode2" },
    ];

    return (
        <section id="shipping-option" className="shipping-option">
            <h2>
                <span className="icon ship-icon"></span>
                {t("shipping-option")}
            </h2>
            <RadioButtonGroup outerCss="radio-group" propertyName="shippingOption" buttons={shippingOption} />
        </section>
    );
}
ShippingOptions.propTypes = {
    t: PropTypes.func,
};
