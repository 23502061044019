import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { CTAButton } from "../../../common";
import { MyxCreditCardDetails } from "./myx-credit-card-details";
import { checkoutActions } from "../data/checkout-page-action";
import { validateForm } from "../../common/validator";
export function MyxUpdateCreditCardModal({
    calculateTax,
    cartItem,
    dispatch,
    doFieldValidation,
    formData,
    formErrors,
    onChange,
    onShowUpdateCCModal,
    onCartDetailsFetchSuccess,
    onCartDetailFetchFailure,
    setFormErrors,
    t,
    validateField,
    defaultCreditCard,
    refreshCreditCardInfo,
}) {
    const [fieldValidation, setFieldValidation] = useState(false);
    const { isCVViFrame } = useSelector((state) => state.checkout);
    const { userDetail } = useSelector((state) => state.pageRouter);
    const { order } = useSelector((state) => state.offers);

    const openUpdateCCModal = () => {
        onShowUpdateCCModal(false);
    };
    const validateFormFields = () => {
        const { ccNumber, ccExpDate, billingFirstName, billingLastName, ccMonth, ccYear, ccCVV, billingPostalCode, isCVVValid, isCCNumberValid } =
            formData || {};
        const fieldsToValidate = (order && order.validateUserConfirmations) || [];
        const validateFields = [...fieldsToValidate, "billingFirstName", "billingLastName"];

        const errors = validateForm(
            { ccNumber, ccExpDate, billingFirstName, billingLastName, ccMonth, ccYear, ccCVV, billingPostalCode, isCVVValid, isCCNumberValid, isMyXFlow: true },
            t,
            userDetail.isExistingFreeUser,
            validateFields,
            false,
            isCVViFrame,
        );
        setFormErrors("", "", errors);
        return !errors ? true : false;
    };
    const ccFormIsFilled =
        formData["ccNumber"] && formData["ccExpDate"] && formData["billingFirstName"] && formData["billingFirstName"] && formData["billingPostalCode"]
            ? true
            : false;
    const setNewCreditCardDetails = () => {
        !fieldValidation && setFieldValidation(true);
        if (validateFormFields()) {
            dispatch({ type: checkoutActions.DEFAULT_CREDIT_CARD, payload: { ...defaultCreditCard, ...formData, isNewCardSaved: true } });
            openUpdateCCModal();
        }
    };
    const cancelCCDetailUpdate = () => {
        refreshCreditCardInfo();
        openUpdateCCModal();
    };
    const formProps = {
        t,
        onChange,
        setFormErrors,
        formErrors,
        formData,
        validateField,
        doFieldValidation,
        calculateTax,
        cartItem,
        onCartDetailsFetchSuccess,
        onCartDetailFetchFailure,
        dispatch,
    };
    const formValidClass = ccFormIsFilled ? "valid" : "";
    return (
        <section id="cust-cc-info" className="cust-cc-info">
            <CTAButton
                outerBtnClass="close-btn-container justify-content-right align-items-start"
                innerBtnClass="close-btn"
                buttonName={"&#x2715;"}
                onButtonClick={cancelCCDetailUpdate}
            />
            <MyxCreditCardDetails {...formProps} sectionTitle={t("payment-method")} doFieldValidation={fieldValidation} showSummary={false} />
            <CTAButton
                outerBtnClass="continue-btn-container justify-content-center align-items-center"
                innerBtnClass={"continue-btn " + formValidClass}
                buttonName={t("save-continue-btn")}
                disableButton={!ccFormIsFilled ? { disabled: "disabled" } : null}
                onButtonClick={ccFormIsFilled ? setNewCreditCardDetails : null}
            />
            <CTAButton
                outerBtnClass="cancel-btn-container justify-content-center align-items-center"
                innerBtnClass="cancel-btn"
                buttonName={t("cancel")}
                onButtonClick={cancelCCDetailUpdate}
            />
        </section>
    );
}
MyxUpdateCreditCardModal.propTypes = {
    calculateTax: PropTypes.func,
    cartItem: PropTypes.object,
    defaultCreditCard: PropTypes.object,
    dispatch: PropTypes.func,
    doFieldValidation: PropTypes.bool,
    formData: PropTypes.object,
    formErrors: PropTypes.object,
    sectionTitle: PropTypes.string,
    setFormErrors: PropTypes.func,
    showSummary: PropTypes.bool,
    t: PropTypes.func,
    validateField: PropTypes.func,
    onChange: PropTypes.func,
    onShowUpdateCCModal: PropTypes.func,
    onCartDetailsFetchSuccess: PropTypes.func,
    onCartDetailFetchFailure: PropTypes.func,
    refreshCreditCardInfo: PropTypes.func,
};
