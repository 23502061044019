import { put, takeLatest, call, all } from "redux-saga/effects";
import { checkoutActions } from "./checkout-page-action";
import { placeCheckoutOrder, fetchUserDetails, sendResetPassword, validateToken } from "../../../api";
import {
    fetchZipocodeDetails,
    fetchTaxForOrderSummary,
    fetchCoachDetails,
    partialProfile,
    loginService,
    sendForgotPasswordEmail,
    doUserLoginSyncup,
    fetchCyberSourceKey,
    fetchCyberSourcePermanentKey,
    fetchGlobalConfigurations,
    doSetUpAuth,
    doEnrollAuth,
    assignToLeadwheel,
} from "../../../api/checkoutApi";
import { offersActions } from "../../offers-page/data/offers-page-action";
import { activationActions } from "../../activation-page/data/activation-page-action";
import { CartDetails, User, AbondonedUserData, DefaultCreditCard } from "../../../model";
import { decipherUserData, updateUserDetails } from "../../common/util";
import { pageRouterActions } from "../../../component/page-router/data/page-router-action";

export function* fetchPlaceCheckoutOrder(action) {
    const { payload, callbacks } = action || {};
    try {
        const { expressCheckout } = payload;
        const response = yield placeCheckoutOrder(expressCheckout);
        if (response && response.status === "ERROR") {
            callbacks && callbacks.failure();
            return;
        } else {
            if (response) {
                if (response.formError) {
                    yield put({ type: checkoutActions.CHECKOUT_FORM_ERROR, payload: response });
                }
                response.order ? callbacks && callbacks.success(response) : callbacks && callbacks.failure();
                response.order && window.digitalData ? (window.digitalData.order = response.order) : "";
                yield put({ type: checkoutActions.ORDER_CONFIRMATION_DETAILS, payload: response });
            }
        }
    } catch (error) {
        callbacks && callbacks.failure();
        console.error("Error", error);
    }
}
export function* fetchMyxPlaceCheckoutOrder(action) {
    const { payload, callbacks } = action || {};
    try {
        const { expressCheckout } = payload;
        const response = yield placeCheckoutOrder(expressCheckout);
        if (response && response.status === "ERROR") {
            callbacks && callbacks.failure();
            return;
        } else {
            if (response) {
                if (response.formError) {
                    yield put({ type: checkoutActions.CHECKOUT_FORM_ERROR, payload: response });
                }
                response.order ? callbacks && callbacks.success() : callbacks && callbacks.failure();
                response.order && window.digitalData ? (window.digitalData.order = response.order) : "";
                activateMyxBike(action);
                yield put({ type: checkoutActions.ORDER_CONFIRMATION_DETAILS, payload: response });
            }
        }
    } catch (error) {
        callbacks && callbacks.failure();
        console.error("Error", error);
    }
}
export function* activateMyxBike(action) {
    const { payload, callbacks } = action || {};
    try {
        const { profileId, activationCode, accessToken } = payload;
        yield put({
            type: activationActions.ACTIVATE_BIKE,
            payload: {
                profileId: profileId,
                activationCode: activationCode,
                accessToken: accessToken,
            },
        });
        callbacks && callbacks.success();
    } catch (error) {
        callbacks && callbacks.failure();
        console.error("Error", error);
    }
}
export function* getUserDetails(action) {
    const { callbacks } = action || {};
    const { success, failure } = callbacks || {};
    let emailId;
    try {
        const result = yield fetchUserDetails(action);
        const {
            userDetails: { search_identity_response, email, entitlements },
        } = result || { userDetails: {} };
        emailId = email;
        const { status, searchUser } = search_identity_response || {};
        if (status === "SUCCESS") {
            yield success && success({ ...searchUser, email, entitlements });
            yield put({ type: checkoutActions.SET_CHECKOUT_STATE, payload: { userDetails: { ...searchUser, email, entitlements } } });
        } else if (status === "FAILURE" || status === "failure" || !status) {
            yield failure && failure(email);
        }
    } catch (error) {
        yield failure && failure(emailId);
        console.error("Error: ", error);
    }
}
export function* getZipCodeDetails(action) {
    const { callbacks, isToCalculateTax, zipCodeName, payload } = action || {};
    try {
        const response = yield fetchZipocodeDetails(action);
        const { result } = response || {};
        const details = { ...result, isToCalculateTax };
        const { STATUS } = result || {};
        const payloadData = { [zipCodeName]: { ...result, isToCalculateTax, zipCodeName, zipcode: payload }, isToCalculateTax };
        STATUS ? yield put({ type: checkoutActions.SET_ZIPCODE_DETAILS, payload: payloadData }) : null;
        if (STATUS === "SUCCESS") {
            if (isToCalculateTax) {
                const response = yield fetchTaxForOrderSummary({ ...result, zipcode: payload });
                if (response.order) {
                    yield put({
                        type: offersActions.SET_OFFERS_DETAILS,
                        payload: { order: new CartDetails(response.order) },
                    });
                }
            }
            callbacks && callbacks.success(details);
        } else if (STATUS === "FAILED") {
            callbacks && callbacks.failure(details);
        } else if (!STATUS || STATUS === "LOOKUP_FAILED") {
            const data = { STATUS: "LOOKUP_FAILED", isToCalculateTax, zipCodeName };
            const payloadDetails = { [zipCodeName]: data, isToCalculateTax };
            yield put({ type: checkoutActions.SET_ZIPCODE_DETAILS, payload: payloadDetails });
            yield callbacks && callbacks.failure(data);
        }
    } catch (error) {
        console.error("ZIP Code Details Error", error);
    }
}
export function* getCoachDetails(action) {
    const { payload, callback } = action || {};
    try {
        const result = yield call(fetchCoachDetails, payload);
        const details = result ? JSON.parse(result.coachDetails) : {};
        const { search_identity_response } = details || {};
        callback instanceof Function
            ? callback(search_identity_response)
            : yield put({ type: checkoutActions.REFERRING_COACH_DETAILS, payload: search_identity_response });
    } catch (error) {
        console.error("Error: ", error);
    }
}
export function* doLogin(action) {
    const { payload, callbacks } = action || {};
    const { success, failure, update } = callbacks || {};
    try {
        const result = yield call(loginService, payload);
        const { status, user, message } = result || {};
        const details = new User({ ...user, ...payload });
        callbacks ? (status === "success" ? success && success(details, update) : failure && failure(details, update, message)) : null;
    } catch (error) {
        console.error("Error: ", error);
    }
}
function* createPartialProfile(action) {
    const { payload, callbacks } = action || {};
    const { success, failure } = callbacks || {};
    try {
        const response = yield call(partialProfile, payload);
        if (response) {
            const { order } = response;
            if (order) {
                yield put({
                    type: offersActions.SET_OFFERS_DETAILS,
                    payload: { order: new CartDetails(order) },
                });
            }
            success && success(response);
        } else {
            failure && failure(response);
        }
    } catch (error) {
        console.error("Error: ", error);
    }
}

export function* fetchSendForgotPasswordEmail(action) {
    const { callbacks } = action || {};
    try {
        const response = yield sendForgotPasswordEmail(action);
        if (response && response.formError === true) {
            callbacks && callbacks.failure(response);
            return;
        } else {
            if (response) {
                callbacks && callbacks.success(response);
            }
        }
    } catch (error) {
        callbacks && callbacks.failure();
        console.error("Error", error);
    }
}

export function* fetchResetPassword(action) {
    const { callbacks } = action || {};
    try {
        const response = yield sendResetPassword(action);
        if (response && response.formError === true) {
            callbacks && callbacks.failure(response);
            return;
        } else {
            if (response) {
                callbacks.success(response);
            }
        }
    } catch (error) {
        callbacks && callbacks.failure();
        console.error("Error", error);
    }
}
function* getLoggedInUserDetails(action) {
    const { callback } = action || {};
    try {
        const result = decipherUserData();
        const isValid = result && result.email;
        callback ? (isValid ? callback(result, true) : callback(result, false)) : null;
    } catch (error) {
        console.error("Error: ", error);
    }
}

export function* fetchValidateToken(action) {
    const { callbacks } = action || {};
    try {
        const response = yield validateToken(action);
        if (response && response.validateToken.result === false) {
            callbacks && callbacks.failure(response);
            return;
        } else {
            if (response) {
                callbacks.success(response);
            }
        }
    } catch (error) {
        callbacks && callbacks.failure();
        console.error("Error", error);
    }
}
export function* syncUpUserLogin(action) {
    const { payload, callbacks } = action || {};
    const { abondonedUser } = payload;
    const { success, failure } = callbacks || {};
    try {
        const response = yield doUserLoginSyncup(action);
        const { order, formError, defaultCreditCard } = response || {};
        if (order) {
            const bikeLoginCheckout = sessionStorage.getItem("bikeLoginCheckout");
            const { profile } = order || {};
            if (bikeLoginCheckout && profile) {
                const userDetails = decipherUserData();
                const details = { ...userDetails, isExistingFreeUser: true, ...profile, email: profile && profile.login };
                yield put({ type: pageRouterActions.SET_USER_DETAILS, payload: details });
                updateUserDetails(details);
            }
            let data = { order: new CartDetails(order) };
            let creditCardData;
            if (defaultCreditCard) {
                const { billingAddress, ...rest } = defaultCreditCard || {};
                creditCardData = new DefaultCreditCard({ ...billingAddress, ...rest });
            }
            if (defaultCreditCard && creditCardData) yield put({ type: checkoutActions.DEFAULT_CREDIT_CARD, payload: creditCardData });
            if (abondonedUser) {
                data = {
                    ...data,
                    abondonedUserData: new AbondonedUserData(abondonedUser),
                };
            }

            yield put({
                type: offersActions.SET_OFFERS_DETAILS,
                payload: data,
            });
            success && success(order);
        } else if (formError) {
            failure && failure();
        }
    } catch (error) {
        console.error("User Login ATG Syncup Fail", error);
    }
}
// export function* myxUpdateCreditCard(action) {
//     const { payload, callbacks } = action || {};
//     const { success, failure } = callbacks || {};
//     try {
//         const { formError, creditCardNumber } = payload || {};
//         const updatedCreditCard = {
//             creditCardNumber: creditCardNumber,
//             creditCardName: cardCardName,

//         }
//         if (updatedCreditCard) {
//             let data = { defaultCreditCard: new DefaultCreditCard(updatedCreditCard) };

//             yield put({
//                 type: offersActions.SET_OFFERS_DETAILS,
//                 payload: data
//             });
//             success && success();
//         } else if (formError) {
//             failure && failure();
//         }
//     } catch (error) {
//         console.error("Credit Card Update Fail", error);
//     }
// }
function* getCyberSourceKey(action) {
    try {
        const result = yield fetchCyberSourceKey(action);
        const { jwt } = result || {}; // For ATG API
        // const { response: { keyId: jwt } } = result || { response: {} }; // For Local Development
        if (jwt) {
            yield put({ type: checkoutActions.SET_CYBERSOURCE_KEY, payload: jwt });
        }
        yield put({ type: checkoutActions.LOAD_PAGE, payload: true });
    } catch (error) {
        console.error("Cyber Source Key Lookup", error);
    }
}
function* getCyberSourcePermanentToken(action) {
    const { payload, callbacks } = action || {};
    const { success, failure } = callbacks || {};
    let errors = null;
    try {
        const result = yield fetchCyberSourcePermanentKey(payload);
        const { formError, formExceptions } = result || {};
        errors = formExceptions || null;
        if (!formError) {
            const {
                result: {
                    paySubscriptionCreateReply: { subscriptionID },
                },
            } = result || { result: { paySubscriptionCreateReply: {} } };
            yield put({ type: checkoutActions.UPDATE_ENROLL_AUTH_3DS_RESULT, payload: null });
            yield put({ type: checkoutActions.CYBS_DEVICE_DATA_COLLECTION, payload: null });
            success && success(subscriptionID);
        } else {
            failure && failure(formExceptions);
        }
    } catch (error) {
        failure && failure(errors);
        console.error("Cyber Source Permanent Key Lookup", error);
    }
}
function* getGlobalConfigurations(action) {
    try {
        const result = yield fetchGlobalConfigurations(action);
        const { merlineConfig } = result || {};
        yield put({ type: checkoutActions.SET_MERLIN_CONFIG, payload: { ...merlineConfig, merlineConfig: true } });
    } catch (error) {
        yield put({ type: checkoutActions.SET_MERLIN_CONFIG, payload: { merlineConfig: false } });
        console.error("Global configuration API", error);
    }
}
function* cybersource3DSSetUpAuth(action) {
    const {
        payload: { ccNumber, ccYear, ccMonth, id },
    } = action || { callbacks: {} };
    try {
        const setUpAuthPayload = {
            token: ccNumber,
            expirationMonth: ccMonth,
            expirationYear: ccYear,
            orderId: id,
        };
        const setUpAuth = yield doSetUpAuth(setUpAuthPayload);
        const { result, formError, formExceptions } = setUpAuth || { result: { consumerAuthenticationInformation: {} } };
        const { status, consumerAuthenticationInformation } = result || {};
        const { token, referenceId, accessToken, deviceDataCollectionUrl } = consumerAuthenticationInformation || {};
        if (status) {
            yield put({ type: checkoutActions.CYBS_DEVICE_DATA_COLLECTION, payload: { accessToken, deviceDataCollectionUrl, status, referenceId, token } });
        } else if (formError === "true" && formExceptions && formExceptions.length) {
            yield put({ type: checkoutActions.CYBS_DEVICE_DATA_COLLECTION, payload: setUpAuth });
            yield put({ type: checkoutActions.CHECKOUT_FORM_ERROR, payload: setUpAuth });
        }
    } catch (error) {
        console.error(error);
    }
}
function* cybersource3DSEnrollAuth(action) {
    const {
        payload: {
            ccNumber,
            ccYear,
            ccMonth,
            firstName,
            lastName,
            ccCVV,
            billingCity,
            billingState,
            billingCountry,
            billingAddressLine1,
            shippingAddressLine1,
            billingPostalCode,
            cybsDeviceDataCollection,
        },
    } = action || { callbacks: {} };
    try {
        const { referenceId } = cybsDeviceDataCollection || {};
        const enrollAuthPayload = {
            firstName,
            lastName,
            token: ccNumber,
            ccMonth,
            ccYear,
            ccCVV,
            bill_addrs: shippingAddressLine1 || billingAddressLine1,
            bill_city: billingCity,
            bill_state: billingState,
            bill_zipcd: billingPostalCode,
            bill_country: billingCountry,
            referenceId: referenceId,
            screenWidth: window.outerWidth,
            screenHeight: window.outerHeight
        };
        const enrollAuth = yield doEnrollAuth(enrollAuthPayload);
        yield put({ type: checkoutActions.UPDATE_ENROLL_AUTH_3DS_RESULT, payload: enrollAuth && enrollAuth.result ? enrollAuth.result : enrollAuth });
    } catch (error) {
        console.error(error);
    }
}
function* watchAssignToLeadwheel(action) {
    const { callbacks } = action || {};
    const { success, failure } = callbacks || {};
    try {
        const response = yield call(assignToLeadwheel, action);
        if (response) {
            if (response?.result?.status === "success") {
                success && success(response);
            } else {
                failure && failure(response);
                console.error("Error: ", response);
            }
        } else {
            failure && failure(response);
            console.error("Error: ", response);
        }
    } catch (error) {
        console.error("Error: ", error);
    }
}
function* actionWatcher() {
    yield all([
        takeLatest(checkoutActions.PLACE_CHECKOUT_ORDER, fetchPlaceCheckoutOrder),
        takeLatest(checkoutActions.PLACE_MYX_CHECKOUT_ORDER, fetchMyxPlaceCheckoutOrder),
        takeLatest(checkoutActions.GET_USER_DETAILS, getUserDetails),
        takeLatest(checkoutActions.GET_ZIPCODE_DETAILS, getZipCodeDetails),
        takeLatest(checkoutActions.FETCH_COACH_DETAILS, getCoachDetails),
        takeLatest(checkoutActions.USER_LOGIN, doLogin),
        takeLatest(checkoutActions.CREATE_PARTIAL_PROFILE, createPartialProfile),
        takeLatest(checkoutActions.SEND_FORGOT_PASSWORD_EMAIL, fetchSendForgotPasswordEmail),
        takeLatest(checkoutActions.GET_SESSION_USER_DATA, getLoggedInUserDetails),
        takeLatest(checkoutActions.RESET_PASSWORD, fetchResetPassword),
        takeLatest(checkoutActions.VALIDATE_TOKEN, fetchValidateToken),
        takeLatest(checkoutActions.USER_LOGIN_SYNCUP_ATG, syncUpUserLogin),
        // takeLatest(checkoutActions.MYX_UPDATE_CREDIT_CARD_INFO, myxUpdateCreditCard),
        takeLatest(checkoutActions.GET_CYBERSOURCE_KEY, getCyberSourceKey),
        takeLatest(checkoutActions.CYBERSOURCE_PERMANENT_TOKEN, getCyberSourcePermanentToken),
        takeLatest(checkoutActions.GET_CONFIGURATIONS, getGlobalConfigurations),
        takeLatest(checkoutActions.CYBERSOURCE_3DS_SETUP_AUTH, cybersource3DSSetUpAuth),
        takeLatest(checkoutActions.CYBERSOURCE_3DS_ENROLL_AUTH, cybersource3DSEnrollAuth),
        takeLatest(checkoutActions.ACTIVATE_MYX_BIKE, activateMyxBike),
        takeLatest(checkoutActions.ASSIGN_TO_LEADWHEEL, watchAssignToLeadwheel),
    ]);
}
export default function* checkoutSaga() {
    yield all([actionWatcher()]);
}
