import { isGBLocale } from "../../helper/util";
import { zip, defaultPostalCodeUK } from "../../module/checkout-page/constants";

class GetBillingDetails {
    constructor(zipDetails = {}) {
        if (zipDetails.billShipSame || zipDetails.billing) {
            this.billingState = zipDetails.state || "";
            this.billingCountry = zipDetails.country || "US";
            this.billingCity = zipDetails.city || "";
            this.billingPostalCode = getZipCode(zipDetails.zipcode);
            this.billingAddressLine1 = "--";
            this.billingAddressLine2 = "";
        }
    }
}
class GetShippingDetails extends GetBillingDetails {
    constructor(zipDetails = {}) {
        super(zipDetails);
        if (zipDetails.billShipSame || !zipDetails.billing) {
            this.shippingState = zipDetails.state || "";
            this.shippingCountry = zipDetails.country || "US";
            this.shippingCity = zipDetails.city || "";
            this.shippingPostalCode = zipDetails.zipcode || "";
        }
    }
}
export class ZIPDetails extends GetShippingDetails {
    constructor(zipDetails = {}) {
        super(zipDetails);
        if (zipDetails.billShipSame) {
            this.shippingAddressLine1 = "--";
            this.shippingAddressLine2 = "";
        }
    }
}

function getZipCode(zipCode) {
    if (!zipCode) {
        return "";
    }
    //Check if default postal code should be used for tax calculations based on user input for UK
    if (isGBLocale()) {
        const postalCodeInput = document.querySelector(`input[id="${zip}"]`);
        const postalCodeEntered = postalCodeInput ? postalCodeInput.getAttribute("data-postal-entered") === "true" : false;
        if (defaultPostalCodeUK === zipCode && !postalCodeEntered) {
            return "";
        }
    }
    return zipCode;
}
