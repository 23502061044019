import React from "react";
import PropTypes from "prop-types";
export const IconRightTextLeft = ({
    fields
}) => {
    const { icon, textContent } = fields;
    return (
        <p>
            <img src={icon.fields.file.url} alt={icon.fields.title} />
            &nbsp;&nbsp;<span >{textContent}</span>
        </p>
    );
};

IconRightTextLeft.propTypes = {
    fields: PropTypes.object,

};
