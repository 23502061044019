import React from "react";
import PropTypes from "prop-types";

export function ErrorPanel({ errorMessage, t }) {
    return (
        <div id="credit-card-error" className="credit-card-error d-flex justify-content-center">
            <div id="credit-card-error-card" className="credit-card-error-card d-flex justify-content-between align-items-center p-3">
                <div className="credit-card-error-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
                        <g fill="none" fillRule="evenodd">
                            <g>
                                <g>
                                    <path
                                        fill="#D50000"
                                        d="M24 42c2.47 0 4.79-.472 6.963-1.417 2.173-.944 4.086-2.243 5.74-3.898 1.655-1.654 2.951-3.565 3.89-5.731C41.53 28.787 42 26.469 42 24c0-2.47-.47-4.787-1.407-6.954-.939-2.166-2.238-4.08-3.899-5.74-1.66-1.66-3.577-2.96-5.75-3.899C28.772 6.47 26.451 6 23.981 6c-2.444 0-4.753.47-6.925 1.407-2.173.939-4.09 2.238-5.75 3.899-1.66 1.66-2.96 3.574-3.899 5.74C6.47 19.213 6 21.531 6 24c0 2.47.472 4.787 1.417 6.954.944 2.166 2.243 4.077 3.898 5.731 1.654 1.655 3.568 2.954 5.74 3.898C19.229 41.528 21.544 42 24 42zm6.056-10.37c-.42 0-.797-.155-1.13-.463l-4.907-4.926-4.926 4.926c-.309.308-.692.463-1.149.463-.42 0-.784-.152-1.092-.454-.309-.303-.463-.676-.463-1.12 0-.42.154-.784.463-1.093l4.944-4.944-4.944-4.926c-.309-.309-.463-.68-.463-1.112 0-.444.154-.811.463-1.101.308-.29.673-.436 1.092-.436.457 0 .828.149 1.112.445l4.963 4.944 4.962-4.963c.31-.308.68-.463 1.112-.463.432 0 .799.152 1.101.454.303.303.454.67.454 1.102 0 .42-.154.79-.463 1.111l-4.944 4.945 4.926 4.925c.308.321.463.692.463 1.112 0 .444-.149.817-.445 1.12-.296.302-.673.454-1.13.454z"
                                        transform="translate(-816.000000, -170.000000) translate(816.000000, 170.000000)"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <div className="credit-card-error-message">
                    <h4 className="error-text">{t("error")}</h4>
                    <div className="error-msg">{errorMessage}</div>
                </div>
            </div>
        </div>
    );
}

ErrorPanel.propTypes = {
    errorMessage: PropTypes.string,
    t: PropTypes.func,
};
