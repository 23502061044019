import { fetchContentfulPage } from "./contentfulApi";
import { validateActivationCode, postActivationCode } from "./devicesApi";
import { addSubSkuToCart, removeSubSkuFromCart, addItemToCart, fetchCartDetails, fetchIPAddress, fetchOffersPlan, fetchWCSContent, getSessionId, logUserAction, updateItemInCart } from "./offersApi";
import { fetchUserDetails, placeCheckoutOrder, sendResetPassword, validateToken, doSetUpAuth, doEnrollAuth } from "./checkoutApi";

export {
    addItemToCart,
    fetchCartDetails,
    fetchContentfulPage,
    fetchIPAddress,
    fetchOffersPlan,
    fetchUserDetails,
    fetchWCSContent,
    getSessionId,
    logUserAction,
    placeCheckoutOrder,
    postActivationCode,
    sendResetPassword,
    updateItemInCart,
    validateToken,
    addSubSkuToCart,
    removeSubSkuFromCart,
    doSetUpAuth,
    doEnrollAuth,
    validateActivationCode
};
