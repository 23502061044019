import React from "react";
import PropTypes from "prop-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
export function Disclaimer({ fields }) {
    const { disclaimer, disclaimerTextColor, backgroundColor } = fields || {};
    const styles = {
        color: disclaimerTextColor || "",
        backgroundColor: backgroundColor || "",
    };
    return (
        <div style={styles} className="disclaimer">{disclaimer && documentToReactComponents(disclaimer)}</div>
    );
}
Disclaimer.propTypes = {
    fields: PropTypes.object,
};