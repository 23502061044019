//Contentful CMS Modules
import {
    ActivationPageContent,
    BeachbodyFooter,
    BeachbodyHeader,
    CheckoutPageContent,
    ConfirmationDetails,
    CreateAccountPageContent,
    FlexHeader,
    FlexHeaderItem,
    Footer,
    MyxCheckoutPageContent,
    MyxConfirmationPage,
    MyxCreateAccountPage,
    MyxForgotPasswordPage,
    MyxLoginPage,
    MyxProductDetailScreen,
    MyxResetPasswordPage,
    MyxSessionExpiredPage,
    NavigationMenu,
    NavigationMenuItem,
    OffersFooter,
    OffersHeader,
    OffersPageContent,
    PageHeader,
    StickyContainer,
    StickyFooter,
} from "../module";

import { Content } from "../field";

import { OneColumnTemplate, StaticPageContentTemplate, PreviewRenderer, FieldRendererTemplate, MyxStaticPageContentTemplate } from "../pageTemplates";
import { ResetPassword } from "../module/reset-password-page/reset-password";
import { CreatePassword } from "../module/create-account/create-account-page/create-password";
import { InterstitialUpsellPage } from "../module/interstitial-page";
import { ConfirmationPasswordContent } from "../module/confirmation-page/confirmation-password-content";

export default {
    moduleComponents: {
        activationPageContent: ActivationPageContent,
        checkoutPageContent: CheckoutPageContent,
        commonFooter: BeachbodyFooter,
        commonHeader: BeachbodyHeader,
        confirmationContent: ConfirmationDetails,
        content: Content,
        createAccountContent: CreateAccountPageContent,
        createPassword: CreatePassword,
        flexHeader: FlexHeader,
        flexHeaderItem: FlexHeaderItem,
        footer: Footer,
        header: PageHeader,
        myxCheckoutPageContent: MyxCheckoutPageContent,
        myxConfirmationPage: MyxConfirmationPage,
        myxCreateAccountPage: MyxCreateAccountPage,
        myxForgotPasswordPage: MyxForgotPasswordPage,
        myxLoginPage: MyxLoginPage,
        myxProductDetailScreen: MyxProductDetailScreen,
        myxResetPasswordPage: MyxResetPasswordPage,
        myxSessionExpiredPage: MyxSessionExpiredPage,
        navigationMenu: NavigationMenu,
        navigationMenuItem: NavigationMenuItem,
        offersFooter: OffersFooter,
        offersHeader: OffersHeader,
        offersPageContent: OffersPageContent,
        resetPasswordContent: ResetPassword,
        confirmationPasswordContent: ConfirmationPasswordContent,
        stickyContainer: StickyContainer,
        stickyFooter: StickyFooter,
        interstitialPage: InterstitialUpsellPage,
    },
    pageTemplateComponents: {
        FieldRendererTemplate: FieldRendererTemplate,
        MyxStaticPageContentTemplate: MyxStaticPageContentTemplate,
        OneColumnTemplate: OneColumnTemplate,
        PreviewRenderer: PreviewRenderer,
        StaticPageContentTemplate: StaticPageContentTemplate,
    },
};
