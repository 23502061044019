import { combineReducers } from "redux";
import pageRouter from "../component/page-router/data/page-router-reducer";
import offers from "../module/offers-page/data/offers-page-reducer";
import checkout from "../module/checkout-page/data/checkout-page-reducer";
import activation from "../module/activation-page/data/activation-page-reducer";

const rootReducer = combineReducers({
    pageRouter,
    offers,
    checkout,
    activation
});
export default rootReducer;
