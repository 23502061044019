import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FieldRenderer } from "../contentful/component-renderer";
import contentfulConfig from "../contentful/config";
import { getInitialBackgroundPositionY, getCSSBackgroundImagePositionY } from '../helper/util';

export const MyxStaticPageContentTemplate = (props) => {
    const { content = null } = props;
    const [initialBackgroundPosition, setInitialBackgroundPosition] = useState(getInitialBackgroundPositionY());
    const { t } = useTranslation();
    const { commonHeader, pageUrl, pageContents = null, commonFooter } = content ? content.fields : {};
    const isDesktopView = useSelector((state) => state.pageRouter.isDesktopView);

    const backgroundImageDesktopUrl = content && content.fields && content.fields.backgroundImageDesktop ? content.fields.backgroundImageDesktop.fields.file.url : "";
    const backgroundImageMobileUrl = content && content.fields && content.fields.backgroundImageMobile ? content.fields.backgroundImageMobile.fields.file.url : "";
    const backgroundImageUrl = isDesktopView ? backgroundImageDesktopUrl : backgroundImageMobileUrl;
    const styles = {
        backgroundImage: "url(" + backgroundImageUrl + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: `center ${initialBackgroundPosition}`,
        backgroundSize: "100%",
        backgroundColor: "#161619",
        minHeight: "100vh",
    };
    const backgroundImage = document.getElementById(pageUrl);
    
    const updateParallax = () => {
        backgroundImage && (backgroundImage.style.backgroundPositionY = getCSSBackgroundImagePositionY());
    };

    useEffect(() => {
        function watchScrollForParallax() {
            window.addEventListener("scroll", updateParallax);
            document.getElementsByTagName("html")[0].style.backgroundColor = "#161619";
        }
        watchScrollForParallax();    
        setInitialBackgroundPosition(getInitialBackgroundPositionY());
        return () => {
            window.removeEventListener("scroll", updateParallax)
            document.getElementsByTagName("html")[0].style.backgroundColor = "null";
        };
    });

    const pageContentConfiguration = {
        "activatebike": "activationPageContent",
        "activatebike/activation-success": "myxConfirmationPage",
        "activatebike/checkout": "myxCheckoutPageContent",
        "activatebike/create-account": "myxCreateAccountPage",
        "activatebike/edit-email": "myxCreateAccountPage",
        "activatebike/forgot-password": "myxForgotPasswordPage",
        "activatebike/login": "myxLoginPage",
        "activatebike/order-confirmation": "myxConfirmationPage",
        "activatebike/reset-password": "myxResetPasswordPage",
        "activatebike/session-expired": "myxSessionExpiredPage",
    };

    const pageContent = content ? pageContentConfiguration[pageUrl] : "";
    const PageContent = pageContent ? contentfulConfig.moduleComponents[pageContent] : null;

    return (
        <div id={pageUrl || ""} className={`myx-template ${pageUrl}`} style={styles} >
            <FieldRenderer contents={commonHeader} />
            <PageContent t={t} contents={pageContents} commonFooter={commonFooter} isTabletFlow={true} {...props} />
        </div>
    );
};

MyxStaticPageContentTemplate.propTypes = {
    content: PropTypes.object,
    primaryProductCode: PropTypes.string
};
