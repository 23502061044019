import { all, put, takeLatest } from "redux-saga/effects";
import { activationActions } from "./activation-page-action";
import { validateActivationCode, postActivationCode } from "../../../api";

function* activateBike(action) {
    const { payload, callbacks } = action || {};
    const { success, failure } = callbacks || {};
    try {
        let response = yield postActivationCode(payload);
        if (response) {
            success && success();
        } else {
            yield put({
                type: activationActions.SET_DEVICE_ACTIVATION_FAIL,
                payload: { deviceActivationFailed: true },
            });
            failure && failure();
        }
    } catch (error) {
        console.error("Error: ", error);
    }
}

function* verifyActivationCode(action) {
    const { payload, callbacks } = action || {};
    const { success, failure } = callbacks || {};
    const { activationCode } = payload || {};
    try {
        // This call returns an error message or an empty response on success
        let responseHasErrors = yield validateActivationCode(payload);
        if (responseHasErrors) {
            yield put({
                type: activationActions.SET_DEVICE_ACTIVATION_FAIL,
                payload: { deviceActivationFailed: true },
            });
            failure && failure();
        } else {
            yield put({
                type: activationActions.SET_ACTIVATION_CODE,
                payload: { activationCode: activationCode },
            });
            yield put({
                type: activationActions.SET_DEVICE_ACTIVATION_FAIL,
                payload: { deviceActivationFailed: false },
            });
            success && success(activationCode);
        }
    } catch (error) {
        console.error("Error: ", error);
    }
}

function* actionWatcher() {
    yield all([takeLatest(activationActions.ACTIVATE_BIKE, activateBike), takeLatest(activationActions.VERIFY_ACTIVATION_CODE, verifyActivationCode)]);
}
export default function* activationSaga() {
    yield all([actionWatcher()]);
}
