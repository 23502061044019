import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./sticky-footer.scss";
import { useSelector } from "react-redux";
import { FieldRenderer } from "../../../contentful/component-renderer";

export const StickyFooter = ({ data, fieldId }) => {
    const { isDesktopView, onScrollBelowScreenHeight } = useSelector(state => state.pageRouter);
    const [showSticky, setSticky] = useState(false);
    const handleScroll = () => {
        if (window.pageYOffset > onScrollBelowScreenHeight) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <Fragment>
            {!isDesktopView ?
                <div
                    id={`sticky-${fieldId}`}
                    style={{ display: showSticky ? "block" : "none" }}
                    className="sticky-footer">
                    <FieldRenderer contents={data} />
                </div> : null}
        </Fragment>
    );

};
StickyFooter.propTypes = {
    data: PropTypes.object,
    fieldId: PropTypes.string,
};