import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { AnimateSection } from "../../common/animation";

export const CoverImageContentLeft = ({ fields, fieldId, isDesktopView, RichText, FieldRenderer }) => {
    const { textContainer = { fields: {} }, imageTextList, theme, animationClass } = fields;

    const { description = "", mobileDescription = "" } = textContainer.fields;

    const descriptionBox = isDesktopView ? description : mobileDescription || description;
    const customizedDescriptionBox = useMemo(() => {
        return (
            <div className="custom-description-box">
                {descriptionBox && <RichText content={descriptionBox} />}
                {imageTextList ? (
                    <div className="image-text-list">
                        <FieldRenderer contents={imageTextList} inlineTheme="list-item" />{" "}
                    </div>
                ) : null}
            </div>
        );
    });

    return (
        <AnimateSection animationClass={animationClass}>
            {({ containerRef }) => (
                <div id={fieldId} className={`${theme} content-container cover-img-txt-lft-container col-12`} ref={containerRef}>
                    {textContainer ? (
                        <FieldRenderer
                            contents={textContainer}
                            customizedDescriptionBox={
                                theme === "cover-img-content-desktop-left-mobile-bottom" ? isDesktopView ? customizedDescriptionBox : <div></div> : null
                            }
                        />
                    ) : null}
                    {(theme === "cover-img-content-desktop-left-mobile-bottom") & !isDesktopView ? customizedDescriptionBox : null}
                </div>
            )}
        </AnimateSection>
    );
};
CoverImageContentLeft.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    isDesktopView: PropTypes.bool,
    RichText: PropTypes.func,
    FieldRenderer: PropTypes.func,
};
