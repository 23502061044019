import React from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";
import { applyCloudinaryTransformations, customClick } from "../../helper/util";
import { logCustomEvents } from "../../web-analytics";
import { click_get_app_link } from "../../constants/tealium-tags";

export const ImageAsLink = ({ fields, fieldId, isDesktopView, inlineClass = "" }) => {
    const {
        linkData,
        image,
        desktopLinkImage,
        mobileLinkImage,
        cloudinaryDesktopLinkImage,
        cloudinaryMobileLinkImage,
        cloudinaryOptimizationEnabled,
        cloudinaryUrlOptimization,
        id,
    } = fields;
    const imageField = isDesktopView ? (desktopLinkImage ? desktopLinkImage : "") : mobileLinkImage ? mobileLinkImage : "";
    const cloudinaryImageField = isDesktopView
        ? cloudinaryDesktopLinkImage
            ? cloudinaryDesktopLinkImage
            : ""
        : cloudinaryMobileLinkImage
        ? cloudinaryMobileLinkImage
        : "";
    const { urlPath, target, title } = linkData.fields;
    const onClick = (event) => {
        const path = process.env.BROWSER ? window.location.pathname : "";
        if (path.indexOf("/product/") !== -1) {
            const node = event.target.closest(`${"#" + fieldId}`);
            const eventData = customClick(node, fieldId, title, id);
            logCustomEvents(click_get_app_link, "click", eventData, true, "link_tracking");
        }
    };
    return image || imageField || cloudinaryImageField ? (
        <a
            id={fieldId}
            className={inlineClass ? `image-as-link ${inlineClass}` : "image-as-link"}
            href={urlPath}
            target={target}
            title={title}
            onClick={onClick}
        >
            {image ? (
                <FieldRenderer contents={image} />
            ) : (
                <img
                    src={
                        cloudinaryImageField
                            ? applyCloudinaryTransformations(cloudinaryImageField[0]?.url, cloudinaryOptimizationEnabled, cloudinaryUrlOptimization)
                            : imageField?.fields?.file?.url
                    }
                    alt={cloudinaryImageField ? cloudinaryImageField[0]?.public_id : imageField?.fields?.title}
                />
            )}
        </a>
    ) : null;
};

ImageAsLink.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
    isDesktopView: PropTypes.bool,
    inlineClass: PropTypes.string,
};
