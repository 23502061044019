import React from "react";
import PropTypes from "prop-types";
import dompurify from "dompurify";
import { useTranslation } from "react-i18next";
import { OrderSummaryConfirmationCard } from "./../checkout-page/components";
import "./confirmation-details.scss";

export const OrderConfirmation = ({ orderConfirmationDetails }) => {
    const { t } = useTranslation();
    const sanitizer = dompurify.sanitize;
    const { dpSpecificDisclaimers, order } = orderConfirmationDetails || {};

    return (
        orderConfirmationDetails && (
            <div className="order-details">
                <div className="order-details-container">
                    <div className="order-details-inner">
                        <h5>{t("order-summary").toUpperCase()}</h5>
                        <div className="order-number">
                            <span className="number-label">{t("order-number-label")}:</span>
                            <span className="number">{order.id}</span>
                        </div>
                        <OrderSummaryConfirmationCard order={order} t={t} isFreeTrailProduct={undefined} />
                        <div className="order-disclaimers">
                            {dpSpecificDisclaimers &&
                                dpSpecificDisclaimers.map((disclaimer, index) => {
                                    return (
                                        <p
                                            key={index}
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizer(disclaimer.text),
                                            }}
                                        ></p>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

OrderConfirmation.propTypes = {
    orderConfirmationDetails: PropTypes.object,
};
