import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Collapse from "react-bootstrap/Collapse";
import { RichText } from "../../../field";
import { CTAButton, SanitizeHTML } from "../../../common";
import { addItemToCart } from "../../common/util";
import { logProductData } from "../../../web-analytics";

export function MyxProductInfoSection({ contents, formattedPrice, sectionTitle, t, enableOneBodi, defaultSelectedOffer }) {
    const { offersDetails, order } = useSelector((state) => state.offers);
    const { userDetail, digitalData } = useSelector((state) => state.pageRouter);
    const productId = localStorage.getItem("offerId");
    const [selectedOfferId, setSelectedOfferId] = useState(order.commerceItems[0]?.catalogRefId);
    const [open, setOpen] = useState(false);
    const { fields } = contents || {};
    const { productDescription, productMoreInfo, productInformation, productMoreInfoClubUser, productInformationOneBODi } = fields || "";
    const dispatch = useDispatch();

    const decimal = formattedPrice ? formattedPrice.indexOf(".") : -1;
    const getDollars = () => (decimal === -1 ? formattedPrice : formattedPrice.substring(0, decimal));
    const getCents = () => (decimal === -1 ? "" : formattedPrice.substring(decimal, formattedPrice.length));
    const dollars = formattedPrice ? getDollars() : "";
    const cents = formattedPrice ? getCents() : "";
    const tMonth = t("month");
    const isClubUser = userDetail.customerType.toLowerCase().split(",").includes("club");
    const displayValueStamp = userDetail && isClubUser && order.priceInfo.promotions !== "$0.00" && order.priceInfo.discounts !== "0,00 $" ? false : true;
    const onAddItemToCartSuccess = (id) => {
        const index = offersDetails.findIndex((offer) => offer.id === id);
        id && logProductData(productId, id, offersDetails[index], { ...digitalData, guid: userDetail?.guid });
    };
    const onAddItemToCartFailure = () => {
        console.log("Failed to add item to cart.");
    };

    useEffect(() => {
        if (enableOneBodi && order.commerceItems.length < 1) {
            const isDefaultSelectedIdInOffers = offersDetails.some((offer) => offer.id === defaultSelectedOffer);
            const catalogRefIds = isDefaultSelectedIdInOffers ? defaultSelectedOffer : offersDetails[0]?.id;
            const cartDetails = { order, catalogRefIds, onAddItemToCartSuccess, onAddItemToCartFailure };
            setSelectedOfferId(catalogRefIds);
            addItemToCart({ dispatch, cartDetails });
        }
    }, []);

    return enableOneBodi ? (
        <section id="product-info-section" className="product-info-section">
            <h2>{sectionTitle}</h2>
            <div className="membership">
                <div className="offers-title">{isClubUser ? productMoreInfoClubUser : productMoreInfo}</div>
                <div className="membership__highlights">
                    <RichText content={productInformationOneBODi} />
                </div>
                {offersDetails &&
                    offersDetails.map((offer) => {
                        const {
                            stamp,
                            marketingValue,
                            localizedTitle = "",
                            rawPrice,
                            subtitle1 = "",
                            subtitle2 = "",
                            id,
                            dynamicPrice,
                            dynamicMonthlyPrice,
                            baseListPrice,
                        } = offer;
                        const cartDetails = { order, catalogRefIds: id, onAddItemToCartSuccess, onAddItemToCartFailure };
                        const showFreeTrial = rawPrice <= 0;
                        const selected = selectedOfferId === id;
                        const formattedBasePrice = baseListPrice?.replace(/\.00|\,00\s/, " ");
                        return (
                            <Collapse key={id} timeout={300} in={open} className={`${selected ? "d-block h-100" : ""}`}>
                                <div>
                                    {stamp && displayValueStamp && <div className="value-stamp">{stamp.altText}</div>}
                                    <div className="offer-tile">
                                        <button
                                            className={`offer-tile__button ${selected ? "selected" : ""}`}
                                            onClick={() => {
                                                setOpen(!open);
                                                if (id !== selectedOfferId) {
                                                    addItemToCart({ dispatch, cartDetails });
                                                    setSelectedOfferId(id);
                                                }
                                            }}
                                        >
                                            <div className="d-flex justify-content-between">
                                                <span className="offer-tile__duration">{localizedTitle}</span>
                                                {showFreeTrial && (
                                                    <SanitizeHTML
                                                        className={`offer-tile__free-trial ${selected ? "selected" : ""}`}
                                                        html={subtitle2}
                                                        tag="span"
                                                    />
                                                )}
                                            </div>
                                            <div className="offer-tile__container">
                                                <div>
                                                    <p className="d-flex display-inline align-items-baseline">
                                                        {dynamicMonthlyPrice ? (
                                                            <>
                                                                <span className="offer-tile__prorated-price">
                                                                    <s>
                                                                        {t("currency-symbol")}
                                                                        {marketingValue}
                                                                    </s>{" "}
                                                                    {t("currency-symbol")}
                                                                    {dynamicMonthlyPrice}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <span className="offer-tile__term-price">
                                                                {t("currency-symbol")}
                                                                {marketingValue}
                                                            </span>
                                                        )}
                                                        <span className="offer-tile__term-duration">{t("plan-tenure").replace("/", " / ")}</span>
                                                    </p>
                                                    {dynamicPrice ? (
                                                        <p className="offer-tile__subtitle1">
                                                            <s>{formattedBasePrice}</s>
                                                            <span className="offer-tile__dynamic-price">{dynamicPrice}</span>
                                                            {t("myx-offer-proration-subtitle")}
                                                        </p>
                                                    ) : (
                                                        <p className="offer-tile__subtitle1">
                                                            <SanitizeHTML html={subtitle1} tag="span" />
                                                        </p>
                                                    )}
                                                </div>
                                                {!showFreeTrial && <SanitizeHTML className="offer-tile__subtitle2" html={subtitle2} tag="p" />}
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </Collapse>
                        );
                    })}
                {!open && offersDetails && offersDetails.length > 1 && (
                    <CTAButton buttonName={t("see-more-plans")} innerBtnClass="see-more-btn" onButtonClick={() => setOpen(!open)} trackId="see-more-plans" />
                )}
            </div>
        </section>
    ) : (
        <>
            <section id="product-info-section" className="product-info-section">
                <h2>{sectionTitle}</h2>
                <div className="section-description">{productDescription}</div>
                <div className="d-flex align-items-center justify-content-center">
                    <div className="pricing-card d-flex align-items-center justify-content-around">
                        <div className="pricing d-flex align-items-stretch justify-content-center">
                            <div className="d-flex mr-2">
                                <div className="dollars">{dollars}</div>
                                <div className="pricing-extra">
                                    {cents && <div className="cents">{cents}</div>}
                                    <div className="per-month">/ {tMonth}</div>
                                </div>
                            </div>
                        </div>
                        <div className="separator" />
                        <div className="pricing-info d-flex align-items-center justify-content-center">
                            <RichText content={productInformation} />
                        </div>
                    </div>
                </div>
                <div className="additional-info">{productMoreInfo}</div>
            </section>
        </>
    );
}
MyxProductInfoSection.propTypes = {
    contents: PropTypes.object,
    defaultSelectedOffer: PropTypes.string,
    formattedPrice: PropTypes.string,
    sectionTitle: PropTypes.string,
    t: PropTypes.func,
    enableOneBodi: PropTypes.bool,
};
