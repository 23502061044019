/* eslint-disable react/prop-types */
import React, { useEffect, Fragment } from "react";
import { useHistory } from "react-router";
import { withOptimizely, WithOptimizelyProps } from "@optimizely/react-sdk";
import { EventTags, UserAttributes } from "@optimizely/optimizely-sdk";
import "./confirmation-details.scss";
import { FieldRenderer } from "../../contentful/component-renderer";
import { useDispatch, useSelector } from "react-redux";
import { PopupEmitter, TRIGGER_POPUP } from "../../common/popup-emitter";
import { pageRouterActions } from "../../component/page-router/data/page-router-action";
import { checkoutActions } from "../checkout-page/data/checkout-page-action";
import { clearCookie, getCookie, getSiteLocale, convertParamsToObject } from "../../helper/util";
import Script from "react-inline-script";
import { OrderConfirmationDetailsData } from "../../types/common/commerce";
import { BEACHBODY_ON_DEMAND, DEFAULT_PRODUCT_CODE } from "../../constants/urls";
import { logCustomEvents } from "../../web-analytics";
import { coachoptinselection } from "../../constants/tealium-tags";
import { COACH_OPT_IN_BUTTON, COACH_OPT_OUT_BUTTON } from "../create-account/components/constants";
import { OrderConfirmation } from "./confirmation-block";

interface ConfirmationDetailProps extends WithOptimizelyProps {
    // eslint-disable-next-line no-unused-vars
    t: (x: string) => string;
    fields: {
        orderConfirmationStatus: unknown;
        thankYouMessage: {
            fields;
        };
        showSurveyMonkey: unknown;
        existingUserGetStartedUrl: unknown;
        surveyMonkey: { fields };
    };
    contents: {
        fields;
    };
}

function ConfirmationDetailsComponent({ t, ...props }: ConfirmationDetailProps) {
    const dispatch = useDispatch();
    const history = useHistory();
    let { fields } = props || { content: { fields: {} } };
    const {
        content: {
            basePageCSS,
            fields: { pageContents },
        },
    } = props || { content: { fields: {} } };
    const confirmationPageId = sessionStorage.getItem("confirmationPageId");
    const { optimizely } = props;
    if (!fields) {
        fields = props.contents.fields;
    }
    const { userDetail, isDesktopView, featureFlagContent } = useSelector((state) => state.pageRouter);
    const { checkoutTwoColumnsLayout: checkoutTwoColumnsLayoutFeatureFlag }: { checkoutTwoColumnsLayout: boolean } = featureFlagContent && featureFlagContent;
    const { sessionId }: { sessionId: string } = useSelector((state) => state.offers);
    const { orderConfirmationStatus, thankYouMessage, showSurveyMonkey, existingUserGetStartedUrl, surveyMonkey } = fields;
    const {
        fields: { thankYouMessage: thankyouMessage, button, buttonLabel, buttonColor, buttonTextColor },
    } = thankYouMessage || { fields: {} };
    const orderConfirmationDetails: OrderConfirmationDetailsData = useSelector((state) => state.checkout.orderConfirmationDetails);
    const { hasWarnings, warningMessages } = orderConfirmationDetails || { order: { id: null } };
    const {
        fields: { surveyMonkeyId, surveyMonkeyIframeHeight },
    } = surveyMonkey || { fields: {} };
    const { disableLeadwheel } = useSelector((state) => state.checkout);
    const getButtonContent = () => {
        const buttonDetails = { ...button };
        userDetail && userDetail.isNewUser ? buttonDetails : (buttonDetails["fields"]["redirectUrl"] = existingUserGetStartedUrl);
        return buttonDetails;
    };
    const siteLocale = getSiteLocale();
    const envName = window.harmonyEnvironment;
    const twoColumnsLayoutFeatureEnabled = checkoutTwoColumnsLayoutFeatureFlag && optimizely.isFeatureEnabled("bb_com__checkout_two_column_layout");
    const { enableFusion } = useSelector((state) => state.checkout);

    const logUserEvents = (optInVal, buttonText) => {
        const eventData = {
            coach_opt_in: optInVal,
            link_label: buttonText,
            coach_id: "",
            coach_email: "",
        };
        logCustomEvents(coachoptinselection, coachoptinselection, eventData, true);
    };
    const updateIdentity = (_fieldId, buttonText) => {
        const optInButton = _fieldId.indexOf(COACH_OPT_IN_BUTTON) !== -1;
        const optOutButton = _fieldId.indexOf(COACH_OPT_OUT_BUTTON) !== -1;
        logUserEvents((optInButton && "Yes") || (optOutButton && "No"), buttonText);
        console.log("Update Identity Call");
        optInButton && assignUserToLeadwheel();
    };

    const assignToLeadwheelSuccess = () => {
        history.push("coach-confirmed");
        sessionStorage.setItem("confirmationPageId", localStorage.getItem("royaltyCode"));
    };

    const assignToLeadwheelFailure = (response) => {
        const message = response.result.message;
        console.error("Error: ", message);
    };

    const assignUserToLeadwheel = () => {
        const guid = orderConfirmationDetails?.order?.profile?.guid;
        const lastOrderId = orderConfirmationDetails?.order?.id;
        dispatch({
            type: checkoutActions.ASSIGN_TO_LEADWHEEL,
            payload: { guid, lastOrderId },
            callbacks: { success: assignToLeadwheelSuccess, failure: assignToLeadwheelFailure },
        });
    };

    const redirectUserToBOD = () => {
        const ctaButton = getContent().fields?.contents?.find((content) => {
            return content.sys.contentType.sys.id === "ctaButton";
        });
        const { redirectUrl = BEACHBODY_ON_DEMAND } = ctaButton.fields || {};
        if (confirmationPageId === DEFAULT_PRODUCT_CODE) {
            userDetail && userDetail.isNewUser ? (window.location.href = BEACHBODY_ON_DEMAND) : (window.location.href = redirectUrl);
        }
    };

    const getContent = (id?) => {
        const result = pageContents?.fields?.content?.find((content) => {
            return content.fields.id === (id || confirmationPageId);
        });
        return result ? result : id ? null : getContent("default");
    };

    useEffect(() => {
        window.scroll(0, 0);
        if (orderConfirmationDetails) {
            const rawPrice = orderConfirmationDetails?.order?.commerceItems[0].priceInfo?.rawTotalPrice;
            const offersPageID = orderConfirmationDetails?.order?.offersPageId;
            const priceInCents = rawPrice * 100;
            const productID = orderConfirmationDetails?.order?.commerceItems[0].productId;
            const productSKU = orderConfirmationDetails?.order?.commerceItems[0].catalogRefId;

            // Optimizely - Track Event - Purchase Conversion
            if (twoColumnsLayoutFeatureEnabled) {
                const currentEventTags: EventTags = { offersPageID, productID, productSKU, revenue: priceInCents };
                const queryParams = getCookie("query");
                const utmObject = convertParamsToObject(queryParams, "utm_");
                let utmParams = "";
                const utmObjectArray = Object.keys(utmObject);
                utmObjectArray.map((obj, index) => {
                    const lastIndex = index + 1 === utmObjectArray.length;
                    utmParams += `${obj}=${utmObject[obj]}${lastIndex ? "" : "&"}`;
                });
                const currentUserAttributes: UserAttributes = {
                    mobile: !isDesktopView,
                    locale: siteLocale,
                    environment: envName,
                    offersPageID,
                    productID,
                    productSKU,
                    utmParams,
                    ...utmObject,
                };

                optimizely.track("bb_com_two_columns_layout_purchase_conversion", sessionId, currentUserAttributes, currentEventTags);
            }

            dispatch({ type: pageRouterActions.SESSION_LOGOUT, payload: {} });
            sessionStorage.removeItem("sessionId");
            localStorage.removeItem("offerId");
            localStorage.removeItem("productCode");
            localStorage.removeItem("landingPage");
            sessionStorage.removeItem("oid");
            sessionStorage.removeItem("refRepId");
            sessionStorage.removeItem("upsell_page");
            sessionStorage.removeItem("upsell_location");
            clearCookie(["webSourceCode", "userData"]);
            document.cookie = "webSourceCode=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";

            if (hasWarnings && !enableFusion) {
                const { errorCode } = warningMessages && warningMessages[0];
                const message =
                    errorCode === "UPGRADE_FAILED" ? t("upgrade-failed-message") : errorCode === "CREATE_FAILED" ? t("create-failed-message") : null;
                message
                    ? PopupEmitter.emit(TRIGGER_POPUP, {
                          closeButton: false,
                          showPopup: true,
                          modalId: "confirmation:its-us",
                          modalHeader: t("its-us"),
                          customizedContent: <p>{message}</p>,
                          footer: true,
                          modalSize: "sm",
                          modalClass: "its-not-you-modal",
                          cancelBtnName: "OK",
                      })
                    : null;
            }
        }
        // Optimizely - Track Event - Page View: Order Confirmation Page
        if (twoColumnsLayoutFeatureEnabled) {
            optimizely.track("bb_com_page_view_order_confirmation_page");
        }
    }, []);
    return (
        <Fragment>
            {
                <div className="confirmation-page">
                    <div className="confirmation-details">
                        {orderConfirmationStatus && (
                            <div className="confirmation-block">
                                <FieldRenderer contents={orderConfirmationStatus} />
                            </div>
                        )}
                        {thankYouMessage && (
                            <div className="thankyou-message-block">
                                <p className="thankyou-message">{thankyouMessage}</p>
                                <div className="get-started-button">
                                    <h2 className="button-label">{buttonLabel}</h2>
                                    {button && <FieldRenderer contents={getButtonContent()} />}
                                    <style>
                                        {` .get-started-button .button{
                                            background-color: ${buttonColor};
                                            color: ${buttonTextColor};
                                        }
                                        .get-started-button .button:hover{
                                            color:#fff;
                                        }`}
                                    </style>
                                </div>
                            </div>
                        )}
                        {((confirmationPageId === "COACH_UPSELL_CONFIRMATION" && !disableLeadwheel) || confirmationPageId !== "COACH_UPSELL_CONFIRMATION") && (
                            <section className="confirmation-details">
                                <style>{basePageCSS}</style>
                                <FieldRenderer
                                    contents={getContent()}
                                    onButtonClick={confirmationPageId === "COACH_UPSELL_CONFIRMATION" ? updateIdentity : redirectUserToBOD}
                                    tealiumId={confirmationPageId === "COACH_UPSELL_CONFIRMATION" ? coachoptinselection : null}
                                />
                            </section>
                        )}
                        {showSurveyMonkey && surveyMonkey && (
                            <div className="survey-monkey-container" style={{ height: `${surveyMonkeyIframeHeight}px` }}>
                                <Script id="smcx-sdk">
                                    {((t, e, s, n) => {
                                        let o, a, c;
                                        (t.SMCX = t.SMCX || []),
                                            e.getElementById(n) ||
                                                ((o = e.getElementsByTagName(s)),
                                                (a = o[o.length - 1]),
                                                (c = e.createElement(s)),
                                                (c.type = "text/javascript"),
                                                (c.async = !0),
                                                (c.id = n),
                                                (c.src = `https://widget.surveymonkey.com/collect/website/js/${surveyMonkeyId}.js`),
                                                a.parentNode.insertBefore(c, a));
                                    })(window, document, "script", "smcx-sdk")}
                                </Script>
                            </div>
                        )}
                        {orderConfirmationDetails && <OrderConfirmation orderConfirmationDetails={orderConfirmationDetails} />}
                    </div>
                </div>
            }
        </Fragment>
    );
}

export const ConfirmationDetails = withOptimizely(ConfirmationDetailsComponent);
