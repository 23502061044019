import React from "react";
import PropTypes from "prop-types";
import dompurify from "dompurify";
import ErrorIcon from "./ErrorIcon";

export const ActivateBikeError = ({ errorTitle, errorDescription, errorButtonLabel, onSubmit }) => {
    const sanitizer = dompurify.sanitize;
    return (
        <div className="error-panel">
            <div className="d-flex align-items-center justify-content-center">
                <ErrorIcon />
            </div>
            <h2>{errorTitle}</h2>
            <p
                className="error-description"
                dangerouslySetInnerHTML={{
                    __html: sanitizer(errorDescription),
                }}
            ></p>
            {errorButtonLabel && (
                <form onSubmit={onSubmit}>
                    <div className="d-flex align-items-center justify-content-center">
                        <input type="submit" value={errorButtonLabel} className="error-button" />
                    </div>
                </form>
            )}
        </div>
    );
};

ActivateBikeError.propTypes = {
    errorTitle: PropTypes.string,
    errorDescription: PropTypes.string,
    errorButtonLabel: PropTypes.string,
    onSubmit: PropTypes.func,
};
