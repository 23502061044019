import { CommerceItem } from "./commerce-item";
import { ZIPDetails } from "../checkout/ZIP-code";
import { checkBoxNames } from "../../module/create-account/components/constants";
import { setOffersPageId } from "../../helper/util";
export class CartDetails {
    constructor(cartDetails = {}) {
        this.commerceItems = cartDetails.commerceItems ? cartDetails.commerceItems.map((product) => new CommerceItem(product)) : [] || [];
        this.id = cartDetails.id ? cartDetails.id : "";
        this.totalCommerceItemCountWithFraction = cartDetails.totalCommerceItemCountWithFraction ? cartDetails.totalCommerceItemCountWithFraction : "";
        this.priceInfo = cartDetails.priceInfo ? cartDetails.priceInfo : "";
        this.removedItems = cartDetails.removedItems ? cartDetails.removedItems : "";
        this.freeCoachBSKAttributes = cartDetails.freeCoachBSKAttributes || null;
        this.includeFreeCoachBSK = cartDetails.includeFreeCoachBSK || true;
        this.includeFreeMembershipFakeSku = cartDetails.includeFreeMembershipFakeSku || false;
        this.customerFreeMembershipSku = cartDetails.customerFreeMembershipSku ? cartDetails.customerFreeMembershipSku : {};
        this.pageComponent = cartDetails.pageComponent || null;
        this.taxPriceInfo = cartDetails.taxPriceInfo || {};
        this.addressDetails = getUserAddress(cartDetails);
        this.validateUserConfirmations = getFieldsToValidate(cartDetails.pageComponent) || [];
        this.shippingTaxCode = cartDetails?.shippings?.[0]?.taxCodes || "";
        this.profile = cartDetails.profile ? cartDetails.profile : {};
        this.mandatoryEntitlements = cartDetails?.mandatoryEntitlements || [];
    }
}
const getFieldsToValidate = (pageComponent) => {
    const validateFields = [];
    const { checkboxes } = pageComponent || {};
    checkboxes &&
        checkboxes.map((checkbox) => {
            const { id } = checkbox || {};
            checkBoxNames[id] && validateFields.push(checkBoxNames[id]);
        });
    return validateFields;
};

const getUserAddress = (cartDetails) => {
    const { commerceItems, shippings } = cartDetails || {};
    let address = new ZIPDetails();
    const { primaryProductCode = "", offersPageId } = cartDetails || {};
    //NOTE: HERE PRIMARY PRODUCT CODE IS THE ROYALTY CODE
    primaryProductCode && localStorage.setItem("productCode", primaryProductCode);
    primaryProductCode && localStorage.setItem("royaltyCode", primaryProductCode);
    setOffersPageId(offersPageId);
    if (commerceItems && commerceItems.length) {
        const isShippable = commerceItems && commerceItems.length && commerceItems["0"].isShippable;
        const shippinAddress = shippings && shippings.length ? shippings["0"].shippingAddress : {};
        address = new ZIPDetails({
            ...shippinAddress,
            billShipSame: !isShippable,
            billing: !isShippable,
            zipcode: shippinAddress && shippinAddress.postalCode,
        });
    }
    return address;
};
