import { defaultLanguages } from "../../helper/language-supported";

const defaultLanguageOfCountry = () => {
    const appLanguage = localStorage.getItem("i18nextLng");
    const countryCode = appLanguage?.split("_")[1]?.toLowerCase();
    return defaultLanguages[countryCode];
};

export class CheckoutForm {
    constructor(data = {}) {
        this.firstName = data.firstName ? data.firstName.trim() : "";
        this.lastName = data.lastName ? data.lastName.trim() : "";
        this.email = data.email ? data.email.trim() : "";
        this.password = data.password || "";
        this.shippingAddressLine1 = data.shippingAddressLine1 || "";
        this.shippingCity = data.shippingCity || "";
        this.shippingState = data.shippingState || "";
        this.shippingPostalCode = data.shippingPostalCode || "";
        this.shippingCountry = data.shippingCountry || "";
        this.ccNumber = data.ccNumber || "";
        this.ccMonth = data.ccMonth || "";
        this.ccYear = data.ccYear ? `20${data.ccYear}` : "";
        this.ccExpDate = data.ccExpDate || "";
        this.ccCVV = data.ccCVV ? data.ccCVV.trim() : "";
        this.billingAddressLine1 = data.billingAddressLine1 || "";
        this.billingCity = data.billingCity || "";
        this.billingState = data.billingState || "";
        this.billingPostalCode = data.billingPostalCode ? data.billingPostalCode.trim() : "";
        this.billingCountry = data.billingCountry || "";
        this.ccType = data.ccType || "";
        this.shippingAddressLine2 = data.shippingAddressLine2 || "";
        this.score = data.score || "";
        this.webSourceCode = data.webSourceCode || "";
        this.coachId = data.coachId || "";
        this.bypassCaptcha = data.bypassCaptcha || false;
        this["g-recaptcha-response"] = data["g-recaptcha-response"] || "";
        this.billingFirstName = data.billingFirstName || "";
        this.billingLastName = data.billingLastName || "";
        this.paymentMethod = data.paymentMethod || "";
        this.paymentMethodAccount = data.paymentMethodAccount || "";
        this.paymentMethodToken = data.paymentMethodToken || "";
        if (data.coachConsent !== undefined) {
            this.coachConsent = data.coachConsent;
        }
        if (data.companyConsent !== undefined) {
            this.companyConsent = data.companyConsent;
        }
        if (data.type === "stepUpResponse") {
            this.transactionId = data.TransactionId || "";
            this.mdParam = data.mdParam || "";
        }
        this.leadWheelLanguage = sessionStorage.getItem("lwOrderLang") || defaultLanguageOfCountry();
        this.coachConflict = data.coachConflict || "";
    }
}
