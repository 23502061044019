import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { FieldRenderer } from "../../contentful/component-renderer";
import { backgroundImageStyleCreator, getImageSource } from "../../helper/util";

export const ImageBox = ({ fields, fieldId, isDesktopView, inlineTheme = "", inlineStyle = null, inlineOuterStyle = {}, onClick }) => {
    const {
        title,
        desktopImage,
        mobileImage,
        cloudinaryDesktopImage,
        cloudinaryMobileImage,
        cloudinaryOptimizationEnabled,
        cloudinaryUrlOptimization,
        svgCanvas = null,
        imageType,
        imageTitle,
        video,
        overlayImages = null,
    } = fields;
    const [renderVideo, IsRenderVideo] = useState(false);
    const desktopImageSrc = getImageSource(cloudinaryDesktopImage, desktopImage, cloudinaryOptimizationEnabled, cloudinaryUrlOptimization);
    const mobileImageSrc = getImageSource(cloudinaryMobileImage, mobileImage, cloudinaryOptimizationEnabled, cloudinaryUrlOptimization);
    const imageSrc = isDesktopView ? desktopImageSrc : mobileImageSrc;

    const ImageElement = (
        <Fragment>
            <img
                id={fieldId}
                alt={imageTitle}
                src={imageSrc}
                style={inlineStyle}
                onClick={(event) => {
                    video && IsRenderVideo(true);
                    onClick && onClick(event);
                }}
            />
            {overlayImages && <FieldRenderer contents={overlayImages} />}
        </Fragment>
    );

    const getBackgroundImageCSS = () => {
        const backgroundImage = { fields: { desktopImage, mobileImage, cloudinaryDesktopImage, cloudinaryMobileImage } };
        const backgroundImageCSS =
            !process.env.BROWSER && backgroundImage && backgroundImage.fields
                ? backgroundImageStyleCreator(fieldId, backgroundImage, isDesktopView, title, "imageBox")
                : null;
        return backgroundImageCSS;
    };

    const getFigureTag = () => {
        const backgroundImageCSS = getBackgroundImageCSS();
        return (
            <Fragment>
                {backgroundImageCSS ? <style>{backgroundImageCSS}</style> : null}
                <figure id={fieldId} className="figure-box" />
                {overlayImages && <FieldRenderer contents={overlayImages} />}
            </Fragment>
        );
    };

    const getBackgroundImageBox = () => {
        const backgroundImageCSS = getBackgroundImageCSS();
        return (
            <Fragment>
                {backgroundImageCSS ? <style>{backgroundImageCSS}</style> : null}
                <div
                    id={fieldId}
                    className="image-box"
                    aria-label={imageTitle}
                    onClick={() => {
                        video && IsRenderVideo(true);
                    }}
                >
                    {overlayImages && <FieldRenderer contents={overlayImages} />}
                </div>
            </Fragment>
        );
    };

    const getSVGCanvas = () => {
        return svgCanvas ? <div id={fieldId} dangerouslySetInnerHTML={{ __html: svgCanvas }}></div> : null;
    };
    return (
        <Fragment>
            {imageType === "SVG_CANVAS" ? getSVGCanvas() : null}
            {imageSrc ? (
                <Fragment>
                    {imageType === "IMAGE_TAG" ? inlineTheme ? <div className={inlineTheme}>{ImageElement}</div> : ImageElement : null}
                    {imageType === "IMAGE_CONTAINER" && (
                        <div
                            id={fieldId}
                            className="image-container"
                            style={inlineOuterStyle}
                            aria-label={imageTitle}
                            onClick={() => {
                                video && IsRenderVideo(true);
                            }}
                        >
                            <img alt={imageTitle} src={imageSrc} />
                            {overlayImages && <FieldRenderer contents={overlayImages} />}
                        </div>
                    )}
                    {imageType === "BACKGROUND_IMAGE" && getBackgroundImageBox()}
                    {imageType === "FIGURE_TAG" && getFigureTag()}
                    {!process.env.BROWSER && video && <FieldRenderer contents={video} isDesktopView={isDesktopView} />}
                    {renderVideo && (
                        <FieldRenderer
                            contents={video}
                            renderVideo={renderVideo}
                            IsRenderVideo={IsRenderVideo}
                            isDesktopView={isDesktopView}
                            imageId={fieldId}
                            videoData={video}
                        />
                    )}
                </Fragment>
            ) : null}
        </Fragment>
    );
};

ImageBox.propTypes = {
    fields: PropTypes.object,
    isDesktopView: PropTypes.bool,
    fieldId: PropTypes.string,
    inlineTheme: PropTypes.string,
    RichText: PropTypes.func,
    inlineStyle: PropTypes.object,
    inlineOuterStyle: PropTypes.object,
    onClick: PropTypes.func,
};
