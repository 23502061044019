import React from "react";
import PropTypes from "prop-types";
import { SelectDropDown } from "../../../common";

export const MyxFreeCoachDropdown = ({ showCoachInfo, coachYesAnswer, coachNoAnswer, setCoachAnswer, coachAnswer, coachDropdownQuestion, setDropdownText }) => {
    const options = [
        {
            value: "leadwheel",
            text: coachYesAnswer,
        },
        {
            value: "",
            text: coachNoAnswer,
        },
    ];
    const handleChange = (name, value, text) => {
        setCoachAnswer(value);
        setDropdownText(text);
    };

    return (
        <div>
            <SelectDropDown
                name="freeCoach"
                id="freeCoach"
                dValue={coachAnswer}
                options={options}
                displayKey="value"
                displayName="text"
                onChange={handleChange}
                floatLabel={coachDropdownQuestion}
                disableElement={showCoachInfo}
            />
        </div>
    );
};
MyxFreeCoachDropdown.propTypes = {
    fields: PropTypes.object,
    props: PropTypes.object,
    showCoachInfo: PropTypes.bool,
    coachYesAnswer: PropTypes.string,
    coachNoAnswer: PropTypes.string,
    setCoachAnswer: PropTypes.func,
    coachAnswer: PropTypes.string,
    coachDropdownQuestion: PropTypes.string,
    setDropdownText: PropTypes.func,
};
