import React from "react";
import PropTypes from "prop-types";
import "./social-media-icon.scss";

export const SocialMediaIcons = ({ fields, fieldId }) => {
    const {
        showFBLike = false,
        showTwitter = false,
        showYoutube = false,
        fbLikeLink = "",
        fbLikeWidth = "",
        fbLikeLayout = "standard",
        fbLikeSize = "small",
        fbLikeShare = "false",
        fbColorScheme = "dark",
        twitterLink = "",
        youTubeChannel = "",
        youTubeChannelId = "",
        youTubeLayout = "default",
        youTubeCount = "default",
        youTubeTheme = "default",
    } = fields;
    return (
        <div id={fieldId} className="social-icons">
            <ul>
                {showFBLike && (
                    <li className="bdd-fb-like-button">
                        <div
                            className="fb-like"
                            data-href={fbLikeLink}
                            data-width={fbLikeWidth}
                            data-layout={fbLikeLayout}
                            data-action="like"
                            data-colorscheme={fbColorScheme}
                            data-size={fbLikeSize}
                            data-share={fbLikeShare}
                        ></div>
                    </li>
                )}
                {showTwitter && (
                    <li className="bdd-twitter-button">
                        <a
                            className="twitter-share-button"
                            href="https://twitter.com/intent/tweet"
                            data-url={twitterLink}
                        >
                            Tweet
                        </a>
                    </li>
                )}
                {showYoutube && (
                    <li className="bdd-youtube-button">
                        <div
                            className="g-ytsubscribe"
                            data-channelid={youTubeChannelId}
                            channel={youTubeChannel}
                            data-layout={youTubeLayout}
                            data-count={youTubeCount}
                            data-theme={youTubeTheme}
                        ></div>
                    </li>
                )}
            </ul>
        </div>
    );
};
SocialMediaIcons.propTypes = {
    fields: PropTypes.object,
    fieldId: PropTypes.string,
};
